import AuthLayout from '~components/layout/AuthLayout';
import DashboardLayout from '~components/layout/DashboardLayout';
import NoMenuLayout from '~components/layout/NoMenuLayout';
import PrivatePageLayout from '~components/layout/PrivatePageLayout';
import ProjectPageLayout from '~components/layout/ProjectPageLayout';
import PublicPageLayout from '~components/layout/PublicPageLayout';

const RoutesPageLayout = {
  AUTH_LAYOUT: AuthLayout,
  DASHBOARD_LAYOUT: DashboardLayout,
  PRIVATE_PAGE_LAYOUT: PrivatePageLayout,
  NO_MENU_LAYOUT: NoMenuLayout,
  PROJECT_PAGE_LAYOUT: ProjectPageLayout,
  PUBLIC_PAGE_LAYOUT: PublicPageLayout,
};

export default RoutesPageLayout;
