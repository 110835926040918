import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';

const selectCollectionTokenGroups = (state: RootState): Array<CollectionTokenGroupType> =>
  state.collectionTokenGroups.order.map((item: string) => state.collectionTokenGroups.groups[item]);

const selectTokenGroupsSupply = createDraftSafeSelector(
  selectCollectionTokenGroups,
  (groups: Array<CollectionTokenGroupType>) => {
    return groups.reduce((acc, group) => {
      return acc + group.totalCount;
    }, 0);
  },
);

export { selectCollectionTokenGroups, selectTokenGroupsSupply };
