import type { RootState } from '~features';
import type TokenBlueprintType from '~types/token/TokenBlueprintType';

const selectTokenBlueprint =
  (blueprintId: string) =>
  (state: RootState): TokenBlueprintType =>
    state.tokenBlueprints.blueprints[blueprintId];

const selectTokenBlueprintStatus =
  (blueprintId: string) =>
  (state: RootState): string =>
    state.tokenBlueprints.status[blueprintId];

export { selectTokenBlueprint, selectTokenBlueprintStatus };
