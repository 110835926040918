import '~components/miscs/ComponentLoader/ComponentLoader.scss';

import { CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import logo from '~images/common/logo/logo.png';

type ComponentLoaderProps = {
  isOpen: boolean;
  title?: string;
};

const ComponentLoader = ({ isOpen, title }: ComponentLoaderProps) => (
  <Modal
    className={'CAKE__component-loader'}
    open={isOpen}
    disableAutoFocus={true}
  >
    <Box className={'CAKE__component-loader__container'}>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Box className={'CAKE__component-loader__image--container'}>
          <CircularProgress
            size={100}
            thickness={1}
            style={{ color: 'white' }}
            disableShrink
          />
          <Box className={'CAKE__component-loader__image--logo'}>
            <img src={logo} />
          </Box>
        </Box>
        <Typography className={'CAKE__component-loader__title'}>{title}</Typography>
      </Stack>
    </Box>
  </Modal>
);

export default ComponentLoader;
