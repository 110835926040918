import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';

import ContractTransactionsUserWallet from '~components/dashboard/ContractTransactionsTable/components/ContactTransactionsUserWallet';
import type { TokenHolder } from '~types/AnalyticsType';

interface Props {
  tokenHolder: TokenHolder;
  chainId?: number;
}

const TokenHolderItem = ({ tokenHolder, chainId }: Props) => {
  return (
    <TableRow
      hover
      key={`${tokenHolder.walletAddress}`}
    >
      <TableCell align="left">
        <ContractTransactionsUserWallet
          address={tokenHolder.walletAddress}
          chainId={chainId}
        />
      </TableCell>
      <TableCell align="left">{tokenHolder.email ?? '--'}</TableCell>
      <TableCell align="left">{tokenHolder.count}</TableCell>
      <TableCell align="left">--</TableCell>
      <TableCell align="left">{moment(tokenHolder.date).format('MM/DD/YYYY HH:mmA')}</TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  );
};

export default TokenHolderItem;
