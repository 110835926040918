import { all, call, put, takeLatest } from 'redux-saga/effects';

import { fetchProtectedAPI } from '~features/utils/api/api.sagas';

import { getPartnerPlugins, getPartnerPluginsFailure, getPartnerPluginsSuccess } from './partner-plugins.slice';

function* fetchPartnerPlugins() {
  try {
    const response = yield call(fetchProtectedAPI, 'plugins');
    const data = yield response.json();

    yield put(
      getPartnerPluginsSuccess(
        data.map((plugin: any) => {
          const temp = {
            ...plugin,
            isEnabled: plugin.is_enabled,
          };
          delete temp.is_enabled;
          return temp;
        }),
      ),
    );
  } catch (error) {
    yield put(getPartnerPluginsFailure(error));
  }
}

export default function* partnerPluginsSaga() {
  yield all([yield takeLatest(getPartnerPlugins.type, fetchPartnerPlugins)]);
}
