import type { RootState } from '~features';

const selectImageGenerations = (state: RootState) => state.imageGenerations.images;

const selectImageGenerationsTotal = (state: RootState) => state.imageGenerations.total;

const selectImageGenerationLoadingState = (state: RootState) => state.imageGenerations.isLoading;

const selectImageGenerationsCurrentPage = (state: RootState) => state.imageGenerations.currentPage;

const selectImageGenerationsCurrentCount = (state: RootState) => state.imageGenerations.images.length;

const selectImageGenerationIsFavourite = (state: RootState) => state.imageGenerations.isFavourite;

export {
  selectImageGenerationIsFavourite,
  selectImageGenerationLoadingState,
  selectImageGenerations,
  selectImageGenerationsCurrentCount,
  selectImageGenerationsCurrentPage,
  selectImageGenerationsTotal,
};
