import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type ImageGeneration from '~types/ImageGeneration';

interface ImageGenerationsState {
  images: ImageGeneration[];
  total: number;
  isLoading: boolean;
  isError: boolean;
  currentPage: number;
}

const initialState = {
  images: [],
  total: 0,
  isFavourite: false,
  isLoading: false,
  isError: false,
  currentPage: 0,
};

const ImageGenerationsState = createSlice({
  name: 'image-generations',
  initialState,
  reducers: {
    setImageGenerationsPage(state, action: PayloadAction<{ page: number }>) {
      state.currentPage = action.payload.page;
    },
    getImageGenerations(state) {
      state.isLoading = true;
      state.isError = false;
      state.isFavourite = false;
      state.images = [];
    },
    getFavouriteImageGenerations(state) {
      state.isLoading = true;
      state.isError = false;
      state.isFavourite = true;
      state.images = [];
    },
    getNextImageGenerationsPage(state) {
      state.isLoading = true;
      state.isError = false;
    },
    getImageGenerationsAborted(state) {
      state.isLoading = false;
      state.isError = false;
    },
    getImageGenerationsSuccess(state, action: PayloadAction<{ images: ImageGeneration[]; total: number }>) {
      const { images, total } = action.payload;

      state.images = images;
      if (!state.isFavourite) {
        state.total = total;
      }
      state.isLoading = false;
    },
    getImageGenerationsError(state) {
      state.isLoading = false;
      state.isError = true;
    },
    resetImageGenerations(state) {
      state.images = [];
      state.total = 0;
      state.isLoading = false;
      state.isError = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateImageGeneration(_state, _action: PayloadAction<{ image: ImageGeneration }>) {},
    updateImageGenerationSuccess(state, action: PayloadAction<{ image: ImageGeneration }>) {
      const { image } = action.payload;
      const index = state.images.findIndex((i) => i._id === image._id);
      if (index !== -1) {
        state.images[index] = image;
      }
    },
  },
});

export const {
  getFavouriteImageGenerations,
  setImageGenerationsPage,
  getNextImageGenerationsPage,
  getImageGenerations,
  getImageGenerationsAborted,
  getImageGenerationsSuccess,
  getImageGenerationsError,
  resetImageGenerations,
  updateImageGeneration,
  updateImageGenerationSuccess,
} = ImageGenerationsState.actions;
export default ImageGenerationsState.reducer;
