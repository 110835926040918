import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ProjectCollectionType } from '~constants/project/project-constants';
import {
  selectCollectionIsNextPage,
  selectCollectionPageLoaded,
  selectCollectionTokensMap,
  selectCollectionTokensSelection,
  selectCollectionTraitsAggregated,
} from '~features/collection-items/collection-items.selectors';
import {
  addCollectionItem,
  addRealTimeCollectionItem,
  clearCollection,
  deleteAllCollectionItems,
  deleteAllCollectionItemsError,
  deleteCollectionItem,
  deleteCollectionItemError,
  deleteCollectionItemSuccess,
  deleteSelectedCollectionItems,
  deleteSelectedCollectionItemsError,
  deleteSelectedCollectionItemsSuccess,
  getCollection,
  getCollectionNextPage,
  getCollectionSuccess,
  getCollectionTraits,
  setCollectionTraits,
  setIsLoading,
  setIsLoadingComplete,
  unselectCollectionItem,
  updateCollectionItem,
  updateCollectionItemError,
  updateCollectionItemSuccess,
  updateCollectionItemWithUpdateIdLocally,
  updateCollectionTraits,
  updateStartingIndex,
  updateStartingIndexComplete,
} from '~features/collection-items/collection-items.slice';
import { updateItemImageSuccess, uploadItemFinished } from '~features/collection-upload/collection-upload.slice';
import { callDeployedContractStateSaga } from '~features/contract-config/contract-config.sagas';
import { selectDeployedContractState } from '~features/contract-config/contract-config.selectors';
import { callDeployedContractState } from '~features/contract-config/contract-config.slice';
import { updateProjectSaga } from '~features/project-config/project-config.sagas';
import {
  selectProjectCollectionId,
  selectProjectConfig,
  selectProjectId,
} from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import { upsertTokenUpdates } from '~features/token-group-updates/token-group-updates.slice';
import { deleteProtectedAPI, fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import type CollectionItemType from '~types/CollectionItemType';
import type AggregateTraitType from '~types/token/AggregateTraitType';
import type UploadTokenValidationType from '~types/token/UploadTokenValidationType';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';
import getProjectNetwork from '~utils/projects/get-network';

import { COLLECTION_PAGE_SIZE } from './collection-items.consts';

function* getCollectionSaga(action: PayloadAction<{ page: string }>) {
  const collectionId = yield select(selectProjectCollectionId);
  const page = action.payload?.page || 0;
  if (!collectionId) {
    return;
  }
  const params = {
    'collection-id': [collectionId],
    page: [page],
    limit: [COLLECTION_PAGE_SIZE],
  };
  let res: Response = yield call(fetchProtectedAPI, 'tokens', params);
  const data = yield res.json();

  const tokensOrder = [];
  const tokenUpdates = [];
  const tokens = data.reduce((acc, curr: CollectionItemType) => {
    if (curr.activeUpdate) {
      tokenUpdates.push(curr.activeUpdate);
    }
    tokensOrder.push(curr._id);
    return {
      ...acc,
      [curr._id?.toString()]: curr,
    };
  }, {});

  yield put(upsertTokenUpdates(tokenUpdates as Partial<CollectionItemType>));
  yield put(getCollectionSuccess({ tokens, tokensOrder }));
  yield put(getCollectionTraits());

  res = yield call(fetchProtectedAPI, `collection/${collectionId}`);
  const totalTokens: { _id: string; count: number } = yield res.json();
}

function* getCollectionTraitsSaga() {
  const collectionId = yield select(selectProjectCollectionId);

  try {
    if (collectionId) {
      const res: Response = yield call(fetchProtectedAPI, `collection/${collectionId}/traits`);
      const data = yield res.json();
      yield put(
        setCollectionTraits(
          data.map((item: any) => ({ trait_type: item._id.trait, value: item._id.value, count: item.count })),
        ),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateCollectionTraitsSaga(action: PayloadAction<CollectionItemType>) {
  const currentAggregatedCollectionTraits = yield select(selectCollectionTraitsAggregated);
  const token = action.payload;
  let newTokenTraits = [];

  if (token.attributes) {
    newTokenTraits = token.attributes.map((attribute: any) => {
      const aggreggateTrait = currentAggregatedCollectionTraits.filter(
        (item: AggregateTraitType) => item.trait_type === attribute.trait_type && item.value === attribute.value,
      );
      return { ...aggreggateTrait, count: aggreggateTrait.count + 1 };
    });
  }

  setCollectionTraits([...currentAggregatedCollectionTraits, ...newTokenTraits]);
}

function* getCollectionNextPageSaga() {
  const page = yield select(selectCollectionPageLoaded);
  const isNext = yield select(selectCollectionIsNextPage);
  if (isNext) {
    yield put(getCollection({ page: page }));
  }
}

function* deleteCollectionItemSaga(action: PayloadAction<CollectionItemType>) {
  const token = action.payload;

  if (!token._id) {
    return;
  }

  yield put(setIsLoading());
  const projectId = yield select(selectProjectId);
  const res: Response = yield call(deleteProtectedAPI, `project/${projectId}/tokens/${token._id}`);
  const data = yield res.json();

  if (data?._id !== token._id) {
    yield put(unselectCollectionItem(token));
    yield put(deleteCollectionItemError());
  } else {
    yield put(deleteCollectionItemSuccess(token));
    yield call(updateProjectProvenanceHashSaga);
  }
  yield put(setIsLoadingComplete());
  return data;
}

function* deleteSelectedCollectionItemsSaga() {
  const selection = yield select(selectCollectionTokensSelection);

  const ids: string = Object.keys(selection).reduce((acc, curr) => (acc.length ? `${acc},${curr}` : curr));

  if (!ids || ids.length === 0) {
    return;
  }

  const projectId = yield select(selectProjectId);
  const res: Response = yield call(deleteProtectedAPI, `project/${projectId}/tokens/${ids}`);

  if (res.status === 200) {
    yield put(deleteSelectedCollectionItemsSuccess(selection));
  } else {
    yield put(deleteSelectedCollectionItemsError());
  }
}

function* deleteAllCollectionItemsSaga() {
  const projectId = yield select(selectProjectId);
  const res: Response = yield call(deleteProtectedAPI, `project/${projectId}/tokens`);

  if (res.status === 200) {
    yield put(clearCollection());
  } else {
    yield put(deleteAllCollectionItemsError());
  }
}

function* updateCollectionItemSaga(action: PayloadAction<CollectionItemType>) {
  const token: CollectionItemType = action.payload;
  delete token.refresh;

  const tokenToUpdate = { ...token };
  delete tokenToUpdate.activeUpdate;

  if (!token._id) {
    yield put(updateCollectionItemError());
    return;
  }

  const projectId = yield select(selectProjectId);
  const res: Response = yield call(putProtectedAPI, `project/${projectId}/tokens/${token._id}`, tokenToUpdate);
  const data = yield res.json();

  if (!data) {
    yield put(updateCollectionItemError());
  } else {
    yield put(updateCollectionItemSuccess(token));
    yield put(updateCollectionTraits(token));
  }
  return data;
}

function* handleUploadItemFinished(action: PayloadAction<UploadTokenValidationType>) {
  const { name, metadata, isMetadataValid } = action.payload;
  const tokens = yield select(selectCollectionTokensMap);

  const existingToken = tokens[name];
  const newToken = {
    name,
    isMetadataValid,
    ...metadata,
  };

  if (existingToken) {
    yield put(updateCollectionItem(newToken));
  } else {
    yield put(addCollectionItem(newToken));
  }
}

function* handleItemImageUpdateSaga(action: PayloadAction<CollectionItemType>) {
  const token = action.payload;
  yield put(updateCollectionItemSuccess(token));
}

function* updateCollectionItemWithUpdateIdLocallySaga(
  action: PayloadAction<{ tokenId: string; tokenMetadata: CollectionItemType; updateId: string }>,
) {
  const { updateId, tokenId, tokenMetadata } = action.payload;
  const tokens = yield select(selectCollectionTokensMap);
  const token = tokens[tokenId];
  const updatedToken = {
    ...token,
    ...tokenMetadata,
  };
  if (updatedToken.activeUpdate?.updateId === updateId) {
    delete updatedToken.activeUpdate;
  }

  yield put(updateCollectionItemSuccess(updatedToken));
}

function* updateProjectProvenanceHashSaga() {
  const projectConfig = yield select(selectProjectConfig);
  const collectionId = projectConfig.collectionId;
  const network = getCurrentContractNetwork(projectConfig.contract);

  try {
    const path = `collection/${collectionId}/provenance`;
    const res: Response = yield call(fetchProtectedAPI, path);

    if (res.status === 200) {
      const data: { provenanceHash: string } = yield res.json();
      yield call(
        updateProjectSaga,
        updateProjectConfig({
          ...projectConfig,
          contract: {
            ...projectConfig.contract,
            provenanceHash: data.provenanceHash,
          },
        }),
      ),
        // update redux state to reflect new update to contarct state
        yield put(callDeployedContractState({ projectId: projectConfig._id, network }));
    } else {
      throw new Error('Error updating provenance hash');
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateStartingIndexSaga() {
  const projectConfig = yield select(selectProjectConfig);
  const collectionId = projectConfig.collectionId;
  const network = getProjectNetwork(projectConfig);

  try {
    // get new starting index from LibDiamond.ProjectConfig
    yield call(callDeployedContractStateSaga, callDeployedContractState({ projectId: projectConfig._id, network }));
    const contractData = yield select(selectDeployedContractState);
    const startingIndex = contractData.find((item: any) => item.name === 'startingIndex').value;

    const path = `collection/${collectionId}/offset`;
    yield call(putProtectedAPI, path, {});

    yield put(updateStartingIndexComplete());
    yield put(clearCollection());
    yield put(getCollection());
    return startingIndex;
  } catch (e) {
    console.log(e);
  }
}

function* addRealTimeCollectionItemSaga(action: PayloadAction<CollectionItemType>) {
  const projectConfig = yield select(selectProjectConfig);

  if (projectConfig !== ProjectCollectionType.CUSTOM) {
    yield put(addCollectionItem(action.payload));
  }
}

export default function* campaignSaga(): Iterator<any> {
  yield all([
    yield takeLatest(addRealTimeCollectionItem.type, addRealTimeCollectionItemSaga),
    yield takeLatest(getCollection.type, getCollectionSaga),
    yield takeLatest(getCollectionNextPage.type, getCollectionNextPageSaga),
    yield takeLatest(updateCollectionItem.type, updateCollectionItemSaga),
    yield takeEvery(deleteCollectionItem.type, deleteCollectionItemSaga),
    yield takeLatest(deleteSelectedCollectionItems.type, deleteSelectedCollectionItemsSaga),
    yield takeLatest(deleteAllCollectionItems.type, deleteAllCollectionItemsSaga),
    yield takeEvery(uploadItemFinished.type, handleUploadItemFinished),
    yield takeLatest(updateItemImageSuccess.type, handleItemImageUpdateSaga),
    yield takeLatest(getCollectionTraits.type, getCollectionTraitsSaga),
    yield takeLatest(updateCollectionTraits.type, updateCollectionTraitsSaga),
    yield takeEvery(updateCollectionItemWithUpdateIdLocally.type, updateCollectionItemWithUpdateIdLocallySaga),
    yield takeLatest(updateStartingIndex.type, updateStartingIndexSaga),
  ]);
}

export { deleteAllCollectionItemsSaga, updateProjectProvenanceHashSaga, updateStartingIndexSaga };
