import './ImageGenerationGrid.scss';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import ItemLoader from '~components/miscs/ItemLoader';
import { updateImageGeneration } from '~features/image-generations/image-generations.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import type ImageGeneration from '~types/ImageGeneration';

interface ImageGenerationItemProps {
  item: ImageGeneration;
  onClick: (item: ImageGeneration) => void;
}

const ImageGenerationItem = ({ item, onClick }: ImageGenerationItemProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>(item.isFavourite || false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
  }, []);

  const toggleFavourite = () => {
    setIsFavourite((prev) => {
      dispatch(updateImageGeneration({ image: { ...item, isFavourite: !prev } }));
      return !prev;
    });
  };
  return (
    <Grid
      position={'relative'}
      item
      xs={4}
      minHeight={'250px'}
    >
      {!isError ? (
        <>
          <img
            onClick={() => onClick(item)}
            onLoad={() => setIsLoading(false)}
            onError={() => {
              setIsError(true);
              setIsLoading(false);
            }}
            className={classNames({
              'CAKE__image-generations-grid__item': true,
              'CAKE__image-generations-grid__item--loading': isLoading,
            })}
            src={item.storedImages[0]}
            alt={`image-generation-${item._id}`}
          />
          <Box className={'CAKE__image-generations-grid__item__dim'} />
          {!isLoading && (
            <Box className={'CAKE__image-generations-grid__item__favourite'}>
              <IconButton onClick={toggleFavourite}>
                {isFavourite ? (
                  <FavoriteIcon sx={{ color: 'aliceblue' }} />
                ) : (
                  <FavoriteBorderIcon sx={{ color: 'aliceblue' }} />
                )}
              </IconButton>
              <IconButton
                href={item.storedImages[0]}
                target="_blank"
              >
                <GetAppIcon sx={{ color: 'aliceblue' }} />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Box className={'CAKE__image-generations-grid__item__error'}>
          <img
            src="/images/empty-state-icon.png"
            alt="empty-state"
          />
          <Typography className={'CAKE__image-generations-grid__item__error__label'}>Missing image</Typography>
        </Box>
      )}
      {isLoading && (
        <Box
          position={'absolute'}
          left={16}
          right={0}
          top={16}
          bottom={0}
        >
          <ItemLoader />
        </Box>
      )}
    </Grid>
  );
};

export default ImageGenerationItem;
