import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';

import { CopyIcon } from '~icons/common';

interface CopyValueIconProps {
  value?: string | number;
}

const CopyValueIcon = ({ value }: CopyValueIconProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleCopyWalletAddress = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
    navigator.clipboard.writeText(String(value)).catch((e) => console.log(e));
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied"
        >
          <IconButton
            disableRipple={true}
            disableFocusRipple={true}
            aria-label="copy-address"
            onClick={handleCopyWalletAddress}
          >
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CopyValueIcon;
