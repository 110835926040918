import './CollectionTokenGroups.scss';

import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import { selectCollectionTokenGroups } from '~features/collection-token-groups/collection-token-groups.selectors';
import { getTokenGroups } from '~features/collection-token-groups/collection-token-groups.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { selectIsTokenGroupEditView } from '~features/token-group-updates/token-group-updates.selectors';
import {
  hideTokenGroupEditView,
  showTokenGroupEditView,
} from '~features/token-group-updates/token-group-updates.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';

// eslint-disable-next-line absolutely-import/import
import CollectionAddTokenGroupModal from '../CollectionAddTokenGroupModal';
// eslint-disable-next-line absolutely-import/import
import CollectionTokenGroupDetails from '../CollectionTokenGroupDetails';
// eslint-disable-next-line absolutely-import/import
import CollectionTokenGroupModal from '../CollectionTokenGroupModal';

type CollectionTokenGroupsProps = {
  toggleAdvancedSettings: () => void;
};

const CollectionTokenGroups = ({ toggleAdvancedSettings }: CollectionTokenGroupsProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [editTokenGroupType, setEditTokenGroupType] = useState<string>(null);
  const [isAddTokenGroupModal, setIsAddTokenGroupModal] = useState<boolean>(false);

  const projectConfig = useAppSelector(selectProjectConfig);
  const dispatch = useAppDispatch();
  const tokenGroups: CollectionTokenGroupType[] = useAppSelector(selectCollectionTokenGroups);
  const tokenGroupsCount = useRef<number>(tokenGroups.length);
  const isTokenGroupModal: boolean = useAppSelector(selectIsTokenGroupEditView);

  useEffect(() => {
    dispatch(getTokenGroups());
  }, []);

  useEffect(() => {
    if (
      (tokenGroups.length > tokenGroupsCount.current && tokenGroupsCount.current > 0) ||
      (tokenGroups.length === 1 && tokenGroupsCount.current === 0)
    ) {
      setExpanded(tokenGroups[tokenGroups.length - 1].name);
    }
    if (tokenGroups.length > tokenGroupsCount.current) {
      tokenGroupsCount.current = tokenGroups.length;
    }
  }, [tokenGroups]);

  const handleChange = (accordion: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? accordion : false);
  };

  const onEditTokenGroupOpen = (tokenGroup: CollectionTokenGroupType, type: string) => {
    setEditTokenGroupType(type);
    dispatch(showTokenGroupEditView(tokenGroup));
  };

  const onEditTokenGroupClose = () => {
    setEditTokenGroupType(null);
    dispatch(hideTokenGroupEditView());
  };

  return (
    <div className={'CAKE__collection-token-groups'}>
      {/* {projectConfig?.collectionType !== ProjectCollectionType.GROUP && (
        <Stack
          direction={'column'}
          gap={1}
        >
          <Typography>
            Token Groups are not available for collections generated using the{' '}
            <span
              onClick={() => toggleAdvancedSettings()}
              className={'CAKE__collection-artworks-card__link'}
            >
              Advanced Settings
            </span>{' '}
            feature.
          </Typography>
          <Typography>
            You may activate Token Groups and overwrite your existing collection data by{' '}
            <span
              onClick={() => setIsAddTokenGroupModal(true)}
              className={'CAKE__collection-artworks-card__link'}
            >
              adding a new Token Group
            </span>{' '}
            and saving your changes.
          </Typography>
        </Stack>
      )} */}
      <Box className={'CAKE__collection-token-groups__accordion-container'}>
        <Scrollbars autoHide>
          {tokenGroups.map((item) => (
            <CollectionTokenGroupDetails
              key={item.name}
              editTokenGroup={onEditTokenGroupOpen}
              tokenGroup={item}
            />
          ))}
          {/* {tokenGroups.map((item) => (
            <Accordion
              key={item.name}
              expanded={expanded === item.name}
              onChange={handleChange(item.name)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>{item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CollectionTokenGroupDetails
                  editTokenGroup={onEditTokenGroupOpen}
                  tokenGroup={item}
                />
              </AccordionDetails>
            </Accordion>
          ))} */}
        </Scrollbars>
      </Box>
      <Box className={'CAKE__collection-token-groups__controls-container'}>
        {isTokenGroupModal && (
          <CollectionTokenGroupModal
            open={isTokenGroupModal}
            onClose={onEditTokenGroupClose}
            editType={editTokenGroupType}
          />
        )}
        {isAddTokenGroupModal && (
          <CollectionAddTokenGroupModal
            open={isAddTokenGroupModal}
            onClose={() => setIsAddTokenGroupModal(false)}
          />
        )}
        {/* <Stack
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Tooltip
            title={getCollectionUploadToolTipWarning(projectConfig)}
            arrow
          >
            <InfoOutlinedIcon
              fontSize="small"
              color={'primary'}
            />
          </Tooltip>
          <Button
            size={'small'}
            onClick={() => toggleAdvancedSettings()}
            disabled={getProjectNetworkLayer(projectConfig) === NetworkLayer.BASE}
          >
            Advanced settings
          </Button>
        </Stack> */}
        {/* <Stack
          spacing={2}
          direction={'row'}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setIsAddTokenGroupModal(true)}
          >
            Add Group
          </Button>
        </Stack> */}
      </Box>
    </div>
  );
};

export default CollectionTokenGroups;
