import '~components/inputs/AutocompleteSingleSelect/AutocompleteSingleSelect.scss';

import { Autocomplete, Chip, Stack, TextField } from '@mui/material/';

type AutocompleteSingleSelectProps = {
  label: string;
  value: string;
  options: Array<string>;
  onUpdate: (value: string) => void;
  errorMsg: string;
  width?: string;
};

const AutocompleteSingleSelect = ({
  label,
  value,
  options,
  errorMsg,
  onUpdate,
  width,
}: AutocompleteSingleSelectProps) => {
  const handleSelectedValue = (values: Array<string>) => {
    onUpdate(values[values.length - 1]); // get last selected value
  };

  return (
    <Stack
      sx={{ width: width || '100%', minWidth: '200px' }}
      gap={1}
    >
      <Autocomplete
        multiple
        fullWidth
        freeSolo
        value={value ? [value] : []}
        renderTags={(value: readonly string[], getTagProps) =>
          [value[0]].map((option: string, index: number) => (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(event, newValue) => {
          handleSelectedValue(newValue);
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
          />
        )}
      />
      {!value && errorMsg && <span className={'CAKE__auto-complete-single-select__error-message'}>{errorMsg}</span>}
    </Stack>
  );
};

export default AutocompleteSingleSelect;
