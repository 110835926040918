import { Box, Slider, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';

import type { TypographyWeight } from '~types/ProjectType';

type FontPreviewProps = {
  fontFamily: string;
  fontWeight?: TypographyWeight;
  isLoading: boolean;
};

const FontPreview = ({ fontFamily, fontWeight, isLoading }: FontPreviewProps) => {
  const [fontSize, setFontSize] = useState(20);

  return (
    <Stack>
      <Typography variant="h6">Preview</Typography>

      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={0.5}
        >
          <Typography
            variant="body3"
            fontWeight={600}
          >
            Font Size
          </Typography>
          <Typography variant="body3">{fontSize}px</Typography>
        </Stack>

        <Slider
          size="small"
          value={fontSize}
          onChange={(_, value) => setFontSize(value as number)}
          valueLabelDisplay="auto"
          style={{ width: 300 }}
        />
      </Stack>

      <Stack>
        <Box
          className={classNames('CAKE__typography-settings-card__preview', {
            'CAKE__typography-settings-card__preview--loading': isLoading,
          })}
          fontSize={fontSize}
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          height={150}
          overflow={'scroll'}
          contentEditable
          suppressContentEditableWarning={true}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </Box>
      </Stack>

      <Typography
        marginTop={2}
        variant="body3"
        fontStyle={'italic'}
      >
        Edit the text to see how your selection will appear.
      </Typography>
    </Stack>
  );
};

export default FontPreview;
