import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';

const selectValidateInvitationIsLoading = (state: RootState) => state.userInvitation.isLoading;

const selectValidateInvitationError = (state: RootState) => state.userInvitation.error;

const selectIsInvitationValidated = (state: RootState) => state.userInvitation.isValidated;

const selectIsSignupInProgress = (state: RootState) => state.userInvitation.isSignupInProgress;

const selectIsSignupFinished = (state: RootState) => state.userInvitation.isSignupFinished;

const selectSignupError = (state: RootState) => state.userInvitation.signupError;

const selectInvitationId = (state: RootState) => state.userInvitation.invitationId;

const selectIsInvitationInvalid = createDraftSafeSelector(selectValidateInvitationError, (error) => !!error);

const selectIsInvitationValid = createDraftSafeSelector(
  selectIsInvitationValidated,
  selectValidateInvitationError,
  (isValidated, error) => isValidated && error === null,
);

export {
  selectInvitationId,
  selectIsInvitationInvalid,
  selectIsInvitationValid,
  selectIsSignupFinished,
  selectIsSignupInProgress,
  selectSignupError,
  selectValidateInvitationError,
  selectValidateInvitationIsLoading,
};
