import TokenGroupAction from '~constants/tokens/TokenGroupAction';

const getTokenGroupModalTitle = (editType: string) => {
  switch (editType) {
    case TokenGroupAction.EDIT:
      return 'Edit Token Group';
    case TokenGroupAction.EDIT_SCHEDULED_UPDATE:
      return 'Edit scheduled update';
    case TokenGroupAction.SCHEDULE_UPDATE:
      return 'Schedule update';
    default:
      return '__UNKNOWN_EDIT_TYPE__';
  }
};

const getSaveButtonLabel = (editType: string) => {
  switch (editType) {
    case TokenGroupAction.SCHEDULE_UPDATE:
      return 'Schedule update';
    case TokenGroupAction.EDIT:
    case TokenGroupAction.EDIT_SCHEDULED_UPDATE:
      return 'Save';
    default:
      return 'Save';
  }
};

const isScheduleField = (editType: string) => {
  switch (editType) {
    case TokenGroupAction.SCHEDULE_UPDATE:
    case TokenGroupAction.EDIT_SCHEDULED_UPDATE:
      return true;
    case TokenGroupAction.EDIT:
      return false;
    default:
      return false;
  }
};

export { getSaveButtonLabel, getTokenGroupModalTitle, isScheduleField };
