import { Grid } from '@mui/material';

import AutocompleteMultiSelect from '~components/inputs/AutocompleteMultiSelect';
import V2TextInput from '~components/inputs/V2TextInput';
import { selectProjectSlugs, selectProjectTags } from '~features/project-content/project-content.selectors';
import useAppSelector from '~hooks/useAppSelector';
import type { ContentItemType } from '~types/ContentType';

type ContentSettingsProps = {
  contentItem: ContentItemType;
  onUpdateSlug: (value: string) => void;
  onUpdateTags: (value: string) => void;
};

const ContentSettings = ({ contentItem, onUpdateSlug, onUpdateTags }: ContentSettingsProps) => {
  const projectSlugs = useAppSelector(selectProjectSlugs);
  const projectTags = useAppSelector(selectProjectTags);

  const slugValidator = (slug: string): boolean => {
    const matchingSlug = projectSlugs.filter((item) => item.slug === slug);
    if (slug.match(/[!$%^&*()_+=[\]{};'"\\|,.<>/]+/)) return false; // slug contains unallowed characters
    if (matchingSlug.length === 0) return true; // no matching content slug in project
    else if (matchingSlug[0].id === contentItem._id) return true; // matching slug is the current content item slug
    else return false;
  };

  return (
    <>
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ margin: '15px 0' }}
      >
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          <V2TextInput
            label={'Slug'}
            value={contentItem.slug}
            onUpdate={onUpdateSlug}
            validator={slugValidator}
            showSuccessIcon={true}
            errorText={'Slug must be unique and not contain special characters'}
          />
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ margin: '10px 0' }}
      >
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          <AutocompleteMultiSelect
            title={'Tags'}
            options={projectTags}
            selected={contentItem.tags}
            onUpdateSelectedInputs={onUpdateTags}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContentSettings;
