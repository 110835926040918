/**
 * Get an email with the user part obscured
 * (ie a*******@gmail.com instead of ajhaupt7@gmail.com).
 */
const getRedactedEmail = (email: string): string => {
  const [name, domain] = email.split('@');

  return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
};

export { getRedactedEmail };
