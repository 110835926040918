import { ContractNetwork } from '~constants/networks/networks';
import type { ProjectType } from '~types/ProjectType';

const getProjectNetwork = (project: ProjectType) => {
  if (project.contract?.livenet.contractAddress) {
    const networkIndex = Object.values(ContractNetwork).indexOf(project.contract.livenet.network);
    return Object.values(ContractNetwork)[networkIndex] as ContractNetwork;
  } else if (project.contract?.testnet.contractAddress) {
    const networkIndex = Object.values(ContractNetwork).indexOf(project.contract.testnet.network);
    return Object.values(ContractNetwork)[networkIndex] as ContractNetwork;
  }
  return ContractNetwork.UNKNOWN;
};

const getFormattedProjectNetwork = (network: ContractNetwork) => {
  return network
    .split(/(?=[A-Z])/)
    .map((itm: string) => itm.charAt(0).toUpperCase() + itm.substring(1))
    .join(' ');
};

export default getProjectNetwork;

export { getFormattedProjectNetwork };
