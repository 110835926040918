import '~components/dashboard/ImageGenerationsCard/ImageGenerationsCard.scss';

import { Tab, Tabs, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import classNames from 'classnames';
import { useState } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import ImageGenerationGrid from '~components/image-generation/ImageGenerationGrid';
import DappButton from '~components/miscs/DappButton';
import ProjectHeader from '~components/projects/ProjectHeader';
import { selectImageGenerationsTotal } from '~features/image-generations/image-generations.selectors';
import useAppSelector from '~hooks/useAppSelector';

const ImageGenerationsCard = () => {
  const imageSubmissionTotal = useAppSelector(selectImageGenerationsTotal);
  const [tabValue, setTabValue] = useState(0);

  return (
    <DashboardCard
      className={classNames({
        'CAKE__project-community-view': true,
      })}
    >
      <ProjectHeader subtitle="Community">
        <DappButton />
      </ProjectHeader>
      <Box className={'CAKE__project-community-view__controls-container'}>
        <Box>
          <Typography variant={'h6'}>Co-Creations</Typography>
          <Typography variant={'body2'}>
            These are images submitted by your holders that may be used in future token updates
          </Typography>
        </Box>
        <Stack className={'CAKE__project-community-view__tabs'}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => setTabValue(value)}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{ fontSize: '14px' }}
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab
              label={`All (${new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 1,
                notation: 'compact',
                compactDisplay: 'short',
              }).format(imageSubmissionTotal)})`}
              value={0}
              sx={{ padding: '2px', alignItems: 'end' }}
              disableFocusRipple={true}
              disableRipple={true}
            />
            <Tab
              label="Favorites"
              value={1}
              sx={{ padding: '2px' }}
              disableFocusRipple={true}
              disableRipple={true}
            />
          </Tabs>
        </Stack>
      </Box>
      <Box className={'CAKE__project-community-view__content'}>
        {tabValue === 0 && <ImageGenerationGrid isFavourite={false} />}
        {tabValue === 1 && <ImageGenerationGrid isFavourite />}
      </Box>
    </DashboardCard>
  );
};

export default ImageGenerationsCard;
