import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, race, select, take } from 'redux-saga/effects';

import { selectProjectConfig, selectProjectId } from '~features/project-config/project-config.selectors';
import { setProjectConfig } from '~features/project-config/project-config.slice';
import { getProjectDropsManager } from '~features/project-drops-manager/project-drops-manager.slice';
import { STOP_WATCHER_TASK } from '~features/project-shopify/project-shopify.consts';
import { getShopDomain } from '~features/project-shopify/project-shopify.helpers';
import { selectProjectShopifyShop } from '~features/project-shopify/project-shopify.selectors';
import {
  connectProjectShopifyAccount,
  getProjectShopifySessionAndConnect,
  setProjectShopify,
  setProjectShopifyAuthenticating,
  setProjectShopifyError,
} from '~features/project-shopify/project-shopify.slice';
import { postProtectedAPI } from '~features/utils/api/api.sagas';
import { takeLatestWithMonitoring } from '~features/utils/saga-utils';
import type { ProjectType } from '~types/ProjectType';
import type { Shopify, ShopifySession } from '~types/ShopifyType';
import { wait } from '~utils/wait';

function* connectShopifyAccount(action: PayloadAction<{ accessToken: string }>): Iterator<any> {
  const { accessToken } = action.payload;
  const shop = yield select(selectProjectShopifyShop);

  try {
    const projectId: string = yield select(selectProjectId);
    const res: Response = yield call(postProtectedAPI, `project/${projectId}/shopify/connect`, {
      accessToken,
      domain: getShopDomain(shop),
    });

    const data: Shopify = yield res.json();
    const currentConfig: ProjectType = yield select(selectProjectConfig);

    yield put(setProjectShopify(data));
    yield put(getProjectDropsManager({ id: projectId, force: true }));
    yield put(setProjectConfig({ ...currentConfig, shopify: data._id }));
  } catch (e) {
    console.error(e);
    yield put(setProjectShopifyError('There was an error connecting your shopify account.'));
  }
}

function* onAuthError(): Iterator<any> {
  yield put(setProjectShopifyError('Could not authenticate with Shopify.'));
}

function* checkAuthWindow({ shopifyAuthWindow }: { shopifyAuthWindow: Window }): Iterator<any> {
  while (true) {
    if (shopifyAuthWindow.closed) {
      const shop = yield select(selectProjectShopifyShop);
      const res: Response = yield call(fetch, `/api/shopify/auth/sessions?shop=${shop}`);
      const currentSession: ShopifySession = yield res.json();

      if (currentSession) {
        yield put(connectProjectShopifyAccount({ accessToken: currentSession.accessToken }));
      } else {
        yield call(onAuthError);
      }

      yield put({ type: STOP_WATCHER_TASK });
    } else {
      yield call(wait, 1000);
      yield call(checkAuthWindow, { shopifyAuthWindow });
    }
  }
}

function* getSessionAndConnect(): Iterator<any> {
  const shop = yield select(selectProjectShopifyShop);

  try {
    yield put(setProjectShopifyAuthenticating());

    const res: Response = yield call(fetch, `/api/shopify/auth/sessions?shop=${shop}`);
    const currentSession: ShopifySession = yield res.json();

    if (currentSession) {
      yield put(connectProjectShopifyAccount({ accessToken: currentSession.accessToken }));
    } else {
      const shopifyAuthWindow = window.open(
        `/api/shopify/auth?shop=${getShopDomain(shop)}`,
        'shopify-login',
        'position=top,width=750,height=500,menubar=no,toolbar=no',
      );

      yield call(checkAuthWindowPollTask, { shopifyAuthWindow });
    }
  } catch (e) {
    console.error(e);
    yield call(onAuthError);
  }
}

function* checkAuthWindowPollTask({ shopifyAuthWindow }: { shopifyAuthWindow: Window }) {
  yield race([call(checkAuthWindow, { shopifyAuthWindow }), take(STOP_WATCHER_TASK)]);
}

export default function* campaignSaga(): Iterator<any> {
  yield all([
    yield takeLatestWithMonitoring(connectProjectShopifyAccount.type, connectShopifyAccount),
    yield takeLatestWithMonitoring(getProjectShopifySessionAndConnect.type, getSessionAndConnect),
  ]);
}
