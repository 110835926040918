type ValidateInvitationType = {
  isValid?: boolean;
  isUser?: boolean;
};

export enum InvitationErrors {
  Validation = 'validation error',
  UserAlreadyExists = 'User already exists',
}

export type { ValidateInvitationType };
