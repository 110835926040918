import DashboardCard from '~components/dashboard/DashboardCard';
import DappButton from '~components/miscs/DappButton';
import ProjectHeader from '~components/projects/ProjectHeader';

const ProjectNameCard = () => {
  return (
    <DashboardCard>
      <ProjectHeader
        subtitle="Site Builder"
        isEditable={true}
      >
        <DappButton />
      </ProjectHeader>
    </DashboardCard>
  );
};

export default ProjectNameCard;
