import '~components/inputs/ColorPicker/ColorPicker.scss';

import { Box, Popover, Stack, Typography } from '@mui/material';
import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
// import { BlockPicker } from 'react-color';
import BlockPicker, { useColorPicker } from 'react-best-gradient-color-picker';

type ColorPickerProps = {
  color: string;
  onUpdate?: (currentColor: string) => void;
  title?: string;
};

function getContrastYIQ(hexcolor: string, alpha: number) {
  const r = parseInt(hexcolor.slice(0, 2), 16);
  const g = parseInt(hexcolor.slice(2, 4), 16);
  const b = parseInt(hexcolor.slice(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 || alpha < 0.4 ? 'black' : 'white';
}

const ColorPicker = ({ color, onUpdate, title }: ColorPickerProps) => {
  const [currentColor, setCurrentColor] = useState<string>(color || '#FFFFFF');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { valueToHex, getGradientObject, rgbaArr } = useColorPicker(currentColor, setCurrentColor);

  useEffect(() => {
    setCurrentColor(color || '#FFFFFF');
  }, [color]);

  const onChange = (color: string) => {
    setCurrentColor(color);
  };

  const onOpen = (e: MouseEvent) => {
    setAnchorEl(isOpen ? null : e.currentTarget);
    setIsOpen(!isOpen);
  };

  const onClosePicker = () => {
    setAnchorEl(null);
    setIsOpen(false);
    if (onUpdate && color !== currentColor) {
      onUpdate(currentColor);
    }
  };
  const hexColor = valueToHex(currentColor);
  const alpha = rgbaArr[3];
  const textColor = getContrastYIQ(hexColor.slice(1), alpha);
  const gradient = getGradientObject();

  return (
    <>
      <Box
        className={'CAKE__color-picker__swatch'}
        sx={{
          background: currentColor,
        }}
        onClick={onOpen}
      >
        {title && (
          <Typography
            color={textColor}
            variant="body2"
          >
            {title}
          </Typography>
        )}
        <Stack mt={1}>
          {!gradient.isGradient && (
            <Typography
              color={textColor}
              variant="body3"
            >
              {currentColor}
            </Typography>
          )}
          {gradient.isGradient && (
            <>
              {gradient.colors.map((gradientColor, index) => (
                <Typography
                  variant="body3"
                  color={textColor}
                  key={gradientColor}
                >
                  {index > 0 && <>→</>} {gradientColor.value}
                </Typography>
              ))}
            </>
          )}
        </Stack>
      </Box>

      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClosePicker}
        sx={{ top: 8 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box padding={2}>
          <BlockPicker
            hidePresets
            value={currentColor}
            onChange={onChange}
            width={300}
            height={175}
            padding={12}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ColorPicker;
