import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import type { provider } from 'web3-core';

import { ContractNetwork, ContractNetworkIds, EtherscanURI } from '~constants/networks/networks';
import { sendTransaction } from '~context/WalletContext/Wallet.helpers';
import { updateStartingIndexSaga } from '~features/collection-items/collection-items.sagas';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { addStreamActivity } from '~features/streams/streams.slice';
import { fetchAPI } from '~features/utils/api/api.sagas';
import type { TransactionLog, TransactionParams, TransactionReceipt } from '~types/Web3Type';
import {
  confirmTransactions,
  decodeTransactionLogData,
  getEthTransactionParams,
  getTransactionLogs,
  pollEventLog,
} from '~utils/web3/web3';

import { REQUEST_FULFILLED_EVENT_SIGNATURE, REQUEST_SENT_ABI, REQUEST_SENT_EVENT_SIGNATURE } from './vrf.constants';
import { callVRFError, requestRandomNumber, setVrfTxnProcessing, setVrfTxnStatus } from './vrf.slice';

function* requestRandomNumberSaga(
  action: PayloadAction<{ network: string; provider: provider; walletAddress: string }>,
) {
  const { network, provider, walletAddress } = action.payload;
  const projectConfig = yield select(selectProjectConfig);
  let requestId: string;
  let newStartingIndex: number;

  try {
    yield put(setVrfTxnStatus('Requesting Chainlink VRF Randomness'));
    const path = `public/contract/${projectConfig._id}/${network}?name=getRandomNumber`;
    const res: Response = yield call(fetchAPI, path);
    const data = yield res.json();
    const transactionParams: TransactionParams = yield call(
      getEthTransactionParams,
      provider,
      walletAddress,
      data.diamondAddress,
      data.abi,
      `0x${ContractNetworkIds[network]}` ?? '',
      [true, 1, [projectConfig.contract.totalSupply]],
    );
    yield put(setVrfTxnProcessing(true));
    const txnHash: Array<string> = yield call(sendTransaction, provider, [transactionParams]);
    yield confirmTransactions(provider, txnHash);
    const txnReciept: TransactionReceipt = yield call(getTransactionLogs, txnHash[0], provider);
    const vrfRequestLogData: TransactionLog = txnReciept.logs.find(
      (log: TransactionLog) => log.topics[0] === REQUEST_SENT_EVENT_SIGNATURE,
    );
    yield put(setVrfTxnStatus('Chainlink VRF Randomness Requested'));
    requestId = decodeTransactionLogData(vrfRequestLogData, REQUEST_SENT_ABI, provider).requestId;
    yield call(pollEventLog, data.diamondAddress, [REQUEST_FULFILLED_EVENT_SIGNATURE], provider, requestId);
    yield put(setVrfTxnStatus('Chainlink VRF Randomness Fulfilled'));

    // update collection starting index
    newStartingIndex = yield call(updateStartingIndexSaga);

    yield put(setVrfTxnProcessing(false));
    yield put(
      addNotification({
        message: `New Starting Index has been set to ${newStartingIndex}`,
        severity: 'success',
        duration: 5000,
      }),
    );
    yield put(
      addStreamActivity({
        verb: 'collections',
        object: {
          content: {
            event: 'VRF Starting Index Set',
            description: `Network: ${network}`,
          },
          link: `${
            EtherscanURI[
              Object.keys(ContractNetwork)[Object.values(ContractNetwork).indexOf(network as ContractNetwork)]
            ]
          }/tx/${txnHash[0]}`,
        },
        projectId: projectConfig._id,
      }),
    );
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'We could not create a transaction for this update. Please contact support.',
        severity: 'error',
        duration: 5000,
      }),
    );
    yield put(setVrfTxnProcessing(false));
    yield put(callVRFError(e));
  }

  return;
}

export default function* vrfSaga(): Iterator<any> {
  yield all([yield takeLatest(requestRandomNumber.type, requestRandomNumberSaga)]);
}
