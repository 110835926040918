import '~components/dashboard/ProjectTimeline/ProjectTimeline.scss';

import { Button } from '@mui/material';

type UpdateNotificationProps = {
  executeScroll: () => void;
};

const UpdateNotification = ({ executeScroll }: UpdateNotificationProps) => {
  return (
    <div className={'CAKE__project_timeline__notification-container'}>
      <div className={'CAKE__project_timeline__notification'}>
        <div></div>
        <Button
          className={'CAKE__project_timeline__notification-button'}
          onClick={executeScroll}
          variant={'contained'}
        >
          New Activity Event Added
        </Button>
      </div>
    </div>
  );
};

export default UpdateNotification;
