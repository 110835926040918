import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import WalletConnectModal from '~components/miscs/WalletConnectModal/WalletConnectModal';
import { useWallet } from '~context/WalletContext';
import { selectContractUpdateItems } from '~features/contract-config/contract-config.selectors';
import type { ContractStateType } from '~features/contract-config/contract-config.slice';
import useAppSelector from '~hooks/useAppSelector';

type ContractUpdateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const ContractUpdateDialog = ({ isOpen, onClose, onAccept }: ContractUpdateDialogProps) => {
  const contractItemsToUpdate = useAppSelector(selectContractUpdateItems);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState<boolean>(false);
  const { walletAddress } = useWallet();

  const handleWalletModalToggle = () => {
    setIsWalletModalOpen(!isWalletModalOpen);
  };

  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>{'Update Your Contract'}</DialogTitle>
        {!contractItemsToUpdate.length ? (
          <>
            <DialogContent sx={{ paddingBottom: '10px' }}>
              <DialogContentText>
                Your project is up-to-date with your deployed contract. There are no updates required at this time.{' '}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: '24px' }}>
              <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent sx={{ paddingBottom: '10px' }}>
              <Stack gap={1}>
                <DialogContentText>The following contract variables require updates:</DialogContentText>
                <Stack gap={0.5}>
                  {contractItemsToUpdate.map((item: ContractStateType) => {
                    return (
                      <Typography
                        key={item.name}
                        variant="subtitle2"
                        ml={2}
                        sx={{
                          padding: '4px 8px',
                          backgroundColor: 'aliceblue',
                          width: 'fit-content',
                          borderRadius: '8px',
                        }}
                      >
                        {item.name}
                      </Typography>
                    );
                  })}
                </Stack>
                <DialogContentText>
                  Your contract is currently deployed on-chain. Making updates to your contract requires submitting a
                  transaction to your contract which will cost a small amount of ETH.
                </DialogContentText>
                <DialogContentText>You can update your contract again in the future if needed.</DialogContentText>
                <DialogContentText>
                  Make sure you are using your Admin wallet when making these changes to your contract.
                </DialogContentText>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: '24px' }}>
              {!walletAddress ? (
                <Button
                  variant={'outlined'}
                  onClick={handleWalletModalToggle}
                >
                  Connect Wallet
                </Button>
              ) : (
                <Button
                  variant={'contained'}
                  onClick={handleAccept}
                  autoFocus
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <WalletConnectModal
        isOpen={isWalletModalOpen}
        onClose={() => setIsWalletModalOpen(false)}
      />
    </div>
  );
};

export default ContractUpdateDialog;
