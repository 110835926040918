import type { ContractNetwork } from '~constants/networks/networks';
import type { ProjectCollectionType } from '~constants/project/project-constants';

type AbiDefinitionsType = {
  contract: string;
  privateMint: string;
  publicMint: string;
};

type FAQEntry = {
  answer: string;
  question: string;
};

interface PaperContractIdType {
  [key: string]: string;
}

type PaperType = {
  isActivated: boolean;
  contractId: PaperContractIdType;
  isFixed?: boolean;
};

type ImageBrandType = 'BACKGROUND' | 'PRIMARY_LOGO' | 'SECONDARY_LOGO' | 'TITLE' | 'LOADER';

type AppearanceColorType = {
  primary: string;
  background: string;
  header: string;
  footer: string;
  title: string;
};

type LogoLinksType = {
  primary?: string;
  secondary?: string;
  title?: string;
};

type SocialLinksType = {
  discord?: string;
  instagram?: string;
  opensea?: string;
  twitter?: string;
  etherscan?: string;
  looksrare?: string;
  website?: string;
};

type ProjectGalleryType = {
  banner: string;
  galleryColumns: number;
  gallery: Array<string>;
};

type PaymentShareType = {
  address: string;
  amount: number;
};

type ContractNetworkType = {
  network: ContractNetwork;
  contractAddress: string;
  isVerified: boolean;
  facets: Array<string>;
  deployedAt?: string;
};

type ContractType = {
  abi: string;
  livenet: ContractNetworkType;
  maxMintTotal: number;
  maxMintTxn: number;
  minMint: number;
  merkleroot?: string;
  price?: number; // BasicForm
  provisionedAdmins?: Array<string>;
  paymentSplit: Array<PaymentShareType>;
  royalty: PaymentShareType;
  superAdmin: string;
  symbol?: string; // BasicForm
  testnet: ContractNetworkType;
  totalSupply?: number; // BasicForm
  whitelist?: Array<string>;
  presale?: number;
  publicSale?: number;
  closeDate?: number;
  provenanceHash?: string;
};

type TypographyWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type TypographyItemType = {
  family: string;
  name: string;
  source: TypographySources;
  url?: string;
  weight?: TypographyWeight;
  isLowercase?: boolean;
};

enum TypographySources {
  Google = 'Google',
  Uploaded = 'Uploaded',
  Default = 'Cake Default',
}

enum TypographyKeys {
  Display = 'fontDisplay',
  Body = 'fontBody',
}

type TypographyType = {
  [TypographyKeys.Display]?: TypographyItemType;
  [TypographyKeys.Body]?: TypographyItemType;
};

type WithDarkMode<T> = {
  light?: T;
  dark?: T;
};

enum Templates {
  Chocolate = 'chocolate',
  Vanilla = 'vanilla',
}

type DappSettingsType = {
  backgroundImage?: WithDarkMode<string>;
  colors?: WithDarkMode<AppearanceColorType>;
  customLoader?: WithDarkMode<string>;
  description: string;
  faq?: Array<FAQEntry>;
  favicon?: string;
  images?: ProjectGalleryType;
  logo?: WithDarkMode<LogoLinksType>;
  social?: SocialLinksType;
  template?: Templates;
  typography?: TypographyType;
  mintAgreementId: string;
  mintBtnText?: string;
  mintBtnColor?: string;
  mintBtnTextColor?: string;
};

type FieldError = { message: string; field?: string };

type CollectionType = ProjectCollectionType.CUSTOM | ProjectCollectionType.GROUP;

type ProjectType = {
  _id?: string;
  slug: string;
  collectionId?: string;
  collectionType?: CollectionType;
  contract: ContractType;
  createdAt?: string;
  customerId?: string;
  dapp: DappSettingsType;
  name?: string; // BasicForm
  paper: PaperType; // missing
  shopify?: string;
  status: string; // new field
  active_plugins?: string[];
};

export type {
  AbiDefinitionsType,
  AppearanceColorType,
  ContractNetworkType,
  ContractType,
  DappSettingsType,
  FAQEntry,
  FieldError,
  ImageBrandType,
  LogoLinksType,
  PaperType,
  PaymentShareType,
  ProjectGalleryType,
  ProjectType,
  SocialLinksType,
  TypographyItemType,
  TypographyType,
  TypographyWeight,
};

export { Templates, TypographyKeys, TypographySources };
