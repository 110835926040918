import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type Plugin from '~types/Plugin';

interface PartnerPluginState {
  isLoading: boolean;
  error: string | null;
  plugins: Plugin[];
}

const initialState: PartnerPluginState = {
  isLoading: false,
  error: null,
  plugins: [],
};
const partnerPluginState = createSlice({
  name: 'partner-plugin',
  initialState,
  reducers: {
    getPartnerPlugins(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPartnerPluginsSuccess(state, action: PayloadAction<Plugin[]>) {
      state.plugins = action.payload;
      state.isLoading = false;
    },
    getPartnerPluginsFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.plugins = [];
    },
  },
});

export const { getPartnerPlugins, getPartnerPluginsSuccess, getPartnerPluginsFailure } = partnerPluginState.actions;
export default partnerPluginState.reducer;
