import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import type { provider } from 'web3-core';

import { ContractNetwork, ContractNetworkIds, EtherscanURI } from '~constants/networks/networks';
import { sendTransaction } from '~context/WalletContext/Wallet.helpers';
import type { ContractUpgradeData } from '~features/contract-config/contract-config.slice';
import {
  callContractConfigError,
  callContractUpgrade,
  callContractUpgradeState,
  callDeployedContractState,
  checkDeploymentContractMatch,
  setDeploymentContractState,
  setIsTxnProcessing,
  setMatchStatus,
  setUpdateParamIndexes,
  setUpgradeData,
  setUpgradeIsProcessing,
  setUpgradeStatus,
  updateDeployedContractState,
} from '~features/contract-config/contract-config.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig, selectProjectId } from '~features/project-config/project-config.selectors';
import { addStreamActivity } from '~features/streams/streams.slice';
import { fetchAPI, fetchProtectedAPI, postAPI, postProtectedAPI } from '~features/utils/api/api.sagas';
import type { ContractStateArrayType } from '~types/ContractConfigType';
import type { ProjectType } from '~types/ProjectType';
import type { TransactionConfirmation, TransactionParams } from '~types/Web3Type';
import getDeployedContracts from '~utils/projects/deployed-contracts';
import { confirmTransactions, getEthTransactionParams } from '~utils/web3/web3';

import {
  checkContractMatch,
  getNotificationMessage,
  getProjectContractData,
  getUpdatedItemArgs,
} from './contract-config.helpers';
import { selectContractUpdateItems, selectDeployedContractState } from './contract-config.selectors';

function* callDeployedContractStateSaga(action: PayloadAction<{ projectId: string; network: string }>): Iterator<any> {
  const { projectId, network } = action.payload;
  const projectConfig = yield select(selectProjectConfig);

  try {
    const path = `public/contract/${projectId}/${network}/call?name=getProjectInfo`;
    const res: Response = yield call(postAPI, path, { args: [] });
    const data: any = yield res.json();
    if (res.status !== 200) {
      throw new Error(data.message && 'Error fetching contract state');
    }
    yield put(setDeploymentContractState(data));
    yield put(checkDeploymentContractMatch({ project: projectConfig }));
  } catch (e) {
    yield put(callContractConfigError(e.toString()));
  }
}

function* checkDeploymentContractMatchSaga(action: PayloadAction<{ project: ProjectType }>) {
  const { project } = action.payload;
  const contractConfig = yield select(selectDeployedContractState);

  if (getDeployedContracts(project).length) {
    try {
      const data = checkContractMatch(project, contractConfig);
      yield put(setMatchStatus(data.isMatch));
      yield put(setUpdateParamIndexes(data.updates));
    } catch (e) {
      console.log(e);
      yield put(callContractConfigError('Contract status check failed'));
    }
  }
}

function* updateDeployedContractStateSaga(
  action: PayloadAction<{ network: string; provider: provider; walletAddress: string }>,
) {
  const { network, provider, walletAddress } = action.payload;
  const contractConfig = yield select(selectDeployedContractState);
  const contactUpdateItems = yield select(selectContractUpdateItems);
  const projectConfig = yield select(selectProjectConfig);
  const projectContractData: ContractStateArrayType = getProjectContractData(projectConfig);

  try {
    const txns: Array<TransactionParams> = [];
    for (let i = 0; i < contactUpdateItems.length; i++) {
      const updateItemArgs: any = getUpdatedItemArgs(projectContractData, contractConfig, contactUpdateItems[i]);
      const path = `public/contract/${projectConfig._id}/${network}?name=${updateItemArgs.functionName}`;
      const res: Response = yield call(fetchAPI, path);
      const data = yield res.json();
      const transactionParams: TransactionParams = yield call(
        getEthTransactionParams,
        provider,
        walletAddress,
        data.diamondAddress,
        data.abi.find((func: Array<any>) => func['name'] === updateItemArgs.functionName),
        `0x${ContractNetworkIds[network]}` ?? '',
        updateItemArgs.args,
      );
      txns.push(transactionParams);
    }
    yield put(setIsTxnProcessing(true));
    const txnHashArray: Array<string> = yield call(sendTransaction, provider, txns);
    const txnStatusArray: Array<TransactionConfirmation> = yield confirmTransactions(provider, txnHashArray);
    const successfulTxns: Array<TransactionConfirmation> = txnStatusArray.filter(
      (txnStatus: TransactionConfirmation) => txnStatus.status === 'success',
    );

    yield put(
      addNotification({
        message: getNotificationMessage(txnStatusArray, successfulTxns),
        severity: successfulTxns.length === txnHashArray.length ? 'success' : 'error',
        duration: 5000,
      }),
    );

    yield put(callDeployedContractState({ projectId: projectConfig._id, network }));
    yield put(setIsTxnProcessing(false));
    // currently only displays first txn URL
    yield put(
      addStreamActivity({
        verb: 'contract',
        object: {
          content: {
            event: 'Contract Updated',
            description: `Network: ${network}`,
          },
          link: `${
            EtherscanURI[
              Object.keys(ContractNetwork)[Object.values(ContractNetwork).indexOf(network as ContractNetwork)]
            ]
          }/tx/${txnStatusArray[0].txnHash}`,
        },
        projectId: projectConfig._id,
      }),
    );
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'We could not create a transaction for this update. Please contact support.',
        severity: 'error',
        duration: 5000,
      }),
    );
    yield put(setIsTxnProcessing(false));
    yield put(callContractConfigError(e));
  }

  return;
}

function* callContractUpgradetStateSaga() {
  const projectId = yield select(selectProjectId);

  const path = `contract/${projectId}/upgrade`;
  const res: Response = yield call(fetchProtectedAPI, path);
  const data: any = yield res.json();
  if (res.status !== 200) {
    throw new Error(data.message ?? 'Error fetching contract facetCut state');
  }
  if (data?.facetCuts.length) {
    yield put(setUpgradeStatus(true));
  } else {
    yield put(setUpgradeStatus(false));
  }
  yield put(setUpgradeData(data));
}

function* callContractUpgradetSaga(
  action: PayloadAction<{ projectId: string; upgradeData: ContractUpgradeData }>,
): Iterator<any> {
  const { projectId, upgradeData } = action.payload;

  yield put(
    addNotification({
      message: 'Upgrading Contract',
      severity: 'info',
      duration: 5000,
    }),
  );

  try {
    yield put(setUpgradeIsProcessing(true));
    const path = `contract/${projectId}/upgrade`;
    const res: Response = yield call(postProtectedAPI, path, upgradeData);
    yield res.json();
    if (res.status !== 200) {
      throw new Error('Failed to initiate contract upgrade background job');
    }
  } catch (e) {
    console.log(e);
    yield put(callContractConfigError(e.toString()));
  }
}

export default function* contractConfigSaga(): Iterator<any> {
  yield all([
    yield takeLatest(callDeployedContractState.type, callDeployedContractStateSaga),
    yield takeLatest(checkDeploymentContractMatch.type, checkDeploymentContractMatchSaga),
    yield takeLatest(updateDeployedContractState.type, updateDeployedContractStateSaga),
    yield takeLatest(callContractUpgradeState.type, callContractUpgradetStateSaga),
    yield takeLatest(callContractUpgrade.type, callContractUpgradetSaga),
  ]);
}

export { callDeployedContractStateSaga };
