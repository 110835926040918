import '~components/layout/PublicPageLayout/PublicPageLayout.scss';

import React from 'react';

type PublicPageLayoutProps = {
  children: React.ReactNode;
};
const PublicPageLayout = ({ children }: PublicPageLayoutProps) => {
  return <div className={'CAKE__public-page-layout'}>{children}</div>;
};

export default PublicPageLayout;
