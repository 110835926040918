import './CollectionAdvancedSettingsModal.scss';

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import FileDropzone from '~components/inputs/FileDropzone';
import Modal from '~components/miscs/Modal';
import { ContractNetwork } from '~constants/networks/networks';
import { selectCollectionTokenGroups } from '~features/collection-token-groups/collection-token-groups.selectors';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectContractConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { UploadIcon } from '~icons/common';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

type CollectionAdvancedSettingsModalProps = {
  open: boolean;
  onClose: () => void;
  onDropSettingFiles: (files: Array<File>) => void;
  showUploadNotice?: boolean;
  onAccept: () => void;
  isAccept?: boolean;
  isPlaceholderImageUpload?: boolean;
  onTogglePlaceholderImageUpload?: () => void;
};

const CollectionAdvancedSettingsModal = ({
  open,
  onClose,
  onDropSettingFiles,
  showUploadNotice,
  onAccept,
  isAccept,
  isPlaceholderImageUpload,
  onTogglePlaceholderImageUpload,
}: CollectionAdvancedSettingsModalProps) => {
  const dispatch = useAppDispatch();
  const projectContractConfig = useAppSelector(selectProjectContractConfig);
  const isDeployedToMainnet = getCurrentContractNetwork(projectContractConfig) === ContractNetwork.MAINNET;
  const tokenGroups: Array<CollectionTokenGroupType> = useAppSelector(selectCollectionTokenGroups);

  const handleUpdateDisabledNotification = () => {
    dispatch(
      addNotification({
        message: 'This project is live. You cannot delete tokens from this collection',
        severity: 'error',
        duration: 5000,
      }),
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        <Stack
          direction={'row'}
          className="CAKE__collection-advanced-settings-modal__title"
        >
          <Typography variant={'h6'}>
            {isPlaceholderImageUpload ? 'Upload Placeholder Image' : 'Import Metadata'}
          </Typography>

          <Stack
            direction={'row'}
            className="CAKE__collection-advanced-settings-modal__title--upload"
            gap={1}
            onClick={onTogglePlaceholderImageUpload}
          >
            <UploadIcon />

            <Typography variant="caption">
              {isPlaceholderImageUpload ? 'Upload Metadata' : 'Upload Placeholder Image'}
            </Typography>
          </Stack>
        </Stack>
      }
      subtitle={
        isPlaceholderImageUpload ? 'Upload an image for unrevealed tokens' : 'Upload your own custom metadata files'
      }
      footer={
        <Box>
          {showUploadNotice && !isPlaceholderImageUpload && (
            <FormGroup>
              <Typography variant="caption">
                Your testnet contract has already been deployed and revealed. Uploading new images to your testnet
                collection may affect some reveal features. Uploads will not impact the integriy of your project when it
                is pushd to production.
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={isAccept} />}
                label={
                  <Typography variant="caption">
                    {' '}
                    I understand only my{' '}
                    <span>
                      <b>testnet</b>
                    </span>{' '}
                    collection may be impacted by new uploads.
                  </Typography>
                }
                onClick={onAccept}
              />
            </FormGroup>
          )}
          <Button onClick={onClose}>CANCEL</Button>
        </Box>
      }
    >
      <Box className={'CAKE__collection-advanced-settings-modal__info'}>
        {!isPlaceholderImageUpload && (
          <Typography variant={'body2'}>
            For more information on how importing works with Cake, please visit our import documentation and follow the
            import spec as described.
          </Typography>
        )}
        {tokenGroups.length && !isPlaceholderImageUpload ? (
          <Typography
            variant="caption"
            color={'red'}
          >
            Warning: uploading tokens using Advanced Settings will cause your existing token groups to be deleted.
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <Box className={'CAKE__collection-advanced-settings-modal__dropzone'}>
        <FileDropzone
          title={'Drop JSON File Here Or Click To Browse'}
          onDropFiles={isDeployedToMainnet ? handleUpdateDisabledNotification : onDropSettingFiles}
        />
      </Box>
    </Modal>
  );
};

export default CollectionAdvancedSettingsModal;
