import type { ContractNetwork } from '~constants/networks/networks';
import type { ContractNetworkType, ContractType } from '~types/ProjectType';

import getCurrentContractInfo from './get-current-contract-info';

const getCurrentContractNetwork = (contract: ContractType): ContractNetwork => {
  const contractInfo: ContractNetworkType = getCurrentContractInfo(contract);
  if (contractInfo) {
    return contractInfo?.network;
  } else {
    return contract?.testnet.network;
  }
};

export default getCurrentContractNetwork;
