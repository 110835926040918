import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import verifyContractArguments from '~components/dashboard/ContractDeploymentCard/ContractDeploymentCard.helpers';
import ErrorModal from '~components/miscs/ErrorModal';
import type { ContractNetwork } from '~constants/networks/networks';
import { PROD_NETWORKS } from '~constants/networks/networks';
import { selectTotalTokens } from '~features/collection-items/collection-items.selectors';
import { callDeployedContractState } from '~features/contract-config/contract-config.slice';
import { selectContractDeployerIsLoading } from '~features/contract-deployer/contract-deployer.selectors';
import { callDeployContract } from '~features/contract-deployer/contract-deployer.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import getCurrentContractAddress from '~utils/contracts/get-current-contract-address';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';
import getDeploymentNetwork from '~utils/contracts/get-deployment-network';

import DeploymentWarningModal from './DeploymentWarningModal';

type DeployButtonProps = {
  buttonStyles?: React.CSSProperties;
  containterStyles?: React.CSSProperties;
};

const DeployButton = ({ containterStyles, buttonStyles }: DeployButtonProps) => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const isDeploying = useAppSelector(selectContractDeployerIsLoading);
  const totalCollectionTokens = useAppSelector(selectTotalTokens);
  const { _id, contract } = projectConfig;
  const [network, setNetwork] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [productionDeploymentNetwork, setProductionDeploymentNetwork] = useState<string>('');
  const [isSkippingTestNet, setIsSkippingTestNet] = useState<boolean>(false);

  useEffect(() => {
    setProductionDeploymentNetwork(getDeploymentNetwork(network));
  }, [network]);

  useEffect(() => {
    const network: ContractNetwork = getCurrentContractNetwork(contract);
    setNetwork(network);
  }, [isDeploying, contract]);

  useEffect(() => {
    if (network) dispatch(callDeployedContractState({ projectId: _id, network }));
  }, [network]);

  useEffect(() => {
    setProductionDeploymentNetwork(getDeploymentNetwork(network));
  }, [network]);

  const handleAcceptTestNetDeferral = () => {
    setIsSkippingTestNet(true);
    onDeployContract(productionDeploymentNetwork as ContractNetwork);
  };

  if (contract.livenet.contractAddress) {
    return null;
  }

  const onDeployContract = (deploymentNetwork: ContractNetwork) => {
    const deploymentStatus = verifyContractArguments(projectConfig, totalCollectionTokens);
    if (deploymentStatus.isVerified) {
      if (PROD_NETWORKS.includes(deploymentNetwork)) {
        if (process.env.REACT_APP_NODE_ENV === 'production') {
          dispatch(callDeployContract({ projectId: _id, network: deploymentNetwork }));
        } else {
          setErrorMessage('Production deployments are only allowed in production environment.');
          setIsErrorModal(true);
        }
      } else {
        dispatch(callDeployContract({ projectId: _id, network: deploymentNetwork }));
      }
    } else {
      setErrorMessage(`Your contract is missing important information: ${deploymentStatus.error}`);
      setIsErrorModal(true);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      style={containterStyles ? { ...containterStyles } : { margin: '10px 0' }}
    >
      {!getCurrentContractAddress(contract) ? (
        <>
          <Button
            disabled={isDeploying ? true : false}
            size="small"
            variant="contained"
            endIcon={isDeploying ? <CircularProgress size={'1rem'} /> : null}
            onClick={() => onDeployContract(network as ContractNetwork)}
          >
            {!isDeploying ? 'Deploy Test' : isSkippingTestNet ? 'Deploying' : 'Deploying Test'}
          </Button>
          {!isSkippingTestNet && (
            <Button
              size="small"
              variant="text"
              onClick={() => setIsOpen(true)}
            >
              Skip Test
            </Button>
          )}
        </>
      ) : (
        <Button
          disabled={isDeploying ? true : false}
          size="small"
          variant="contained"
          endIcon={isDeploying ? <CircularProgress size={'1rem'} /> : null}
          onClick={() => onDeployContract(productionDeploymentNetwork as ContractNetwork)}
          sx={{ ...buttonStyles }}
        >
          {isDeploying ? 'Deploying' : 'Go live'}
        </Button>
      )}
      <DeploymentWarningModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={handleAcceptTestNetDeferral}
      />
      <ErrorModal
        open={isErrorModal}
        onClose={() => setIsErrorModal(false)}
        footer={<Button onClick={() => setIsErrorModal(false)}>Okay</Button>}
        height={'25%'}
        title={
          <Stack
            direction={'row'}
            alignContent={'center'}
            gap={1}
          >
            <WarningRoundedIcon color="warning" />
            <Typography variant="h6">Warning</Typography>
          </Stack>
        }
      >
        <Typography>{errorMessage}</Typography>
      </ErrorModal>
    </Stack>
  );
};

export default DeployButton;
