import { type PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select } from 'redux-saga/effects';

import { selectProjectId } from '~features/project-config/project-config.selectors';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { takeLatestWithMonitoring } from '~features/utils/saga-utils';
import type ImageGeneration from '~types/ImageGeneration';

import {
  selectImageGenerationIsFavourite,
  selectImageGenerations,
  selectImageGenerationsCurrentCount,
  selectImageGenerationsCurrentPage,
  selectImageGenerationsTotal,
} from './image-generations.selectors';
import {
  getFavouriteImageGenerations,
  getImageGenerations,
  getImageGenerationsAborted,
  getImageGenerationsError,
  getImageGenerationsSuccess,
  getNextImageGenerationsPage,
  setImageGenerationsPage,
  updateImageGeneration,
  updateImageGenerationSuccess,
} from './image-generations.slice';

const PAGE_SIZE = 30;
function* fetchImageGenerationsPage(page: number, isFavourite: boolean) {
  const projectId = yield select(selectProjectId);
  const total = yield select(selectImageGenerationsTotal);
  const currentCount = yield select(selectImageGenerationsCurrentCount);

  if (total > 0 && currentCount >= total) {
    yield put(getImageGenerationsAborted());
    return;
  }

  try {
    let path = `image-submissions?pageNumber=${page}&pageSize=${PAGE_SIZE}&projectId=${projectId}`;
    if (isFavourite) {
      path += '&isFavourited=true';
    }
    const response = yield call(fetchProtectedAPI, path);
    const data: { images: ImageGeneration[]; total: number } = yield response.json();
    const currentIsFavourite = yield select(selectImageGenerationIsFavourite);
    if (isFavourite !== currentIsFavourite) {
      return;
    }
    yield put(setImageGenerationsPage({ page }));
    if (page > 0) {
      const currentImages = yield select(selectImageGenerations);
      yield put(getImageGenerationsSuccess({ images: [...currentImages, ...data.images], total: data.total }));
    } else {
      yield put(getImageGenerationsSuccess(data));
    }
  } catch (error) {
    yield put(getImageGenerationsError(error));
  }
}

function* fetchImageGenerationsSaga() {
  yield call(fetchImageGenerationsPage, 0, false);
}

function* fetchFavouriteImageGenerationsSaga() {
  yield call(fetchImageGenerationsPage, 0, true);
}

function* fetchNextImageGenerationsPageSaga() {
  const currentPage = yield select(selectImageGenerationsCurrentPage);
  const isFavourite = yield select(selectImageGenerationIsFavourite);
  yield call(fetchImageGenerationsPage, currentPage + 1, isFavourite);
}

function* updateImageGenerationSaga({ payload }: PayloadAction<{ image: ImageGeneration }>) {
  const { image } = payload;
  const path = `image-submissions/${image._id}`;
  const response = yield call(putProtectedAPI, path, image);
  const data: ImageGeneration = yield response.json();
  yield put(updateImageGenerationSuccess({ image: data }));
}

export default function* imageGenerationsSaga(): Iterator<any> {
  yield all([
    takeLatestWithMonitoring(getImageGenerations.type, fetchImageGenerationsSaga, 'image-generations.fetch'),
    takeLatestWithMonitoring(
      getFavouriteImageGenerations.type,
      fetchFavouriteImageGenerationsSaga,
      'image-generations.fetch-favourite',
    ),
    takeLatestWithMonitoring(
      getNextImageGenerationsPage.type,
      fetchNextImageGenerationsPageSaga,
      'image-generations.fetch-next-page',
    ),
    takeLatestWithMonitoring(updateImageGeneration.type, updateImageGenerationSaga, 'image-generations.update'),
  ]);
}
