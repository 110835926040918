import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select } from 'redux-saga/effects';

import { setContractVerificationState } from '~features/contract-deployer/contract-deployer.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  getImageUploadTypeForStreamEvent,
  getUpdatedDappSettings,
  updateProjectImagePath,
} from '~features/project-config/project-config.helpers';
import { selectProjectConfig, selectProjectId } from '~features/project-config/project-config.selectors';
import {
  cancelProjectConfigLoad,
  deleteBrandingImage,
  deleteGalleryImage,
  deleteGalleryImageError,
  deleteGalleryImageSuccess,
  getProjectConfig,
  setProjectConfig,
  setProjectConfigError,
  updatePaperConfig,
  updateProjectActivePlugins,
  updateProjectActivePluginsFailed,
  updateProjectActivePluginsSuccess,
  updateProjectConfig,
  updateProjectConfigError,
  updateProjectConfigSuccess,
  updateProjectContractConfig,
  updateProjectDappConfig,
  updateTypography,
  updateTypographyError,
  updateTypographySuccess,
  uploadGalleryImage,
  uploadGalleryImageSuccess,
  uploadImage,
  uploadImageError,
  uploadImageSuccess,
} from '~features/project-config/project-config.slice';
import { subscribeChannels } from '~features/pubsub/pubsub.slice';
import { addStreamActivity, initProjectStream, resetProjectStreamData } from '~features/streams/streams.slice';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { doesFileExistsOnS3, uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import { takeEveryWithMonitoring, takeLatestWithMonitoring } from '~features/utils/saga-utils';
import type {
  ContractType,
  DappSettingsType,
  FieldError,
  ImageBrandType,
  PaperType,
  ProjectType,
  TypographyItemType,
  TypographyKeys,
} from '~types/ProjectType';
import type { CakeActivityType } from '~types/Streams';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';
import getS3TokenFilePath from '~utils/api/getS3TokenFilePath';

window.Buffer = window.Buffer || Buffer;

function* fetchProjectSaga(action: PayloadAction<{ id: string; force: boolean }>): Iterator<any> {
  const { id, force } = action.payload;
  const currentId: string = yield select(selectProjectId);

  if (!force && id === currentId) {
    yield put(cancelProjectConfigLoad());
    return;
  }

  try {
    const path = `project/${id}`;
    const res: Response = yield call(fetchProtectedAPI, path);
    if (res.status === 404) {
      yield put(setProjectConfigError('Project not found'));
      return;
    }
    if (res.status === 401) {
      yield put(setProjectConfigError('Unauthorized'));
      return;
    }
    if (res.status >= 400) {
      yield put(setProjectConfigError('Failed to fetch project'));
      return;
    }
    const data: ProjectType = yield res.json();

    if (data) {
      yield put(setProjectConfig(data));
      yield put(
        setContractVerificationState({
          testnet: data.contract.testnet.isVerified,
          livenet: data.contract.livenet.isVerified,
        }),
      );
      yield put(subscribeChannels([`project_${data._id}`]));
      yield put(resetProjectStreamData());
      yield put(initProjectStream());
    } else {
      yield put(setProjectConfigError('Project not found'));
    }
  } catch (e) {
    console.log(e);
    yield put(setProjectConfigError(e.toString()));
  }
}

function* handleProjectErrorSaga(payload: { message: string; error: string }) {
  const { message, error } = payload;

  yield put(updateProjectConfigError(error));

  yield put(
    addNotification({
      severity: 'error',
      message: `Error updating project: ${message}`,
      duration: 5000,
    }),
  );
}

function* updateProjectSaga(action: PayloadAction<ProjectType>) {
  const currentProjectConfig = yield select(selectProjectConfig);
  const updatedProjectConfig = {
    ...currentProjectConfig,
    ...action.payload,
  };

  const path = `project/${currentProjectConfig._id}`;
  try {
    const res: Response = yield call(putProtectedAPI, path, updatedProjectConfig);

    if (res.status === 200) {
      const data: ProjectType = yield res.json();
      yield put(updateProjectConfigSuccess({ ...data, _id: currentProjectConfig._id }));
    } else {
      const data: FieldError = yield res.json();
      yield call(handleProjectErrorSaga, {
        message: data.message,
        error: `Update failed with status code ${res.status}`,
      });
    }
  } catch (e) {
    yield call(handleProjectErrorSaga, {
      message: 'Sorry, something went wrong updating the project.',
      error: e.toString(),
    });
    return e.toString();
  }
}

function* updateProjectContractSaga(action: PayloadAction<{ contract: ContractType; event?: CakeActivityType }>) {
  const currentProjectConfig = yield select(selectProjectConfig);
  const updatedProjectConfig = {
    ...currentProjectConfig,
    contract: action.payload.contract,
  };
  yield call(updateProjectSaga, updateProjectConfig(updatedProjectConfig));
}

function* updateProjectDappSaga(action: PayloadAction<{ dapp: DappSettingsType; event?: CakeActivityType }>) {
  const currentProjectConfig = yield select(selectProjectConfig);
  const updatedProjectConfig = {
    ...currentProjectConfig,
    dapp: action.payload.dapp,
  };

  yield call(updateProjectSaga, updateProjectConfig(updatedProjectConfig));

  if (action.payload.event) {
    yield put(
      addStreamActivity({
        verb: 'dapp',
        object: {
          ...action.payload.event,
        },
        projectId: currentProjectConfig._id,
      }),
    );
  }
}

function* updateProjectActivePluginsSaga(action: PayloadAction<string[]>) {
  const currentProjectConfig = yield select(selectProjectConfig);
  const updatedProjectConfig = {
    ...currentProjectConfig,
    active_plugins: action.payload,
  };

  const result = yield call(updateProjectSaga, updateProjectConfig(updatedProjectConfig));
  if (!result) {
    yield put(updateProjectActivePluginsSuccess(action.payload));
  } else {
    yield put(updateProjectActivePluginsFailed(result));
  }
}

function* updateTypographySaga(action: PayloadAction<{ font: TypographyItemType; file?: File; key: TypographyKeys }>) {
  const { file, key, font } = action.payload;
  const project: ProjectType = yield select(selectProjectConfig);

  try {
    if (file) {
      const fileLocation: { dir: string; path: string } = getS3RootProjectPath(project.name);
      const data = yield uploadFileToS3(file, fileLocation.dir);

      font.url = data.location;

      yield put(
        addStreamActivity({
          verb: 'dapp',
          object: {
            content: {
              event: `Uploaded custom font: ${font.name}.`,
            },
          },
          projectId: project._id,
        }),
      );
    }

    const updatedProject = {
      ...project,
      dapp: {
        ...project.dapp,
        typography: {
          ...(project.dapp.typography ?? {}),
          [key]: font,
        },
      },
    };

    yield call(updateProjectSaga, updateProjectConfig(updatedProject));
    yield put(
      addStreamActivity({
        verb: 'dapp',
        object: {
          content: {
            event: 'dApp Settings Update',
            description: 'Updated dApp typography settings.',
          },
        },
        projectId: project._id,
      }),
    );
    yield put(updateTypographySuccess(key));
  } catch (err) {
    yield put(updateTypographyError({ key, error: err.toString() }));
  }
}

function* uploadImageSaga(action: PayloadAction<{ file: File; key: string; extraPath?: string }>) {
  const { file, key, extraPath } = action.payload;
  const project: ProjectType = yield select(selectProjectConfig);

  try {
    const fileLocation: {
      dir: string;
      path: string;
    } = getS3TokenFilePath(project.slug || project.name, extraPath);
    const data = yield uploadFileToS3(file, fileLocation.dir);
    const updatedDappSettings: DappSettingsType = updateProjectImagePath(project.dapp, data.location, key);
    const updatedProject = {
      ...project,
      dapp: updatedDappSettings,
    };

    yield call(updateProjectSaga, updateProjectConfig(updatedProject));
    yield put(
      addStreamActivity({
        verb: 'dapp',
        object: {
          content: {
            event: `${getImageUploadTypeForStreamEvent(key)}`,
          },
          media: data.location,
        },
        projectId: project._id,
      }),
    );
    yield put(uploadImageSuccess(key));
  } catch (err) {
    yield put(uploadImageError({ key, error: err.toString() }));
  }
}

function* uploadGalleryImageSaga(action: PayloadAction<{ file: File; key: string }>): Iterator<any> {
  const { file, key } = action.payload;

  const project: ProjectType = yield select(selectProjectConfig);
  const fileLocation: {
    dir: string;
    path: string;
  } = getS3RootProjectPath(project.name);

  const isFile: boolean = yield doesFileExistsOnS3(file.name, fileLocation.path);

  if (isFile) {
    const updatedProject: ProjectType = {
      ...project,
      dapp: {
        ...project.dapp,
        images: {
          ...project.dapp.images,
          gallery: [...project.dapp.images.gallery, `${fileLocation.path}/${file.name}`],
        },
      },
    };
    yield call(updateProjectSaga, updateProjectConfig(updatedProject));
  } else {
    const data: any = yield uploadFileToS3(file, fileLocation.dir);
    const updatedProject: ProjectType = {
      ...project,
      dapp: {
        ...project.dapp,
        images: {
          ...project.dapp.images,
          gallery: [...project.dapp.images.gallery, data.location],
        },
      },
    };
    yield call(updateProjectSaga, updateProjectConfig(updatedProject));
    yield put(uploadGalleryImageSuccess({ path: data.location, key }));
  }
}

function* deleteGalleryImageSaga(action: PayloadAction<number>) {
  const index = action.payload;
  const project: ProjectType = yield select(selectProjectConfig);
  const galleryImages = [...project.dapp.images.gallery];
  galleryImages.splice(index, 1);

  const updatedProject: ProjectType = {
    ...project,
    dapp: {
      ...project.dapp,
      images: {
        ...project.dapp.images,
        gallery: galleryImages,
      },
    },
  };
  try {
    yield call(updateProjectSaga, updateProjectConfig(updatedProject));
    yield put(deleteGalleryImageSuccess());
  } catch (e) {
    yield put(deleteGalleryImageError());
  }
}

function* deleteBrandingImageSaga(action: PayloadAction<ImageBrandType>) {
  const brandImageType = action.payload;
  const project: ProjectType = yield select(selectProjectConfig);
  const dapp: DappSettingsType = { ...project.dapp };

  const updatedDappSettings = getUpdatedDappSettings(brandImageType, dapp);

  const updatedProject: ProjectType = {
    ...project,
    dapp: { ...updatedDappSettings },
  };

  try {
    yield call(updateProjectSaga, updateProjectConfig(updatedProject));
  } catch (e) {
    console.log(e);
  }
}

function* updatePaperConfigSaga(action: PayloadAction<{ isActivated: boolean; event: CakeActivityType }>) {
  const { isActivated } = action.payload;
  const currentProjectConfig = yield select(selectProjectConfig);
  const path = `project/${currentProjectConfig._id}/paper-xyz/register`;

  try {
    const res: Response = yield call(putProtectedAPI, path, { isActivated });
    const data: PaperType = yield res.json();
    if (res.status === 200) {
      const updatedProjectConfig = {
        ...currentProjectConfig,
        paper: {
          ...data,
        },
      };
      yield call(updateProjectSaga, updateProjectConfig(updatedProjectConfig));

      yield put(
        addStreamActivity({
          verb: 'plugins',
          object: {
            ...action.payload.event,
          },
          projectId: currentProjectConfig._id,
        }),
      );
    }
  } catch (e) {
    console.log(e);
    yield put(setProjectConfigError('Failed to update Paper payment setting.'));
  }
}

export default function* campaignSaga(): Iterator<any> {
  yield all([
    yield takeLatestWithMonitoring(getProjectConfig.type, fetchProjectSaga, 'project.fetch'),
    yield takeLatestWithMonitoring(updateProjectConfig.type, updateProjectSaga, 'project.update'),
    yield takeLatestWithMonitoring(
      updateProjectContractConfig.type,
      updateProjectContractSaga,
      'project.contract.update',
    ),
    yield takeLatestWithMonitoring(updateProjectDappConfig.type, updateProjectDappSaga, 'project.dapp.update'),
    yield takeLatestWithMonitoring(uploadImage.type, uploadImageSaga, 'project.image.upload'),
    yield takeLatestWithMonitoring(updateTypography.type, updateTypographySaga, 'project.typography.update'),
    yield takeLatestWithMonitoring(uploadGalleryImage.type, uploadGalleryImageSaga, 'project.gallery-image.upload'),
    yield takeEveryWithMonitoring(deleteGalleryImage.type, deleteGalleryImageSaga, 'project.gallery-image.delete'),
    yield takeEveryWithMonitoring(deleteBrandingImage.type, deleteBrandingImageSaga, 'project.brand-image.delete'),
    yield takeEveryWithMonitoring(updatePaperConfig.type, updatePaperConfigSaga, 'project.paper.update'),
    yield takeEveryWithMonitoring(
      updateProjectActivePlugins.type,
      updateProjectActivePluginsSaga,
      'project.plugins.update',
    ),
  ]);
}

export { updateProjectSaga };
