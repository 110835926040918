import './InfoItem.scss';

import type { StackProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

type InfoItemProps = StackProps &
  PropsWithChildren<{
    editable?: boolean;
    onEdit?: (value: string) => void;
    label: string;
    value: string;
  }>;

const InfoItem = ({ editable, onEdit, label, value, children, ...props }: InfoItemProps) => {
  const inputRef = useRef();

  return (
    <Stack
      className="CAKE__info-item-input"
      direction="row"
      spacing={0.5}
      {...props}
    >
      <Typography
        variant="body3"
        fontWeight={600}
      >
        {label}
      </Typography>

      <Typography variant="body3">
        {editable ? (
          <input
            ref={inputRef}
            role="textbox"
            value={value}
            onInput={(e) => onEdit((e.target as any).value)}
          />
        ) : (
          value
        )}
      </Typography>

      {children}
    </Stack>
  );
};

export default InfoItem;
