import './TraitEditModal.scss';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import LightTooltip from '~components/miscs/LightTooltip/LightTooltip';
import type TraitType from '~types/token/TraitType';

type TraitEditModalProps = {
  isOpen: boolean;
  trait?: TraitType;
  onConfirm: (trait: TraitType) => void;
  onCancel: () => void;
};

const TraitEditModal = ({ isOpen, trait, onConfirm, onCancel }: TraitEditModalProps) => {
  const [currentTrait, setCurrentTrait] = useState<TraitType>(trait || { value: '' });

  const onTraitTypeChange = (value: string) => {
    setCurrentTrait({
      ...currentTrait,
      trait_type: value,
    });
  };

  const onTraitValueChange = (value: string) => {
    setCurrentTrait({
      ...currentTrait,
      value,
    });
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Stack flexDirection={'row'}>
          <Typography paddingRight={1}>{'Add metadata'}</Typography>
          <LightTooltip
            placement={'right'}
            title={
              <Stack
                direction={'column'}
                height={'150px'}
              >
                <Typography
                  variant="subtitle1"
                  padding={1}
                >
                  Traits
                </Typography>
                <Divider />
                <img src="/images/add_metadata_info.png" />
              </Stack>
            }
          >
            <InfoOutlinedIcon />
          </LightTooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          paddingY={1}
        >
          <V2TextInput
            label={'Trait Type'}
            value={currentTrait.trait_type || ''}
            onUpdate={onTraitTypeChange}
          />

          <V2TextInput
            label={'Trait Value'}
            value={(currentTrait.value || '').toString()}
            onUpdate={onTraitValueChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onConfirm(currentTrait)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TraitEditModal;
