import { Button } from '@mui/material';

import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';
import dappUrlGenerator from '~utils/url-builder/dappUrlGenerator/dappUrlGenerator';

const DappButton = () => {
  const projectConfig = useAppSelector(selectProjectConfig);

  const handleViewDapp = () => {
    window.open(dappUrlGenerator(projectConfig.slug));
  };

  return (
    <Button
      size="small"
      variant="outlined"
      onClick={handleViewDapp}
    >
      Visit Co-Creation Site
    </Button>
  );
};

export default DappButton;
