import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import AlertBanner from '~components/miscs/AlertBanner';
import ComponentLoader from '~components/miscs/ComponentLoader';
import { selectContractUpdateIsProcessing } from '~features/contract-config/contract-config.selectors';
import { selectContractDeployerIsLoading } from '~features/contract-deployer/contract-deployer.selectors';
import { callDeployContract } from '~features/contract-deployer/contract-deployer.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import getProjectNetwork from '~utils/projects/get-network';

const ContractMigrationAlert = () => {
  const dispatch = useAppDispatch();
  const isDeploying = useAppSelector(selectContractDeployerIsLoading);
  const isUpdateProcessing = useAppSelector(selectContractUpdateIsProcessing);
  const project = useAppSelector(selectProjectConfig);
  const network = getProjectNetwork(project);

  const handleContractMigration = () => {
    dispatch(callDeployContract({ projectId: project._id, network }));
  };

  return (
    <>
      <ComponentLoader isOpen={isUpdateProcessing} />
      <AlertBanner
        severity={'high'}
        isLoading={isDeploying}
        title={'Your contract must be migrated.'}
        loadingTitle={`${network.charAt(0).toUpperCase() + network.slice(1)} Contract Migration In Progress...`}
        tooltipTitle={
          "You must migrate your contract to Cake's upgradeable contract in order to utilize Cake's contract management tools"
        }
        buttonTitle={'Migrate Now'}
        onAccept={handleContractMigration}
        icon={<WarningRoundedIcon sx={{ color: 'white' }} />}
      />
    </>
  );
};

export default ContractMigrationAlert;
