import ProjectMessageType from '~constants/pubsub/ProjectMessageType';
import {
  callDeployContractError,
  callDeployContractSuccess,
  callVerifyContractError,
  callVerifyContractSuccess,
} from '~features/contract-deployer/contract-deployer.slice';

import contractUpgradeFailedHandler from './handlers/contract-upgrade-failed-handler';
import contractUpgradeSuccessHandler from './handlers/contract-upgrade-success-handler';
import randomNumberFulfilledHandler from './handlers/random-number-fulfilled-handler';
import randomNumberRequestHandler from './handlers/random-number-request-handler';
import singleTokenMintedHandler from './handlers/single-token-minted-handler';
import singleTokenTransferredHandler from './handlers/single-token-transferred-handler';
import singleTokenUpdateHandler from './handlers/single-token-update-handler';
import tokenGroupLayeredUpdateCloseHandler from './handlers/token-group-layered-update-close-handler';
import tokenGroupLayeredUpdateOpenHandler from './handlers/token-group-layered-update-open-handler';
import tokenGroupLayeredUpdateScheduleHandler from './handlers/token-group-layered-update-schedule-handler';
import tokenGroupStandardUpdateHandler from './handlers/token-group-standard-update-handler';
import unknownTransactionErrorHandler from './handlers/unknown-transaction-error-handler';

const processProjectMessages = (emitter, message) => {
  const payload = message.message;

  switch (payload.type) {
    case ProjectMessageType.CONTRACT_DEPLOYMENT_SUCCESS:
      emitter(
        callDeployContractSuccess({
          network: payload.data.network,
          contractAddress: payload.data.contractAddress,
          projectId: payload.data.projectId,
        }),
      );
      break;
    case ProjectMessageType.CONTRACT_DEPLOYMENT_FAILED:
      emitter(callDeployContractError('Contract deployment failed'));
      break;
    case ProjectMessageType.CONTRACT_UPGRADE_SUCCESS:
      emitter(contractUpgradeSuccessHandler(emitter, payload.data));
      break;
    case ProjectMessageType.CONTRACT_UPGRADE_FAILED:
      emitter(contractUpgradeFailedHandler(emitter, payload.data));
      break;
    case ProjectMessageType.CONTRACT_VERIFICATION_SUCCESS:
      emitter(callVerifyContractSuccess({ network: payload.data.network }));
      break;
    case ProjectMessageType.CONTRACT_VERIFICATION_FAILED:
      emitter(callVerifyContractError({ network: payload.data.network, error: 'Contract verification failed' }));
      break;
    case ProjectMessageType.TOKEN_GROUP_STANDARD_UPDATE_PERFORMED:
      tokenGroupStandardUpdateHandler(emitter, payload.data);
      break;
    case ProjectMessageType.TOKEN_GROUP_LAYERED_UPDATE_CLOSED:
      tokenGroupLayeredUpdateCloseHandler(emitter, payload.data);
      break;
    case ProjectMessageType.TOKEN_GROUP_LAYERED_UPDATE_OPENED:
      tokenGroupLayeredUpdateOpenHandler(emitter, payload.data);
      break;
    case ProjectMessageType.TOKEN_GROUP_LAYERED_UPDATE_SCHEDULED:
      tokenGroupLayeredUpdateScheduleHandler(emitter, payload.data);
      break;
    case ProjectMessageType.SINGLE_TOKEN_MINTED:
      singleTokenMintedHandler(emitter, payload.data);
      break;
    case ProjectMessageType.SINGLE_TOKEN_TRANSFERRED:
      singleTokenTransferredHandler(emitter, payload.data);
      break;
    case ProjectMessageType.SINGLE_TOKEN_UPDATED:
      singleTokenUpdateHandler(emitter, payload.data);
      break;
    case ProjectMessageType.UNKOWN_TRANSACTION_ERROR:
      unknownTransactionErrorHandler(emitter, payload.data);
      break;
    case ProjectMessageType.VRF_RANDOM_NUMBER_REQUESTED:
      randomNumberRequestHandler(emitter, payload.data);
      break;
    case ProjectMessageType.VRF_RANDOM_NUMBER_FULFILLED:
      randomNumberFulfilledHandler(emitter, payload.data);
      break;
  }
};

const pubsubEvents = (emitter, pubnub) => ({
  message: (message) => {
    if (message.channel.startsWith('project_')) {
      processProjectMessages(emitter, message);
    }
  },
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-empty-function
  presence: () => {},
  status: (statusEvent) => {
    switch (statusEvent.category) {
      case 'PNConnectedCategory':
        // PubNub connected
        break;
      case 'PNNetworkUpCategory':
        console.log('pubnub reconnecting');
        if (pubnub) {
          pubnub.reconnect();
        }
        break;
      case 'PNNetworkDownCategory':
        console.log('pubnub connection failed');
        break;
      default:
        break;
    }
  },
});

export { pubsubEvents };
