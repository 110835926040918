import type { RouteType } from '~constants/routes/Routes.types';
import RoutesPageLayout from '~constants/routes/RoutesPageLayout';
import ProjectAnalyticsView from '~views/projects/ProjectAnalyticsView';
import ProjectBuilderView from '~views/projects/ProjectBuilderView';
import ProjectCollectionView from '~views/projects/ProjectCollectionView';
import ProjectCommunityView from '~views/projects/ProjectCommunityView';
import ProjectContentItemView from '~views/projects/ProjectContentItemView';
import ProjectContractView from '~views/projects/ProjectContractView';
import ProjectDAppView from '~views/projects/ProjectDAppView';
import ProjectDataRoomObjectView from '~views/projects/ProjectDataRoomObjectView';
import ProjectDropView from '~views/projects/ProjectDropView';
import ProjectOverview from '~views/projects/ProjectOverview';
import ProjectShopifyAuthView from '~views/projects/ProjectShopifyAuth';
import ProjectsListView from '~views/projects/ProjectsListView';
import ProjectUtilityView from '~views/projects/ProjectUtilityView';

import PrivateRoutes from './private-routes.consts';

const priavteRoutes: Array<RouteType> = [
  {
    path: PrivateRoutes.DASHBOARD_PROJECTS_LIST,
    Component: ProjectsListView,
    LayoutComponent: RoutesPageLayout.DASHBOARD_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_ANALYTICS,
    Component: ProjectAnalyticsView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_CONTRACT,
    Component: ProjectContractView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_COLLECTION,
    Component: ProjectCollectionView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_COMMUNITY,
    Component: ProjectCommunityView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_DAPP,
    Component: ProjectDAppView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_OVERVIEW,
    Component: ProjectOverview,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_UTILITY,
    Component: ProjectUtilityView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_CONTENT_ITEM,
    Component: ProjectContentItemView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_DATA_ROOM_OBJECT,
    Component: ProjectDataRoomObjectView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_DROP,
    Component: ProjectDropView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.DASHBOARD_PROJECT_NEW_DROP,
    Component: ProjectDropView,
    LayoutComponent: RoutesPageLayout.PROJECT_PAGE_LAYOUT,
  },
  {
    path: PrivateRoutes.PROJECT_BUILDER,
    Component: ProjectBuilderView,
    LayoutComponent: RoutesPageLayout.NO_MENU_LAYOUT,
  },
  {
    path: PrivateRoutes.PROJECT_SHOPIFY_AUTH,
    Component: ProjectShopifyAuthView,
    LayoutComponent: RoutesPageLayout.NO_MENU_LAYOUT,
  },
];

export default priavteRoutes;
