import BN from 'bn.js';
import _ from 'lodash';
import moment from 'moment';
import { select } from 'redux-saga/effects';

import { selectProjectContractConfig } from '~features/project-config/project-config.selectors';
import type { TransferDataType } from '~types/AnalyticsType';
import { ChartResolution } from '~types/ChartResolution';
import type { ContractNetworkType } from '~types/ProjectType';
import { UpdateType } from '~types/token/TokenUpdateType';
import getCurrentContractInfo from '~utils/contracts/get-current-contract-info';
import convertWeiToEth from '~utils/web3/conversion/eth-converter';

const getDeploymentDate = (data) => {
  const contractNetworkData: ContractNetworkType = getCurrentContractInfo(data);
  return contractNetworkData?.deployedAt || new Date(data.publicSale).toISOString();
};

const getTransferChartTicksAmount = (deploymentDate: string, resolution: ChartResolution) => {
  let firstTick;
  let lastTick;

  switch (resolution) {
    case ChartResolution.Day:
      firstTick = moment(deploymentDate).set('hour', 0);
      lastTick = moment().set('hour', 0);
      return lastTick.diff(firstTick, 'days') + 1;
    case ChartResolution.Hour:
      firstTick = moment(deploymentDate).set('minutes', 0);
      lastTick = moment().set('minutes', 0);
      return lastTick.diff(firstTick, 'hours') + 1;
  }
};

const getTransferChartResolution = (deploymentDate: string) => {
  const firstTick = moment(deploymentDate);
  const lastTick = moment();

  return lastTick.diff(firstTick, 'hours') > 72 ? ChartResolution.Day : ChartResolution.Hour;
};

const getDateFormatForResolution = (resolution: ChartResolution) => {
  switch (resolution) {
    case ChartResolution.Day:
      return 'YYYY-MM-DD';
    case ChartResolution.Hour:
      return 'YYYY-MM-DD HH:mm';
  }
};

const getEventLabel = (event) => {
  if (event.type === 'TOKEN_UPDATE') {
    if (event.subType === UpdateType.LAYERED) {
      return 'Dynamic Token Update';
    } else if (event.type === UpdateType.STANDARD) {
      return 'Standard Token Update';
    }
  }
  return 'Event';
};

function* computeCumulativeTransferData(data: TransferDataType[], events) {
  const contractData = yield select(selectProjectContractConfig);
  const deploymentDate = getDeploymentDate(contractData);

  const resolution: ChartResolution = getTransferChartResolution(deploymentDate);
  const dateFormat = getDateFormatForResolution(resolution);
  const ticksAmount = getTransferChartTicksAmount(deploymentDate, resolution);
  const transactionData = _.range(0, ticksAmount + 1).reduce((curr, tick) => {
    const tickDate = moment(deploymentDate).add(tick, resolution);
    tickDate.minutes(0);
    return {
      ...curr,
      [tickDate.format(dateFormat)]: {
        volume: 0,
        x: tickDate.format(dateFormat),
        events: {},
      },
    };
  }, {});

  events.forEach((event) => {
    const eventDate = moment(event.date);
    eventDate.minutes(0);
    const eventDateFormatted = eventDate.format(dateFormat);

    if (!transactionData[eventDateFormatted]) {
      return;
    }
    transactionData[eventDateFormatted] = {
      ...transactionData[eventDateFormatted],
      events: {
        title: getEventLabel(event),
        entries: (transactionData[eventDateFormatted].events?.entries ?? []).concat(event),
      },
    };
  });

  let cumulativeValue: BN = new BN('0');
  _.reverse(data).forEach((item) => {
    if (!item.date) {
      return;
    }
    const itemDate = moment(item.date);
    itemDate.minutes(0);
    const itemDateFormatted = itemDate.format(dateFormat);

    if (!transactionData[itemDateFormatted]) {
      return;
    }

    cumulativeValue = new BN(cumulativeValue).add(new BN(item.value || 0));
    transactionData[itemDateFormatted] = {
      ...transactionData[itemDateFormatted],
      volume: transactionData[itemDateFormatted].volume + 1,
      y: convertWeiToEth(cumulativeValue),
    };
  });

  const transactionDataArray: any[] = Object.values(transactionData);
  let prevCumulativeValue = 0;
  const cumulativeTransferData = transactionDataArray.map((item: any) => {
    prevCumulativeValue = item.y ?? prevCumulativeValue;
    return {
      ...item,
      y: prevCumulativeValue,
    };
  });

  return {
    data: cumulativeTransferData,
    resolution,
  };
}

export { computeCumulativeTransferData, getDateFormatForResolution };
