/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { FileUploadStatus } from '~components/inputs/FileUploadInput';
import { UploadStatus } from '~types/miscs/UploadStatus';
import type {
  ContractType,
  DappSettingsType,
  ImageBrandType,
  ProjectType,
  TypographyItemType,
} from '~types/ProjectType';
import type { TypographyKeys } from '~types/ProjectType';
import type { CakeActivityType } from '~types/Streams';

import { getTypographyStatusKey } from './project-config.helpers';

interface ProjectConfigState {
  project: ProjectType | null;
  isLoading: boolean;
  isUpdating: boolean;
  error: string | null;
  fileUploader: Partial<Record<keyof FileUploadStatus, UploadStatus>>;
}

const initialState: ProjectConfigState = {
  project: null,
  isLoading: false,
  isUpdating: false,
  error: null,
  fileUploader: {},
};

const projectConfigState = createSlice({
  name: 'project-config',
  initialState,
  reducers: {
    getProjectConfig(state, action: PayloadAction<{ id: string; force: boolean }>) {
      state.isLoading = true;
      state.error = null;
    },
    setProjectConfig(state, action: PayloadAction<ProjectType>) {
      state.project = action.payload;
      state.isLoading = false;
    },
    cancelProjectConfigLoad(state) {
      state.isLoading = false;
    },
    setProjectConfigError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.project = null;
    },
    updateProjectContractConfig(state, action: PayloadAction<{ contract: ContractType; event?: CakeActivityType }>) {},
    updateProjectDappConfig(state, action: PayloadAction<{ dapp: DappSettingsType; event?: CakeActivityType }>) {},
    updateProjectConfig(state, action: PayloadAction<ProjectType>) {
      state.isUpdating = true;
    },
    updateProjectConfigSuccess(state, action: PayloadAction<ProjectType>) {
      if (state.project) {
        state.project = action.payload;
      }
      state.isUpdating = false;
    },
    updateProjectFieldConfigSuccess(state, action: PayloadAction<{ field: string; value: any }>) {
      if (state.project) {
        const project = state.project;
        project[action.payload.field] = action.payload.value;
        state.project = project;
      }
      state.isUpdating = false;
    },
    updateProjectConfigError(state, action: PayloadAction<string>) {
      state.isUpdating = false;
      state.error = action.payload;
    },
    updateProjectActivePlugins(state, action: PayloadAction<string[]>) {
      state.isUpdating = true;
    },
    updateProjectActivePluginsFailed(state, action: PayloadAction<string>) {
      state.isUpdating = false;
      state.error = action.payload;
    },
    updateProjectActivePluginsSuccess(state, action: PayloadAction<string[]>) {
      state.isUpdating = false;
      state.project.active_plugins = action.payload;
    },
    updateTypography(state, action: PayloadAction<{ font: TypographyItemType; file?: File; key: TypographyKeys }>) {
      state.fileUploader = {
        ...state.fileUploader,
        [getTypographyStatusKey(action.payload.key)]: UploadStatus.LOADING,
      };
    },
    updateTypographyError(state, action: PayloadAction<{ key: TypographyKeys; error: string }>) {
      state.fileUploader = {
        ...state.fileUploader,
        [getTypographyStatusKey(action.payload.key)]: UploadStatus.ERROR,
      };
    },
    updateTypographySuccess(state, action: PayloadAction<TypographyKeys>) {
      state.fileUploader = {
        ...state.fileUploader,
        [getTypographyStatusKey(action.payload)]: undefined,
      };
    },
    uploadImage(state, action: PayloadAction<{ file: File; key: string; extraPath?: string }>) {
      state.fileUploader = {
        ...state.fileUploader,
        [action.payload.key]: UploadStatus.LOADING,
      };
    },
    uploadImageError(state, action: PayloadAction<{ key: string; error: string }>) {
      state.fileUploader = {
        ...state.fileUploader,
        [action.payload.key]: UploadStatus.ERROR,
      };
    },
    uploadImageSuccess(state, action: PayloadAction<string>) {
      state.fileUploader = {
        ...state.fileUploader,
        [action.payload]: undefined,
      };
    },
    uploadGalleryImage(state, action: PayloadAction<{ file: File; key: string }>) {
      state.fileUploader = {
        ...state.fileUploader,
        [action.payload.key]: UploadStatus.LOADING,
      };
    },
    uploadGalleryImageError(state, action: PayloadAction<{ error: File; key: string }>) {
      const { key } = action.payload;
      state.fileUploader = {
        ...state.fileUploader,
        [key]: UploadStatus.ERROR,
      };
    },
    uploadGalleryImageSuccess(state, action: PayloadAction<{ path: string; key: string }>) {
      const { key } = action.payload;
      state.fileUploader = {
        ...state.fileUploader,
        [key]: undefined,
      };
    },
    deleteGalleryImage(state, action: PayloadAction<number>) {},
    deleteGalleryImageSuccess(state) {},
    deleteGalleryImageError(state) {},
    deleteBrandingImage(state, action: PayloadAction<ImageBrandType>) {},
    updatePaperConfig(state, action: PayloadAction<{ isActivated: boolean; event: CakeActivityType }>) {},
  },
});

export const {
  getProjectConfig,
  setProjectConfig,
  cancelProjectConfigLoad,
  setProjectConfigError,
  updateProjectConfig,
  updateProjectConfigSuccess,
  updateProjectConfigError,
  updateProjectContractConfig,
  updateProjectDappConfig,
  updateTypography,
  updateTypographyError,
  updateTypographySuccess,
  uploadImage,
  uploadImageError,
  uploadImageSuccess,
  updateProjectActivePlugins,
  updateProjectActivePluginsFailed,
  updateProjectActivePluginsSuccess,
  updateProjectFieldConfigSuccess,
  uploadGalleryImage,
  uploadGalleryImageError,
  uploadGalleryImageSuccess,
  deleteGalleryImage,
  deleteGalleryImageSuccess,
  deleteGalleryImageError,
  deleteBrandingImage,
  updatePaperConfig,
} = projectConfigState.actions;
export default projectConfigState.reducer;
