import { Stack, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';

type UploadBoxProps = PropsWithChildren<{
  text: string;
  helpText?: string;
  helpTextColor?: string;
}>;

const UploadBox = ({ children, text, helpText, helpTextColor }: UploadBoxProps) => {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      spacing={2}
    >
      {children}

      <Stack>
        <Typography
          fontWeight={600}
          variant="body2"
        >
          {text}
        </Typography>

        <Typography
          color={helpTextColor}
          fontStyle={'italic'}
          variant="body3"
        >
          {helpText ?? '-'}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UploadBox;
