import './TemplateSettingsCard.scss';

import { Stack } from '@mui/system';

import DashboardCard from '~components/dashboard/DashboardCard';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updateProjectDappConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { Templates } from '~types/ProjectType';

import TemplateBox from './components/TemplateBox';
import { templateOptions } from './TemplateSettingsCard.consts';

const TemplateSettingsCard = () => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const { dapp } = projectConfig;
  const { template } = dapp;
  const selectedTemplate = template ?? Templates.Vanilla;

  const onUpdateTemplate = (value: Templates) => {
    if (value === selectedTemplate) {
      return;
    }

    dispatch(
      updateProjectDappConfig({
        dapp: { ...dapp, template: value },
        event: {
          content: {
            event: 'dApp Settings Update',
            description: `Updated template to ${value}.`,
          },
        },
      }),
    );
  };

  return (
    <DashboardCard
      className="CAKE__template-settings-card"
      title={'Template'}
    >
      <Stack
        direction="row"
        spacing={2}
      >
        {Object.values(templateOptions).map((option) => (
          <TemplateBox
            key={option.template}
            template={option}
            isSelected={selectedTemplate === option.template}
            onSelect={() => onUpdateTemplate(option.template)}
          />
        ))}
      </Stack>
    </DashboardCard>
  );
};

export default TemplateSettingsCard;
