import './ProjectItem.scss';

import { Box, Typography } from '@mui/material';
import moment from 'moment';

import StatusChip from '~components/miscs/StatusChip';
import { DashboardSelectors } from '~constants/cypress/dashboard.selectors';
import placeholder from '~images/common/cake/cake-placeholder.png';
import type { ProjectType } from '~types/ProjectType';

type ProjectItemProps = {
  project: ProjectType;
  onClick: (project: ProjectType) => void;
};
const ProjectItem = ({ project, onClick }: ProjectItemProps) => {
  return (
    <Box
      onClick={() => onClick(project)}
      className={'CAKE__project-table__item'}
      data-cy={DashboardSelectors.Project}
    >
      <Box className={'CAKE__project-table__item__image'}>
        <img
          src={project.dapp?.images?.gallery.length ? project.dapp.images.gallery[0] : placeholder}
          onError={(e) => {
            const image = e.target as HTMLImageElement;
            image.src = placeholder;
          }}
        />
        <Box className={'CAKE__project-table__item__status'}>
          <StatusChip status={project.status} />
        </Box>
      </Box>
      <Box className={'CAKE__project-table__item__container'}>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '12px' }}
        >
          {project.name}
        </Typography>
        <Typography variant="body2">{`Items: ${project.contract.totalSupply || 'Not defined'}`}</Typography>
        <Typography variant="body2">{`Created: ${moment(project.createdAt).format('MMM, YYYY')}`}</Typography>
        {/* <Typography variant="body2">{`Price: ${project.contract.price}`}</Typography> */}
      </Box>
    </Box>
  );
};

export default ProjectItem;
