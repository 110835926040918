/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import type TokenBlueprintType from '~types/token/TokenBlueprintType';

type CollectionTokenGroupsState = {
  error: string;
  isLoading: boolean;
  groups: Record<string, CollectionTokenGroupType>;
  order: Array<string>;
};

const initialState: CollectionTokenGroupsState = {
  error: null,
  isLoading: false,
  groups: {},
  order: [],
};

const collectionTokenGroupsState = createSlice({
  name: 'collection-token-groups',
  initialState,
  reducers: {
    addTokenGroup(state, action: PayloadAction<Partial<CollectionTokenGroupType>>) {},
    addTokenGroupSuccess(state, action: PayloadAction<CollectionTokenGroupType>) {
      const tokenGroup: CollectionTokenGroupType = action.payload;

      state.groups[tokenGroup._id] = tokenGroup;
      state.order.push(tokenGroup._id);
    },
    addTokenGroupFailed(state, action: PayloadAction<string>) {},
    getTokenGroups(state) {
      state.isLoading = true;
      state.error = null;
      state.groups = initialState.groups;
      state.order = initialState.order;
    },
    getTokenGroupsSuccess(state, action: PayloadAction<Array<CollectionTokenGroupType>>) {
      const { order, groups } = action.payload.reduce(
        (acc, curr) => {
          return {
            order: [...acc.order, curr._id],
            groups: {
              ...acc.groups,
              [curr._id]: curr,
            },
          };
        },
        { order: [], groups: {} },
      );

      state.isLoading = false;
      state.groups = groups;
      state.order = order;
    },
    getTokenGroupsFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeTokenGroup(state, action: PayloadAction<string>) {},
    removeTokenGroupSuccess(state, action: PayloadAction<string>) {
      const tokenGroupId = action.payload;
      delete state.groups[tokenGroupId];

      const index = state.order.indexOf(tokenGroupId);
      state.order.splice(index, 1);
    },
    removeTokenGroupFailed(state) {},
    updateTokenGroup(
      state,
      action: PayloadAction<{ tokenGroup: CollectionTokenGroupType; tokenBlueprint: TokenBlueprintType }>,
    ) {
      state.isLoading = true;
      state.error = null;
    },
    updateTokenGroupSuccess(state, action: PayloadAction<CollectionTokenGroupType>) {
      const tokenGroup = action.payload;

      state.isLoading = false;
      state.groups = {
        ...state.groups,
        [tokenGroup._id]: tokenGroup,
      };
    },
    updateTokenGroupFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateTokenGroupLocally(state, action: PayloadAction<Partial<CollectionTokenGroupType>>) {
      const tokenGroup = action.payload;
      state.groups[tokenGroup._id] = { ...state.groups[tokenGroup._id], ...tokenGroup };
    },
  },
});

export const {
  addTokenGroup,
  addTokenGroupSuccess,
  addTokenGroupFailed,
  getTokenGroups,
  getTokenGroupsSuccess,
  getTokenGroupsFailed,
  removeTokenGroup,
  removeTokenGroupSuccess,
  removeTokenGroupFailed,
  updateTokenGroup,
  updateTokenGroupSuccess,
  updateTokenGroupFailed,
  updateTokenGroupLocally,
} = collectionTokenGroupsState.actions;
export default collectionTokenGroupsState.reducer;
