/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type CollectionItemType from '~types/CollectionItemType';
import type UploadTokenValidationType from '~types/token/UploadTokenValidationType';

type CollectionUploadState = {
  uploadQueue: Array<UploadTokenValidationType>;
  totalItems: number;
  itemsCounter: number;
  uploaded: { [name: string]: boolean };
  errors: Array<string>;
  isTokenPlaceholderImageLoading: boolean;
  tokenPlaceholderImageError: string;
};

const initialState: CollectionUploadState = {
  uploadQueue: [],
  totalItems: 0,
  itemsCounter: 0,
  uploaded: {},
  errors: [],
  isTokenPlaceholderImageLoading: false,
  tokenPlaceholderImageError: '',
};

const collectionUploadSlice = createSlice({
  name: 'collection-upload',
  initialState,
  reducers: {
    addItemsToUploadQueue(state, action: PayloadAction<Array<UploadTokenValidationType>>) {
      state.uploadQueue = [...action.payload, ...state.uploadQueue];
      state.totalItems += action.payload.length;
    },
    removeItemFromUploadQueue(state) {
      state.uploadQueue.shift();
    },
    uploadItems(state, action: PayloadAction<Array<File>>) {},
    uploadItemFinished(state, action: PayloadAction<UploadTokenValidationType>) {
      state.uploaded[action.payload.name] = true;
      state.itemsCounter += 1;
    },
    uploadItemAlreadyExists(state, action: PayloadAction<UploadTokenValidationType>) {},
    uploadItemsFinished(state) {},
    cancelUpload(state) {},
    cancelUploadSuccess(state) {},
    addValidationErrors(state, action: PayloadAction<Array<string>>) {
      state.errors = [...state.errors, ...action.payload];
    },
    updateItemImage(state, action: PayloadAction<{ file: File; token: CollectionItemType }>) {},
    updateItemImageSuccess(state, action: PayloadAction<any>) {},
    updateItemImageError(state, action: PayloadAction<any>) {},
    updateTokenPlaceholderImage(state, action: PayloadAction<{ file: File }>) {},
    setTokenPlaceholderImageLoading(state) {
      state.isTokenPlaceholderImageLoading = true;
    },
    setTokenPlaceholderImageSuccess(state) {
      state.isTokenPlaceholderImageLoading = false;
    },
    setTokenPlaceholderImageError(state, action: PayloadAction<string>) {
      state.isTokenPlaceholderImageLoading = false;
      state.tokenPlaceholderImageError = action.payload;
    },
  },
});

export const {
  addItemsToUploadQueue,
  addValidationErrors,
  removeItemFromUploadQueue,
  uploadItems,
  uploadItemFinished,
  uploadItemAlreadyExists,
  uploadItemsFinished,
  updateItemImage,
  updateItemImageSuccess,
  updateItemImageError,
  updateTokenPlaceholderImage,
  setTokenPlaceholderImageLoading,
  setTokenPlaceholderImageSuccess,
  setTokenPlaceholderImageError,
} = collectionUploadSlice.actions;
export default collectionUploadSlice.reducer;
