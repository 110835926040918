import type { PayloadAction } from '@reduxjs/toolkit';
import type { AllEffect, CallEffectDescriptor, ForkEffect, SimpleEffect } from 'redux-saga/effects';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { getAuthClient } from '~features/auth/auth.client';
import {
  login,
  loginWithSocial,
  logout,
  setIsLoggedIn,
  setSessionId,
  setUser,
  signup,
} from '~features/auth/auth.slice';
import type { Connections, LoginCredentials } from '~features/auth/auth.types';
import { addNotification } from '~features/notifications/notifications.slice';
import Monitoring from '~utils/monitoring';

function* setUserSaga(): Iterator<any> {
  const authClient = getAuthClient();
  const user: string | any | undefined = yield call([authClient, 'getUser']);
  Monitoring.setUser(user);
  yield put(setUser(user));
}

function* initSaga(): Iterator<any> {
  const isAuthenticated: boolean | undefined = yield call(isAuthenticatedSaga);
  const sessionId: string = uuidv4();
  yield put(setSessionId(sessionId));
  Monitoring.setSession(sessionId);
  if (isAuthenticated) {
    yield call(setUserSaga);
  }
  yield put(setIsLoggedIn(!!isAuthenticated));
}

function* getTokenSaga(): Iterator<any, string | null, string | undefined> {
  const authClient = getAuthClient();

  try {
    const token: string | undefined = yield call([authClient, 'getTokenSilently']);

    return token || null;
  } catch (error) {
    console.error(error);

    return null;
  }
}

function* isAuthenticatedSaga(): Iterator<any, boolean, string> {
  const token: string = yield call(getTokenSaga);

  return token ? true : false;
}

function* loginSaga(action: PayloadAction<LoginCredentials>): Iterator<any> {
  const authClient = getAuthClient();

  try {
    yield call([authClient, 'loginWithPassword'], action.payload);
  } catch (e) {
    console.error('Error Logging in', e);
    yield put(
      addNotification({
        message: e.error_description,
        severity: 'error',
        duration: 10000,
      }),
    );
  }

  const isAuthenticated: boolean = yield call(isAuthenticatedSaga);

  yield put(setIsLoggedIn(Boolean(isAuthenticated)));

  if (isAuthenticated) {
    yield call(setUserSaga);
  }
}

function* loginWithSocialSaga(action: PayloadAction<{ connection: Connections }>): Iterator<any> {
  const authClient = getAuthClient();

  try {
    yield call([authClient, 'loginWithSocial'], action.payload);
  } catch (e) {
    // Auth0 redirects immediately after an error for this request, so no point in showing a notification.
    console.error(`Error Logging in with ${action.payload.connection}`, e);
  }
}

function* signupSaga(action: PayloadAction<string>): Iterator<any> {
  const invitationId = action.payload;
  const authClient = getAuthClient();

  yield call([authClient, 'loginWithRedirect'], { fragment: `invitation:${invitationId}` });
}

function* logoutSaga(): Iterator<any> {
  const authClient = getAuthClient();
  yield call([authClient, 'logout']);
  yield put(setIsLoggedIn(false));
}

export default function* authSaga(): Generator<
  AllEffect<unknown> | ForkEffect<any | never> | SimpleEffect<'CALL', CallEffectDescriptor<void>>,
  void,
  void
> {
  yield fork(initSaga);
  yield all([
    yield takeLatest(login.type, loginSaga),
    yield takeLatest(loginWithSocial.type, loginWithSocialSaga),
    yield takeLatest(logout.type, logoutSaga),
    yield takeLatest(signup.type, signupSaga),
  ]);
}

export { getTokenSaga, isAuthenticatedSaga };
