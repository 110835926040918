/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { WhitelistUpdateType } from '~types/WhitelistTypes';

interface WhitelistConfigState {
  whitelist: Array<string>;
  merkleroot: string;
  contractMerkleroot: string;
  isLoading: boolean;
  isUpdating: boolean;
  error: string | null;
}

const initialState: WhitelistConfigState = {
  whitelist: [],
  merkleroot: '',
  contractMerkleroot: '',
  isLoading: false,
  isUpdating: false,
  error: null,
};

const whitelistConfigState = createSlice({
  name: 'whitelist-config',
  initialState,
  reducers: {
    getWhitelistConfig(state, action: PayloadAction<{ projectId: string; forceUpdate: boolean }>) {
      state.isLoading = true;
      state.error = null;
    },
    resetWhitelistConfig(state) {
      state.whitelist = initialState.whitelist;
      state.merkleroot = initialState.merkleroot;
      state.contractMerkleroot = initialState.contractMerkleroot;
      state.isLoading = initialState.isLoading;
      state.isUpdating = initialState.isUpdating;
      state.error = initialState.error;
    },
    setWhitelistConfig(state, action: PayloadAction<{ whitelist: Array<string>; merkleroot: string }>) {
      state.whitelist = action.payload.whitelist;
      state.merkleroot = action.payload.merkleroot;
      state.isLoading = false;
    },
    setContractMerkleroot(state, action: PayloadAction<string>) {
      state.contractMerkleroot = action.payload;
    },
    cancelWhitelistConfigLoad(state) {
      state.isLoading = false;
    },
    setWhitelistConfigError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateWhitelistConfig(state, action: PayloadAction<WhitelistUpdateType>) {
      state.isUpdating = true;
    },
    updateWhitelistConfigSuccess(state) {
      state.isUpdating = false;
    },
    updateWhitelistConfigError(state, action: PayloadAction<string>) {
      state.isUpdating = false;
      state.error = action.payload;
    },
  },
});

export const {
  getWhitelistConfig,
  setWhitelistConfig,
  setContractMerkleroot,
  resetWhitelistConfig,
  cancelWhitelistConfigLoad,
  setWhitelistConfigError,
  updateWhitelistConfig,
  updateWhitelistConfigSuccess,
  updateWhitelistConfigError,
} = whitelistConfigState.actions;
export default whitelistConfigState.reducer;
