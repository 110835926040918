/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type VRFState = {
  status: string;
  isTxnProcessing: boolean;
  isTxnSuccess: boolean;
  error: string;
};

const initialState: VRFState = {
  status: '',
  isTxnProcessing: false,
  isTxnSuccess: false,
  error: '',
};

const vrfSlice = createSlice({
  name: 'vrf',
  initialState,
  reducers: {
    requestRandomNumber(state, action: PayloadAction<{ network: string; provider: any; walletAddress: string }>) {},
    setVrfTxnProcessing(state, action: PayloadAction<boolean>) {
      state.isTxnProcessing = action.payload;
    },
    setVrfTxnStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    callVRFError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isTxnProcessing = false;
    },
    callVRFSuccess(state, action: PayloadAction<string>) {
      state.status = '';
      state.isTxnProcessing = false;
      state.isTxnSuccess = true;
      state.error = '';
    },
  },
});

export const { requestRandomNumber, setVrfTxnProcessing, callVRFError, setVrfTxnStatus, callVRFSuccess } =
  vrfSlice.actions;

export default vrfSlice.reducer;
