import type { ContractType } from '~types/ProjectType';

import getCurrentContractInfo from './get-current-contract-info';

const getCurrentContractAddress = (contract: ContractType): string => {
  const contractInfo = getCurrentContractInfo(contract);
  return contractInfo?.contractAddress;
};

export default getCurrentContractAddress;
