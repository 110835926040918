import { Box } from '@mui/material';
import { useRef } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import { ProjectSelectors } from '~constants/cypress/project.selectors';
import useOutsideClick from '~hooks/useOutsideClick';

type ProjectNameFieldProps = {
  onChange: (value: string) => void;
  onClickOutside: () => void;
  projectName: string;
};
const ProjectNameField = ({ onChange, onClickOutside, projectName }: ProjectNameFieldProps) => {
  const input = useRef();

  useOutsideClick(input, () => onClickOutside());

  return (
    <Box ref={input}>
      <V2TextInput
        autoFocus
        data-cy={ProjectSelectors.EditNameTextField}
        label="Project Name"
        placeholder="Your project name"
        value={projectName}
        onUpdate={(value) => onChange(value)}
        variant="standard"
      />
    </Box>
  );
};

export default ProjectNameField;
