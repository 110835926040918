/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type TokenBlueprintType from '~types/token/TokenBlueprintType';

type TokenBlueprintsState = {
  status: Record<string, string>;
  blueprints: Record<string, TokenBlueprintType>;
};

const initialState: TokenBlueprintsState = {
  status: {},
  blueprints: {},
};

const tokenBlueprintsState = createSlice({
  name: 'token-blueprints',
  initialState,
  reducers: {
    getTokenBlueprint(state, action: PayloadAction<string>) {
      const blueprinId = action.payload;
      state.status = {
        ...state.status,
        [blueprinId]: 'loading',
      };
    },
    getTokenBlueprintSuccess(state, action: PayloadAction<TokenBlueprintType>) {
      const blueprint = action.payload;
      state.status = {
        ...state.status,
        [blueprint._id]: undefined,
      };
      state.blueprints = {
        ...state.blueprints,
        [blueprint._id]: blueprint,
      };
    },
    getTokenBlueprintFailed(state, action: PayloadAction<Record<string, string>>) {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    updateBlueprintImage(state, action: PayloadAction<{ blueprint: TokenBlueprintType; file: File }>) {},
    updateBlueprint(state, action: PayloadAction<TokenBlueprintType>) {
      const { _id: blueprinId } = action.payload;
      state.status = {
        ...state.status,
        [blueprinId]: 'updating',
      };
    },
    updateBlueprintSuccess(state, action: PayloadAction<TokenBlueprintType>) {
      const blueprint = action.payload;
      state.status = {
        ...state.status,
        [blueprint._id]: undefined,
      };
      state.blueprints = {
        ...state.blueprints,
        [blueprint._id]: blueprint,
      };
    },
    updateBlueprintFailed(state, action: PayloadAction<Record<string, string>>) {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    storeBlueprint(state, action: PayloadAction<TokenBlueprintType>) {
      const blueprint = action.payload;
      state.blueprints = {
        ...state.blueprints,
        [blueprint._id]: blueprint,
      };
    },
  },
});

export const {
  getTokenBlueprint,
  getTokenBlueprintSuccess,
  getTokenBlueprintFailed,
  updateBlueprint,
  updateBlueprintImage,
  updateBlueprintSuccess,
  updateBlueprintFailed,
  storeBlueprint,
} = tokenBlueprintsState.actions;
export default tokenBlueprintsState.reducer;
