import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import CollectionArtworksCard from '~components/dashboard/CollectionArtworksCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import type { ContractNetwork } from '~constants/networks/networks';
import { callDeployedContractState, resetContractConfig } from '~features/contract-config/contract-config.slice';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import getProjectNetwork from '~utils/projects/get-network';
import NotFoundView from '~views/NotFoundView';

const ProjectCollectionView = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const error = useAppSelector(selectProjectConfigError);
  const projectConfig = useAppSelector(selectProjectConfig);
  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  useEffect(() => {
    if (projectConfig) {
      const network: ContractNetwork = getProjectNetwork(projectConfig);
      dispatch(callDeployedContractState({ projectId, network }));
    }

    return () => {
      dispatch(resetContractConfig());
    };
  }, [isLoading]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error || !projectConfig) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <CollectionArtworksCard />
    </PageLayout>
  );
};

export default ProjectCollectionView;
