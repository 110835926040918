import type { PaymentShareType, ProjectType } from '~types/ProjectType';
import walletAddressValidator from '~utils/web3/validators/wallet-address-validator';
import { ProjectBuilderSteps } from '~views/projects/ProjectBuilderView/ProjectBuilderView.constants';

const isBasicInfoValid = (projectConfig: ProjectType) => projectConfig.name.length !== 0;

const isSuperAdminAddressValid = (address: string) => walletAddressValidator(address);

const areSplitPaymentRecipientsValid = (paymentSplit: Array<PaymentShareType>) => {
  const validationResult = paymentSplit.reduce(
    (acc, { address, amount }) => {
      if (!acc.areRecipientsValid) {
        return {
          ...acc,
          total: acc.total + amount,
        };
      }
      return {
        areRecipientsValid: walletAddressValidator(address),
        total: acc.total + amount,
      };
    },
    { total: 0, areRecipientsValid: true },
  );

  return validationResult.total === 100 && validationResult.areRecipientsValid;
};

const canMoveToNextStep = (projectConfig: ProjectType, step: number) => {
  switch (step) {
    case ProjectBuilderSteps.BASIC_INFO:
      return isBasicInfoValid(projectConfig);
    case ProjectBuilderSteps.SUPER_ADMIN_ADDRESS:
      return isSuperAdminAddressValid(projectConfig.contract.superAdmin);
    case ProjectBuilderSteps.SPLIT_PAYMENT:
      return areSplitPaymentRecipientsValid(projectConfig.contract.paymentSplit);
  }

  return true;
};

const projectConfigCompleted = (projectConfig: ProjectType) => {
  return (
    isBasicInfoValid(projectConfig) &&
    isSuperAdminAddressValid(projectConfig.contract.superAdmin) &&
    areSplitPaymentRecipientsValid(projectConfig.contract.paymentSplit)
  );
};

const getStepCount = (): number => {
  return Object.keys(ProjectBuilderSteps).length;
};

export { canMoveToNextStep, getStepCount, projectConfigCompleted };
