import agreementsConfigSlice from '~features/agreements-config/agreements-config.slice';
import analyticsSlice from '~features/analytics/analytics.slice';
import authSlice from '~features/auth/auth.slice';
import collectionItemsSlice from '~features/collection-items/collection-items.slice';
import collectionTokenGroupsSlice from '~features/collection-token-groups/collection-token-groups.slice';
import collectionUploadSlice from '~features/collection-upload/collection-upload.slice';
import contractConfigSlice from '~features/contract-config/contract-config.slice';
import contractDeployerSlice from '~features/contract-deployer/contract-deployer.slice';
import contractManagerSlice from '~features/contract-manager/contract-manager.slice';
import imageGenerationsSlice from '~features/image-generations/image-generations.slice';
import notificationsSlice from '~features/notifications/notifications.slice';
import partnerPluginsSlice from '~features/partner-plugins/partner-plugins.slice';
import projectBuilderSlice from '~features/project-builder/project-builder.slice';
import projectConfigSlice from '~features/project-config/project-config.slice';
import projectConentSlice from '~features/project-content/project-content.slice';
import projectDataRoomSlice from '~features/project-data-room/project-data-room.slice';
import projectDropSlice from '~features/project-drop/project-drop.slice';
import projectDropsManagerSlice from '~features/project-drops-manager/project-drops-manager.slice';
import projectShopifySlice from '~features/project-shopify/project-shopify.slice';
import projectsListSlice from '~features/projects-list/projects-list.slice';
import pubsubSlice from '~features/pubsub/pubsub.slice';
import streamsSlice from '~features/streams/streams.slice';
import tokenBlueprintsSlice from '~features/token-blueprints/token-blueprints.slice';
import tokenGatePolicyConfigSlice from '~features/token-gate-policy/token-gate-policy.slice';
import tokenGroupUpdatesSlice from '~features/token-group-updates/token-group-updates.slice';
import userInvitationSlice from '~features/user-invitation/user-invitation.slice';
import vrfSlice from '~features/vrf/vrf.slice';
import whitelistConfigSlice from '~features/whitelist-config/whitelist-config.slice';

const reducers = {
  agreementsConfig: agreementsConfigSlice,
  analytics: analyticsSlice,
  auth: authSlice,
  collectionTokenGroups: collectionTokenGroupsSlice,
  collectionItems: collectionItemsSlice,
  contractManager: contractManagerSlice,
  collectionUpload: collectionUploadSlice,
  contractConfig: contractConfigSlice,
  contractDeployer: contractDeployerSlice,
  imageGenerations: imageGenerationsSlice,
  notifications: notificationsSlice,
  partnerPlugins: partnerPluginsSlice,
  projectBuilder: projectBuilderSlice,
  projectConfig: projectConfigSlice,
  projectContent: projectConentSlice,
  projectDataRoom: projectDataRoomSlice,
  projectDropsManager: projectDropsManagerSlice,
  projectDrop: projectDropSlice,
  projectShopify: projectShopifySlice,
  projectsList: projectsListSlice,
  pubsub: pubsubSlice,
  tokenBlueprints: tokenBlueprintsSlice,
  tokenGatePolicyConfig: tokenGatePolicyConfigSlice,
  tokenGroupUpdates: tokenGroupUpdatesSlice,
  streams: streamsSlice,
  userInvitation: userInvitationSlice,
  vrf: vrfSlice,
  whitelistConfig: whitelistConfigSlice,
};

export default reducers;
