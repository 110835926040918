import './ContactTransactionsItem.scss';

import { TableCell, TableRow } from '@mui/material';
import { Stack } from '@mui/system';

import CopyValueIcon from '~components/miscs/CopyValueIcon';
import trimWalletAddress from '~utils/contracts/trim-wallet-address';
import etherscanLinkBuilder from '~utils/url-builder/etherscanLinkBuilder/etherscanLinkBuilder';

import ContractTransactionsUserWallet from './ContactTransactionsUserWallet';

type ContractTransactionsItemProps = {
  transaction: any;
  chainId?: number;
};

const ContractTransactionsItem = ({ chainId, transaction }: ContractTransactionsItemProps) => {
  return (
    <TableRow
      hover
      key={`${transaction.transactionHash}-${transaction.tokenId}-${transaction._id}`}
    >
      <TableCell align="left">
        <Stack
          direction={'row'}
          alignItems={'center'}
        >
          <a
            className={'CAKE__contract-transaction-item__link'}
            href={etherscanLinkBuilder({
              chainId,
              type: 'txn',
              data: { transactionHash: transaction.transactionHash },
            })}
            target="_blank"
            rel="noreferrer"
          >
            {trimWalletAddress(transaction.transactionHash)}
          </a>
          <CopyValueIcon value={transaction.transactionHash} />
        </Stack>
      </TableCell>
      <TableCell align="left">
        <ContractTransactionsUserWallet
          address={transaction.from}
          chainId={chainId}
        />
      </TableCell>
      <TableCell align="left">
        <ContractTransactionsUserWallet
          address={transaction.to}
          chainId={chainId}
        />
      </TableCell>
      <TableCell align="center">
        <a
          className={'CAKE__contract-transaction-item__link'}
          href={etherscanLinkBuilder({
            chainId,
            type: 'tokenId',
            data: { tokenId: transaction.tokenId, address: transaction.contractAddress },
          })}
          target="_blank"
          rel="noreferrer"
        >
          {transaction.tokenId}
        </a>
      </TableCell>
      <TableCell align="left">
        <div className={'CAKE__contract-transaction-item__pill'}>{transaction.isMint ? 'Mint' : 'Transfer'}</div>
      </TableCell>
      <TableCell align="center">
        {transaction.value ? `${Number(transaction.value) / Number(10 ** 18)} Eth` : '-'}
      </TableCell>
      <TableCell align="left">{new Date(transaction.date).toLocaleDateString()}</TableCell>
    </TableRow>
  );
};

export default ContractTransactionsItem;
