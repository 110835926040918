import { orange } from '@mui/material/colors';
import type { CSSInterpolation } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xsmall: true;
  }
}

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: '#599CFF',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#111111',
    },
    success: {
      main: '#20D111',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            pointerEvents: 'unset', // allow :hover styles to be triggered
            cursor: 'pointer',
            color: '#599CFF',
          },
          borderRadius: '8px',
        },
      },
      defaultProps: {
        disableElevation: true,
        style: {},
      },
      variants: [
        {
          props: { size: 'xsmall' },
          style: {
            borderRadius: '2px',
            height: '24px',
            fontSize: '12px',
            fontWeight: '500',
            padding: '4px 12px',
          },
        },
      ],
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, .03)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, .1)',
          boxShadow: 'none',
          margin: 0,

          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        } as CSSInterpolation,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, .1)',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    body1: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 800,
      fontSize: '28px',
    },
    body2: {
      fontWeight: 500,
    },
    body3: {
      color: 'rgba(0,0,0, 0.4)',
      fontSize: 12,
    },
    h6: {
      fontWeight: 700,
    },
    button: {
      fontSize: 'inherit',
      textTransform: 'inherit',
    },
  },
});

export default theme;
