import '~components/auth/MultiFactorAuth/MultiFactorAuth.scss';

import { Stack, Typography } from '@mui/material';

import { getRedactedEmail } from '~utils/auth/get-redacted-email';

type MultiFactorAuthProps = {
  code: number[];
  email: string;
  onCodeUpdate: (value: string, index: number) => void;
};

const MultiFactorAuth = ({ code, email, onCodeUpdate }: MultiFactorAuthProps) => {
  return (
    <Stack className={'CAKE__multifactor-auth'}>
      <Typography>We have sent a one-time code to {getRedactedEmail(email)}</Typography>

      <Stack
        className={'CAKE__multifactor-auth__input-container'}
        direction="row"
        spacing={2}
      >
        {code.map((num, index) => {
          return (
            <input
              className={'CAKE__multifactor-auth__input'}
              key={index}
              value={num ? String(num) : ''}
              maxLength={1}
              onChange={(e) => onCodeUpdate(e.target.value, index)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default MultiFactorAuth;
