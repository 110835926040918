type IPFSFileType = {
  id: string;
  cid: string;
  url: string;
  name: string;
};

type DataRoomObjectType = {
  _id: string;
  owner: string;
  status: string;
  category: string;
  isDownloadable: boolean;
  ipfsFile: IPFSFileType;
  isArchived: boolean;
  created: string;
  updated: string;
  policyId: string;
};

enum FileTypes {
  Text = 'text',
  Pdf = 'pdf',
  Image = 'image',
  Spreadsheet = 'spreadsheet',
  Video = 'video',
}

export default DataRoomObjectType;

export { FileTypes };
