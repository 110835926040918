import './DetailedAnalyticsInformation.scss';

import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';

import SegmentedButton from '~components/buttons/SegmentedButton/SegmentedButton';
import ContractTransactionsTable from '~components/dashboard/ContractTransactionsTable/ContractTransactionsTable';
import TokenHoldersTable from '~components/dashboard/TokenHoldersTable/TokenHoldersTable';
import V2TextInput from '~components/inputs/V2TextInput';
import { selectTransferData, selectWalletHolders } from '~features/analytics/analytics.selectors';
import useAppSelector from '~hooks/useAppSelector';
import type { TokenHolder, TransferDataType } from '~types/AnalyticsType';
import downloadDataAsCsv from '~utils/csv/download-data-as-csv';
import generateCsvData from '~utils/csv/generate-csv-data';

interface DetailedAnalyticsInformationProps {
  chainId?: number;
}

enum Tab {
  TRANSACTIONS = 'Txns',
  HOLDERS = 'Holders',
}

const tokenHoldersCsvColumns = {
  walletAddress: 'Wallet Address',
  email: 'Email',
  count: 'Total Tokens',
  update: 'Updates',
  date: 'Last Activity',
};

const transactionsCsvColumns = {
  transactionHash: 'Transaction',
  from: 'From',
  to: 'To',
  tokenId: 'Token',
  isMint: 'is Mint',
  value: 'Value',
  date: 'Date',
};

const getFilename = (tab: Tab) => {
  switch (tab) {
    case Tab.TRANSACTIONS:
      return 'transactions';
    case Tab.HOLDERS:
      return 'token-holders';
  }
};

const getCsvColumns = (tab: Tab) => {
  switch (tab) {
    case Tab.TRANSACTIONS:
      return transactionsCsvColumns;
    case Tab.HOLDERS:
      return tokenHoldersCsvColumns;
  }
};

const DetailedAnalyticsInformation = ({ chainId }: DetailedAnalyticsInformationProps) => {
  const [currentTab, setCurrentTab] = useState(Tab.TRANSACTIONS);
  const [searchFilter, setSearchFilter] = useState('');
  const walletHolders: TokenHolder[] = useAppSelector(selectWalletHolders);
  const transactions: TransferDataType[] = useAppSelector(selectTransferData);
  const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
  const [filteredHolders, setFilteredHolders] = useState<any[]>([]);

  useEffect(() => {
    if (!filteredTransactions.length || !filteredHolders.length) {
      setFilteredTransactions(transactions);
      setFilteredHolders(walletHolders);
    }
  }, [transactions, walletHolders]);

  const getCurrentData = (tab: Tab) => {
    switch (tab) {
      case Tab.TRANSACTIONS:
        return transactions;
      case Tab.HOLDERS:
        return walletHolders;
    }
  };

  const applySearchFilter = (data: any[], filter) => {
    const filteredList = data.filter((record: TransferDataType & TokenHolder) => {
      const filterValues = [
        record?.transactionHash,
        record?.to,
        record?.from,
        record?.tokenId,
        record?.walletAddress,
        record?.email,
      ];
      for (let i = 0; i < filterValues.length; i++) {
        if (String(filterValues[i]).toLowerCase().includes(filter)) {
          return record;
        }
      }
    });
    return filteredList;
  };

  const handleSerachFiltering = (val: string) => {
    setSearchFilter(val);

    if (!val) {
      setFilteredTransactions(transactions);
      setFilteredHolders(walletHolders);
      return;
    }

    if (!filteredTransactions.length || !filteredHolders.length) {
      [transactions, walletHolders].forEach((data: TransferDataType[] | TokenHolder[], index: number) => {
        const filteredData = applySearchFilter(data, val.toLowerCase());
        if (index === 0) {
          setFilteredTransactions(filteredData);
        } else {
          setFilteredHolders(filteredData);
        }
      });
    } else {
      [filteredTransactions, filteredHolders].forEach((data: TransferDataType[] | TokenHolder[], index: number) => {
        const filteredData = applySearchFilter(data, val.toLowerCase());
        if (index === 0) {
          setFilteredTransactions(filteredData);
        } else {
          setFilteredHolders(filteredData);
        }
      });
    }
  };

  return (
    <Box className={'CAKE__detailed-analytics-information'}>
      <Box className={'CAKE__detailed-analytics-information__top-container'}>
        <SegmentedButton
          onChange={(value) => setCurrentTab(value as Tab)}
          options={Object.values(Tab)}
          value={currentTab.toString()}
        />
        <Stack
          direction="row"
          gap={2}
        >
          <V2TextInput
            debounce={null}
            name="search"
            label="Search"
            placeholder="txnHash, walletAddress, tokenId"
            variant="standard"
            value={searchFilter}
            sx={{ minWidth: '350px' }}
            onUpdate={(val) => handleSerachFiltering(val)}
            inputProps={{ sx: { fontSize: '12px' } }}
          />
          <Typography
            className={'CAKE__detailed-analytics-information__download-btn'}
            onClick={() => {
              const data = generateCsvData(getCsvColumns(currentTab), getCurrentData(currentTab));

              downloadDataAsCsv(getFilename(currentTab), data);
            }}
          >
            Download CSV
          </Typography>
        </Stack>
      </Box>
      {(() => {
        switch (currentTab) {
          case Tab.TRANSACTIONS:
            return (
              <ContractTransactionsTable
                chainId={chainId}
                transactions={filteredTransactions}
              />
            );
          case Tab.HOLDERS:
            return <TokenHoldersTable walletHolders={filteredHolders} />;
        }
        return null;
      })()}
    </Box>
  );
};

export default DetailedAnalyticsInformation;
