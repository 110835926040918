import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { ContractNetworkIds } from '~constants/networks/networks';
import { sendTransaction } from '~context/WalletContext/Wallet.helpers';
import { callDeployedContractState } from '~features/contract-config/contract-config.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { fetchAPI } from '~features/utils/api/api.sagas';
import type { ProjectType } from '~types/ProjectType';
import type { TransactionConfirmation } from '~types/Web3Type';
import { confirmTransactions, getEthTransactionParams } from '~utils/web3/web3';

import { callContractFunction, callContractManagerError, setIsTxnProcessing } from './contract-manager.slice';

function* callContractFunctionSaga(
  action: PayloadAction<{
    network: string;
    provider: any;
    walletAddress: string;
    functionName: string;
    args: Array<number | string>;
  }>,
) {
  const { network, provider, walletAddress, functionName, args } = action.payload;
  const projectConfig: ProjectType = yield select(selectProjectConfig);

  try {
    const path = `public/contract/${projectConfig._id}/${network}?name=${functionName}`;
    const res: Response = yield call(fetchAPI, path);
    const data = yield res.json();
    const transactionParams = yield call(
      getEthTransactionParams,
      provider,
      walletAddress,
      data.diamondAddress,
      data.abi.find((func) => func.name === functionName),
      `0x${ContractNetworkIds[network]}` ?? '',
      args,
    );
    yield put(setIsTxnProcessing(true));
    const txnHashArray = yield call(sendTransaction, provider, [transactionParams]);
    const txnStatusArray = yield confirmTransactions(provider, txnHashArray);
    const successfulTxns: Array<TransactionConfirmation> = txnStatusArray.filter(
      (txnStatus: TransactionConfirmation) => txnStatus.status === 'success',
    );

    yield put(
      addNotification({
        message: successfulTxns.length === txnStatusArray.length ? 'Transaction succeeded' : 'Transaction failed',
        severity: successfulTxns.length === txnStatusArray.length ? 'success' : 'error',
        duration: 5000,
      }),
    );
    yield put(
      callDeployedContractState({
        projectId: projectConfig._id,
        network,
      }),
    );
    yield put(setIsTxnProcessing(false));
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'There was an error while handling your request.',
        severity: 'error',
        duration: 5000,
      }),
    );
    yield put(setIsTxnProcessing(false));
    yield put(callContractManagerError(e));
  }

  return;
}

export default function* contractManagerSaga(): Iterator<any> {
  yield all([yield takeLatest(callContractFunction.type, callContractFunctionSaga)]);
}
