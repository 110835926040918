import '~components/dashboard/ProjectTimeline/ProjectTimeline.scss';

import AppsIcon from '@mui/icons-material/Apps';
import ConstructionIcon from '@mui/icons-material/Construction';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Stack } from '@mui/system';
import moment from 'moment';

import ActivityContent from '~components/dashboard/ProjectTimeline/components/ActivityContent/ActivityContent';
import { isDuplicateEvent } from '~components/dashboard/ProjectTimeline/ProjectTimeline.helpers';
import ProjectVerbs from '~constants/streams/ProjectVerbs';
import { ActivityIcon, AnalyticsIcon, ContractIcon, DappSettingsIcon } from '~icons/project/menu';
import type { FeedAggregateActivityType } from '~types/Streams';

import GroupedDateLineBreak from './GroupedDateLineBreak';

type FeedItemProps = {
  data: FeedAggregateActivityType;
  prevItem: FeedAggregateActivityType | null;
};

const FeedItem = ({ data, prevItem }: FeedItemProps) => {
  if (!ProjectVerbs.includes(data.verb) || isDuplicateEvent(data, prevItem)) {
    return <></>;
  }

  return (
    <Stack direction={'column'}>
      <GroupedDateLineBreak
        currentItm={data}
        prevItem={prevItem}
      />
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          {data.updated_at ? moment(new Date(`${data.updated_at}Z`)).format('H:mm:ss') : <></>}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector sx={{ width: '1px', color: 'rgba(0, 0, 0, 0.03)' }} />
        </TimelineSeparator>
        <TimelineContent>
          <Stack
            direction={'row'}
            gap={1}
          >
            {(() => {
              switch (data.verb) {
                case 'cakeConfig':
                  return <ConstructionIcon />;
                case 'dapp':
                  return <DappSettingsIcon />;
                case 'contract':
                  return <ContractIcon />;
                case 'collections':
                  return <AppsIcon />;
                case 'analytics':
                  return <AnalyticsIcon />;
                default:
                  return <ActivityIcon />;
              }
            })()}
            <ActivityContent data={data} />
          </Stack>
        </TimelineContent>
      </TimelineItem>
    </Stack>
  );
};

export default FeedItem;
