/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { ContractNetwork } from '~constants/networks/networks';
import { type ProjectType, TypographySources } from '~types/ProjectType';

type ProjectBuilderState = {
  currentStep: number;
  project: ProjectType;
  imageFile: File | null;
  status: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string;
  };
};

const initialState: ProjectBuilderState = {
  currentStep: 0,
  project: {
    name: '',
    slug: '',
    contract: {
      abi: '',
      livenet: {
        network: ContractNetwork.BASE_MAINNET,
        contractAddress: '',
        isVerified: false,
        facets: [],
      },
      testnet: {
        network: ContractNetwork.BASE_SEPOLIA,
        contractAddress: '',
        isVerified: false,
        facets: [],
      },
      maxMintTotal: null,
      maxMintTxn: null,
      minMint: 1,
      merkleroot: '0x0000000000000000000000000000000000000000000000000000000000000000',
      price: 0.08,
      paymentSplit: [],
      royalty: {
        address: '',
        amount: 0,
      },
      superAdmin: '',
      symbol: null,
      totalSupply: 100,
      whitelist: [],
      presale: moment().valueOf(),
      publicSale: moment().valueOf(),
      closeDate: moment().valueOf(),
    },
    dapp: {
      description: '',
      images: {
        banner: '',
        galleryColumns: 1,
        gallery: [],
      },
      social: {},
      mintAgreementId: null,
      typography: {
        fontDisplay: {
          source: TypographySources.Default,
          url: '../fonts/Druk Wide Bold.woff2',
          name: 'Druk Wide',
          family: 'Druk Wide',
          weight: 900,
        },
        fontBody: {
          source: TypographySources.Default,
          name: 'IBM Plex Mono',
          family: 'IBM Plex Mono',
          weight: 400,
        },
      },
    },
    status: 'DRAFT',
    paper: {
      isActivated: false,
      contractId: {
        goerli: '',
        ethereum: '',
        base: '',
        basegoerli: '',
        sepolia: '',
      },
      isFixed: null,
    },
  },
  imageFile: null,
  status: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
};

const projectBuilderSlice = createSlice({
  name: 'project-builder',
  initialState,
  reducers: {
    createProject(state) {
      state.status.isLoading = true;
      state.status.isSuccess = false;
      state.status.error = null;
    },
    createProjectSuccess(state, action: PayloadAction<string>) {
      state.project = { ...state.project, _id: action.payload };
      state.status.isSuccess = true;
      state.status.error = null;
    },
    createProjectError(state, action: PayloadAction<string>) {
      state.status.isLoading = false;
      state.status.isSuccess = false;
      state.status.error = action.payload;
    },
    nextStep(state) {},
    prevStep(state) {},
    setStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setProjectConfig(state, action: PayloadAction<ProjectType>) {
      state.project = action.payload;
    },
    resetProjectConfig(state) {
      state.currentStep = initialState.currentStep;
      state.project = initialState.project;
      state.status = initialState.status;
    },
    setImageFile(state, action: PayloadAction<File>) {
      state.imageFile = action.payload;
    },
  },
});

export const {
  createProject,
  createProjectSuccess,
  createProjectError,
  nextStep,
  prevStep,
  setStep,
  setProjectConfig,
  resetProjectConfig,
  setImageFile,
} = projectBuilderSlice.actions;
export default projectBuilderSlice.reducer;
