import { GridViewOutlined } from '@mui/icons-material';
import { Button, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import classNames from 'classnames';
import { useState } from 'react';

import type { TemplateOption } from '~components/dashboard/TemplateSettingsCard/TemplateSettingsCard.consts';

import TemplatePreview from './TemplatePreview';

type TemplateBoxProps = {
  onSelect: () => void;
  isSelected: boolean;
  template: TemplateOption;
};

const TemplateBox = ({ isSelected, onSelect, template }: TemplateBoxProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const onPreviewClick = (event) => {
    event.stopPropagation();
    setPreviewOpen(true);
  };

  const onSelectFromPreview = () => {
    onSelect();
    setPreviewOpen(false);
  };

  return (
    <>
      <Box
        className={classNames('CAKE__template-settings-card__template', {
          'CAKE__template-settings-card__template--selected': isSelected,
        })}
        onClick={onSelect}
      >
        <Typography variant="body2">{template.name}</Typography>

        <Stack>
          <Tooltip title="Preview Template">
            <Button
              color="secondary"
              variant="text"
              onClick={onPreviewClick}
            >
              <GridViewOutlined />
              <Typography
                fontSize={'small'}
                fontWeight={600}
                ml={1}
              >
                Preview
              </Typography>
            </Button>
          </Tooltip>
        </Stack>
      </Box>

      <TemplatePreview
        isOpen={previewOpen}
        isSelected={isSelected}
        onClose={() => setPreviewOpen(false)}
        onSelect={onSelectFromPreview}
        template={template}
      />
    </>
  );
};

export default TemplateBox;
