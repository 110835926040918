import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { selectAgreementConfig } from '~features/agreements-config/agreements-config.selectors';
import {
  createAgreement,
  createAgreementError,
  createAgreementSuccess,
  getAgreementConfig,
  setAgreementConfig,
  updateAgreementConfig,
  uploadAgreementFile,
} from '~features/agreements-config/agreements-config.slice';
import {
  selectProjectConfig,
  selectProjectDappConfig,
  selectProjectId,
  selectProjectMintAgreementId,
} from '~features/project-config/project-config.selectors';
import { updateProjectDappConfig } from '~features/project-config/project-config.slice';
import { addStreamActivity } from '~features/streams/streams.slice';
import { fetchProtectedAPI, postProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type { AgreementType } from '~types/AgreementTypes';
import type { DappSettingsType, ProjectType } from '~types/ProjectType';
import type { CakeActivityType } from '~types/Streams';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

window.Buffer = window.Buffer || Buffer;

function* createAgreementSaga(): Iterator<any> {
  const projectConfigDapp: DappSettingsType = yield select(selectProjectDappConfig);

  try {
    const res: Response = yield call(postProtectedAPI, 'agreements', {});
    const data: any = yield res.json();
    if (res.status === 200 && data && projectConfigDapp) {
      const updatedDapp: DappSettingsType = {
        ...projectConfigDapp,
        mintAgreementId: String(data._id),
      };

      const event: CakeActivityType = {
        content: {
          event: 'dApp Settings Update',
          description: 'Mint Agreement Created',
        },
      };
      yield put(updateProjectDappConfig({ dapp: updatedDapp, event }));
      yield put(createAgreementSuccess(data));
      yield put(getAgreementConfig(data._id));
    }
  } catch (e) {
    console.log(e);
    yield put(createAgreementError());
  }
}

function* fetchAgreementSaga(): Iterator<any> {
  const id = yield select(selectProjectMintAgreementId);

  try {
    if (id) {
      const path = `agreements/${id}`;
      const res: Response = yield call(fetchProtectedAPI, path, {});

      if (res.status === 200) {
        const data: AgreementType = yield res.json();
        yield put(setAgreementConfig(data));
      } else {
        throw new Error('Error retrieving collections data');
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateAgreementSaga(
  action: PayloadAction<{ updatedAgreementData: AgreementType; event?: CakeActivityType }>,
) {
  const { updatedAgreementData, event } = action.payload;
  const projectId = yield select(selectProjectId);
  const currentAgreementConfig: AgreementType = yield select(selectAgreementConfig);
  const agreementId = currentAgreementConfig._id;

  const updatedAgreementConfig = {
    ...currentAgreementConfig,
    ...updatedAgreementData,
  };

  const path = `agreements/${agreementId}`;
  try {
    const res: Response = yield call(putProtectedAPI, path, updatedAgreementConfig);

    if (res.status === 200) {
      const data: AgreementType = yield res.json();
      yield put(setAgreementConfig(data));

      if (event) {
        yield put(
          addStreamActivity({
            verb: 'dapp',
            object: {
              ...event,
            },
            projectId,
          }),
        );
      }
    } else {
      throw new Error('Error updating agreement data');
    }
  } catch (e) {
    console.log(e);
  }
}

function* uploadAgreementFileSaga(action: PayloadAction<{ file: File }>) {
  const { file } = action.payload;
  const project: ProjectType = yield select(selectProjectConfig);
  const agreement: AgreementType = yield select(selectAgreementConfig);

  const fileLocation: {
    dir: string;
    path: string;
  } = getS3RootProjectPath(project.slug);

  try {
    const data = yield uploadFileToS3(file, fileLocation.dir);
    const updatedAgreement = {
      ...agreement,
      url: data.location,
    };

    const event: CakeActivityType = {
      content: {
        event: 'dApp Settings Update',
        description: 'New Terms File Uploaded',
      },
      media: data.location,
    };

    yield call(updateAgreementSaga, updateAgreementConfig({ updatedAgreementData: updatedAgreement, event }));
  } catch (err) {
    console.log(err);
  }
}

export default function* agreementsConfigSaga(): Iterator<any> {
  yield all([
    yield takeLatest(createAgreement.type, createAgreementSaga),
    yield takeLatest(getAgreementConfig.type, fetchAgreementSaga),
    yield takeLatest(updateAgreementConfig.type, updateAgreementSaga),
    yield takeLatest(uploadAgreementFile.type, uploadAgreementFileSaga),
  ]);
}
