import { FileUploadKey } from '~components/inputs/FileUploadInput';
import type { DappSettingsType, ImageBrandType } from '~types/ProjectType';
import { TypographyKeys } from '~types/ProjectType';

const getTypographyStatusKey = (key: TypographyKeys) => {
  return key === TypographyKeys.Display ? FileUploadKey.FONT_DISPLAY : FileUploadKey.FONT_BODY;
};

const updateProjectImagePath = (dappSettings: DappSettingsType, path: string, key: string) => {
  switch (key) {
    case FileUploadKey.LIGHT_BACKGROUND_LOGO:
      return {
        ...dappSettings,
        backgroundImage: {
          ...dappSettings.backgroundImage,
          light: path,
        },
      };
    case FileUploadKey.LIGHT_PRIMARY_LOGO:
      return {
        ...dappSettings,
        logo: {
          ...dappSettings.logo,
          light: {
            ...dappSettings.logo.light,
            primary: path,
          },
        },
      };
    case FileUploadKey.LIGHT_SECONDARY_LOGO:
      return {
        ...dappSettings,
        logo: {
          ...dappSettings.logo,
          light: {
            ...dappSettings.logo.light,
            secondary: path,
          },
        },
      };
    case FileUploadKey.LIGHT_TITLE_LOGO:
      return {
        ...dappSettings,
        logo: {
          ...dappSettings.logo,
          light: {
            ...dappSettings.logo.light,
            title: path,
          },
        },
      };
    case FileUploadKey.LIGHT_CUSTOM_LOADER:
      return {
        ...dappSettings,
        customLoader: {
          ...dappSettings.customLoader,
          light: path,
          dark: path,
        },
      };
    case FileUploadKey.DARK_BACKGROUND_LOGO:
      return {
        ...dappSettings,
        backgroundImage: {
          ...dappSettings.backgroundImage,
          dark: path,
          light: path, // temporarily added
        },
      };
    case FileUploadKey.DARK_PRIMARY_LOGO:
      return {
        ...dappSettings,
        logo: {
          ...dappSettings.logo,
          dark: {
            ...dappSettings.logo.dark,
            primary: path,
          },
          // temporarily added
          light: {
            ...dappSettings.logo.light,
            primary: path,
          },
        },
      };
    case FileUploadKey.DARK_SECONDARY_LOGO:
      return {
        ...dappSettings,
        logo: {
          ...dappSettings.logo,
          dark: {
            ...dappSettings.logo.dark,
            secondary: path,
          },
          // temporarily added
          light: {
            ...dappSettings.logo.light,
            secondary: path,
          },
        },
      };
    case FileUploadKey.DARK_TITLE_LOGO:
      return {
        ...dappSettings,
        logo: {
          ...dappSettings.logo,
          dark: {
            ...dappSettings.logo.dark,
            title: path,
          },
          // temporarily added
          light: {
            ...dappSettings.logo.light,
            title: path,
          },
        },
      };
    case FileUploadKey.DARK_CUSTOM_LOADER:
      return {
        ...dappSettings,
        customLoader: {
          ...dappSettings.customLoader,
          light: path,
          dark: path,
        },
      };
    case FileUploadKey.FAVICON:
      return {
        ...dappSettings,
        favicon: path,
      };
  }
  return dappSettings;
};

const getImageUploadTypeForStreamEvent = (key: string) => {
  switch (key) {
    case FileUploadKey.LIGHT_BACKGROUND_LOGO:
      return 'New light-mode background logo uploaded.';
    case FileUploadKey.LIGHT_PRIMARY_LOGO:
      return 'New light-mode primary logo uploaded.';
    case FileUploadKey.LIGHT_SECONDARY_LOGO:
      return 'New light-mode secondary logo uploaded.';
    case FileUploadKey.LIGHT_TITLE_LOGO:
      return 'New light-mode title image uploaded.';
    case FileUploadKey.DARK_BACKGROUND_LOGO:
      return 'New dark-mode background logo uploaded';
    case FileUploadKey.DARK_PRIMARY_LOGO:
      return 'New dark-mode primary logo uploaded';
    case FileUploadKey.DARK_SECONDARY_LOGO:
      return 'New dark-mode secondary logo uploaded';
    case FileUploadKey.DARK_TITLE_LOGO:
      return 'New dark-mode title image uploaded';
    case FileUploadKey.FAVICON:
      return 'New favicon image uploaded';
  }
  return 'New image uploaded';
};

const getUpdatedDappSettings = (brandImageType: ImageBrandType, dapp: DappSettingsType) => {
  switch (brandImageType) {
    case 'BACKGROUND':
      dapp = { ...dapp, backgroundImage: { light: '', dark: '' } };
      break;
    case 'PRIMARY_LOGO':
      dapp = {
        ...dapp,
        logo: {
          light: { ...dapp.logo.light, primary: '' },
          dark: { ...dapp.logo.dark, primary: '' },
        },
      };
      break;
    case 'SECONDARY_LOGO':
      dapp = {
        ...dapp,
        logo: {
          light: { ...dapp.logo.light, secondary: '' },
          dark: { ...dapp.logo.dark, secondary: '' },
        },
      };
      break;
    case 'TITLE':
      dapp = {
        ...dapp,
        logo: {
          light: { ...dapp.logo.light, secondary: '' },
          dark: { ...dapp.logo.dark, secondary: '' },
        },
      };
      break;
    case 'LOADER':
      dapp = {
        ...dapp,
        customLoader: {
          light: '',
          dark: '',
        },
      };
      break;
  }

  return dapp;
};

export { getImageUploadTypeForStreamEvent, getTypographyStatusKey, getUpdatedDappSettings, updateProjectImagePath };
