import { addNotification } from '~features/notifications/notifications.slice';
import { setVrfTxnProcessing } from '~features/vrf/vrf.slice';

const randomNumberRequestHandler = (emitter, data) => {
  emitter(setVrfTxnProcessing(true));

  emitter(
    addNotification({
      message: 'Collection Reveal In Progress',
      severity: 'info',
      duration: 5000,
    }),
  );
};

export default randomNumberRequestHandler;
