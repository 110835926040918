const ProjectVerbs = [
  'cake',
  'cakeConfig',
  'mint',
  'transfer',
  'userPost',
  'dapp',
  'contract',
  'collections',
  'analytics',
  'plugins',
];

export default ProjectVerbs;
