import type { RootState } from '~features';

const selectPartnerPluginsIsLoading = (state: RootState) => state.partnerPlugins.isLoading;

const selectPartnerPluginsError = (state: RootState) => state.partnerPlugins.error;

const selectPartnerPlugins = (state: RootState) => state.partnerPlugins.plugins;

const selectPartnerEnabledlugins = (state: RootState) =>
  state.partnerPlugins.plugins.filter((plugin) => plugin.isEnabled);

export { selectPartnerEnabledlugins, selectPartnerPlugins, selectPartnerPluginsError, selectPartnerPluginsIsLoading };
