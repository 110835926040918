import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useState } from 'react';

/**
 * Font family name and styles
 * @see https://fonts.google.com/
 */
export type GoogleFontFamily = string;

export enum GoogleFontsStatus {
  LOADING = 0,
  LOADED = 1,
  FAILED = -1,
}

/**
 * Returned object from the `useGoogleFonts` hook.
 */
export interface UseGoogleFontsResult {
  loadFont(font: GoogleFontFamily): void;
  status: GoogleFontsStatus;
  error: Error | undefined;
}

function formatFontFamilyAsLink(font: GoogleFontFamily) {
  return font.replace(/ +/g, '+');
}

function createFontLink(font: GoogleFontFamily) {
  return `https://fonts.googleapis.com/css?family=${formatFontFamilyAsLink(
    font,
  )}:100,200,300,400,500,600,700,800,900&display=swap`;
}

export function useGoogleFonts(): UseGoogleFontsResult {
  const [status, setStatus] = useState(GoogleFontsStatus.LOADED);
  const [error, setError] = useState<Error>();
  const [links, setLinks] = useState([]);

  function loadFont(font: GoogleFontFamily) {
    setError(undefined);
    setStatus(GoogleFontsStatus.LOADING);

    const link = document.createElement('link');
    link.href = createFontLink(font);
    link.rel = 'stylesheet';

    document.head.appendChild(link);

    new FontFaceObserver(font)
      .load()
      .then(() => {
        setLinks([...links, link]);
        setError(undefined);
        setStatus(GoogleFontsStatus.LOADED);
      })
      .catch((err) => {
        setError(err);
        setStatus(GoogleFontsStatus.FAILED);
      });
  }

  useEffect(() => {
    return () => {
      links.forEach(document.head.removeChild);
    };
  }, []);

  return { loadFont, status, error };
}
