import { Stack } from '@mui/system';
import { useCallback } from 'react';

import ActivityHeader from '~components/dashboard/ProjectTimeline/components/ActivityContent/components/ActivityHeader';
import CakeConfigActivityContent from '~components/dashboard/ProjectTimeline/components/ActivityContent/components/CakeConfigActivityContent';
import ProjectVerbs from '~constants/streams/ProjectVerbs';
import type { FeedAggregateActivityType } from '~types/Streams';

type ActivityContentProps = {
  data: FeedAggregateActivityType;
};

const ActivityContent = ({ data }: ActivityContentProps) => {
  const renderContent = useCallback(() => {
    if (ProjectVerbs.includes(data.verb)) {
      return <CakeConfigActivityContent data={data} />;
    }
  }, [data]);

  return (
    <Stack
      direction={'row'}
      gap={1}
      sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}
    >
      <ActivityHeader
        profileImage={data ? data.activities[0].actor.data?.profileImage : null}
        userName={data ? data.activities[0].actor.data?.name : null}
      />
      {renderContent()}
    </Stack>
  );
};

export default ActivityContent;
