import type { provider } from 'web3-core';

import type { TransactionParams } from '~types/Web3Type';
import { getETHTransactionParams } from '~utils/contracts/ethereum';

declare global {
  interface Window {
    ethereum: {
      isCoinbaseWallet?: boolean;
      isMetaMask?: boolean;
      providers?: Array<any>;
    };
  }
}

const WalletMessage = {
  CONNECTION_FAILED: '@wallet/CONNECTION_FAILED',
  CONNECTION_SUCCESS: '@wallet/CONNECTION_SUCCESS',
  NO_ETHEREUM: '@wallet/NO_ETHEREUM',
  INSUFFICIENT_FUNDS: '@wallet/INSUFFICIENT_FUNDS',
  UNKNOWN_ERROR: '@wallet/UNKNOWN_ERROR',
};

const isEthereum = () => {
  if (typeof window.ethereum !== 'undefined') {
    // checks for injected web-provider at window.ethereum
    return true;
  }
  return false;
};

const resolveSelectedProvider = (selectedProvider: provider) => {
  console.log(`Resolving Provider to: ${selectedProvider}`);
  const provider = resloveMultipleProviders(selectedProvider);
  if (provider) {
    console.log('Returning Provider!');
    return provider;
  }

  if (isEthereum()) {
    if (selectedProvider === 'cb-wallet' && !window.ethereum.isCoinbaseWallet) {
      window.open('https://www.coinbase.com/wallet', '_blank');
      return;
    } else if (selectedProvider === 'metamask' && !window.ethereum.isMetaMask) {
      window.open('https://metamask.io/download/', '_blank');
      return;
    } else {
      console.log('Choosing native provider');
      return window.ethereum;
    }
  }
};

const resloveMultipleProviders = (walletProvider: provider) => {
  console.log(`Wallet Provider: ${walletProvider}`);

  if (window.ethereum?.providers && window.ethereum?.providers?.length > 1) {
    for (let i = 0; i < window.ethereum.providers.length; i++) {
      const provider = window.ethereum.providers[i];

      if (walletProvider === 'cb-wallet' && provider.isCoinbaseWallet) {
        console.log('Grabbing CBWallet injected provider');
        return provider;
      } else if (walletProvider === 'metamask' && provider.isMetaMask) {
        console.log('Grabbing Metamask injected provider');
        return provider;
      }
    }
  }
  console.log('User does not have multiple wallet providers');
  return;
};

const autoDetectProvider = () => {
  let provider: any;

  return new Promise((resolve) => {
    if (isEthereum()) {
      provider = resloveMultipleProviders('cb-wallet'); // default to cb-wallet provider
      if (!provider) {
        console.log('defaulting to native injected provider');
        provider = window.ethereum;
      }
    }
    resolve(provider);
  });
};

const connect = async (provider: any) => {
  console.log(`Connecting to ${provider}`);

  if (provider) {
    try {
      await provider.request({ method: 'eth_requestAccounts' });
    } catch (e) {
      return { res: WalletMessage.CONNECTION_FAILED };
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return { res: WalletMessage.CONNECTION_SUCCESS, address: provider.selectedAddress };
    }
  }

  return { res: WalletMessage.NO_ETHEREUM };
};

const getConnectedWalletAddress = (provider: any) => {
  console.log(provider);

  if (isEthereum() && provider?.isConnected() && provider?.selectedAddress) {
    return provider?.selectedAddress;
  }
  return null;
};

const getCurrentChainId = async (provider: any) => {
  if (isEthereum()) {
    let chainId = null;
    try {
      chainId = await provider.request({ method: 'eth_chainId' });
    } catch (e) {
      return null;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return chainId;
    }
  }
};

const getTransactionParams = (
  provider: any,
  walletAddress: string,
  contractAddress: string,
  abi: string,
  chainId: string,
  params: any,
): any => {
  try {
    console.log(provider, walletAddress, contractAddress, abi, chainId, params);
    const transactionParams = getETHTransactionParams(provider, walletAddress, contractAddress, abi, chainId, params);
    return transactionParams;
  } catch (e) {
    console.log(e);
    return;
  }
};

const sendTransaction = async (provider: any, txns: Array<TransactionParams>) => {
  const hashes: Array<string> = [];

  if (!txns || !txns.length) {
    throw Error('wrong transaction params');
  }

  for (let i = 0; i < txns.length; i++) {
    const txHash: string = await provider.request({
      method: 'eth_sendTransaction',
      params: [txns[i]],
    });
    hashes.push(txHash);
  }

  return hashes;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const addWalletEventListener = (provider: any, event: any, handler: Function) => {
  provider.on(event, handler);
};

// eslint-disable-next-line @typescript-eslint/ban-types
const removeWalletEventListener = (provider: any, event: any, handler: Function) => {
  if (provider?.isMetaMask) {
    provider.removeListener(event, handler);
  }
};

export {
  addWalletEventListener,
  autoDetectProvider,
  connect,
  getConnectedWalletAddress,
  getCurrentChainId,
  getTransactionParams,
  isEthereum,
  removeWalletEventListener,
  resolveSelectedProvider,
  sendTransaction,
  WalletMessage,
};
