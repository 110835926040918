import './CollectionItemModal.scss';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';

import CollectionItemContent from '~components/collections/CollectionItemModal/components/CollectionItemContent';
import ErrorModal from '~components/miscs/ErrorModal';
import Modal from '~components/miscs/Modal';
import { ContractNetwork } from '~constants/networks/networks';
import { selectProjectContractConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';
import type CollectionItemType from '~types/CollectionItemType';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

import CollectionItemFooter from './components/CollectionItemFooter';

type CollectionItemModalProps = {
  token: CollectionItemType;
  open: boolean;
  onAccept: (token: CollectionItemType) => void;
  onClose: () => void;
  onDelete: (token: CollectionItemType) => void;
};

const CollectionItemModal = ({ token, open, onAccept, onClose, onDelete }: CollectionItemModalProps) => {
  const projectContractConfig = useAppSelector(selectProjectContractConfig);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [currentToken, setCurrentToken] = useState<CollectionItemType>(token);
  const [isErrorModal, setIsErrorModal] = useState<boolean>(false);

  useEffect(() => {
    setCurrentToken(token);
  }, [token]);

  const onChangeToken = (newToken: CollectionItemType) => {
    if (!isChanged) {
      setIsChanged(true);
    }
    setCurrentToken(newToken);
  };

  const handleTokenDelete = () => {
    if (getCurrentContractNetwork(projectContractConfig) === ContractNetwork.MAINNET) {
      setIsErrorModal(true);
    } else {
      onDelete(currentToken);
    }
  };

  return (
    <>
      <Modal
        className={'CAKE__collection-item-modal'}
        open={open}
        onClose={onClose}
        footer={
          <CollectionItemFooter
            isChanged={isChanged}
            onAccept={() => onAccept(currentToken)}
            onDelete={handleTokenDelete}
          />
        }
      >
        {currentToken && (
          <CollectionItemContent
            token={currentToken}
            onChange={onChangeToken}
          />
        )}
      </Modal>
      <ErrorModal
        open={isErrorModal}
        onClose={() => setIsErrorModal(false)}
        footer={<Button onClick={() => setIsErrorModal(false)}>Okay</Button>}
        height={'300px'}
        width={'400px'}
        title={
          <Stack
            direction={'row'}
            alignContent={'center'}
            gap={1}
          >
            <WarningRoundedIcon color="warning" />
            <Typography variant="h6">Warning</Typography>
          </Stack>
        }
      >
        <Typography>This project is live. You cannot delete tokens from a live collection.</Typography>
      </ErrorModal>
    </>
  );
};

export default CollectionItemModal;
