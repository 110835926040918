import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import type { FAQEntry } from '~types/ProjectType';

type FAQSettingsModalProps = {
  isOpen: boolean;
  faq: FAQEntry;
  onCancel: () => void;
  onSave: (item: FAQEntry) => void;
};

const FAQSettingsModal = ({ isOpen, faq, onCancel, onSave }: FAQSettingsModalProps) => {
  const [question, setQuestion] = useState<string | null>(faq.question || '');
  const [answer, setAnswer] = useState<string | null>(faq.answer || '');

  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{'FAQ Settings'}</DialogTitle>
      <DialogContent>
        <Stack
          paddingY={1}
          spacing={3}
        >
          <V2TextInput
            label={'Question'}
            value={question}
            multiline
            onUpdate={setQuestion}
            inputProps={{ sx: { fontSize: 14 } }}
          />

          <V2TextInput
            label={'Answer'}
            value={answer}
            multiline
            inputProps={{ sx: { fontSize: 14 } }}
            onUpdate={setAnswer}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => onSave({ question, answer })}
          disabled={answer.length === 0 || question.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FAQSettingsModal;
