import { ContractNetworkIds } from '~constants/networks/networks';
import type { ProjectType } from '~types/ProjectType';

const getContractAddressForChainId = (projectConfig: ProjectType, chainId: number) => {
  if (!projectConfig) {
    return '';
  }

  return [ContractNetworkIds.MAINNET, ContractNetworkIds.BASE_MAINNET].includes(chainId)
    ? projectConfig.contract.livenet.contractAddress
    : projectConfig.contract.testnet.contractAddress;
};

export default getContractAddressForChainId;
