import type { RootState } from '~features';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';

const selectIsTokenGroupEditView = (state: RootState): boolean => Boolean(state.tokenGroupUpdates.currentTokenGroup);

const selectCurrentTokenGroup = (state: RootState): CollectionTokenGroupType =>
  state.tokenGroupUpdates.currentTokenGroup;

const selectTokenUpdates = (state: RootState) => state.tokenGroupUpdates.updates;

export { selectCurrentTokenGroup, selectIsTokenGroupEditView, selectTokenUpdates };
