/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import type { TokenGroupUpdateType } from '~types/token/TokenUpdateType';

type TokenGroupUpdatesState = {
  currentTokenGroup: CollectionTokenGroupType;
  error: string;
  isLoading: boolean;
  updates: Record<string, Partial<TokenGroupUpdateType>>;
  order: Array<string>;
};

const initialState: TokenGroupUpdatesState = {
  currentTokenGroup: null,
  error: null,
  isLoading: false,
  updates: {},
  order: [],
};

const tokenGroupUpdates = createSlice({
  name: 'token-group-updates',
  initialState,
  reducers: {
    createTokenUpdate(state, action: PayloadAction<Partial<TokenGroupUpdateType>>) {},
    createTokenUpdateSuccess(state, action: PayloadAction<TokenGroupUpdateType>) {},
    createTokenUpdateFailure(state, action: PayloadAction<string>) {},
    getTokenUpdates(state, action: PayloadAction<string>) {
      state.error = null;
      state.isLoading = true;
      state.updates = initialState.updates;
      state.order = initialState.order;
    },
    getTokenUpdatesSuccess(state, action: PayloadAction<TokenGroupUpdateType>) {
      const updateId = action.payload._id.toString();
      state.updates[action.payload._id.toString()] = action.payload;
      state.order.push(updateId);
    },
    getTokenUpdatesFailure(state, action: PayloadAction<string>) {},
    showTokenGroupEditView(state, action: PayloadAction<CollectionTokenGroupType>) {
      state.currentTokenGroup = action.payload;
    },
    hideTokenGroupEditView(state) {
      state.currentTokenGroup = null;
    },
    upsertSingleTokenUpdate(state, action: PayloadAction<Partial<TokenGroupUpdateType & { updateId: string }>>) {
      const key = (action.payload._id || action.payload.updateId).toString();
      if (state.updates[key]) {
        state.updates[key] = {
          ...state.updates[key],
          ...action.payload,
        };
      } else {
        state.updates[key] = action.payload;
      }
    },
    upsertTokenUpdates(state, action: PayloadAction<Partial<TokenGroupUpdateType & { updateId: string }[]>>) {
      const updates: Record<string, Partial<TokenGroupUpdateType>> = action.payload.reduce(
        (acc, curr: Partial<TokenGroupUpdateType & { updateId: string }>) => {
          const key = (curr._id || curr.updateId).toString();
          return {
            ...acc,
            [key]: {
              ...state.updates[key],
              ...curr,
            },
          };
        },
        {},
      );
      state.updates = updates;
    },
  },
});

export const {
  createTokenUpdate,
  createTokenUpdateSuccess,
  createTokenUpdateFailure,
  getTokenUpdates,
  getTokenUpdatesFailure,
  getTokenUpdatesSuccess,
  showTokenGroupEditView,
  hideTokenGroupEditView,
  upsertSingleTokenUpdate,
  upsertTokenUpdates,
} = tokenGroupUpdates.actions;
export default tokenGroupUpdates.reducer;
