import './CollectionViewerHeader.scss';

import { Box } from '@mui/material';

import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';
// import { useState } from 'react';

// import V2TextInput from '~components/inputs/V2TextInput';

type CollectionViewerHeaderProps = {
  onAdvancedSettings: () => void;
};

const CollectionViewerHeader = ({ onAdvancedSettings }: CollectionViewerHeaderProps) => {
  const projectConfig = useAppSelector(selectProjectConfig);

  // const [searchPhrase, setSearchPhrase] = useState<string>('');
  return (
    <Box className={'CAKE__collection-viewer-header'}>
      <Box className={'CAKE__collection-viewer-header__left-container'}>
        {/* <V2TextInput
          label={'Search Token'}
          variant="standard"
          value={searchPhrase}
          onUpdate={setSearchPhrase}
        /> */}
      </Box>
      {/* <Box className={'CAKE__collection-viewer-header__right-container'}>
        <Tooltip
          title={getCollectionUploadToolTipWarning(projectConfig)}
          arrow
        >
          <InfoOutlinedIcon
            fontSize="small"
            color={'primary'}
          />
        </Tooltip>
        <Button
          variant="text"
          size={'small'}
          onClick={() => onAdvancedSettings()}
          disabled={getProjectNetworkLayer(projectConfig) === NetworkLayer.BASE}
        >
          Advanced Settings
        </Button>
      </Box> */}
    </Box>
  );
};

export default CollectionViewerHeader;
