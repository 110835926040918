import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { selectProjectSlug } from '~features/project-config/project-config.selectors';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { S3_STORAGE_TOKENS_ARTWORK_DIR } from '~features/utils/s3storage/s3.storage.consts';
import { uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type TokenBlueprintType from '~types/token/TokenBlueprintType';
import getS3TokenFilePath from '~utils/api/getS3TokenFilePath';

import {
  getTokenBlueprint,
  getTokenBlueprintFailed,
  getTokenBlueprintSuccess,
  updateBlueprint,
  updateBlueprintFailed,
  updateBlueprintImage,
  updateBlueprintSuccess,
} from './token-blueprints.slice';

function* fetchTokenBlueprintSaga(action: PayloadAction<string>) {
  const blueprintId = action.payload;
  const res: Response = yield call(fetchProtectedAPI, `blueprints/${blueprintId}`);

  try {
    const data = yield res.json();

    if (res.status !== 200) {
      yield put(getTokenBlueprintFailed(data.message));
    } else {
      yield put(getTokenBlueprintSuccess(data));
    }
  } catch (e) {
    yield put(getTokenBlueprintFailed(e.message));
  }
}

function* updateBlueprintImageSaga(action: PayloadAction<{ blueprint: TokenBlueprintType; file: File }>) {
  const { blueprint, file } = action.payload;

  const projectSlug: string = yield select(selectProjectSlug);
  const fileLocation: {
    dir: string;
    path: string;
  } = getS3TokenFilePath(projectSlug, S3_STORAGE_TOKENS_ARTWORK_DIR);

  const [, extension] = file.name.split('.');

  const updatedFile = new File([file], `${blueprint.name}.${extension}`, {
    type: file.type,
  });
  const result = yield call(uploadFileToS3, updatedFile, fileLocation.dir);

  yield put(updateBlueprint({ ...blueprint, image: result.location }));
}

function* updateBlueprintSaga(action: PayloadAction<TokenBlueprintType>) {
  const blueprint = action.payload;

  const res: Response = yield call(putProtectedAPI, `blueprints/${blueprint._id}`, blueprint);

  try {
    const data = yield res.json();

    if (res.status !== 200) {
      yield put(updateBlueprintFailed(data.message));
    } else {
      yield put(updateBlueprintSuccess(data));
    }
  } catch (e) {
    yield put(updateBlueprintFailed(e.message));
  }
}

export default function* tokenBlueprintsSaga() {
  yield all([
    yield takeLatest(getTokenBlueprint.type, fetchTokenBlueprintSaga),
    yield takeEvery(updateBlueprint.type, updateBlueprintSaga),
    yield takeEvery(updateBlueprintImage.type, updateBlueprintImageSaga),
  ]);
}
