import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, Divider, FormControlLabel, FormGroup, Menu, MenuItem, Slider, Switch } from '@mui/material';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import googleFonts from '~components/dashboard/TypographySettingsCard/data/google-fonts.json';
import { getFontNameFromFile, removeExtension } from '~components/dashboard/TypographySettingsCard/helpers';
import ImageDropzone from '~components/inputs/ImageDropzone';
import InfoItem from '~components/inputs/InfoItem/InfoItem';
import theme from '~theme';
import type { TypographyItemType, TypographyWeight } from '~types/ProjectType';
import { TypographySources } from '~types/ProjectType';
import { GoogleFontsStatus, useGoogleFonts } from '~utils/hooks/useGoogleFonts';

import FontPreview from './FontPreview';
import UploadBox from './UploadBox';

type FontSelectorProps = {
  originalFont: TypographyItemType;
  onSelectFont: (font: TypographyItemType, file?: File) => void;
};

const FontSelector = ({ originalFont, onSelectFont }: FontSelectorProps) => {
  const [selectedFontKey, setSelectedFontKey] = useState(originalFont.family);
  const [uploadError, setUploadError] = useState(null);
  const [previewFonts, setPreviewFonts] = useState<Record<string, TypographyItemType>>({
    [originalFont.family]: originalFont,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [savedFiles, setFiles] = useState<File[]>([]);
  const { loadFont, status } = useGoogleFonts();
  const [isLowercase, setIsLowercase] = useState<boolean>(
    originalFont?.isLowercase ? originalFont?.isLowercase : false,
  );

  const selectedPreviewFont = previewFonts[selectedFontKey];

  useEffect(() => {
    const fontFile = savedFiles.find((file) => file.name.includes(selectedPreviewFont.family));

    onSelectFont(selectedPreviewFont, fontFile);
  }, [selectedFontKey, selectedPreviewFont]);

  /**
   * Handle a change in font selection.
   */
  const onSelectFontFromList = (font: TypographyItemType) => {
    setSelectedFontKey(font.family);
  };

  /**
   * When a font is deleted from the list, remove it and
   * switch the selected font if the selected fort was the
   * one deleted.
   */
  const onDeleteFont = (font: TypographyItemType) => {
    const { [font.family]: _, ...rest } = previewFonts; // eslint-disable-line

    if (!rest[selectedFontKey]) {
      setSelectedFontKey(Object.keys(previewFonts)[0]);
    }

    setPreviewFonts(rest);
  };

  /**
   * Load a google font
   */
  const onAddGoogleFont = (font: string) => {
    const item = { family: font, name: font, source: TypographySources.Google, weight: 400 as TypographyWeight };
    loadFont(font);
    setSelectedFontKey(font);
    setAnchorEl(null);

    if (!previewFonts[font]) {
      setPreviewFonts({ ...previewFonts, [font]: item });
    }
  };

  /**
   * Load a preview of an uploaded font.
   */
  const loadUploadedFontPreview: (file: File) => Promise<TypographyItemType | null> = async (file) => {
    return new Promise((resolve, reject) => {
      const name = getFontNameFromFile(file);
      const family = removeExtension(file.name, 'woff2');
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const fontFile = new FontFace(family, `url(${e.target.result})`);
          document.fonts.add(fontFile);

          const fontFace = await fontFile.load();

          setUploadError(null);
          resolve({ family: fontFace.family, name, source: TypographySources.Uploaded });
        } catch (e) {
          setUploadError('Sorry, there was an issue adding that font. Please ensure it is a valid .woff2 file.');
          reject(e);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  /**
   * Handle the upload of a font or multiple fonts.
   */
  const onCustomFontUpload = async (files: File[]) => {
    setFiles([...savedFiles, ...files]);

    if (files[0].type !== 'font/woff2') {
      setUploadError('Please upload a file with a .woff2 extension.');
    }

    const fonts = await Promise.all(files.map(loadUploadedFontPreview));

    if (fonts.length > 0) {
      setPreviewFonts({
        ...previewFonts,
        ...fonts.reduce((acc, font) => ({ ...acc, [font.family]: font }), {}),
      });

      setSelectedFontKey(fonts[0].family);
    }
  };

  const onChangeLowercase = () => {
    const value = !isLowercase;
    setIsLowercase(value);
    setPreviewFonts({
      ...previewFonts,
      [selectedFontKey]: { ...selectedPreviewFont, isLowercase: value },
    });
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction={'row'}
        overflow={'scroll'}
        spacing={1}
        paddingBottom={2}
        position={'relative'}
        sx={{ transform: 'translateY(10px)' }}
      >
        {Object.values(previewFonts).map((font) => {
          const isOriginal = font.family === originalFont.family;
          const isSelected = font.family === selectedFontKey;

          return (
            <Chip
              color={isSelected ? 'primary' : 'default'}
              key={font.name}
              label={font.name}
              variant={isSelected ? 'filled' : 'outlined'}
              onClick={() => onSelectFontFromList(font)}
              onDelete={isOriginal ? null : () => onDeleteFont(font)}
            />
          );
        })}
      </Stack>

      <Divider />

      <Stack spacing={1}>
        <InfoItem
          editable
          onEdit={(value: string) => {
            setPreviewFonts({
              ...previewFonts,
              [selectedFontKey]: { ...selectedPreviewFont, name: value },
            });
          }}
          label="Font Name"
          value={selectedPreviewFont.name}
        />

        <InfoItem
          label="Font Weight"
          value={String(selectedPreviewFont.weight ?? 'Default')}
          height={20}
          alignItems={'center'}
        >
          {selectedPreviewFont.weight && (
            <Slider
              sx={{ transform: 'translateX(5px)' }}
              size="small"
              value={selectedPreviewFont.weight}
              onChange={(_, value) => {
                setPreviewFonts({
                  ...previewFonts,
                  [selectedFontKey]: { ...selectedPreviewFont, weight: value as TypographyWeight },
                });
              }}
              step={100}
              marks
              min={100}
              max={900}
              valueLabelDisplay="auto"
              style={{ width: 300 }}
            />
          )}
        </InfoItem>

        <InfoItem
          label="Source"
          value={selectedPreviewFont.source}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isLowercase}
                size={'small'}
              />
            }
            label={
              <InfoItem
                label="Lowercase"
                value={''}
              />
            }
            onChange={onChangeLowercase}
          />
        </FormGroup>
      </Stack>

      <Divider />

      <Menu
        id="font-selector"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {googleFonts.map((font) => (
          <MenuItem
            key={font}
            value={font}
            onClick={() => onAddGoogleFont(font)}
          >
            {font}
          </MenuItem>
        ))}
      </Menu>

      <Stack
        alignItems={'center'}
        direction={'row'}
        spacing={3}
        marginTop={2}
      >
        <UploadBox text="Add Google Font">
          <Box
            className="CAKE__image-dropzone CAKE__image-dropzone--small"
            alignItems={'center'}
            justifyContent={'center'}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <AddIcon
              color={'inherit'}
              fontSize={'inherit'}
            />
          </Box>
        </UploadBox>

        <UploadBox
          helpTextColor={uploadError ? theme.palette.error.light : null}
          helpText={uploadError ?? 'In woff2 format'}
          text="Add Custom Font"
        >
          <ImageDropzone
            title="Add Custom Font"
            onDropFile={onCustomFontUpload}
            onError={setUploadError}
            variant="small"
            inputProps={{ accept: 'woff2' }}
          />
        </UploadBox>
      </Stack>

      <Divider />

      <FontPreview
        fontFamily={selectedPreviewFont.family}
        fontWeight={selectedPreviewFont.weight}
        isLoading={status === GoogleFontsStatus.LOADING}
      />
    </Stack>
  );
};

export default FontSelector;
