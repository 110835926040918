import './TransactionValueChart.scss';

import { Box } from '@mui/material';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';

import ChartTooltip from '~components/analytics/ChartTooltip/ChartTooltip';
import { selectCumulativeTransferData } from '~features/analytics/analytics.selectors';
import useAppSelector from '~hooks/useAppSelector';

import EventsLayer from './events-layer';
import VolumeLayer from './volume-layer';

const TransactionValueChart = () => {
  const data = useAppSelector(selectCumulativeTransferData);
  const tickAmount: number = Math.floor(data.length / 6);
  const tickValues = data.filter((_, index) => index % tickAmount === 0).map((item) => item.x);

  return (
    <Box sx={{ width: '100%', height: 260 }}>
      {data.length > 0 && (
        <ResponsiveLine
          data={[
            {
              id: 'cumulative',
              color: '#2057A4',
              data,
            },
          ]}
          colors={['hsl(205deg 70% 42% / 20%)']}
          margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
          curve="catmullRom"
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickValues: tickValues,
            tickRotation: 0,
            tickPadding: 5,
            format: (value) =>
              moment(value, 'YYYY-MM-DD HH:mm', true).isValid()
                ? moment(value).format('MMM DD HH:mm')
                : moment(value).format('MMM DD'),
          }}
          axisLeft={null}
          enableArea={true}
          useMesh={true}
          isInteractive={true}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          layers={['axes', 'areas', 'lines', 'slices', 'mesh', VolumeLayer(data), EventsLayer(data), 'legends']}
          defs={[
            linearGradientDef('gradientArea', [
              { offset: 30, color: '#5979D8' },
              { offset: 100, color: '#FFFFFF', opacity: 20 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientArea' }]}
          tooltip={({ point }) => {
            return <ChartTooltip point={point} />;
          }}
        />
      )}
    </Box>
  );
};

export default TransactionValueChart;
