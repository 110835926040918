const dappUrlGenerator = (slug: string) => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'staging':
      return `https://staging.mint.cakeapp.xyz/${slug}`;
    case 'production':
      return `https://mint.cakeapp.xyz/${slug}`;
    default:
      return `http://localhost:3001/${slug}`;
  }
};

export default dappUrlGenerator;
