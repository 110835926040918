import { all } from 'redux-saga/effects';

import agreementsConfigSaga from '~features/agreements-config/agreements-config.sagas';
import analyticsSaga from '~features/analytics/analytics.sagas';
import authSaga from '~features/auth/auth.sagas';
import collectionItemsSaga from '~features/collection-items/collection-items.sagas';
import collectionTokenGroupsSaga from '~features/collection-token-groups/collection-token-groups.sagas';
import collectionUploadSaga from '~features/collection-upload/collection-upload.sagas';
import contractConfigSaga from '~features/contract-config/contract-config.sagas';
import contractDeployerSaga from '~features/contract-deployer/contract-deployer.sagas';
import contractManagerSaga from '~features/contract-manager/contract-manager.sagas';
import imageGenerationsSaga from '~features/image-generations/image-generations.sagas';
import partnerPluginsSaga from '~features/partner-plugins/partner-plugins.sagas';
import projectBuilderSaga from '~features/project-builder/project-builder.sagas';
import projectConfigSaga from '~features/project-config/project-config.sagas';
import projectContentSaga from '~features/project-content/project-content.sagas';
import projectDataRoomSaga from '~features/project-data-room/project-data-room.sagas';
import projectDropSaga from '~features/project-drop/project-drop.sagas';
import projectDropsManagerSaga from '~features/project-drops-manager/project-drops-manager.sagas';
import projectShopifySaga from '~features/project-shopify/project-shopify.sagas';
import projectsListSaga from '~features/projects-list/projects-list.sagas';
import pubsubSaga from '~features/pubsub/pubsub.sagas';
import streamsSaga from '~features/streams/streams.sagas';
import tokenBlueprintsSaga from '~features/token-blueprints/token-blueprints.sagas';
import tokenGatePolicyConfigSaga from '~features/token-gate-policy/token-gate-policy.sagas';
import tokenGroupUpdatesSaga from '~features/token-group-updates/token-group-updates.sagas';
import userInvitationSaga from '~features/user-invitation/user-invitation.sagas';
import vrfSaga from '~features/vrf/vrf.sagas';
import whitelistSaga from '~features/whitelist-config/whitelist-config.sagas';

function* rootSaga() {
  yield all([
    agreementsConfigSaga(),
    analyticsSaga(),
    authSaga(),
    collectionItemsSaga(),
    collectionTokenGroupsSaga(),
    collectionUploadSaga(),
    contractConfigSaga(),
    contractManagerSaga(),
    contractDeployerSaga(),
    imageGenerationsSaga(),
    partnerPluginsSaga(),
    projectBuilderSaga(),
    projectConfigSaga(),
    projectContentSaga(),
    projectDataRoomSaga(),
    projectDropSaga(),
    projectDropsManagerSaga(),
    projectShopifySaga(),
    projectsListSaga(),
    pubsubSaga(),
    streamsSaga(),
    tokenBlueprintsSaga(),
    tokenGatePolicyConfigSaga(),
    tokenGroupUpdatesSaga(),
    userInvitationSaga(),
    vrfSaga(),
    whitelistSaga(),
  ]);
}

export default rootSaga;
