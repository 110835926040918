import { createDraftSafeSelector } from '@reduxjs/toolkit';

import ContractDeploymentStatus from '~constants/contract/ContractDeploymentStatus';
import type { RootState } from '~features';
import type { ContractType, DappSettingsType, ProjectGalleryType, ProjectType } from '~types/ProjectType';

const selectProjectConfig = (state: RootState): ProjectType => state.projectConfig.project;

const selectProjectConfigShopify = (state: RootState): string => state.projectConfig.project?.shopify;

const selectProjectContractConfig = (state: RootState): ContractType => state.projectConfig.project?.contract;

const selectProjectDappConfig = (state: RootState): DappSettingsType => state.projectConfig.project?.dapp;

const selectProjectGallery = (state: RootState): ProjectGalleryType => state.projectConfig.project?.dapp?.images;

const selectProjectConfigIsLoading = (state: RootState): boolean => state.projectConfig.isLoading;

const selectProjectConfigError = (state: RootState): string | null => state.projectConfig.error;

const selectUploadStatus = (state: RootState): object => state.projectConfig.fileUploader;

const selectProjectActivePlugins = (state: RootState): string[] => state.projectConfig.project?.active_plugins || [];

const selectProjectName = createDraftSafeSelector(selectProjectConfig, (config) => (config ? config.name : null));

const selectProjectSlug = createDraftSafeSelector(selectProjectConfig, (config) => (config ? config.slug : null));

const selectProjectId = createDraftSafeSelector(selectProjectConfig, (config) => (config ? config._id : null));

const selectProjectCollectionType = createDraftSafeSelector(selectProjectConfig, (config) =>
  config ? config.collectionType : null,
);

const selectProjectCollectionId = createDraftSafeSelector(selectProjectConfig, (config) =>
  config ? config.collectionId : null,
);

const selectProjectMintAgreementId = createDraftSafeSelector(selectProjectDappConfig, (dapp) =>
  dapp ? dapp.mintAgreementId : null,
);

const selectProjectDeploymentStatus = createDraftSafeSelector(selectProjectContractConfig, (contract: ContractType) => {
  if (contract?.livenet.contractAddress?.length > 0) {
    return ContractDeploymentStatus.LIVE_DEPLOYED;
  }
  if (contract?.testnet.contractAddress?.length > 0) {
    return ContractDeploymentStatus.TEST_DEPLOYED;
  }
  return ContractDeploymentStatus.DRAFT;
});

const selectContractTotalSupply = createDraftSafeSelector(
  selectProjectContractConfig,
  (contract: ContractType) => contract?.totalSupply,
);

export {
  selectContractTotalSupply,
  selectProjectActivePlugins,
  selectProjectCollectionId,
  selectProjectCollectionType,
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
  selectProjectConfigShopify,
  selectProjectContractConfig,
  selectProjectDappConfig,
  selectProjectDeploymentStatus,
  selectProjectGallery,
  selectProjectId,
  selectProjectMintAgreementId,
  selectProjectName,
  selectProjectSlug,
  selectUploadStatus,
};
