const generateCsvData = (headers: Record<string, string>, data: any[]) => {
  const csvHeaders = `${Object.values(headers).join(';')};`;
  const csvData = data.map((item) => {
    return Object.keys(headers).reduce((acc, curr) => {
      return `${acc}${item[curr] ?? '--'};`;
    }, '');
  });
  return [csvHeaders, ...csvData].join('\n');
};

export default generateCsvData;
