import '~components/dashboard/ProjectTimeline/ProjectTimeline.scss';

import { Typography } from '@mui/material';

import type { FeedAggregateActivityType } from '~types/Streams';

type CakeConfigActivityContentProps = {
  data: FeedAggregateActivityType;
};

const CakeConfigActivityContent = ({ data }: CakeConfigActivityContentProps) => {
  return (
    <div>
      <Typography
        variant="body1"
        color="text"
      >
        {data.activities[0].object?.data?.content['event'] ?? 'Unknown event'}
      </Typography>
      <Typography fontSize={'14px'}>
        {data.activities[0].object.data.content['description'] && data.activities[0].object.data.content['description']}
      </Typography>
      <Typography>
        {(data.activities[0].object.data['link'] || data.activities[0].object.data['media']) && (
          <a
            className={'CAKE__project_timeline__activityCard__content--activityLink'}
            href={data.activities[0].object.data['link'] ?? data.activities[0].object.data['media']}
            target="_blank"
            rel="noreferrer"
          >
            View here
          </a>
        )}
      </Typography>
    </div>
  );
};

export default CakeConfigActivityContent;
