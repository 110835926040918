import { ContractNetwork } from '~constants/networks/networks';

const ChainlinkVRFCoordinator = {
  [ContractNetwork.MAINNET]: {
    vrfCoordinator: '0x271682DEB8C4E0901D1a1550aD2e64D568E69909',
  },
  [ContractNetwork.GOERLI]: {
    vrfCoordinator: '0x2Ca8E0C643bDe4C2E08ab1fA0da3401AdAD7734D',
  },
};

const REQUEST_SENT_EVENT_SIGNATURE = '0xcc58b13ad3eab50626c6a6300b1d139cd6ebb1688a7cced9461c2f7e762665ee';

const REQUEST_SENT_ABI = [
  {
    type: 'uint256',
    name: 'requestId',
  },
  {
    type: 'uint32',
    name: 'numWords',
  },
];

const REQUEST_FULFILLED_EVENT_SIGNATURE = '0xfe2e2d779dba245964d4e3ef9b994be63856fd568bf7d3ca9e224755cb1bd54d';

const REQUEST_FULFILLED_ABI = [
  {
    type: 'uint256',
    name: 'requestId',
  },
  {
    type: 'uint256[]',
    name: 'randomWords',
  },
];

export {
  ChainlinkVRFCoordinator,
  REQUEST_FULFILLED_ABI,
  REQUEST_FULFILLED_EVENT_SIGNATURE,
  REQUEST_SENT_ABI,
  REQUEST_SENT_EVENT_SIGNATURE,
};
