import '~views/user/SignupView/SignupView.scss';

import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginSignupContainer from '~components/auth/LoginSignupContainer';
import LoginSignupForm from '~components/auth/LoginSignupForm';
import { SignupSelectors } from '~constants/cypress/signup.selectors';
import { PublicRoutes } from '~constants/routes';
import {
  selectInvitationId,
  selectIsSignupFinished,
  selectIsSignupInProgress,
  selectSignupError,
} from '~features/user-invitation/user-invitation.selectors';
import { invitationSignup } from '~features/user-invitation/user-invitation.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const SignupView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isInProgress = useAppSelector(selectIsSignupInProgress);
  const isSignupFinished = useAppSelector(selectIsSignupFinished);
  const signupError = useAppSelector(selectSignupError);
  const invitationId = useAppSelector(selectInvitationId);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const loginState = { username, password, confirmPassword };

  useEffect(() => {
    if (invitationId === null) {
      navigate(PublicRoutes.LOGIN);
    }
  }, [invitationId, navigate]);

  const onSignup = () => {
    dispatch(invitationSignup({ user: loginState.username, password: loginState.password }));
  };

  return (
    <LoginSignupContainer
      className="CAKE__signup-view"
      canSubmit={true}
      isLoading={isInProgress}
      hideActions={isSignupFinished}
      submitText={'Sign Up'}
      onSubmit={onSignup}
    >
      {!isSignupFinished && (
        <LoginSignupForm
          loginState={loginState}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          setUsername={setUsername}
        />
      )}

      {signupError && (
        <Stack
          data-cy={SignupSelectors.ErrorMessage}
          className="CAKE__signup-view__error"
        >
          {signupError}
        </Stack>
      )}

      {isSignupFinished && (
        <Stack gap={12}>
          <Typography variant={'h6'}>Signup Completed!</Typography>

          <Button
            onClick={() => navigate(PublicRoutes.LOGIN)}
            size="large"
            variant={'contained'}
          >
            Go to Login Page
          </Button>
        </Stack>
      )}
    </LoginSignupContainer>
  );
};

export default SignupView;
