import '~components/dashboard/DataRoomObjectEditor/DataRoomObjectEditor.scss';

import { Checkbox, Chip, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DashboardCard from '~components/dashboard/DashboardCard';
import AutocompleteSingleSelect from '~components/inputs/AutocompleteSingleSelect/AutocompleteSingleSelect';
import FileUploadInput from '~components/inputs/FileUploadInput';
import TokenGatePolicyConfigForm from '~components/inputs/TokenGatePolicyConfigForm';
import FileViewer from '~components/miscs/FileViewer/FileViewer';
import UndoDeleteBanner from '~components/miscs/UndoDeleteBanner/UndoDeleteBanner';
import UtilityPublishControls from '~components/miscs/UtilityPublishControls/UtilityPublishControls';
import tokenGatedDocumentStatus from '~constants/token-gate/TokenGateContentStatus';
import { selectCollectionTraitTypes } from '~features/collection-items/collection-items.selectors';
import { getCollection } from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  selectIsObjectLoading,
  selectProjectDataRoomCategories,
} from '~features/project-data-room/project-data-room.selectors';
import {
  getProjectDataRoomObjects,
  updateProjectDataRoomObject,
  uploadDataObject,
} from '~features/project-data-room/project-data-room.slice';
import { selectTokenGatePolicy } from '~features/token-gate-policy/token-gate-policy.selectors';
import {
  getTokenGatePolicyConfig,
  resetTokenGatePolicyConfig,
  updateTokenGatePolicyConfig,
} from '~features/token-gate-policy/token-gate-policy.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type DataRoomObjectType from '~types/DataRoomTypes';
import { UploadStatus } from '~types/miscs/UploadStatus';
import type { TokenGatePolicy } from '~types/TokenGateTypes';

type DataRoomObjectEditorProps = {
  object: DataRoomObjectType;
};

const DataRoomObjectEditor = ({ object }: DataRoomObjectEditorProps) => {
  const dispatch = useAppDispatch();
  const projectDataRomCategories = useAppSelector(selectProjectDataRoomCategories);
  const projectCollectionTraits = useAppSelector(selectCollectionTraitTypes);
  const isDataObjectLoading = useAppSelector(selectIsObjectLoading);
  const utilityPolicy = useAppSelector(selectTokenGatePolicy);
  const isPublished = object.status === tokenGatedDocumentStatus.PUBLISHED;

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectDataRoomObjects({ id: projectId }));
    dispatch(getCollection());
    dispatch(getTokenGatePolicyConfig({ id: object.policyId }));

    return () => {
      dispatch(resetTokenGatePolicyConfig());
    };
  }, []);

  const handleDelete = () => {
    dispatch(updateProjectDataRoomObject({ ...object, isArchived: true, status: 'Archived' }));
  };

  const handleUndoDelete = () => {
    dispatch(updateProjectDataRoomObject({ ...object, isArchived: false, status: 'Draft' }));
    dispatch(
      addNotification({
        message: 'Item has been restored to draft-mode',
        severity: 'success',
        duration: 3000,
      }),
    );
  };

  const handleStatusChange = () => {
    const status =
      object.status === tokenGatedDocumentStatus.PUBLISHED
        ? tokenGatedDocumentStatus.DRAFT
        : tokenGatedDocumentStatus.PUBLISHED;
    dispatch(updateProjectDataRoomObject({ ...object, status }));
  };

  const handleCategoryChange = (category: string) => {
    dispatch(updateProjectDataRoomObject({ ...object, category }));
  };

  const handleDownloadableChange = (isDownloadable: boolean) => {
    dispatch(updateProjectDataRoomObject({ ...object, isDownloadable }));
  };

  const onUploadIPFSFile = (file: File) => {
    dispatch(uploadDataObject({ file }));
  };

  const handlePolicyUpdate = (policy: TokenGatePolicy) => {
    dispatch(updateTokenGatePolicyConfig(policy));
  };

  return (
    <>
      <UndoDeleteBanner
        isOpen={object.isArchived}
        handleUndoDelete={handleUndoDelete}
      />
      <DashboardCard
        subtitle={'Data Room Manager'}
        rightContainer={
          <UtilityPublishControls
            isPublished={object.status === tokenGatedDocumentStatus.PUBLISHED ? true : false}
            handleStatusChange={handleStatusChange}
            handleDelete={handleDelete}
          />
        }
      >
        <Stack
          direction="column"
          spacing={2}
        >
          <Stack
            alignItems="start"
            spacing={1}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
            >
              Status
            </Typography>

            <Chip
              label={isPublished ? tokenGatedDocumentStatus.PUBLISHED : object.status}
              variant="filled"
              color={isPublished ? 'primary' : 'default'}
              sx={{ padding: '0 1rem' }}
            />
          </Stack>

          <Stack
            alignItems="start"
            spacing={1}
          >
            <Typography variant="h6">Category</Typography>

            <AutocompleteSingleSelect
              label="Select a Category"
              value={object.category ? object.category : 'Other'}
              options={projectDataRomCategories}
              onUpdate={handleCategoryChange}
              errorMsg="Please add a category"
            />
          </Stack>

          <Stack
            alignItems="stretch"
            spacing={1}
          >
            <Typography variant="h6">File</Typography>

            <FileUploadInput
              title={object.ipfsFile.name}
              uploadState={isDataObjectLoading ? UploadStatus.LOADING : 'A'}
              path={object.ipfsFile.url || ''}
              onUpload={(file: File) => onUploadIPFSFile(file)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(object.isDownloadable)}
                  onChange={(event) => handleDownloadableChange(event.target.checked)}
                  name="isDownloadable"
                />
              }
              label="Downloadable"
            />
          </Stack>

          <Stack
            alignItems="stretch"
            spacing={1}
          >
            <Typography variant="h6">Token-Gated Access Requirements</Typography>

            <TokenGatePolicyConfigForm
              tokenGatePolicy={utilityPolicy}
              traits={projectCollectionTraits}
              handlePolicyUpdate={handlePolicyUpdate}
            />
          </Stack>

          {object.ipfsFile.url && (
            <Stack
              alignItems="stretch"
              spacing={1}
              position="relative"
            >
              <Typography variant="h6">Preview File</Typography>

              <Stack
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Stack
                  flex={1}
                  position="relative"
                  zIndex={10}
                  width={'100%'}
                >
                  <FileViewer {...object.ipfsFile} />
                </Stack>

                <CircularProgress
                  size={100}
                  thickness={1}
                  style={{ position: 'absolute' }}
                  disableShrink
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </DashboardCard>
    </>
  );
};

export default DataRoomObjectEditor;
