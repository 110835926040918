import './CollectionTokenGroupModal.scss';

import { LoadingButton } from '@mui/lab';
import type { TextFieldProps } from '@mui/material';
import { FormControlLabel, Switch } from '@mui/material';
import { Box, Button, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import type { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';

import CollectionItemImage from '~components/collections/CollectionItemImage';
import TraitEditModal from '~components/collections/TraitEditModal';
import TraitsTable from '~components/collections/TraitsTable';
import V2TextInput from '~components/inputs/V2TextInput';
import Modal from '~components/miscs/Modal';
import { selectProjectConfig, selectUploadStatus } from '~features/project-config/project-config.selectors';
import { selectTokenBlueprint } from '~features/token-blueprints/token-blueprints.selectors';
import { selectCurrentTokenGroup } from '~features/token-group-updates/token-group-updates.selectors';
import { createTokenUpdate } from '~features/token-group-updates/token-group-updates.slice';
import { S3_STORAGE_TOKENS_ARTWORK_DIR } from '~features/utils/s3storage/s3.storage.consts';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { TokenGroupUpdateType } from '~types/token/TokenUpdateType';
import { UpdateType } from '~types/token/TokenUpdateType';
import type TraitType from '~types/token/TraitType';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

import { getTokenGroupModalTitle } from './CollectionTokenGroupModal.helpers';

type CollectionTokenGroupModalProps = {
  editType: string;
  open: boolean;
  onClose: () => void;
};

const CollectionTokenGroupModal = ({ editType, open, onClose }: CollectionTokenGroupModalProps) => {
  const dispatch = useAppDispatch();
  const tokenGroup = useAppSelector(selectCurrentTokenGroup);
  const project = useAppSelector(selectProjectConfig);
  const uploadStatus = useAppSelector(selectUploadStatus);
  const { blueprintId, collectionId, name: tokenGroupName, _id: tokenGroupId } = tokenGroup || {};

  const blueprint = useAppSelector(selectTokenBlueprint(blueprintId));
  const { name, image, description, attributes } = blueprint || {};

  const [isEndDate, setIsEndDate] = useState<boolean>(false);
  const [isTraitModal, setIsTraitModal] = useState<boolean>(false);
  const [editTrait, setEditTrait] = useState<{ trait: TraitType; index: number }>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDateInPastError, setIsDateInPastError] = useState<boolean>(false);
  const [isEndDateBeforeStartDateError, setIsEndDateBeforeStartDateError] = useState<boolean>(false);

  const [uploadingImage, setUploadingImage] = useState<string>(null);

  const [currentName, setCurrentName] = useState<string>(name);
  const [currentDescription, setCurrentDescription] = useState<string>(description);
  const [currentStartDate, setCurrentStartDate] = useState<Moment>(moment(new Date()));
  const [currentEndDate, setCurrentEndDate] = useState<Moment>(moment(new Date()));
  const [currentAttributes, setCurrentAttributes] = useState<Array<TraitType>>(attributes);
  const [currentImage, setCurrentImage] = useState<string>(uploadStatus[uploadingImage] || image);
  const [currentImageFile, setCurrentImageFile] = useState<File | null>();

  useEffect(() => {
    if (uploadingImage && !uploadStatus[uploadingImage]) {
      setCurrentImage(`${getS3RootProjectPath(project.slug).path}${S3_STORAGE_TOKENS_ARTWORK_DIR}/${uploadingImage}`);
      setUploadingImage(null);
    }
  }, [uploadStatus]);

  useEffect(() => {
    if (isEndDate) {
      setCurrentEndDate(currentStartDate.clone().add(1, 'days'));
    }
    if (!isEndDate) {
      setIsEndDateBeforeStartDateError(false);
    }
  }, [isEndDate]);

  const getCurrentTokenUpdate = (): Partial<TokenGroupUpdateType> => {
    return {
      type: isEndDate ? UpdateType.LAYERED : UpdateType.STANDARD,
      imageFile: currentImageFile,
      blueprint: {
        name: currentName,
        collectionId,
        description: currentDescription,
        image: currentImage,
        attributes: currentAttributes.map((item: TraitType) => ({
          ...item,
          operation: isEndDate ? 'add' : 'overwrite',
        })),
      },
      tokenGroupId,
      startDate: currentStartDate.toString(),
      endDate: isEndDate ? currentEndDate.toString() : undefined,
    };
  };

  const isValidStartDate = (date: Moment) => {
    return moment().isBefore(date);
  };

  const isValidEndDate = (date: Moment) => {
    return moment(currentStartDate).isBefore(date);
  };

  const onUpdateName = (value: string) => setCurrentName(value);

  const onUpdateDescription = (value: string) => setCurrentDescription(value);

  const onUpdateStartDate = (value) => {
    setCurrentStartDate(value);
    if (isEndDate) {
      setIsEndDateBeforeStartDateError(!isValidEndDate(currentEndDate));
    }
    setIsDateInPastError(!isValidStartDate(value));
  };

  const onUpdateEndDate = (value) => {
    setCurrentEndDate(value);
    setIsEndDateBeforeStartDateError(!isValidEndDate(value));
  };

  const onUpdateAttributes = (value: Array<TraitType>) => setCurrentAttributes(value);

  const onUpdateImage = (files: Array<File>) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setCurrentImage(reader.result as string);
      setCurrentImageFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onSave = () => {
    if (isUpdating) {
      return;
    }
    const isStartDateValid = isValidStartDate(currentStartDate);
    const isEndDateValid = isValidEndDate(currentEndDate);
    if (!isStartDateValid) {
      setIsDateInPastError(true);
    }
    if (isEndDate && !isEndDateValid) {
      setIsEndDateBeforeStartDateError(true);
    }
    if (!isStartDateValid || (isEndDate && !isEndDateValid)) {
      return;
    }
    setIsUpdating(true);
    const update = getCurrentTokenUpdate();
    dispatch(createTokenUpdate(update));
  };

  const onTraitModalClose = () => {
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onEditTrait = (item: TraitType, idx: number) => {
    setEditTrait({ trait: item, index: idx });
    setIsTraitModal(true);
  };

  const onConfirmTraitEdit = (item: TraitType) => {
    if (editTrait && !Number.isNaN(editTrait?.index)) {
      onTraitEdited(item, editTrait.index);
    } else {
      onTraitAdded(item);
    }
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onAddTrait = () => {
    setIsTraitModal(true);
    setEditTrait(null);
  };

  const onTraitAdded = (item: TraitType) => {
    console.log({ item, currentAttributes });
    onUpdateAttributes([...currentAttributes, item]);
  };

  const onTraitEdited = (item: TraitType, idx: number) => {
    const newAttributes = [...currentAttributes];
    newAttributes[idx] = item;
    onUpdateAttributes(newAttributes);
  };

  const onDeleteTrait = (item: TraitType, idx: number) => {
    const newAttributes = [...currentAttributes];
    newAttributes.splice(idx, 1);
    onUpdateAttributes(newAttributes);
  };

  return (
    <Modal
      className={'CAKE__collection-token-group-modal'}
      open={open}
      onClose={onClose}
      title={`${getTokenGroupModalTitle(editType)} for ${tokenGroupName}`}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            onClick={onSave}
            loading={isUpdating}
            loadingIndicator="Updating…"
            sx={{ padding: '0 20px' }}
            disabled={isDateInPastError || isEndDateBeforeStartDateError}
          >
            <span>Save</span>
          </LoadingButton>
        </>
      }
    >
      <Box className={'CAKE__collection-token-group-modal__content'}>
        <Box className={'CAKE__collection-token-group-modal__content__left'}>
          <Box className={'CAKE__collection-token-group-modal__content__left__inner'}>
            <CollectionItemImage
              image={currentImage}
              isDropzone
              onDropImage={onUpdateImage}
            />
          </Box>
        </Box>
        <Box className={'CAKE__collection-token-group-modal__content__right'}>
          <V2TextInput
            label="Token name"
            size="small"
            value={currentName || ''}
            onUpdate={onUpdateName}
          />

          <V2TextInput
            label="Description"
            size="small"
            multiline
            rows={4}
            value={currentDescription || ''}
            onUpdate={onUpdateDescription}
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                sx: { fontSize: 14 },
              },
            }}
            control={
              <Switch
                checked={isEndDate}
                onChange={(e) => setIsEndDate(e.target.checked)}
                size="small"
              />
            }
            label="Dynamic NFT Update"
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Start date"
              value={currentStartDate}
              disablePast
              onChange={onUpdateStartDate}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  size="small"
                  helperText={isDateInPastError && 'Start date must be in the future'}
                  error={isDateInPastError}
                />
              )}
            />
            {isEndDate && (
              <DateTimePicker
                label="End date"
                value={currentEndDate}
                disablePast
                onChange={onUpdateEndDate}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    size="small"
                    helperText={isEndDate && isEndDateBeforeStartDateError && 'End date must be after start date'}
                    error={isEndDate && isEndDateBeforeStartDateError}
                  />
                )}
              />
            )}
          </LocalizationProvider>
          <Box className={'CAKE__collection-token-group-modal__traits'}>
            <TraitsTable
              traits={currentAttributes}
              onAdd={onAddTrait}
              onEdit={onEditTrait}
              onDelete={onDeleteTrait}
            />
          </Box>
          {isTraitModal && (
            <TraitEditModal
              isOpen={isTraitModal}
              trait={editTrait?.trait}
              onConfirm={onConfirmTraitEdit}
              onCancel={onTraitModalClose}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CollectionTokenGroupModal;
