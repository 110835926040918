/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type CollectionItemType from '~types/CollectionItemType';
import type AggregateTraitType from '~types/token/AggregateTraitType';

import { COLLECTION_PAGE_SIZE } from './collection-items.consts';

type CollectionUploadState = {
  tokens: Record<string, CollectionItemType>;
  traits: Array<AggregateTraitType>;
  tokensOrder: Array<string>;
  pageLoaded: number;
  isPageLoading: boolean;
  isNextPage: boolean;
  selection: { [name: string]: string };
  isLoading: boolean;
  totalTokens: number;
};

const initialState: CollectionUploadState = {
  tokens: {},
  traits: [],
  tokensOrder: [],
  pageLoaded: 0,
  isPageLoading: false,
  isNextPage: true,
  selection: {},
  isLoading: false,
  totalTokens: 0,
};

const collectionItemsSlice = createSlice({
  name: 'collection-items',
  initialState,
  reducers: {
    clearCollection(state) {
      state.tokens = initialState.tokens;
      state.traits = initialState.traits;
      state.tokensOrder = initialState.tokensOrder;
      state.pageLoaded = initialState.pageLoaded;
      state.selection = initialState.selection;
      state.isPageLoading = initialState.isPageLoading;
      state.isNextPage = initialState.isNextPage;
      state.isLoading = initialState.isLoading;
      state.totalTokens = initialState.totalTokens;
    },
    getCollection(state, action: PayloadAction<{ page: string }>) {
      state.isPageLoading = true;
    },
    getCollectionNextPage(state) {},
    getCollectionSuccess(
      state,
      action: PayloadAction<{ tokens: Record<string, CollectionItemType>; tokensOrder: string[] }>,
    ) {
      const { tokensOrder, tokens } = action.payload;

      state.pageLoaded += 1;
      state.isPageLoading = false;
      if (tokensOrder.length !== COLLECTION_PAGE_SIZE) {
        state.isNextPage = false;
      }
      state.tokens = {
        ...state.tokens,
        ...tokens,
      };
      state.tokensOrder = [...state.tokensOrder, ...tokensOrder];
    },
    getCollectionFailed(state) {},
    addCollectionItem(state, action: PayloadAction<CollectionItemType>) {
      const { _id } = action.payload;
      state.tokens[_id] = action.payload;
      state.tokensOrder.push(_id);
    },
    addRealTimeCollectionItem(state, action: PayloadAction<CollectionItemType>) {},
    deleteCollectionItem(state, action: PayloadAction<CollectionItemType>) {},
    deleteCollectionItemSuccess(state, action: PayloadAction<CollectionItemType>) {
      const { _id } = action.payload;
      state.tokens[_id] = undefined;
      state.tokensOrder = state.tokensOrder.filter((item: string) => item !== _id);
    },
    deleteCollectionItemError(state, action: PayloadAction<CollectionItemType>) {},
    deleteSelectedCollectionItems(state) {},
    deleteSelectedCollectionItemsSuccess(state, action: PayloadAction<{ [_id: string]: string }>) {
      const ids = Object.values(action.payload);

      const newTokens = state.tokens;
      ids.forEach((item) => {
        delete newTokens[item];
      });
      state.tokens = newTokens;
      state.tokensOrder = state.tokensOrder.filter((item: string) => !ids.includes(item));
      state.selection = initialState.selection;
    },
    deleteSelectedCollectionItemsError(state) {},
    deleteAllCollectionItems(state) {},
    deleteAllCollectionItemsError(state) {},
    updateCollectionItem(state, action: PayloadAction<CollectionItemType>) {},
    updateCollectionItemSuccess(state, action: PayloadAction<CollectionItemType>) {
      const { _id } = action.payload;
      state.tokens[_id] = action.payload;
    },
    updateCollectionItemError(state, action: PayloadAction<string>) {},
    selectCollectionItem(state, action: PayloadAction<CollectionItemType>) {
      const token = action.payload;
      state.selection[token._id] = token._id;
    },
    unselectCollectionItem(state, action: PayloadAction<CollectionItemType>) {
      const token = action.payload;
      delete state.selection[token._id];
    },
    setCollectionTraits(state, action: PayloadAction<Array<AggregateTraitType>>) {
      state.traits = [...action.payload];
    },
    getCollectionTraits(state) {},
    updateCollectionTraits(state, action: PayloadAction<CollectionItemType>) {},
    updateCollectionItemWithUpdateIdLocally(
      state,
      action: PayloadAction<{ tokenId: string; tokenMetadata: CollectionItemType; updateId: string }>,
    ) {},
    updateMultipleCollectionItemsWithUpdateIdLocally(
      state,
      action: PayloadAction<{ tokenIds: Array<string>; tokenMetadata: CollectionItemType; updateId: string }>,
    ) {
      const { tokenIds, tokenMetadata, updateId } = action.payload;
      tokenIds.forEach((item: string) => {
        const token = {
          ...state.tokens[item],
          ...tokenMetadata,
        };
        if (token.activeUpdate?.updateId === updateId) {
          delete token.activeUpdate;
        }
        state.tokens[item] = token;
      });
    },
    updateActiveUpdateForTokens(
      state,
      action: PayloadAction<{ tokenIds: string[]; tokenUpdate: { status: string; type: string; updateId: string } }>,
    ) {
      const { tokenIds, tokenUpdate } = action.payload;

      const updatedTokens = {};
      tokenIds.forEach((tokenId: string) => {
        const token = state.tokens[tokenId];
        if (token) {
          updatedTokens[tokenId] = {
            ...token,
            activeUpdate: tokenUpdate,
          };
        }
      });
      state.tokens = {
        ...state.tokens,
        ...updatedTokens,
      };
    },
    updateStartingIndex(state) {
      state.isLoading = true;
    },
    updateStartingIndexComplete(state) {
      state.isLoading = false;
    },
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsLoadingComplete(state) {
      state.isLoading = false;
    },
  },
});

export const {
  addCollectionItem,
  addRealTimeCollectionItem,
  clearCollection,
  deleteCollectionItem,
  deleteCollectionItemError,
  deleteCollectionItemSuccess,
  deleteSelectedCollectionItems,
  deleteSelectedCollectionItemsError,
  deleteSelectedCollectionItemsSuccess,
  deleteAllCollectionItems,
  deleteAllCollectionItemsError,
  getCollection,
  getCollectionFailed,
  getCollectionNextPage,
  getCollectionSuccess,
  selectCollectionItem,
  unselectCollectionItem,
  updateCollectionItem,
  updateCollectionItemError,
  updateCollectionItemSuccess,
  setCollectionTraits,
  getCollectionTraits,
  updateCollectionTraits,
  updateCollectionItemWithUpdateIdLocally,
  updateMultipleCollectionItemsWithUpdateIdLocally,
  updateActiveUpdateForTokens,
  updateStartingIndex,
  updateStartingIndexComplete,
  setIsLoading,
  setIsLoadingComplete,
} = collectionItemsSlice.actions;
export default collectionItemsSlice.reducer;
