import type { RootState } from '~features';

const selectProjectShopifyAuthenticating = (state: RootState) => state.projectShopify.isAuthenticating;

const selectProjectShopifyConnecting = (state: RootState) => state.projectShopify.isConnecting;

const selectProjectShopifyError = (state: RootState) => state.projectShopify.error;

const selectProjectShopifyConfig = (state: RootState) => state.projectShopify.shopifyConfig;

const selectProjectShopifyShop = (state: RootState) => state.projectShopify.shop;

export {
  selectProjectShopifyAuthenticating,
  selectProjectShopifyConfig,
  selectProjectShopifyConnecting,
  selectProjectShopifyError,
  selectProjectShopifyShop,
};
