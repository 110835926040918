import { Stack, TextField } from '@mui/material';
import { useState } from 'react';

import InputIconButton from '~components/buttons/InputIconButton';
import { defaultFonts } from '~components/dashboard/TypographySettingsCard/constants';
import { getFontStyle } from '~components/dashboard/TypographySettingsCard/helpers';
import { PencilIcon } from '~icons/common';
import type { TypographyItemType, TypographyType } from '~types/ProjectType';
import { TypographyKeys } from '~types/ProjectType';

import FontSelectorModal from './FontSelectorModal';

type TypographySettingsProps = {
  settings?: TypographyType;
  onUpdateFont: (newFont: TypographyItemType, name: TypographyKeys, file?: File) => void;
};

const TypographySettings = ({ settings, onUpdateFont }: TypographySettingsProps) => {
  const [editor, setEditor] = useState<TypographyKeys | null>(null);

  const settingsWithFallback: TypographyType = {
    fontDisplay: settings?.fontDisplay ?? defaultFonts.fontDisplay,
    fontBody: settings?.fontBody ?? defaultFonts.fontBody,
  };

  const onSelectFont = (fontToUpload: TypographyItemType, name: TypographyKeys, file?: File) => {
    onUpdateFont(fontToUpload, name, file);
    setEditor(null);
  };

  return (
    <Stack
      flexDirection={'row'}
      gap={'20px'}
    >
      <TextField
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputIconButton onClick={() => setEditor(TypographyKeys.Display)}>
              <PencilIcon />
            </InputIconButton>
          ),
          style: getFontStyle(settingsWithFallback.fontDisplay),
        }}
        label={'Display Font'}
        value={settingsWithFallback.fontDisplay.name}
      />

      <TextField
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputIconButton onClick={() => setEditor(TypographyKeys.Body)}>
              <PencilIcon />
            </InputIconButton>
          ),
          style: getFontStyle(settingsWithFallback.fontBody),
        }}
        label={'Body Font'}
        value={settingsWithFallback.fontBody.name}
      />

      <FontSelectorModal
        name={editor}
        isOpen={Boolean(editor)}
        onCancel={() => setEditor(null)}
        onSelect={onSelectFont}
        selectedFont={settingsWithFallback[editor ?? '']}
      />
    </Stack>
  );
};

export default TypographySettings;
