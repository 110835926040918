import { useState } from 'react';

import AppearanceModeSettings from '~components/dashboard/AppearanceSettingsCard/components/AppearanceModeSettings';
import DashboardCard from '~components/dashboard/DashboardCard/DashboardCard';
import { selectProjectDappConfig } from '~features/project-config/project-config.selectors';
import { updateProjectDappConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { AppearanceColorType } from '~types/ProjectType';

const AppearanceSettingsCard = () => {
  const dispatch = useAppDispatch();
  const [isLightMode] = useState<boolean>(false);
  const dappConfig = useAppSelector(selectProjectDappConfig);
  const { colors } = dappConfig;

  // TODO: Temporarly commented as we don't support light/dark mode at the moment
  // const onUpdateLightColors = (newValue: AppearanceColorType) => {
  //   dispatch(
  //     updateProjectDappConfig({
  //       dapp: {
  //         ...dappConfig,
  //         colors: { ...colors, light: newValue },
  //       },
  //       event: {
  //         content: {
  //           event: 'dApp Settings Update',
  //           description: 'Updated dApp light-mode colors.',
  //         },
  //       },
  //     }),
  //   );
  // };

  // const onUpdateDarkColors = (newValue: AppearanceColorType) => {
  //   dispatch(
  //     updateProjectDappConfig({
  //       dapp: {
  //         ...dappConfig,
  //         colors: { ...colors, dark: newValue },
  //       },
  //       event: {
  //         content: {
  //           event: 'dApp Settings Update',
  //           description: 'Updated dApp dark-mode colors.',
  //         },
  //       },
  //     }),
  //   );
  // };

  const onUpdateColors = (newValue: AppearanceColorType) => {
    dispatch(
      updateProjectDappConfig({
        dapp: {
          ...dappConfig,
          colors: { ...colors, dark: newValue, light: newValue },
        },
        event: {
          content: {
            event: 'dApp Settings Update',
            description: 'Updated dApp dark-mode colors.',
          },
        },
      }),
    );
  };
  return (
    <DashboardCard
      title={'Color settings'}
      subtitle={'Please select your brand colors and hex codes.'}
    >
      <AppearanceModeSettings
        colors={isLightMode ? colors?.light : colors?.dark}
        // onUpdateColors={isLightMode ? onUpdateLightColors : onUpdateDarkColors}
        onUpdateColors={onUpdateColors}
      />
    </DashboardCard>
  );
};

export default AppearanceSettingsCard;
