import { updateActiveUpdateForTokens } from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { upsertSingleTokenUpdate } from '~features/token-group-updates/token-group-updates.slice';

const tokenGroupLayeredUpdateScheduleHandler = (emitter, data) => {
  const { status, type, updateId, tokenIds } = data;
  const tokenUpdate = { updateId: updateId as string, status: status as string, type: type as string };

  // emitter(
  //   updateTokenGroupLocally({
  //     _id: tokenGroupId,
  //     blueprintId: blueprint._id,
  //   }),
  // );
  const ids: string[] = tokenIds.map((item) => item._id.toString());

  emitter(upsertSingleTokenUpdate(tokenUpdate));
  emitter(updateActiveUpdateForTokens({ tokenIds: ids, tokenUpdate }));
  emitter(
    addNotification({
      message: 'Layered Update - update window scheduled',
      severity: 'info',
      duration: 2000,
    }),
  );
};

export default tokenGroupLayeredUpdateScheduleHandler;
