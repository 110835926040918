import { Stack, Typography } from '@mui/material';

interface NotFoundViewProps {
  label?: string;
}

const NotFoundView = ({ label }: NotFoundViewProps) => (
  <Stack
    alignContent={'center'}
    justifyContent={'center'}
    width={'100%'}
    height={'100%'}
  >
    <Typography textAlign={'center'}>{label || 'Page not found'}</Typography>
  </Stack>
);

export default NotFoundView;
