import { Stack } from '@mui/material';
import React from 'react';

interface EmptyStateViewProps {
  message: string;
}

const EmptyStateView: React.FC<EmptyStateViewProps> = ({ message }) => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      height={200}
      width={'100%'}
    >
      <h3>{message}</h3>
    </Stack>
  );
};

export default EmptyStateView;
