import './ChartTooltip.scss';

import { Typography } from '@mui/material';

interface ChartTooltipProps {
  point: any;
}

const ChartTooltip = ({ point }: ChartTooltipProps) => {
  return (
    <div className="CAKE__chart-tooltip">
      <Typography
        fontSize={13}
        fontWeight={'600'}
        variant="subtitle1"
      >{`date: ${point.data.x}`}</Typography>
      <Typography
        fontSize={13}
        fontWeight={'600'}
        variant="subtitle1"
      >{`cumulative transaction value: ${point.data.y} ETH`}</Typography>
      <Typography
        fontSize={13}
        fontWeight={'600'}
        variant="subtitle1"
      >{`transactions volume: ${point.data.volume}`}</Typography>
    </div>
  );
};

export default ChartTooltip;
