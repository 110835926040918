import { Typography } from '@mui/material';
import { useEffect } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import ProjectControls from '~components/projects/ProjectControls';
import ProjectsTable from '~components/projects/ProjectsTable';
import PrivateRoutes from '~constants/routes/private-routes.consts';
import { selectAllProjects, selectAllProjectsIsLoading } from '~features/projects-list/projects-list.selectors';
import { getAllProjects } from '~features/projects-list/projects-list.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import useSmartNavigate from '~hooks/useSmartNavigate';
import type { ProjectType } from '~types/ProjectType';

const ProjectsListView = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useSmartNavigate();

  const projects: Array<ProjectType> = useAppSelector(selectAllProjects);
  const isLoading: boolean = useAppSelector(selectAllProjectsIsLoading);

  useEffect(() => {
    dispatch(getAllProjects());
  }, []);

  const onSelectProject = (project: ProjectType) => {
    navigate(PrivateRoutes.DASHBOARD_PROJECT_COMMUNITY, {
      id: project._id,
    });
  };

  const onAddProject = () => {
    navigate(PrivateRoutes.PROJECT_BUILDER);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageLayout>
      <DashboardCard
        title={'Home'}
        subtitle={`${projects?.length ?? 0} total collections`}
        rightContainer={<ProjectControls onAddProject={onAddProject} />}
      >
        {projects.length > 0 ? (
          <ProjectsTable
            projects={projects}
            onSelectItem={onSelectProject}
          />
        ) : (
          <Typography>You have not created any projects yet.</Typography>
        )}
      </DashboardCard>
    </PageLayout>
  );
};

export default ProjectsListView;
