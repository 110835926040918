import type { ContractNetworkType, ContractType } from '~types/ProjectType';

const getCurrentContractInfo = (contract: ContractType): ContractNetworkType => {
  if (contract?.livenet.contractAddress) {
    return contract.livenet;
  } else if (contract?.testnet.contractAddress) {
    return contract.testnet;
  }
  return null;
};

export default getCurrentContractInfo;
