const ContractState = [
  {
    name: 'name',
    updateFunction: 'setProjectNameAndSymbol',
    type: 'string',
    paramIndexes: [0, 1],
    value: '',
  },
  {
    name: 'symbol',
    updateFunction: 'setProjectNameAndSymbol',
    type: 'string',
    paramIndexes: [0, 1],
    value: '',
  },
  {
    name: 'maxSupply',
    updateFunction: 'setMaxSupply',
    type: 'integer',
    paramIndexes: [2],
    value: 0,
  },
  {
    name: 'price',
    updateFunction: 'setPrice',
    type: 'integer',
    paramIndexes: [3],
    value: 0,
  },
  {
    name: 'maxTotalMints',
    updateFunction: 'setMintLimits',
    type: 'integer',
    paramIndexes: [4, 5],
    value: 0,
  },
  {
    name: 'maxMintTxns',
    updateFunction: 'setMintLimits',
    type: 'integer',
    paramIndexes: [4, 5],
    value: 0,
  },
  {
    name: 'privateSaleTimestamp',
    updateFunction: 'setTimestamps',
    type: 'integer',
    paramIndexes: [6, 7],
    value: 0,
  },
  {
    name: 'publicSaleTimestamp',
    updateFunction: 'setTimestamps',
    type: 'integer',
    paramIndexes: [6, 7],
    value: 0,
  },
  {
    name: 'superAdmin',
    updateFunction: 'updateSuperAdmin',
    type: 'string',
    paramIndexes: [8],
    value: '',
  },
  {
    name: 'primaryDistRecipients',
    updateFunction: '',
    type: 'Array(string)',
    paramIndexes: [9, 10],
    value: [],
  },
  {
    name: 'primaryDistShares',
    updateFunction: '',
    type: 'Array(integer)',
    paramIndexes: [9, 10],
    value: [],
  },
  {
    name: 'royaltyReceiver',
    updateFunction: '',
    type: 'string',
    paramIndexes: [11],
    value: '',
  },
  {
    name: 'royaltyFraction',
    updateFunction: '',
    type: 'integer',
    paramIndexes: [12],
    value: 0,
  },
  {
    name: 'merkleroot',
    updateFunction: 'setMerkleroot',
    type: 'string',
    paramIndexes: [13],
    value: '0x0000000000000000000000000000000000000000000000000000000000000000',
  },
  {
    name: '_baseURI',
    updateFunction: 'setBaseURI',
    type: 'string',
    paramIndexes: [14],
    value: '',
  },
  {
    name: 'closeDate',
    updateFunction: 'setOEClose',
    type: 'integer',
    paramIndexes: [15],
    value: 0,
  },
  {
    name: 'minMint',
    updateFunction: 'setMinMint',
    type: 'integer',
    paramIndexes: [16],
    value: 1,
  },
  {
    name: 'startingIndex',
    updateFunction: '',
    type: 'integer',
    paramIndexes: [17],
    value: null,
  },
  {
    name: 'vrfCoordinator',
    updateFunction: '',
    type: 'string',
    paramIndexes: [18],
    value: '',
  },
  {
    name: 's_subscriptionId',
    updateFunction: '',
    type: 'integer',
    paramIndexes: [19],
    value: 0,
  },
  {
    name: 'keyHash',
    updateFunction: '',
    type: 'string',
    paramIndexes: [20],
    value: '',
  },
  {
    name: 'requestConfirmations',
    updateFunction: '',
    type: 'integer',
    paramIndexes: [21],
    value: 0,
  },
  {
    name: 'callbackGasLimit',
    updateFunction: '',
    type: 'integer',
    paramIndexes: [22],
    value: 0,
  },
  {
    name: 'provenanceHash',
    updateFunction: 'setProvenanceHash',
    type: 'string',
    paramIndexes: [23],
    value: '',
  },
  {
    name: 'lastRequestId',
    updateFunction: '',
    type: 'integer',
    paramIndexes: [24],
    value: 0,
  },
];

const BasicInfoStateVariablesToIgnore = [
  'merkleroot',
  '_baseURI',
  'startingIndex',
  'vrfCoordinator',
  's_subscriptionId',
  'keyHash',
  'requestConfirmations',
  'callbackGasLimit',
  'lastRequestId',
];

export { BasicInfoStateVariablesToIgnore, ContractState };
