import { useEffect } from 'react';

import ImageGenerationsCard from '~components/dashboard/ImageGenerationsCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import { selectProjectConfig, selectProjectConfigError } from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { selectProjectDropIsLoading } from '~features/project-drop/project-drop.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectCommunityView = () => {
  const dispatch = useAppDispatch();
  const projectId = location.pathname.split('/')[3];
  const projectConfig = useAppSelector(selectProjectConfig);
  const isLoading = useAppSelector(selectProjectDropIsLoading);
  const error = useAppSelector(selectProjectConfigError);

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error || projectConfig === null) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <ImageGenerationsCard />
    </PageLayout>
  );
};

export default ProjectCommunityView;
