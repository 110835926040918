import './PluginSwitch.scss';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';

import type Plugin from '~types/Plugin';

type PluginSwitchProps = {
  plugin: Plugin;
  isActive: boolean;
  isLoading?: boolean;
  onChange: (value: boolean) => void;
};

const PluginSwitch = ({ plugin, isActive, isLoading = false, onChange }: PluginSwitchProps) => {
  return (
    <Stack
      className={'CAKE__plugin-switch__container'}
      display={'flex'}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
    >
      <Stack direction={'row'}>
        <Stack
          width={'105px'}
          maxHeight={'105px'}
          pb={'8px'}
          pr={'24px'}
          display={'flex'}
          justifyContent={'center'}
        >
          <img
            className={'CAKE__plugin-switch__image'}
            src={plugin.logo}
          />
        </Stack>

        <Stack
          display={'flex'}
          justifyContent={'flex-start'}
          pt={'8px'}
        >
          <Typography
            variant={'h5'}
            color={'#1A1D1F'}
          >
            {plugin.name}
          </Typography>
          <Box className={'CAKE__plugin-switch__info-container'}>
            <Typography
              variant={'body2'}
              className={'CAKE__plugin-switch__category-label'}
              fontWeight={'600'}
            >
              {plugin.category}
            </Typography>
            <Typography
              variant={'body2'}
              className={'CAKE__plugin-switch__divider'}
            >
              |
            </Typography>
            <Typography
              variant={'body2'}
              className={'CAKE__plugin-switch__plan-label'}
            >
              {`${plugin.plan} available`}
            </Typography>
          </Box>
          <Box className={'CAKE__plugin-switch__buttons-container'}>
            <LoadingButton
              loading={isLoading}
              disabled={isActive}
              size="xsmall"
              variant={isActive ? 'outlined' : 'contained'}
              onClick={() => onChange(!isActive)}
            >
              {isActive ? 'Activated' : 'Activate'}
            </LoadingButton>
          </Box>
          <Typography
            variant={'body2'}
            sx={{ fontSize: '13px' }}
          >
            {plugin.description}
          </Typography>
        </Stack>
      </Stack>

      {/* <Switch
        checked={isActive}
        onChange={(event) => onChange(event.target.checked)}
      /> */}
    </Stack>
  );
};

export default PluginSwitch;
