const getDeploymentNetwork = (currentNetwork: string) => {
  switch (currentNetwork) {
    case 'goerli':
      return 'mainnet';
    case 'sepolia':
      return 'mainnet';
    case 'baseGoerli':
      return 'baseMainnet';
    case 'baseSepolia':
      return 'baseMainnet';
  }
  return;
};

export default getDeploymentNetwork;
