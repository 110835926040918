import { updateMultipleCollectionItemsWithUpdateIdLocally } from '~features/collection-items/collection-items.slice';
import { updateTokenGroupLocally } from '~features/collection-token-groups/collection-token-groups.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { storeBlueprint } from '~features/token-blueprints/token-blueprints.slice';

const tokenGroupStandardUpdateHandler = (emitter, data) => {
  const { blueprint, tokenGroupId, tokenUpdateId, tokenMetadata, tokenIds } = data;

  emitter(storeBlueprint(blueprint));
  emitter(
    updateTokenGroupLocally({
      _id: tokenGroupId,
      blueprintId: blueprint._id,
    }),
  );
  emitter(
    updateMultipleCollectionItemsWithUpdateIdLocally({
      tokenIds,
      tokenMetadata,
      updateId: tokenUpdateId,
    }),
  );
  emitter(
    addNotification({
      message: 'Standard Update performed',
      severity: 'info',
      duration: 5000,
    }),
  );
};

export default tokenGroupStandardUpdateHandler;
