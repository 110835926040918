import TableContainer from '~components/miscs/TableContainer/TableContainer';
import type { TokenHolder } from '~types/AnalyticsType';

import TokenHolderItem from './components/TokenHolderItem';

const tableHeaders = [
  { text: 'Wallet Address', value: 'walletAddress' },
  { text: 'Email', value: 'email' },
  { text: 'Total Tokens', value: 'totalTokens' },
  { text: 'Updates', value: 'update' },
  { text: 'Last Activity', value: 'date' },
  { text: '', value: 'actions' },
];

interface TokenHoldersTableProps {
  walletHolders: TokenHolder[];
}
const TokenHoldersTable = ({ walletHolders }: TokenHoldersTableProps) => {
  return (
    <TableContainer
      data={walletHolders}
      headers={tableHeaders}
      itemRenderer={(item: TokenHolder, key: string) => (
        <TokenHolderItem
          key={key}
          tokenHolder={item}
        />
      )}
      keyExtractor={(item: TokenHolder) => `token-holder-${item.walletAddress}`}
      noDataText="There are no token holders for this contract."
      pagination
    />
  );
};

export default TokenHoldersTable;
