import '~components/miscs/WalletConnectModal/WalletConnectModal.scss';

import { Dialog, DialogContent } from '@mui/material';
import { Stack } from '@mui/system';

import { useWallet } from '~context/WalletContext';
import { CoinbaseIcon, MetamaskIcon } from '~icons/common';

type WalletConnectModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletConnectModal = ({ isOpen, onClose }: WalletConnectModalProps) => {
  const { selectProvider } = useWallet();

  const onSelectProvider = (walletType: string) => {
    selectProvider(walletType);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <DialogContent>
        <Stack
          width={'100%'}
          justifyContent="center"
          alignItems={'center'}
        >
          <Stack
            direction={'column'}
            className={'CAKE__wallet-connect-button__modal__option__container'}
            gap={1}
            onClick={() => onSelectProvider('metamask')}
          >
            <MetamaskIcon />
            <div className={'CAKE__wallet-connect-button__modal__option__text'}>Metamask</div>
          </Stack>
          <Stack
            direction={'column'}
            alignItems={'center'}
            gap={1}
            onClick={() => onSelectProvider('cb-wallet')}
            className={'CAKE__wallet-connect-button__modal__option__container'}
          >
            <CoinbaseIcon />
            <div className={'CAKE__wallet-connect-button__modal__option__text'}>Coinbase Wallet</div>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default WalletConnectModal;
