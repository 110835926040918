import { Box, Grid } from '@mui/material';

import ValueBox from '~components/analytics/ValueBox';

type Props = {
  data: {
    label: string;
    sublabel?: string;
    value?: number | string;
    changeFactor?: number | string;
    type: string;
    tooltip?: string;
  }[];
};

const BasicAnalytics = ({ data }: Props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
      >
        {data.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            xl={3}
            key={index}
          >
            <ValueBox {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BasicAnalytics;
