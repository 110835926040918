import { ContractNetworkIds, EtherscanURI } from '~constants/networks/networks';

type etherscanLinkBuilderDataType = {
  address?: string;
  transactionHash?: string;
  tokenId?: number;
};

type etherscanLinkBuilderProps = {
  chainId: number | string;
  type: string;
  data: etherscanLinkBuilderDataType;
};

const getBaseURL = (chainId: number | string) => {
  switch (chainId) {
    case ContractNetworkIds.MAINNET:
      return EtherscanURI.MAINNET;
    case ContractNetworkIds.GOERLI:
      return EtherscanURI.GOERLI;
    case ContractNetworkIds.SEPOLIA:
      return EtherscanURI.SEPOLIA;
    case ContractNetworkIds.BASE_GOERLI:
      return EtherscanURI.BASE_GOERLI;
    case ContractNetworkIds.BASE_MAINNET:
      return EtherscanURI.BASE_MAINNET;
  }
};

const getURLPath = (type: string, data: etherscanLinkBuilderDataType) => {
  switch (type) {
    case 'tokenId':
      return `token/${data.address}?a=${data.tokenId}`;
    case 'txn':
      return `tx/${data.transactionHash}`;
    case 'address':
      return `address/${data.address}`;
    default:
      return;
  }
};

const etherscanLinkBuilder = ({ chainId, type, data }: etherscanLinkBuilderProps) => {
  const baseUrl = getBaseURL(chainId);
  const path = getURLPath(type, data);
  return `${baseUrl}/${path}`;
};

export default etherscanLinkBuilder;
