/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type ContractManagerState = {
  isTxnProcessing: boolean;
  isTxnSuccess: boolean;
  error: string;
};

const initialState: ContractManagerState = {
  isTxnProcessing: false,
  isTxnSuccess: false,
  error: '',
};

const contractManager = createSlice({
  name: 'contract-manager',
  initialState,
  reducers: {
    callContractFunction(
      state,
      action: PayloadAction<{
        network: string;
        provider: any;
        walletAddress: string;
        functionName: string;
        args: Array<number | string>;
      }>,
    ) {},
    resetContractConfig(state) {
      state.isTxnProcessing = initialState.isTxnProcessing;
      state.isTxnSuccess = initialState.isTxnSuccess;
      state.error = initialState.error;
    },
    setTransactionStatus(state, action: PayloadAction<{ status: string }>) {
      action.payload.status === 'success' ? (state.isTxnSuccess = true) : (state.isTxnSuccess = false);
    },
    setIsTxnProcessing(state, action: PayloadAction<boolean>) {
      state.isTxnProcessing = action.payload;
    },
    callContractManagerError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const {
  callContractFunction,
  callContractManagerError,
  setTransactionStatus,
  setIsTxnProcessing,
  resetContractConfig,
} = contractManager.actions;

export default contractManager.reducer;
