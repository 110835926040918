import '~components/collections/CollectionItemModal/components/CollectionItemContent/CollectionItemContent.scss';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, IconButton } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';

import CollectionItemImage from '~components/collections/CollectionItemImage';
import TraitEditModal from '~components/collections/TraitEditModal';
import TraitsTable from '~components/collections/TraitsTable';
import V2TextInput from '~components/inputs/V2TextInput';
import { updateItemImage } from '~features/collection-upload/collection-upload.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import type CollectionItemType from '~types/CollectionItemType';
import type TraitType from '~types/token/TraitType';

type CollectionItemContentProps = {
  token: CollectionItemType;
  onChange: (token: CollectionItemType) => void;
};

const CollectionItemContent = ({ token, onChange }: CollectionItemContentProps) => {
  const dispatch = useAppDispatch();
  const { attributes, description, image, tokenId, name } = token;
  const [isImage, setIsImage] = useState<boolean>(!!image);
  const [isTraitModal, setIsTraitModal] = useState<boolean>(false);
  const [editTrait, setEditTrait] = useState<{ trait: TraitType; index: number }>(null);

  const onTraitModalClose = () => {
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onEditTrait = (item: TraitType, idx: number) => {
    setEditTrait({ trait: item, index: idx });
    setIsTraitModal(true);
  };

  const onConfirmTraitEdit = (item: TraitType) => {
    if (editTrait && !Number.isNaN(editTrait?.index)) {
      onTraitEdited(item, editTrait.index);
    } else {
      onTraitAdded(item);
    }
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onAddTrait = () => {
    setIsTraitModal(true);
    setEditTrait(null);
  };

  const onTraitAdded = (item: TraitType) => {
    onChange({
      ...token,
      attributes: [...attributes, item],
    });
  };

  const onTraitEdited = (item: TraitType, idx: number) => {
    const newAttributes = [...attributes];
    newAttributes[idx] = item;
    onChange({
      ...token,
      attributes: newAttributes,
    });
  };

  const onDeleteTrait = (item: TraitType, idx: number) => {
    const newAttributes = [...attributes];
    newAttributes.splice(idx, 1);
    onChange({
      ...token,
      attributes: newAttributes,
    });
  };

  const onUpdateDescription = (description: string) => {
    onChange({
      ...token,
      description: description.length === 0 ? undefined : description,
    });
  };

  const onUpdateName = (name: string) => {
    onChange({
      ...token,
      name: name.length === 0 ? undefined : name,
    });
  };

  const onUpdateImageFile = (files: Array<File>) => {
    const file: File = files[0];
    dispatch(updateItemImage({ file, token }));
  };

  return (
    <Box className={'CAKE__collection-item-content'}>
      <Box
        className={classNames({
          'CAKE__collection-item-content__image': true,
          'CAKE__collection-item-content__image--no': !isImage,
        })}
      >
        <CollectionItemImage
          image={image}
          onImageError={() => setIsImage(false)}
          isDropzone
          onDropImage={onUpdateImageFile}
        />
      </Box>
      <Box className={'CAKE__collection-item-content__properties'}>
        <V2TextInput
          label="Image"
          value={image || ''}
          size="small"
          disabled
          onUpdate={() => console.log('ignore')}
          inputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={() => window.open(image, '_blank')}
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <V2TextInput
          label="Token id"
          size="small"
          value={parseInt(tokenId.toString()) < 0 ? 'N/A' : tokenId.toString()}
          disabled
          onUpdate={() => console.log('ignore')}
        />
        <V2TextInput
          label="Token name"
          size="small"
          value={name || ''}
          onUpdate={onUpdateName}
        />
        <V2TextInput
          className="CAKE__collection-item-content__description-input"
          size="small"
          label="Description for NFT Media"
          value={description || ''}
          multiline
          rows={4}
          onUpdate={onUpdateDescription}
        />
        <Box className={'CAKE__collection-item-content__traits'}>
          <TraitsTable
            traits={attributes}
            onAdd={onAddTrait}
            onEdit={onEditTrait}
            onDelete={onDeleteTrait}
          />
        </Box>
        {isTraitModal && (
          <TraitEditModal
            isOpen={isTraitModal}
            trait={editTrait?.trait}
            onConfirm={onConfirmTraitEdit}
            onCancel={onTraitModalClose}
          />
        )}
      </Box>
    </Box>
  );
};

export default CollectionItemContent;
