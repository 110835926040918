import { projectMenuItems } from './MenuDrawer.consts';
import MenuDrawerVariant from './MenuDrawerVariant';

const getMenuItems = (variant: MenuDrawerVariant) => {
  switch (variant) {
    case MenuDrawerVariant.PROJECT:
      return projectMenuItems;
    default:
      return [];
  }
};

export { getMenuItems };
