import { FileTypes } from '~types/DataRoomTypes';
import { getFileType } from '~utils/file';

interface FileViewerProps {
  name: string;
  url: string;
}

const FileViewer = ({ name, url }: FileViewerProps) => {
  const fileType = getFileType(name);

  if (fileType === FileTypes.Image) {
    return (
      <img
        alt={url}
        src={url}
        style={{ objectFit: 'contain', padding: '1rem' }}
      />
    );
  }

  if (fileType === FileTypes.Video) {
    return (
      <video
        src={url}
        controls
      />
    );
  }

  return (
    <iframe
      src={`${url}#toolbar=0`}
      style={{ width: '100%', minHeight: '800px' }}
    />
  );
};

export default FileViewer;
