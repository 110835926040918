import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ProjectTimeline from '~components/dashboard/ProjectTimeline/ProjectTimeline';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import {
  selectIsFeedLoading,
  selectIsProjectFeedUpdating,
  selectStreamFeedData,
  selectStreamNextPage,
} from '~features/streams/streams.selectors';
import { fetchProjectFeed } from '~features/streams/streams.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectOverview = () => {
  const dispatch = useAppDispatch();

  const projectConfig = useAppSelector(selectProjectConfig);
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const error = useAppSelector(selectProjectConfigError);

  const projectFeed = useAppSelector(selectStreamFeedData);
  const feedIsLoading = useAppSelector(selectIsFeedLoading);
  const isProjectFeedUpdating = useAppSelector(selectIsProjectFeedUpdating);
  const nextProjectFeedPage = useAppSelector(selectStreamNextPage);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    if (projectConfig && projectConfig._id !== projectId) {
      dispatch(getProjectConfig({ id: projectId, force: true }));
    } else {
      dispatch(getProjectConfig({ id: projectId, force: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollFeedUpdate = (values) => {
    if (projectFeed) {
      const isPageBottom = values.scrollHeight * values.top >= values.scrollHeight - 200;
      if (isPageBottom && !isProjectFeedUpdating && nextProjectFeedPage) {
        dispatch(fetchProjectFeed());
      }
    }
  };

  if (isLoading || feedIsLoading) {
    return <PageLoader />;
  }

  if (error || projectConfig === null) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout scrollHandler={handleScrollFeedUpdate}>
      <ProjectTimeline />
    </PageLayout>
  );
};

export default ProjectOverview;
