import './TraitsTable.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import type { MouseEvent } from 'react';

import InputIconButton from '~components/buttons/InputIconButton';
import type TraitType from '~types/token/TraitType';

type TraitsTableType = {
  onAdd?: () => void;
  onDelete?: (item: TraitType, idx: number) => void;
  onEdit?: (item: TraitType, idx: number) => void;
  traits?: Array<TraitType>;
};

const TraitsTable = ({ onAdd, onDelete, onEdit, traits }: TraitsTableType) => {
  const onDeleteHandler = (e: MouseEvent<HTMLDivElement>, item: TraitType, idx: number) => {
    e.stopPropagation();
    onDelete(item, idx);
  };
  return (
    <>
      <Box className={'CAKE__traits-table__header'}>
        <Typography variant={'body2'}>Metadata</Typography>
        {onAdd && (
          <IconButton
            onClick={onAdd}
            size={'small'}
          >
            <AddIcon color={'primary'} />
          </IconButton>
        )}
      </Box>
      <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Table
          stickyHeader
          size={'small'}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              {onDelete && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(traits || []).map((item: TraitType, idx: number) => (
              <TableRow
                sx={{ cursor: 'pointer' }}
                key={`trait-${item.trait_type}=${idx}`}
                onClick={() => onEdit && onEdit(item, idx)}
              >
                <TableCell>{item.trait_type}</TableCell>
                <TableCell>{item.value}</TableCell>
                {onDelete && (
                  <TableCell
                    width={'20px'}
                    sx={{ padding: '6px' }}
                  >
                    <InputIconButton
                      onClick={(e) => onDeleteHandler(e, item, idx)}
                      color="#599CFF"
                    >
                      <DeleteOutlineIcon />
                    </InputIconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TraitsTable;
