import type { TypographyItemType } from '~types/ProjectType';

export function getFontStyle(font: TypographyItemType) {
  return {
    fontFamily: font.family,
    fontWeight: font.weight,
  };
}

export function getFontNameFromFile(file: File) {
  return removeExtension(file.name.replace(/[-_]/g, ' '));
}

export function removeExtension(string: string, extension = 'woff2') {
  return string.replace(`.${extension}`, '');
}
