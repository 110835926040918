import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getAllProjects, setAllProjects } from '~features/projects-list/projects-list.slice';
import { unsubscribeChannels } from '~features/pubsub/pubsub.slice';
import { fetchProtectedAPI } from '~features/utils/api/api.sagas';
import type { ProjectType } from '~types/ProjectType';

function* fetchAllProjectsSaga(): Iterator<any> {
  try {
    const params = { fields: ['name', 'status', 'createdAt', 'dapp.images', 'contract.totalSupply', 'contract.price'] };
    const res: Response = yield call(fetchProtectedAPI, 'project', params);
    const data: Array<ProjectType> = yield res.json();
    yield put(unsubscribeChannels());
    yield put(setAllProjects(data));
  } catch (e) {
    console.log(e);
  }
}
export default function* campaignSaga(): Iterator<any> {
  yield all([yield takeLatest(getAllProjects.type, fetchAllProjectsSaga)]);
}
