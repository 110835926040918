import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import AlertBanner from '~components/miscs/AlertBanner';
import ComponentLoader from '~components/miscs/ComponentLoader';
import Modal from '~components/miscs/Modal';
import {
  selectIsContractUpgrade,
  selectUpgradeData,
  selectUpgradeIsProcessing,
} from '~features/contract-config/contract-config.selectors';
import { callContractUpgrade } from '~features/contract-config/contract-config.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import getProjectNetwork from '~utils/projects/get-network';

const ContractUpgradeAlert = () => {
  const dispatch = useAppDispatch();
  const contractUpgradeData = useAppSelector(selectUpgradeData);
  const upgradeIsProcessing = useAppSelector(selectUpgradeIsProcessing);
  const isUpgradeNeeded = useAppSelector(selectIsContractUpgrade);
  const project = useAppSelector(selectProjectConfig);
  const network = getProjectNetwork(project);
  const [isOpen, setIsOpen] = useState<boolean>(isUpgradeNeeded);

  const handleUpgrade = () => {
    if (project) {
      dispatch(callContractUpgrade({ projectId: project._id, upgradeData: contractUpgradeData }));
    }
  };

  return (
    <>
      <ComponentLoader isOpen={upgradeIsProcessing} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Upgrade Your Contract!'}
        height="350px"
        footer={
          <Stack
            direction={'row'}
            gap={1}
            sx={{ marginBottom: '10px' }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={handleUpgrade}
            >
              Upgrade
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                console.log('dont upgrade my contract!');
                setIsOpen(false);
              }}
            >
              Ignore
            </Button>
          </Stack>
        }
      >
        <Stack
          direction={'column'}
          gap={1}
        >
          <Typography variant={'h6'}>Cake has added some new contract functionality to its smart contracts.</Typography>
          <Typography>You must upgrade your contract in order to access these new features.</Typography>
          <Stack direction={'column'}>
            {contractUpgradeData &&
              contractUpgradeData.upgradeDetails.features.map((feature, idx) => {
                return (
                  <Typography
                    variant={'caption'}
                    key={idx}
                  >
                    • {feature}
                  </Typography>
                );
              })}
          </Stack>
        </Stack>
      </Modal>

      <AlertBanner
        isLoading={upgradeIsProcessing}
        title={'Your contract must be upgraded.'}
        loadingTitle={`${network.charAt(0).toUpperCase() + network.slice(1)} Contract Upgrade In Progress...`}
        tooltipTitle={'Your contract does not have the latest Cake features:'}
        buttonTitle={'Upgarde Now'}
        onAccept={() => setIsOpen(true)}
        icon={<WarningRoundedIcon sx={{ color: 'white' }} />}
      />
    </>
  );
};

export default ContractUpgradeAlert;
