/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ProjectType } from '~types/ProjectType';

import { ContractState } from './contract-config.constants';

export type ContractStateType = {
  name: string;
  updateFunction: string;
  type: string;
  paramIndexes: Array<number>;
  value: string | number | Array<string | number>;
};

type FacetCut = {
  facetAddress: string;
  action: number;
  selectors: string[];
};

export type ContractUpgradeData = {
  upgradeDetails: {
    requiresInit: boolean;
    features: string[];
  };
  facetCuts: Array<FacetCut>;
};

type ContractConfigState = {
  data: Array<ContractStateType>;
  updateParamIndexes: Array<number>;
  isMatch: boolean;
  isUpgrade: boolean;
  upgradeCheckInProcess: boolean;
  upgradeData: ContractUpgradeData | null;
  isUpgradeInProcess: boolean;
  isTxnProcessing: boolean;
  isTxnSuccess: boolean;
  error: string;
};

const initialState: ContractConfigState = {
  data: ContractState,
  updateParamIndexes: [],
  isMatch: true,
  isUpgrade: false,
  upgradeData: null,
  upgradeCheckInProcess: true,
  isUpgradeInProcess: false,
  isTxnProcessing: false,
  isTxnSuccess: false,
  error: '',
};

const contractConfig = createSlice({
  name: 'contract-config',
  initialState,
  reducers: {
    callDeployedContractState(state, action: PayloadAction<{ projectId: string; network: string }>) {},
    setDeploymentContractState(state, action: PayloadAction<Array<any>>) {
      state.data = ContractState.map((item: ContractStateType) => {
        return {
          ...item,
          value: action.payload[item.name],
        };
      });
    },
    setMatchStatus(state, action: PayloadAction<boolean>) {
      state.isMatch = action.payload;
    },
    callContractUpgradeState(state) {},
    callContractUpgrade(state, action: PayloadAction<{ projectId: string; upgradeData: ContractUpgradeData }>) {},
    setUpgradeStatus(state, action: PayloadAction<boolean>) {
      state.isUpgrade = action.payload;
    },
    setUpgradeIsProcessing(state, action: PayloadAction<boolean>) {
      state.isUpgradeInProcess = action.payload;
    },
    setUpgradeData(state, action: PayloadAction<ContractUpgradeData | null>) {
      state.upgradeCheckInProcess = false;
      state.upgradeData = action.payload;
    },
    setUpdateParamIndexes(state, action: PayloadAction<Array<number>>) {
      state.updateParamIndexes = action.payload;
    },
    checkDeploymentContractMatch(state, action: PayloadAction<{ project: ProjectType }>) {},
    resetContractConfig(state) {
      state.data = initialState.data;
      state.updateParamIndexes = initialState.updateParamIndexes;
      state.isMatch = initialState.isMatch;
      state.isUpgrade = initialState.isUpgrade;
      state.upgradeCheckInProcess = initialState.upgradeCheckInProcess;
      state.upgradeData = initialState.upgradeData;
      (state.isUpgradeInProcess = initialState.isUpgradeInProcess),
        (state.isTxnProcessing = initialState.isTxnProcessing);
      state.isTxnSuccess = initialState.isTxnSuccess;
      state.error = initialState.error;
    },
    updateDeployedContractState(
      state,
      action: PayloadAction<{ network: string; provider: any; walletAddress: string }>,
    ) {},
    setTransactionStatus(state, action: PayloadAction<{ status: string }>) {
      action.payload.status === 'success' ? (state.isTxnSuccess = true) : (state.isTxnSuccess = false);
    },
    setIsTxnProcessing(state, action: PayloadAction<boolean>) {
      state.isTxnProcessing = action.payload;
    },
    callContractConfigError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const {
  callDeployedContractState,
  setDeploymentContractState,
  checkDeploymentContractMatch,
  setMatchStatus,
  callContractUpgradeState,
  callContractUpgrade,
  setUpgradeIsProcessing,
  setUpgradeStatus,
  setUpgradeData,
  setUpdateParamIndexes,
  callContractConfigError,
  updateDeployedContractState,
  setTransactionStatus,
  setIsTxnProcessing,
  resetContractConfig,
} = contractConfig.actions;

export default contractConfig.reducer;
