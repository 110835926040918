import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BasicAnalytics from '~components/analytics/BasicAnalytics/BasicAnalytics';
import DetailedAnalyticsInformation from '~components/analytics/DetailedAnalyticsInformation';
import TransactionValueChart from '~components/analytics/TransactionValueChart/TransactionValueChart';
import DashboardCard from '~components/dashboard/DashboardCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import ProjectHeader from '~components/projects/ProjectHeader/ProjectHeader';
import {
  selectFloorPrice,
  selectLastWeekFloorPrice,
  selectLastWeekTotalSupply,
  selectLastWeekVolume,
  selectTotalEligibleTokens,
  selectTotalMonthlyUpdates,
  selectTotalSupply,
  selectVolume,
  selectWalletHoldersData,
} from '~features/analytics/analytics.selectors';
import {
  clearAnalyticsData,
  getEligibleTokens,
  getTotalSupply,
  getTransferData,
  getWalletHolders,
} from '~features/analytics/analytics.slice';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { ProjectType } from '~types/ProjectType';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';
import getContractAddressForChainId from '~utils/projects/getContractAddressForChainId';
import getProjectCurrentChainId from '~utils/projects/getProjectCurrentChainId';
import NotFoundView from '~views/NotFoundView';

import {
  computeEligibleTokenChange,
  computeFloorPriceChange,
  computeHoldersChange,
  computeTotalSupplyChange,
  computeVolumeChange,
} from './ProjectAnalyticsView.helpers';

const ProjectAnalyticsView = () => {
  const dispatch = useAppDispatch();

  const projectConfig: ProjectType = useAppSelector(selectProjectConfig);
  const chainId = getProjectCurrentChainId(projectConfig);
  const contractAddress = getContractAddressForChainId(projectConfig, chainId);
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const error = useAppSelector(selectProjectConfigError);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];
  const floorPrice = useAppSelector(selectFloorPrice);
  const lastWeekFloorPrice = useAppSelector(selectLastWeekFloorPrice);
  const volume = useAppSelector(selectVolume);
  const lastWeekVolume = useAppSelector(selectLastWeekVolume);
  const holdersData = useAppSelector(selectWalletHoldersData);
  const totalSupply = useAppSelector(selectTotalSupply);
  const lastWeekTotalSupply = useAppSelector(selectLastWeekTotalSupply);
  const totalUpdates = useAppSelector(selectTotalMonthlyUpdates);
  const eligibleTokens = useAppSelector(selectTotalEligibleTokens);

  const statisticsData = [
    {
      label: 'Total Supply',
      value: totalSupply,
      changeFactor: computeTotalSupplyChange(totalSupply, lastWeekTotalSupply),
      type: 'percentChange',
    },
    {
      label: 'Holders',
      value: holdersData?.order.length ?? null,
      changeFactor: computeHoldersChange(holdersData),
      type: 'percentChange',
    },
    {
      label: 'Volume',
      value: volume,
      changeFactor: computeVolumeChange(volume, lastWeekVolume),
      type: 'percentChange',
    },
    {
      label: 'Floor Price',
      value: floorPrice,
      changeFactor: computeFloorPriceChange(floorPrice, lastWeekFloorPrice),
      type: 'percentChange',
      tooltip: 'Lowest value traded for a token in this collection.',
    },
    {
      label: 'Total Updates',
      sublabel: 'Trailing 30 days',
      value: totalUpdates,
      changeFactor: null,
      type: 'percentChange',
    },
    {
      label: 'Eligible Tokens',
      value: eligibleTokens,
      changeFactor: computeEligibleTokenChange(totalSupply, eligibleTokens),
      type: 'percentTotal',
      tooltip: 'Total of all tokens with no past updates or the latest update.',
    },
  ];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  useEffect(() => {
    if (projectConfig && projectConfig._id && contractAddress?.length) {
      const network = getCurrentContractNetwork(projectConfig.contract);
      dispatch(
        getTransferData({
          projectId: projectConfig._id,
          contractAddress,
          refresh: true,
        }),
      );
      dispatch(getWalletHolders({ projectId, contractAddress, refresh: true }));
      dispatch(getTotalSupply({ projectId, network }));
      dispatch(getEligibleTokens({ projectId }));
    }
    return () => {
      dispatch(clearAnalyticsData());
    };
    // eslint-disable-next-line
  }, [chainId, projectConfig]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error || !projectConfig) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <DashboardCard>
        <ProjectHeader subtitle="Analytics" />
      </DashboardCard>
      <Box
        style={{ display: 'flex', flexDirection: 'column' }}
        marginRight={8}
        marginTop={2}
        gap={4}
      >
        <BasicAnalytics data={statisticsData} />
        <TransactionValueChart />
        <DetailedAnalyticsInformation />
      </Box>
    </PageLayout>
  );
};

export default ProjectAnalyticsView;
