import './ImageGenerationEmptyState.scss';

import { Box, Typography } from '@mui/material';

const ImageGenerationEmptyState = () => {
  return (
    <Box className={'CAKE__image-generation-empty-state'}>
      <img src="/images/empty-state-icon.png" />
      <Typography className={'CAKE__image-generation-empty-state__label'}>No images</Typography>
    </Box>
  );
};

export default ImageGenerationEmptyState;
