/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Shopify } from '~types/ShopifyType';

interface ProjectShopifyState {
  shopifyConfig: Shopify | null;
  isAuthenticating: boolean;
  isConnecting: boolean;
  error: string | null;
  shop: string | null;
}

const initialState: ProjectShopifyState = {
  shopifyConfig: null,
  isAuthenticating: false,
  isConnecting: false,
  error: null,
  shop: null,
};

const projectShopifyState = createSlice({
  name: 'project-shopify',
  initialState,
  reducers: {
    authenticateProjectShopifyAccount(state) {
      state.isAuthenticating = true;
      state.isConnecting = false;
      state.error = null;
    },
    checkProjectShopifyAuthWindow(state, action: PayloadAction<{ shopifyAuthWindow: Window }>) {},
    connectProjectShopifyAccount(state, action: PayloadAction<{ accessToken: string }>) {
      state.isAuthenticating = false;
      state.isConnecting = true;
      state.error = null;
    },
    cancelProjectShopifyConnect(state) {
      state.isAuthenticating = false;
      state.isConnecting = false;
    },
    getProjectShopifySessionAndConnect(state) {},
    setProjectShopify(state, action: PayloadAction<Shopify>) {
      state.isAuthenticating = false;
      state.isConnecting = false;
      state.shopifyConfig = action.payload;
    },
    setProjectShopifyAuthenticating(state) {
      state.isAuthenticating = true;
      state.isConnecting = false;
      state.error = null;
    },
    setProjectShopifyError(state, action: PayloadAction<string>) {
      state.isAuthenticating = false;
      state.isConnecting = false;
      state.error = action.payload;
    },
    setProjectShopifyShop(state, action: PayloadAction<{ shop: string }>) {
      state.shop = action.payload.shop;
    },
  },
});

export const {
  authenticateProjectShopifyAccount,
  checkProjectShopifyAuthWindow,
  connectProjectShopifyAccount,
  cancelProjectShopifyConnect,
  getProjectShopifySessionAndConnect,
  setProjectShopify,
  setProjectShopifyAuthenticating,
  setProjectShopifyError,
  setProjectShopifyShop,
} = projectShopifyState.actions;

export default projectShopifyState.reducer;
