enum ContractNetwork {
  MAINNET = 'mainnet',
  GOERLI = 'goerli',
  BASE_GOERLI = 'baseGoerli',
  BASE_MAINNET = 'baseMainnet',
  SEPOLIA = 'sepolia',
  BASE_SEPOLIA = 'baseSepolia',
  UNKNOWN = 'unknown',
}

enum ContractNetworkIds {
  MAINNET = 1,
  GOERLI = 5,
  BASE_GOERLI = 84531,
  BASE_MAINNET = 8453,
  SEPOLIA = 11155111,
  BASE_SEPOLIA = 84532,
}

enum EtherscanURI {
  MAINNET = 'https://etherscan.io/',
  GOERLI = 'https://goerli.etherscan.io/',
  BASE_GOERLI = 'https://goerli.basescan.org/',
  BASE_MAINNET = 'https://basescan.org/',
  SEPOLIA = 'https://sepolia.etherscan.io/',
  BASE_SEPOLIA = 'https://sepolia-explorer.base.org/',
}

enum NetworkLayer {
  ETH = 'Ethereum',
  BASE = 'Base (L2)',
}

const PROD_NETWORKS = [ContractNetwork.MAINNET, ContractNetwork.BASE_MAINNET];

export { ContractNetwork, ContractNetworkIds, EtherscanURI, NetworkLayer, PROD_NETWORKS };
