import { ProjectCollectionType } from '~constants/project/project-constants';
import type { ProjectType } from '~types/ProjectType';

const verifyContractArguments = (project: ProjectType, collectionTokensCount: number) => {
  if (!project.name) return { isVerified: false, error: 'add project name' };
  if (!project.contract.symbol) return { isVerified: false, error: 'add project symbol' };
  if (project.collectionType === ProjectCollectionType.CUSTOM && project.contract.totalSupply !== collectionTokensCount)
    return { isVerified: false, error: 'your total supply does not match the number of tokens in your collection' };
  if (!project.contract.presale) return { isVerified: false, error: 'add project presale date' };
  if (!project.contract.publicSale) return { isVerified: false, error: 'add project public sale date' };
  if (!project.contract.superAdmin) return { isVerified: false, error: 'add project admin address' };

  const paymentPercentageTotal = project.contract.paymentSplit.length
    ? project.contract.paymentSplit.reduce((acc: number, curr: { address: string; amount: number }) => {
        return curr ? (acc += curr.amount) : (acc += 0);
      }, 0)
    : 0;

  if (paymentPercentageTotal !== 100) return { isVerified: false, error: 'payment split must equal 100' };
  if (!project.contract.royalty.address || project.contract.royalty.amount < 0)
    return { isVerified: false, error: 'add royalty address' };

  return { isVerified: true };
};

export default verifyContractArguments;
