import { createDraftSafeSelector } from '@reduxjs/toolkit';
import BN from 'bn.js';
import moment from 'moment';

import type { RootState } from '~features';
import type { CumulativeTransferData, TransferDataType } from '~types/AnalyticsType';
import convertWeiToEth from '~utils/web3/conversion/eth-converter';

const selectTransferData = (state: RootState) => state.analytics.transferData;

const selectWalletHolders = (state: RootState) =>
  state.analytics.walletHolders.order.map((address) => state.analytics.walletHolders.holders[address]);

const selectWalletHoldersData = (state: RootState) => state.analytics.walletHolders;

const selectCumulativeTransferData = (state: RootState) => state.analytics.cumulativeTransferData.data;

const selectCumulativeTransferResolution = (state: RootState) => state.analytics.cumulativeTransferData.resolution;

const selectFloorPrice = createDraftSafeSelector(selectTransferData, (data: TransferDataType[]) => {
  if (data.length === 0) {
    return '--';
  }
  let floorPrice: BN = new BN(0);
  data.every((transfer) => {
    if (!transfer.isMint && new BN(transfer.value)) {
      if (!floorPrice || new BN(transfer.value).lt(floorPrice)) {
        floorPrice = new BN(transfer.value);
      }
    }
    if (floorPrice.eq(new BN(0))) {
      return false;
    }
    return true;
  });

  return convertWeiToEth(floorPrice);
});

const selectLastWeekFloorPrice = createDraftSafeSelector(selectTransferData, (data: TransferDataType[]) => {
  if (data.length === 0) {
    return '--';
  }
  let floorPrice: BN = new BN(0);
  data
    .filter(
      (transfer: TransferDataType) => new Date(transfer.date) <= new Date(new Date().getTime() - 7 * 1000 * 60 * 60),
    )
    .every((itm) => {
      if (!itm.isMint && new BN(itm.value)) {
        if (!floorPrice || new BN(itm.value).lt(floorPrice)) {
          floorPrice = new BN(itm.value);
        }
      }
      if (floorPrice.eq(new BN(0))) {
        return false;
      }
      return true;
    });

  return convertWeiToEth(floorPrice);
});

const selectVolume = createDraftSafeSelector(selectCumulativeTransferData, (data: CumulativeTransferData[]) => {
  return data.length && data[data.length - 1].y;
});

const selectLastWeekVolume = createDraftSafeSelector(selectCumulativeTransferData, (data: CumulativeTransferData[]) => {
  const lastWeekCumulativeTransfers: CumulativeTransferData[] | undefined =
    data.length && data.filter((itm: CumulativeTransferData) => moment(itm.x).isBefore(moment().subtract(7, 'days')));
  return lastWeekCumulativeTransfers.length && lastWeekCumulativeTransfers[lastWeekCumulativeTransfers.length - 1]?.y;
});

const selectHoldersCount = (state: RootState) => state.analytics.walletHolders.order.length;

const selectTotalSupply = (state: RootState) => state.analytics.totalSupply;

const selectTotalMonthlyUpdates = (state: RootState) => state.analytics.events.lastMonthCount;

const selectTotalEligibleTokens = (state: RootState) => state.analytics.eligibleTokens;

const selectLastWeekTotalSupply = createDraftSafeSelector(selectTransferData, (data: TransferDataType[]) => {
  return data.filter(
    (transfer: TransferDataType) =>
      transfer.isMint && moment(transfer.date).isBefore(moment().subtract(7, 'days'), 'day'),
  ).length;
});

export {
  selectCumulativeTransferData,
  selectCumulativeTransferResolution,
  selectFloorPrice,
  selectHoldersCount,
  selectLastWeekFloorPrice,
  selectLastWeekTotalSupply,
  selectLastWeekVolume,
  selectTotalEligibleTokens,
  selectTotalMonthlyUpdates,
  selectTotalSupply,
  selectTransferData,
  selectVolume,
  selectWalletHolders,
  selectWalletHoldersData,
};
