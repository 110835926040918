import type { RootState } from '~features';

const selectWhitelistConfig = (state: RootState): Array<string> => state.whitelistConfig.whitelist;

const selectProjectMerkleroot = (state: RootState): string => state.whitelistConfig.merkleroot;

const selectContractMerkleroot = (state: RootState): string => state.whitelistConfig.contractMerkleroot;

const selectWhitelistConfigIsLoading = (state: RootState): boolean => state.whitelistConfig.isLoading;

const selectWhitelistConfigError = (state: RootState): string | null => state.whitelistConfig.error;

export {
  selectContractMerkleroot,
  selectProjectMerkleroot,
  selectWhitelistConfig,
  selectWhitelistConfigError,
  selectWhitelistConfigIsLoading,
};
