import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';

import ProjectRecipientsTable from '~components/projects/ProjectSplitPayment/components/ProjectRecipientsTable';
import type { ProjectType } from '~types/ProjectType';

type ProjectSplitPaymentProps = {
  config: ProjectType;
  onConfigUpdated: (config: ProjectType) => void;
};

const ProjectSplitPayment = ({ config, onConfigUpdated }: ProjectSplitPaymentProps) => {
  const onAddRecipient = () => {
    onConfigUpdated({
      ...config,
      contract: {
        ...config.contract,
        paymentSplit: [...config.contract.paymentSplit, { address: '0x', amount: 0 }],
      },
    });
  };

  const onUpdateRecipients = (paymentSplit) => {
    onConfigUpdated({
      ...config,
      contract: {
        ...config.contract,
        paymentSplit,
      },
    });
  };

  return (
    <Stack>
      <ProjectRecipientsTable
        recipients={config.contract.paymentSplit}
        onUpdateRecipients={onUpdateRecipients}
      />

      <Stack
        display={'flex'}
        alignItems={'flex-start'}
        paddingTop={2}
      >
        <Button
          onClick={onAddRecipient}
          size={'small'}
          variant="contained"
        >
          <AddIcon fontSize={'small'} />
          <Typography
            fontSize={'small'}
            sx={{ paddingTop: '1px' }}
          >
            Add Recipient
          </Typography>
        </Button>
      </Stack>

      <Stack
        display={'flex'}
        alignItems={'flex-start'}
        paddingTop={4}
      >
        <Typography variant={'body2'}>* Total share has to be equal to 100</Typography>
      </Stack>
    </Stack>
  );
};

export default ProjectSplitPayment;
