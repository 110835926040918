import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';

import type { TypographyItemType } from '~types/ProjectType';
import { TypographyKeys } from '~types/ProjectType';

import FontSelector from './FontSelector';

type FontSelectorModalProps = {
  isOpen: boolean;
  name: TypographyKeys;
  onCancel: () => void;
  onSelect: (fontToUpload: TypographyItemType, name: TypographyKeys, file?: File) => void;
  selectedFont: TypographyItemType;
};

const titles = {
  [TypographyKeys.Body]: 'Body',
  [TypographyKeys.Display]: 'Display',
};

const FontSelectorModal = ({ isOpen, name, onCancel, onSelect, selectedFont }: FontSelectorModalProps) => {
  const [fontToUpload, setFontToUpload] = useState<{ font: TypographyItemType; file?: File }>(null);

  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>
        Select {titles[name]} Font
        <DialogContentText
          fontWeight={'normal'}
          variant="body3"
        >
          Choose from the list of Google fonts, or upload a custom font. You can customize the weight of each inserted
          Google font, or change the name of a font by clicking into the Font Name field below.
        </DialogContentText>
      </DialogTitle>

      <DialogContent>
        {selectedFont && (
          <FontSelector
            originalFont={selectedFont}
            onSelectFont={(font, file) => setFontToUpload({ font, file })}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ padding: '1rem 1.5rem' }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => onSelect(fontToUpload.font, name, fontToUpload.file)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FontSelectorModal;
