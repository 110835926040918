import { addNotification } from '~features/notifications/notifications.slice';
import { upsertSingleTokenUpdate } from '~features/token-group-updates/token-group-updates.slice';
import { UpdateType } from '~types/token/TokenUpdateType';

const tokenGroupLayeredUpdateHandler = (emitter, data) => {
  // const { blueprint, tokenGroupId } = data;

  // emitter(
  //   updateTokenGroupLocally({
  //     _id: tokenGroupId,
  //     blueprintId: blueprint._id,
  //   }),
  // );
  // emitter(storeBlueprint(blueprint));
  emitter(
    upsertSingleTokenUpdate({
      type: UpdateType.LAYERED,
      status: 'ACTIVE',
      updateId: data.tokenUpdateId,
    }),
  );
  emitter(
    addNotification({
      message: 'Layered Update - update window opened',
      severity: 'info',
      duration: 2000,
    }),
  );
};

export default tokenGroupLayeredUpdateHandler;
