import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import type { TemplateOption } from '~components/dashboard/TemplateSettingsCard/TemplateSettingsCard.consts';

type TemplatePreviewProps = {
  isOpen: boolean;
  isSelected: boolean;
  onClose: () => void;
  onSelect: () => void;
  template: TemplateOption;
};

const TemplatePreview = ({ isOpen, isSelected, template, onClose, onSelect }: TemplatePreviewProps) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={'sm'}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">Preview | {template.name}</DialogTitle>

      <DialogContent>
        <Typography variant="body2">{template.description}</Typography>

        <Stack padding={4}>
          <img
            className="CAKE__template-settings-card__preview"
            alt={`Template Preview for ${template.name}`}
            src={template.preview}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={isSelected}
          variant="contained"
          onClick={onSelect}
        >
          Select Template
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplatePreview;
