import { DeleteObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Buffer } from 'buffer';
import S3FileUpload from 'react-s3';
import { v1 as uuidv1 } from 'uuid';

import type { S3FileUploadResponse } from '~types/S3';
window.Buffer = window.Buffer || Buffer;

const bucketName = String(process.env.REACT_APP_AWS_S3_BUCKET_NAME || '');
const keyId = String(process.env.REACT_APP_AWS_KEY_ID || '');
const secret = String(process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '');
const region = 'us-west-2';

const s3Client = new S3Client({
  region,
  credentials: {
    accessKeyId: keyId,
    secretAccessKey: secret,
  },
});

function* uploadFileToS3(file: File, dir: string, name?: string): Iterator<S3FileUploadResponse> {
  const config = {
    bucketName: bucketName,
    dirName: dir,
    region,
    accessKeyId: keyId,
    secretAccessKey: secret,
  };
  try {
    const newName = name || uuidv1();
    const [, extension] = file.name.split('.');
    const newFile = new File([file], `${newName}.${extension}`);
    const data = yield S3FileUpload.uploadFile(newFile, config);
    return data;
  } catch (error) {
    console.log({ error });
  }
  return null;
}

function* deleteFileFromS3(path: string): Iterator<any> {
  const splittedPath = path.split('amazonaws.com/');

  const params = {
    Bucket: bucketName,
    Key: splittedPath.length === 2 ? splittedPath[1] : path,
  };
  const command = new DeleteObjectCommand(params);
  yield s3Client.send(command);
}

function* doesFileExistsOnS3(fileName: string, path: string): Iterator<any, boolean> {
  try {
    const result: Response = yield fetch(`${path}/${fileName}`, { method: 'HEAD' });
    return result.ok;
  } catch {
    return false;
  }
}

export { deleteFileFromS3, doesFileExistsOnS3, uploadFileToS3 };
