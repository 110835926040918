const volumeGradient = (
  <linearGradient
    id="volumeGradient"
    x1="0"
    y1="0"
    x2="0"
    y2="1"
  >
    <stop
      offset="0%"
      stopColor="#2057A4"
      stopOpacity={1}
    />
    <stop
      offset="7%"
      stopColor="#2077B4"
      stopOpacity={0.8}
    />
    <stop
      offset="70%"
      stopColor="#2077B4"
      stopOpacity={0.6}
    />
  </linearGradient>
);

const eventGradient = (
  <linearGradient
    id="eventGradient"
    x1="0"
    y1="0"
    x2="0"
    y2="1"
  >
    <stop
      offset="0%"
      stopColor="#f5da62"
      stopOpacity={1}
    />

    <stop
      offset="100%"
      stopColor="#f5da62"
      stopOpacity={1}
    />
  </linearGradient>
);

export { eventGradient, volumeGradient };
