import { clearCollection, getCollection } from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { setVrfTxnProcessing } from '~features/vrf/vrf.slice';

const randomNumberFulfilledHandler = (emitter, data) => {
  emitter(setVrfTxnProcessing(false));
  emitter(clearCollection());
  emitter(getCollection());

  emitter(
    addNotification({
      message: 'Collection Reveal Complete',
      severity: 'success',
      duration: 5000,
    }),
  );
};

export default randomNumberFulfilledHandler;
