const getShopDomain = (shop: string) => {
  return `${shop}.myshopify.com`;
};

const getSubmitButtonText = ({
  isAuthenticating,
  isConnecting,
}: {
  isAuthenticating: boolean;
  isConnecting: boolean;
}) => {
  if (isAuthenticating) {
    return 'Authenticating';
  }

  if (isConnecting) {
    return 'Connecting';
  }

  return 'Connect';
};

const shopIsValid = (shop: string | null) => {
  return shop?.length > 2;
};

export { getShopDomain, getSubmitButtonText, shopIsValid };
