import { addNotification } from '~features/notifications/notifications.slice';

const unknownTransactionErrorHandler = (emitter, data) => {
  emitter(
    addNotification({
      message: 'There was an error with your transaction',
      severity: 'error',
      duration: 5000,
    }),
  );
};

export default unknownTransactionErrorHandler;
