import './ImageDropzone.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress } from '@mui/material';
import { default as classNames, default as cx } from 'classnames';
import { useState } from 'react';
import type { DropzoneInputProps } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import isVideo from '~utils/regex/isVideoURL';

const FILE_SIZE_LIMIT_B = 20 * 1024 * 1024;

type ImageDropzoneProps = {
  image?: string;
  inputProps?: DropzoneInputProps;
  isUploading?: boolean;
  onDropFile: (files: Array<File>) => void;
  onError?: (message: string) => void;
  sizeRecommendation?: string;
  title?: string;
  variant?: 'small' | 'default';
  onDelete?: () => void;
};

const ImageDropzone = ({
  image,
  inputProps: dropzoneInputProps = {},
  isUploading = false,
  onDropFile,
  onError,
  sizeRecommendation = '',
  title,
  variant = 'default',
  onDelete,
}: ImageDropzoneProps) => {
  const isSmall = variant === 'small';

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (files) => {
      const filesWithSizeExceeded = files.filter((file: File) => file.size > FILE_SIZE_LIMIT_B);
      if (filesWithSizeExceeded.length > 0) {
        onError && onError('File is too big!');
        return;
      }
      onDropFile(files);
    },
  });
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const inputProps = getInputProps({ multiple: false, accept: 'image/png, image/jpeg', ...dropzoneInputProps });
  delete inputProps.multiple;

  return (
    <Box
      className={cx({
        'CAKE__image-dropzone': true,
        'CAKE__image-dropzone--small': isSmall,
        'CAKE__image-dropzone--active': isDragActive,
      })}
      {...getRootProps()}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {onDelete && image && (
        <div
          className={cx({
            'CAKE__image-dropzone__delete': true,
            'CAKE__image-dropzone__delete--mouseover': isMouseOver,
          })}
          onClick={onDelete}
        >
          <DeleteIcon color={'primary'} />
        </div>
      )}
      {isUploading && (
        <Box
          className={classNames({
            'CAKE__image-dropzone__upload': true,
            'CAKE__image-dropzone__upload--small': isSmall,
          })}
        >
          <CircularProgress size={isSmall ? 18 : 24} />
        </Box>
      )}
      <div
        onClick={open}
        className={'CAKE__image-dropzone__image--container'}
      >
        {image && !isVideo(image) && !isUploading && (
          <img
            className={'CAKE__image-dropzone__image--image'}
            src={image}
          />
        )}
        {image && isVideo(image) && !isUploading && (
          <video
            className={'CAKE__image-dropzone__image--image'}
            autoPlay
            loop
            muted={true}
            src={image}
          />
        )}
      </div>
      {!isUploading && (
        <>
          <Box
            className={classNames({
              'CAKE__image-dropzone__info': true,
              'CAKE__image-dropzone__info--small': isSmall,
              'CAKE__image-dropzone__info--hidden': Boolean(image) && !isMouseOver,
              'CAKE__image-dropzone__info--with-image': Boolean(image) && isMouseOver,
            })}
            onClick={open}
          >
            <AddIcon
              color={'inherit'}
              fontSize={'inherit'}
            />
            {!isSmall && (
              <>
                <p className={'CAKE__image-dropzone__title'}>{title}</p>
                <p className={'CAKE__image-dropzone__size-recommendation'}>{sizeRecommendation}</p>
              </>
            )}
          </Box>
          <input {...inputProps} />
        </>
      )}
    </Box>
  );
};

export default ImageDropzone;
