import '~views/projects/ProjectShopifyAuth/ProjectShopifyAuth.scss';

import { Box, Link, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

import PageLoader from '~components/miscs/PageLoader';
import { setProjectShopifyError } from '~features/project-shopify/project-shopify.slice';
import useAppDispatch from '~hooks/useAppDispatch';

const ProjectShopifyAuth = () => {
  const params = new URLSearchParams(window.location.search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.get('error')) {
      dispatch(setProjectShopifyError('Could not authenticate with Shopify.'));
    }
  }, []);

  if (params.get('loading')) {
    return <PageLoader />;
  }

  return (
    <Stack className="CAKE__project-shopify-auth">
      <Box className="CAKE__project-shopify-auth__container">
        {params.get('success') && (
          <Typography>
            You have successfully authenticated with the Shopify store{' '}
            <Link
              href={params.get('shop')}
              className="CAKE__project-shopify-auth__emphasize"
            >
              {params.get('shop')}
            </Link>
            . Close the window to continue connecting your account.
          </Typography>
        )}

        {params.get('error') && (
          <Typography>
            Sorry, something went wrong authenticating with Shopify. Please try again or contact us to report the issue.
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default ProjectShopifyAuth;
