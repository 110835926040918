import './CollectionTokenGroupDetails.scss';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Box, Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import CollectionItemImage from '~components/collections/CollectionItemImage';
import TraitEditModal from '~components/collections/TraitEditModal';
import TraitsTable from '~components/collections/TraitsTable';
import V2TextInput from '~components/inputs/V2TextInput';
import ComponentLoader from '~components/miscs/ComponentLoader';
import ContractDeploymentStatus from '~constants/contract/ContractDeploymentStatus';
import TokenGroupAction from '~constants/tokens/TokenGroupAction';
import { removeTokenGroup, updateTokenGroup } from '~features/collection-token-groups/collection-token-groups.slice';
import { selectProjectDeploymentStatus } from '~features/project-config/project-config.selectors';
import {
  selectTokenBlueprint,
  selectTokenBlueprintStatus,
} from '~features/token-blueprints/token-blueprints.selectors';
import { getTokenBlueprint, updateBlueprintImage } from '~features/token-blueprints/token-blueprints.slice';
import { selectTokenUpdates } from '~features/token-group-updates/token-group-updates.selectors';
import { getTokenUpdates } from '~features/token-group-updates/token-group-updates.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import type TraitType from '~types/token/TraitType';

type CollectionTokenGroupDetailsProps = {
  tokenGroup: CollectionTokenGroupType;
  editTokenGroup: (tokenGroup: CollectionTokenGroupType, type: string) => void;
};

const CollectionTokenGroupDetails = ({ editTokenGroup, tokenGroup }: CollectionTokenGroupDetailsProps) => {
  const { blueprintId } = tokenGroup;

  const dispatch = useAppDispatch();
  const blueprint = useAppSelector(selectTokenBlueprint(blueprintId));
  const blueprintStatus = useAppSelector(selectTokenBlueprintStatus(blueprintId));
  const deploymentStatus = useAppSelector(selectProjectDeploymentStatus);

  const tokenGroupUpdates = useAppSelector(selectTokenUpdates);

  const [isTraitModal, setIsTraitModal] = useState<boolean>(false);
  const [editTrait, setEditTrait] = useState<{ trait: TraitType; index: number }>(null);

  const [tokenCount, setTokenCount] = useState<number>(tokenGroup.totalCount);
  const [tokenName, setTokenName] = useState<string>(blueprint?.name ?? '');
  const [tokenDescription, setTokenDescription] = useState<string>(blueprint?.description ?? '');

  useEffect(() => {
    if (tokenGroup) {
      dispatch(getTokenUpdates(tokenGroup._id));
    }
  }, [tokenGroup]);

  useEffect(() => {
    if (blueprintStatus === undefined && blueprint === undefined) {
      dispatch(getTokenBlueprint(blueprintId));
    }
  }, []);

  useEffect(() => {
    if (blueprint) {
      setTokenName(blueprint.name);
      setTokenDescription(blueprint.description);
    }
  }, [blueprint]);

  const onUpdateImageFile = (files: Array<File>) => {
    const file: File = files[0];
    dispatch(updateBlueprintImage({ file, blueprint }));
  };

  const onRemoveTokenGroup = (tokenGroupId: string) => {
    dispatch(removeTokenGroup(tokenGroupId));
  };

  const handleCountUpdate = (value: string) => {
    setTokenCount(Number.parseInt(value, 10));
    handleTokenGroupUpdate({ totalCount: Number.parseInt(value, 10) });
  };

  const handleNameUpdate = (value: string) => {
    setTokenName(value);
    handleBlueprintUpdate({ name: value });
  };

  const handleDescriptionUpdate = (value: string) => {
    setTokenDescription(value);
    handleBlueprintUpdate({ description: value });
  };

  const handleTokenGroupUpdate = (value: any) => {
    dispatch(
      updateTokenGroup({
        tokenGroup: { ...tokenGroup, ...value },
        tokenBlueprint: blueprint,
      }),
    );
  };

  const handleBlueprintUpdate = (value: any) => {
    dispatch(
      updateTokenGroup({
        tokenGroup: tokenGroup,
        tokenBlueprint: { ...blueprint, image: blueprint.image ?? '', ...value },
      }),
    );
  };

  const onTraitModalClose = () => {
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onEditTrait = (item: TraitType, idx: number) => {
    setEditTrait({ trait: item, index: idx });
    setIsTraitModal(true);
  };

  const onConfirmTraitEdit = (item: TraitType) => {
    if (editTrait && !Number.isNaN(editTrait?.index)) {
      onTraitEdited(item, editTrait.index);
    } else {
      onTraitAdded(item);
    }
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onAddTrait = () => {
    setIsTraitModal(true);
    setEditTrait(null);
  };

  const onTraitAdded = (item: TraitType) => {
    handleBlueprintUpdate({
      attributes: [...attributes, item],
    });
  };

  const onTraitEdited = (item: TraitType, idx: number) => {
    const newAttributes = [...attributes];
    newAttributes[idx] = item;
    handleBlueprintUpdate({
      attributes: [...newAttributes, item],
    });
  };

  const onDeleteTrait = (item: TraitType, idx: number) => {
    const newAttributes = [...attributes];
    newAttributes.splice(idx, 1);
    handleBlueprintUpdate({
      attributes: newAttributes,
    });
  };

  if (!blueprint && (blueprintStatus === 'loading' || blueprintStatus === undefined)) {
    return <ComponentLoader isOpen />;
  }

  // if (blueprintStatus !== undefined && blueprintStatus !== 'loading') {
  //   return <Typography>{'Error fetching data'}</Typography>;
  // }

  const { attributes, image } = blueprint;

  return (
    <Box className={'CAKE__collection-token-group-details'}>
      <Box className={'CAKE__collection-token-group-details__left-container'}>
        <V2TextInput
          label="Total tokens"
          type="number"
          value={tokenCount.toString()}
          size="small"
          onUpdate={(value: string) => handleCountUpdate(value)}
          disabled={deploymentStatus === ContractDeploymentStatus.LIVE_DEPLOYED ? true : false}
          validator={(value: string) => parseInt(value, 10) > 0}
        />
        <V2TextInput
          label="Token name"
          value={tokenName}
          size="small"
          disabled={deploymentStatus === ContractDeploymentStatus.LIVE_DEPLOYED ? true : false}
          onUpdate={(value: string) => handleNameUpdate(value)}
        />
        <V2TextInput
          className="CAKE__collection-item-content__description-input"
          label="Description"
          value={tokenDescription}
          multiline
          rows={3}
          inputProps={{ sx: { fontSize: 14 } }}
          disabled={deploymentStatus === ContractDeploymentStatus.LIVE_DEPLOYED ? true : false}
          onUpdate={(value: string) => handleDescriptionUpdate(value)}
        />
        <Box className={'CAKE__collection-token-group-details__traits'}>
          <TraitsTable
            traits={attributes}
            onAdd={onAddTrait}
            onEdit={onEditTrait}
            onDelete={onDeleteTrait}
          />
        </Box>
        <Box>
          {isTraitModal && (
            <TraitEditModal
              isOpen={isTraitModal}
              trait={editTrait?.trait}
              onConfirm={onConfirmTraitEdit}
              onCancel={onTraitModalClose}
            />
          )}
        </Box>
      </Box>
      <Box className={'CAKE__collection-token-group-details__right-container'}>
        <Box className={'CAKE__collection-token-group-details__right-container__top'}>
          {((process.env.REACT_APP_NODE_ENV === 'production' &&
            deploymentStatus === ContractDeploymentStatus.LIVE_DEPLOYED) ||
            ((process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'development') &&
              deploymentStatus === ContractDeploymentStatus.TEST_DEPLOYED)) && (
            <Box className={'CAKE__collection-token-group-details__buttons__end'}>
              <Tooltip
                title={
                  Object.keys(tokenGroupUpdates).length
                    ? 'There is already an update scheduled for this token.'
                    : 'Schedule an update for this token.'
                }
                placement="top"
              >
                <span>
                  <Button
                    size={'small'}
                    onClick={() => editTokenGroup(tokenGroup, TokenGroupAction.SCHEDULE_UPDATE)}
                    endIcon={<CalendarMonthOutlinedIcon />}
                    disabled={Object.keys(tokenGroupUpdates).length && true}
                  >
                    Schedule update
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
          {/* {(deploymentStatus === ContractDeploymentStatus.DRAFT ||
            deploymentStatus === ContractDeploymentStatus.TEST_DEPLOYED) && (
            <Box className={'CAKE__collection-token-group-details__buttons__end'}>
              <Button
                size={'small'}
                variant="text"
                endIcon={<DeleteOutlineIcon />}
                onClick={() => onRemoveTokenGroup(tokenGroup._id)}
              >
                Remove
              </Button>
            </Box>
          )} */}
        </Box>
        <div className={'CAKE__collection-token-group-details__image-container'}>
          <CollectionItemImage
            image={image || null}
            onDropImage={onUpdateImageFile}
            isDropzone={true}
            variant="card"
          />
        </div>
      </Box>
    </Box>
  );
};

export default CollectionTokenGroupDetails;
