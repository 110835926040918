/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CumulativeTransferData, TokenHolder, TransferDataType } from '~types/AnalyticsType';
import type { ChartResolution } from '~types/ChartResolution';

interface AnalyticsState {
  cumulativeTransferData: {
    data: CumulativeTransferData[];
    resolution: ChartResolution | null;
  };
  transferData: Array<TransferDataType>;
  walletHolders: {
    holders: Record<string, TokenHolder>;
    order: string[];
    trailingWalletCount: number;
  };
  totalSupply?: number;
  eligibleTokens?: number;
  events: {
    totalCount: number;
    lastMonthCount: number;
  };
}

const initialState: AnalyticsState = {
  cumulativeTransferData: {
    data: [],
    resolution: null,
  },
  transferData: [],
  walletHolders: {
    holders: {},
    order: [],
    trailingWalletCount: 0,
  },
  totalSupply: null,
  eligibleTokens: null,
  events: {
    totalCount: 0,
    lastMonthCount: 0,
  },
};

const analyticsState = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    processNewTransfer(state, action: PayloadAction<TransferDataType>) {},
    getTransferData(state, action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>) {},
    setTransferData(state, action: PayloadAction<TransferDataType[]>) {
      state.transferData = action.payload;
    },
    setCumulativeTransferData(
      state,
      action: PayloadAction<{ data: CumulativeTransferData[]; resolution: ChartResolution }>,
    ) {
      state.cumulativeTransferData = action.payload;
    },
    getWalletHolders(state, action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>) {},
    setWalletHolders(
      state,
      action: PayloadAction<{ holders: Record<string, TokenHolder>; order: string[]; trailingWalletCount: number }>,
    ) {
      state.walletHolders = action.payload;
    },
    resetWalletHolders(state) {
      state.walletHolders = {
        holders: {},
        order: [],
        trailingWalletCount: 0,
      };
    },
    getTotalSupply(state, action: PayloadAction<{ projectId: string; network: string }>) {},
    setTotalSupply(state, action: PayloadAction<number>) {
      state.totalSupply = action.payload;
    },
    increaseTotalSupply(state, action: Action) {
      if (state.totalSupply !== null) {
        state.totalSupply += 1;
      }
    },
    clearAnalyticsData(state) {
      state.cumulativeTransferData = initialState.cumulativeTransferData;
      state.transferData = initialState.transferData;
      state.walletHolders = initialState.walletHolders;
      state.totalSupply = initialState.totalSupply;
      state.eligibleTokens = initialState.eligibleTokens;
      state.events = initialState.events;
    },
    setTotalEventsCount(state, action: PayloadAction<number>) {
      state.events.totalCount = action.payload;
    },
    setTotalMonthlyEventsCount(state, action: PayloadAction<number>) {
      state.events.lastMonthCount = action.payload;
    },
    getEligibleTokens(state, action: PayloadAction<{ projectId: string }>) {},
    setEligibleTokens(state, action: PayloadAction<number>) {
      state.eligibleTokens = action.payload;
    },
  },
});

export const {
  clearAnalyticsData,
  getTotalSupply,
  getTransferData,
  getWalletHolders,
  increaseTotalSupply,
  processNewTransfer,
  resetWalletHolders,
  setCumulativeTransferData,
  setTotalSupply,
  setTransferData,
  setWalletHolders,
  setTotalEventsCount,
  setTotalMonthlyEventsCount,
  getEligibleTokens,
  setEligibleTokens,
} = analyticsState.actions;
export default analyticsState.reducer;
