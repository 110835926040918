import './ImageGenerationGrid.scss';

import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import ImageGenerationEmptyState from '~components/image-generation/ImageGenerationEmptyState';
import ImageGenerationModal from '~components/image-generation/ImageGenerationModal';
import {
  selectImageGenerationLoadingState,
  selectImageGenerations,
} from '~features/image-generations/image-generations.selectors';
import {
  getFavouriteImageGenerations,
  getImageGenerations,
  getNextImageGenerationsPage,
} from '~features/image-generations/image-generations.slice';
import { selectProjectId } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type ImageGeneration from '~types/ImageGeneration';

import ImageGenerationItem from './ImageGenerationItem';

interface ImageGenerationGridProps {
  isFavourite: boolean;
}

const ImageGenerationGrid = ({ isFavourite }: ImageGenerationGridProps) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectProjectId);
  const isLoading = useAppSelector(selectImageGenerationLoadingState);

  const [isLoadRequested, setIsLoadRequested] = useState<boolean>(false);
  const [item, setItem] = useState<ImageGeneration | null>(null);

  const images = useAppSelector(selectImageGenerations);

  useEffect(() => {
    if (projectId) {
      if (isFavourite) {
        dispatch(getFavouriteImageGenerations());
      } else {
        dispatch(getImageGenerations());
      }

      setIsLoadRequested(true);
    }
  }, [projectId]);

  const onScrollHandler = (values: any) => {
    if (isLoading) {
      return;
    }
    const shouldGetPage = values.scrollHeight * values.top > values.scrollHeight - 300;
    if (!shouldGetPage) {
      return;
    }

    dispatch(getNextImageGenerationsPage());
  };
  return (
    <Box className={'CAKE__image-generations-grid'}>
      {images.length === 0 && !isLoading && isLoadRequested && <ImageGenerationEmptyState />}
      {images.length > 0 && (
        <Scrollbars
          autoHide
          onScrollFrame={onScrollHandler}
        >
          <Grid
            container
            spacing={2}
            paddingY={1}
            columns={{ xs: 2, sm: 8, md: 8, lg: 12, xl: 12 }}
            width={'100%'}
          >
            {images
              .filter((item) => Boolean(item.storedImages?.[0]))
              .map((item: ImageGeneration) => (
                <ImageGenerationItem
                  key={`collection-item-${item._id}`}
                  item={item}
                  onClick={(item) => setItem(item)}
                />
              ))}
          </Grid>
        </Scrollbars>
      )}
      <ImageGenerationModal
        imageGeneration={item}
        isOpen={Boolean(item)}
        onClose={() => setItem(null)}
      />
    </Box>
  );
};

export default ImageGenerationGrid;
