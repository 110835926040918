import { setUpgradeIsProcessing } from '~features/contract-config/contract-config.slice';
import { addNotification } from '~features/notifications/notifications.slice';

const contractUpgradeFailedHandler = (emitter, data) => {
  emitter(setUpgradeIsProcessing(false));
  emitter(
    addNotification({
      message: 'Contract failed to upgrade',
      severity: 'error',
      duration: 5000,
    }),
  );
};

export default contractUpgradeFailedHandler;
