import TableContainer from '~components/miscs/TableContainer/TableContainer';
import type { TransferDataType } from '~types/AnalyticsType';

import ContractTransactionsItem from './components/ContractTransactionsItem';

type ContractTransactionsTableProps = {
  chainId?: number;
  transactions: TransferDataType[];
};

const tableHeaders = [
  { text: 'Transaction', value: 'transaction.hash' },
  { text: 'From', value: 'from' },
  { text: 'To', value: 'to' },
  { text: 'Token', value: 'tokenId' },
  { text: 'Type', value: 'transfer.type' },
  { text: 'Value', value: 'value' },
  { text: 'Date', value: 'date' },
];

const ContractTransactionsTable = ({ chainId, transactions }: ContractTransactionsTableProps) => {
  const uniqueTransactions = Object.values(
    transactions.reduce((acc: { [key: string]: TransferDataType }, curr: TransferDataType) => {
      return { ...acc, ...{ [curr.transactionHash]: curr } };
    }, {}),
  );

  return (
    <TableContainer
      data={uniqueTransactions}
      headers={tableHeaders}
      itemRenderer={(item: TransferDataType, key: string) => (
        <ContractTransactionsItem
          key={key}
          transaction={item}
          chainId={chainId}
        />
      )}
      keyExtractor={(item: TransferDataType) => `transfer-${item.transactionHash}-${item.tokenId}-${item._id}`}
      noDataText="There are no transactions for this contract"
      pagination
    />
  );
};

export default ContractTransactionsTable;
