import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';

import type { ContractStateType } from './contract-config.slice';

const selectDeployedContractState = (state: RootState): Array<ContractStateType> => state.contractConfig.data;

const selectDeployedContractStateMatch = (state: RootState): boolean => state.contractConfig.isMatch;

const selectIsContractUpgrade = (state: RootState): boolean => state.contractConfig.isUpgrade;

const selectUpgradeCheckIsProcessing = (state: RootState): boolean => state.contractConfig.upgradeCheckInProcess;

const selectUpgradeIsProcessing = (state: RootState): boolean => state.contractConfig.isUpgradeInProcess;

const selectUpgradeData = (state: RootState): any => state.contractConfig.upgradeData;

const selectContractUpdateParamIndex = (state: RootState): Array<number> => state.contractConfig.updateParamIndexes;

const selectContractUpdateIsProcessing = (state: RootState): boolean => state.contractConfig.isTxnProcessing;

const selectContractUpdateItems = createDraftSafeSelector(
  selectDeployedContractState,
  selectContractUpdateParamIndex,
  (contract: Array<ContractStateType>, updateParamIndex: Array<number>) =>
    updateParamIndex.map((idx: number) => contract[idx]),
);

export {
  selectContractUpdateIsProcessing,
  selectContractUpdateItems,
  selectContractUpdateParamIndex,
  selectDeployedContractState,
  selectDeployedContractStateMatch,
  selectIsContractUpgrade,
  selectUpgradeCheckIsProcessing,
  selectUpgradeData,
  selectUpgradeIsProcessing,
};
