import './ViewTypeSwitch.scss';

import { Box, IconButton } from '@mui/material';
import classNames from 'classnames';

import ViewType from '~constants/miscs/ViewType';
import { GroupViewIcon, TokensViewIcon } from '~icons/collection';

type ViewTypeSwitchProps = {
  onChange: (value: string) => void;
  viewType: string;
};
const ViewTypeSwitch = ({ onChange, viewType }: ViewTypeSwitchProps) => {
  return (
    <Box className={'CAKE__view-type-switch'}>
      <IconButton
        className={classNames({
          'CAKE__view-type-switch__button--active': viewType === ViewType.LIST,
        })}
        disabled={viewType === ViewType.GRID}
        onClick={() => onChange(ViewType.GRID)}
      >
        <TokensViewIcon />
      </IconButton>
      <IconButton
        className={classNames({
          'CAKE__view-type-switch__button--active': viewType === ViewType.GRID,
        })}
        disabled={viewType === ViewType.LIST}
        onClick={() => onChange(ViewType.LIST)}
      >
        <GroupViewIcon />
      </IconButton>
    </Box>
  );
};

export default ViewTypeSwitch;
