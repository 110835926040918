import { Stack } from '@mui/material';

import DashboardCard from '~components/dashboard/DashboardCard/DashboardCard';
import ProjectHeader from '~components/projects/ProjectHeader/ProjectHeader';
import PluginSwitch from '~components/utilities/PluginSwitch/PluginSwitch';
import { selectPartnerEnabledlugins } from '~features/partner-plugins/partner-plugins.selectors';
import { selectProjectActivePlugins, selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updatePaperConfig, updateProjectActivePlugins } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type Plugin from '~types/Plugin';

const PluginsCard = () => {
  const plugins = useAppSelector(selectPartnerEnabledlugins);
  const activePlugins = useAppSelector(selectProjectActivePlugins);
  const projectConfig = useAppSelector(selectProjectConfig);

  const dispatch = useAppDispatch();

  const { paper } = projectConfig || {};

  const onPluginToggle = (plugin: Plugin, value: boolean) => {
    if (value) {
      dispatch(updateProjectActivePlugins([...activePlugins, plugin._id]));
    } else {
      dispatch(updateProjectActivePlugins(activePlugins.filter((activePlugin) => activePlugin !== plugin._id)));
    }
  };

  const onPaperPluginToggle = (value: boolean) => {
    dispatch(
      updatePaperConfig({
        isActivated: value,
        event: {
          content: {
            event: 'dApp Settings Update',
            description: `Credit Card Payments have been ${!paper.isActivated ? 'enabled' : 'disabled'}`,
          },
        },
      }),
    );
  };

  return (
    <DashboardCard>
      <ProjectHeader subtitle="Plugins" />
      <Stack
        display={'grid'}
        gridTemplateColumns={'auto auto'}
        gap={'32px'}
        pt={'80px'}
      >
        {paper && (
          <PluginSwitch
            plugin={{
              _id: 'paper',
              name: 'Paper',
              description: 'Enable Secure Credit Card Payments Courtesy of Paper',
              logo: 'https://withpaper.com/icons/paper-logo-icon.svg',
              isEnabled: true,
              plan: 'Free plan',
              category: 'Checkout',
            }}
            isActive={paper.isActivated}
            onChange={onPaperPluginToggle}
          />
        )}
        {plugins.map((plugin) => (
          <PluginSwitch
            key={plugin._id}
            plugin={plugin}
            isActive={activePlugins.includes(plugin._id)}
            onChange={(newValue) => onPluginToggle(plugin, newValue)}
          />
        ))}
      </Stack>
    </DashboardCard>
  );
};

export default PluginsCard;
