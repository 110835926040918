/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AgreementType } from '~types/AgreementTypes';
import type { CakeActivityType } from '~types/Streams';

interface AgreementsConfigState {
  agreement: AgreementType;
  isLoading: boolean;
}

const initialState: AgreementsConfigState = {
  agreement: {
    _id: '',
    type: '',
    isActivated: false,
    url: '',
    data: [],
  },
  isLoading: false,
};

const agreementsConfigSlice = createSlice({
  name: 'agreements-config',
  initialState,
  reducers: {
    createAgreement(state) {
      state.isLoading = true;
    },
    createAgreementSuccess(state, action: PayloadAction<{ _id: string }>) {
      state.agreement = { ...state.agreement, isActivated: true, ...action.payload };
      state.isLoading = false;
    },
    createAgreementError(state) {
      state.isLoading = false;
    },
    getAgreementConfig(state, action: PayloadAction<string>) {},
    updateAgreementConfig(
      state,
      action: PayloadAction<{ updatedAgreementData: AgreementType; event?: CakeActivityType }>,
    ) {},
    setAgreementConfig(state, action: PayloadAction<AgreementType>) {
      state.agreement = action.payload;
    },
    uploadAgreementFile(state, action: PayloadAction<{ file: File }>) {},
    resetAgreementConfig(state) {
      state.agreement = initialState.agreement;
      state.isLoading = initialState.isLoading;
    },
  },
});

export const {
  createAgreement,
  createAgreementSuccess,
  createAgreementError,
  getAgreementConfig,
  updateAgreementConfig,
  setAgreementConfig,
  uploadAgreementFile,
  resetAgreementConfig,
} = agreementsConfigSlice.actions;
export default agreementsConfigSlice.reducer;
