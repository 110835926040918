import './CollectionItemFooter.scss';

// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Box, Button } from '@mui/material';

type CollectionItemFooterProps = {
  isChanged: boolean;
  onAccept: () => void;
  onDelete: () => void;
};

const CollectionItemFooter = ({ isChanged, onAccept, onDelete }: CollectionItemFooterProps) => {
  return (
    <Box className={'CAKE__collection-item-footer'}>
      <Box>
        {/* <Button
          size={'medium'}
          onClick={() => console.log('schedule update')}
          endIcon={<CalendarMonthOutlinedIcon />}
        >
          Schedule updates
        </Button> */}
      </Box>
      <Box>
        <Button
          color={'error'}
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          onClick={onAccept}
          disabled={!isChanged}
        >
          Accept
        </Button>
      </Box>
    </Box>
  );
};

export default CollectionItemFooter;
