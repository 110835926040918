import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { fetchProtectedAPI, postProtectedAPI } from '~features/utils/api/api.sagas';
import { uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type { S3FileUploadResponse } from '~types/S3';
import type { TokenGroupUpdateType } from '~types/token/TokenUpdateType';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

import {
  createTokenUpdate,
  getTokenUpdates,
  getTokenUpdatesFailure,
  getTokenUpdatesSuccess,
  hideTokenGroupEditView,
  upsertTokenUpdates,
} from './token-group-updates.slice';

function* getTokenUpdatesSaga(action: PayloadAction<{ tokenGroupId: string }>) {
  const tokenGroupId = action.payload;

  const path = `token-updates/${tokenGroupId}`;
  try {
    const res: Response = yield call(fetchProtectedAPI, path);
    const tokenGroupUpdates: TokenGroupUpdateType & { updateId: string }[] = yield res.json();

    if (res.status === 200) {
      yield put(upsertTokenUpdates(tokenGroupUpdates));
    }
  } catch (e) {
    console.log(e);
  }
}

function* createTokenUpdateSaga(action: PayloadAction<Partial<TokenGroupUpdateType>>) {
  const projectConfig = yield select(selectProjectConfig);
  const tokenUpdate: Partial<TokenGroupUpdateType> = action.payload;
  if (tokenUpdate.imageFile) {
    const { dir } = getS3RootProjectPath(projectConfig.slug);
    const imageData: S3FileUploadResponse = yield uploadFileToS3(tokenUpdate.imageFile, dir);
    tokenUpdate.blueprint.image = imageData.location;
  }
  delete tokenUpdate.imageFile;

  if (!tokenUpdate.blueprint.image) {
    delete tokenUpdate.blueprint.image;
  }

  const path = 'token-updates/';
  try {
    const res: Response = yield call(postProtectedAPI, path, tokenUpdate);
    const insertedTokenUpdate: TokenGroupUpdateType = yield res.json();

    if (res.status === 200) {
      yield put(getTokenUpdatesSuccess(insertedTokenUpdate));
      // update tokens with update status
      yield put(hideTokenGroupEditView());
    } else {
      throw new Error('Failed to create token update');
    }
  } catch (e) {
    console.log(e);
    yield put(getTokenUpdatesFailure('token update creation failed'));
  }
}

export default function* tokenGroupUpdatesSaga() {
  yield all([
    yield takeLatest(getTokenUpdates.type, getTokenUpdatesSaga),
    yield takeLatest(createTokenUpdate.type, createTokenUpdateSaga),
  ]);
}
