import type { CustomLayerProps } from '@nivo/line';
import { range } from 'd3';
import { scaleBand, scaleLog } from 'd3-scale';

import { volumeGradient } from './chart-gradients';

const VolumeLayer = (data) => {
  const VolumeLayerComponent = ({ innerWidth, innerHeight }: CustomLayerProps) => {
    const xScale = scaleBand<number>()
      .domain(range(0, data.length))
      .range([0, innerWidth + innerWidth / (data.length - 1)])
      .padding(0);

    const yMax = Math.max(...data.map((item) => Number(item.volume)));
    const yScale = scaleLog<number>().domain([1, yMax]).range([0, 40]);

    const barWidth = innerWidth / data.length - 1;

    return (
      <g className="volume-layer">
        <defs>{volumeGradient}</defs>
        {data.map((item, index) => {
          const value = Number(item.volume);
          if (value === 0) {
            return;
          }
          const isHalfBar = index === 0 || index === data.length - 1;
          const isFirst = index === 0;
          const posX = isFirst ? xScale(index) : xScale(index) - barWidth / 2;
          const computedHeight = yScale(value + 1);

          if (computedHeight === 0 || isNaN(computedHeight)) {
            return;
          }
          return (
            <rect
              key={index}
              className="bar"
              x={posX}
              y={innerHeight - computedHeight}
              width={isHalfBar ? barWidth / 2 : barWidth}
              height={computedHeight}
              fill="url(#volumeGradient)"
            />
          );
        })}
      </g>
    );
  };
  VolumeLayerComponent.displayName = 'VolumeLayer';
  return VolumeLayerComponent;
};

export default VolumeLayer;
