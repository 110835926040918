/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type DeployerState = {
  network: string;
  isDeploying: boolean;
  isVerified: {
    testnet?: boolean;
    livenet?: boolean;
  };
  isVerifiying?: boolean;
  verificationStatusId: string;
  verificationStatus: string;
  verificationError: string;
  error?: string;
};

const initialState: DeployerState = {
  network: '',
  isDeploying: false,
  isVerified: {
    testnet: false,
    livenet: false,
  },
  isVerifiying: null,
  verificationStatusId: '',
  verificationStatus: '',
  verificationError: '',
  error: null,
};

const contractDeployerState = createSlice({
  name: 'contract-deployer',
  initialState,
  reducers: {
    setContractVerificationState(state, action: PayloadAction<{ testnet: boolean | null; livenet: boolean }>) {
      state.isVerified.testnet = action.payload.testnet;
      state.isVerified.livenet = action.payload.livenet;
    },
    callDeployContract(state, action: PayloadAction<{ projectId: string; network: string }>) {
      if (action.payload.network === 'mainnet') {
        state.isVerified.livenet = false;
      } else if (action.payload.network === 'goerli') {
        state.isVerified.testnet = false;
      }
      state.network = action.payload.network;
      state.isDeploying = true;
    },
    callDeployContractSuccess(
      state,
      action: PayloadAction<{ network: string; contractAddress: string; projectId: string }>,
    ) {
      state.isVerifiying = true;
      state.isDeploying = false;
    },
    callDeployContractError(state, action: PayloadAction<string>) {
      state.isDeploying = false;
      state.error = action.payload;
    },
    callVerifyContract(
      state,
      action: PayloadAction<{ network: string; contractAddress: string; projectId: string }>,
    ) {},
    callVerifyContractSuccess(state, action: PayloadAction<{ network: string }>) {
      state.network = '';
      state.isVerifiying = false;
      state.verificationStatusId = '';
      state.verificationStatus = '';

      if (action.payload.network === 'mainnet') {
        state.isVerified.livenet = true;
      } else if (action.payload.network === 'goerli') {
        state.isVerified.testnet = true;
      }
    },
    callVerifyContractError(state, action: PayloadAction<{ network: string; error: string }>) {
      state.network = '';
      state.isVerifiying = false;
      state.verificationStatusId = '';
      state.verificationStatus = '';

      if (action.payload.network === 'mainnet') {
        state.isVerified.livenet = false;
      } else if (action.payload.network === 'goerli') {
        state.isVerified.testnet = false;
      }
      state.verificationError = action.payload.error;
    },
  },
});

export const {
  setContractVerificationState,
  callDeployContract,
  callDeployContractSuccess,
  callDeployContractError,
  callVerifyContract,
  callVerifyContractSuccess,
  callVerifyContractError,
} = contractDeployerState.actions;

export default contractDeployerState.reducer;
