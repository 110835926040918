import { ContractNetwork } from '~constants/networks/networks';
import { ProjectCollectionType } from '~constants/project/project-constants';
import type { ContractStateArrayType } from '~types/ContractConfigType';
import type { ProjectType } from '~types/ProjectType';
import type { TransactionConfirmation } from '~types/Web3Type';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';
import { getWeb3Provider } from '~utils/web3/web3';

import { BasicInfoStateVariablesToIgnore } from './contract-config.constants';
import type { ContractStateType } from './contract-config.slice';

const checkContractMatch = (project: ProjectType, contractConfig: Array<ContractStateType>) => {
  const projectContract = getProjectContractData(project);
  const updateItems = contractConfig.map((item: ContractStateType, index: number) => {
    // ignore specific LibDiamond state variable comparisons
    if (BasicInfoStateVariablesToIgnore.includes(item.name)) {
      return;
    } else if (Array.isArray(item.value)) {
      if (JSON.stringify(item.value) !== JSON.stringify(projectContract[index])) {
        return index;
      }
    }
    // avoid comparing changes to provenanceHash if custom collection and contract is deployed to goerli and already revealed
    // this is to allow updates to the collection pre-production launch without confusion
    else if (
      item.name === 'provenanceHash' &&
      project.collectionType === ProjectCollectionType.CUSTOM &&
      [ContractNetwork.GOERLI, ContractNetwork.SEPOLIA].includes(getCurrentContractNetwork(project.contract)) &&
      contractConfig.find((item: any) => item.name === 'startingIndex').value !== 0
    ) {
      return;
    } else if (Array.isArray(item)) {
      if (JSON.stringify(item) != JSON.stringify(projectContract[index])) {
        return index;
      }
    } else if (item.value != projectContract[index]) {
      return index;
    }
    return;
  });

  return {
    isMatch: !updateItems.filter((val) => val === 0 || Boolean(val)).length,
    updates: updateItems.filter((val) => val === 0 || Boolean(val)),
  };
};

const getProjectContractData = (project: ProjectType): ContractStateArrayType => {
  const web3 = getWeb3Provider();
  return [
    project.name,
    project.contract.symbol,
    project.contract.totalSupply,
    web3.utils.toWei(`${project.contract.price}`, 'ether'),
    project.contract.maxMintTotal ? project.contract.maxMintTotal : 0,
    project.contract.maxMintTxn ? project.contract.maxMintTxn : 0,
    Math.ceil(project.contract.presale / 1000),
    Math.ceil(project.contract.publicSale / 1000),
    project.contract.superAdmin,
    project.contract.paymentSplit.map((item) => item.address),
    project.contract.paymentSplit.map((item) => item.amount),
    project.contract.royalty.address,
    project.contract.royalty.amount,
    '0x0000000000000000000000000000000000000000000000000000000000000000', // not used in Contract Config comparison
    `${process.env.REACT_APP_BATTER_URL}/v1/collection/${project.collectionId}/tokens/`,
    Math.ceil(project.contract.closeDate / 1000),
    project.contract.minMint,
    null,
    null,
    null,
    null,
    null,
    null,
    project.contract.provenanceHash,
    null,
  ];
};

const getUpdatedItemArgs = (
  contractData: ContractStateArrayType,
  contractConfig: Array<ContractStateType>,
  contactUpdateItem: ContractStateType,
) => {
  const updateItemIdx = contractConfig.findIndex(
    (contractStateItem: ContractStateType) => contractStateItem.updateFunction === contactUpdateItem.updateFunction,
  );

  return {
    functionName: contractConfig[updateItemIdx].updateFunction,
    args: contractConfig[updateItemIdx].paramIndexes.map((idx: number) => {
      return formatFunctionArgument(contractData, contractConfig, idx);
    }),
  };
};

const formatFunctionArgument = (
  contractData: ContractStateArrayType,
  contractConfig: Array<ContractStateType>,
  idx: number,
) => {
  if (Array.isArray(contractData[idx])) {
    if (contractConfig[idx].type === 'Array(integer)') {
      return Array(contractData[idx]).map((item: string) => String(item));
    } else {
      return Array(contractData[idx]).map((item: string) => item);
    }
  } else if (contractConfig[idx].type === 'integer') {
    return String(contractData[idx]);
  }
  return contractData[idx];
};

const getNotificationMessage = (
  txnHashArray: Array<TransactionConfirmation>,
  successfulTxns: Array<TransactionConfirmation>,
) => {
  return successfulTxns.length === txnHashArray.length
    ? `${txnHashArray.length > 1 ? txnHashArray.length + ' transactions' : 'Transaction'} succeeded`
    : `${txnHashArray.length - txnHashArray.length > 1 ? txnHashArray.length + ' transactions' : 'Transaction'} failed`;
};

export { checkContractMatch, getNotificationMessage, getProjectContractData, getUpdatedItemArgs };
