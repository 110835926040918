import type { TypographyWeight } from '~types/ProjectType';
import { TypographySources } from '~types/ProjectType';

export enum DefaultFonts {
  Display = 'Druk Wide',
  Body = 'IBM Plex Mono',
}

export const defaultFonts = {
  fontDisplay: {
    source: TypographySources.Default,
    url: '../fonts/Druk Wide Bold.woff2',
    name: 'Druk Wide',
    family: 'Druk Wide',
    weight: 900 as TypographyWeight,
  },
  fontBody: {
    source: TypographySources.Default,
    name: 'IBM Plex Mono',
    family: 'IBM Plex Mono',
    weight: 400 as TypographyWeight,
  },
};
