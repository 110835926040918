import { FileTypes } from '~types/DataRoomTypes';

const fileMap = {
  pdf: FileTypes.Pdf,
  xls: FileTypes.Spreadsheet,
  png: FileTypes.Image,
  jpg: FileTypes.Image,
  jpeg: FileTypes.Image,
  mp4: FileTypes.Video,
  mov: FileTypes.Video,
};

function getFileType(name: string): FileTypes | null {
  const fileType = name.slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1);

  return fileMap[fileType as keyof typeof fileMap] ?? null;
}

export { getFileType };
