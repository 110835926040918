enum ProjectMessageType {
  CONTRACT_VERIFICATION_FAILED = 'CONTRACT_VERIFICATION_FAILED',
  CONTRACT_VERIFICATION_SUCCESS = 'CONTRACT_VERIFICATION_SUCCESS',
  CONTRACT_DEPLOYMENT_FAILED = 'CONTRACT_DEPLOYMENT_FAILED',
  CONTRACT_DEPLOYMENT_SUCCESS = 'CONTRACT_DEPLOYMENT_SUCCESS',
  CONTRACT_UPGRADE_SUCCESS = 'CONTRACT_UPGRADE_SUCCESS',
  CONTRACT_UPGRADE_FAILED = 'CONTRACT_UPGRADE_FAILED',
  SINGLE_TOKEN_MINTED = 'SINGLE_TOKEN_MINTED',
  SINGLE_TOKEN_UPDATED = 'SINGLE_TOKEN_UPDATED',
  SINGLE_TOKEN_TRANSFERRED = 'SINGLE_TOKEN_TRANSFERRED',
  TOKEN_GROUP_STANDARD_UPDATE_PERFORMED = 'TOKEN_GROUP_STANDARD_UPDATE_PERFORMED',
  TOKEN_GROUP_LAYERED_UPDATE_CLOSED = 'TOKEN_GROUP_LAYERED_UPDATE_CLOSED',
  TOKEN_GROUP_LAYERED_UPDATE_OPENED = 'TOKEN_GROUP_LAYERED_UPDATE_OPENED',
  TOKEN_GROUP_LAYERED_UPDATE_SCHEDULED = 'TOKEN_GROUP_LAYERED_UPDATE_SCHEDULED',
  UNKOWN_TRANSACTION_ERROR = 'UNKOWN_TRANSACTION_ERROR',
  VRF_RANDOM_NUMBER_REQUESTED = 'VRF_RANDOM_NUMBER_REQUESTED',
  VRF_RANDOM_NUMBER_FULFILLED = 'VRF_RANDOM_NUMBER_FULFILLED',
}

export default ProjectMessageType;
