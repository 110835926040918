import './ValueBox.scss';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';

interface Props {
  label: string;
  sublabel?: string;
  value?: number | string;
  changeFactor?: number | string;
  type: string;
  tooltip?: string;
}

const ValueBox: React.FC<Props> = ({ label, sublabel, value, changeFactor, type, tooltip }) => {
  const isPositive = Number(changeFactor) > 0;
  const isNeutral = Number(changeFactor) === 0 || changeFactor === null;
  return (
    <Box className="CAKE__value-box">
      <Box className="CAKE__value-box__label-container">
        <Typography
          className="CAKE__value-box__label"
          variant="subtitle1"
          fontWeight="bold"
        >
          {label}
        </Typography>
        {sublabel && (
          <Typography
            className="CAKE__value-box__sublabel"
            variant="subtitle2"
          >
            {`- ${sublabel}`}
          </Typography>
        )}
        {tooltip && (
          <Tooltip
            className="CAKE__value-box__tooltip"
            title={tooltip}
            placement="top"
            arrow
          >
            <InfoOutlinedIcon
              className="CAKE__value-box__tooltip__icon"
              fontSize="small"
              color="secondary"
            />
          </Tooltip>
        )}
      </Box>
      <Box className="CAKE__value-box__value-container">
        <Typography
          className="CAKE__value-box__value-container__value"
          variant="h4"
          fontWeight="bold"
        >
          {value || '--'}
        </Typography>

        <Box className="CAKE__value-box__value-container__change-indicator-container">
          {type.toLowerCase().includes('change') && changeFactor && (
            <Box className="CAKE__value-box__value-container__change-indicator">
              {isPositive ? <NorthIcon fontSize="small" /> : isNeutral ? <></> : <SouthIcon fontSize="small" />}
            </Box>
          )}
          <span className="CAKE__value-box__value-container__change-factor">
            {`${changeFactor ? `${changeFactor}${type.toLowerCase().includes('percent') && '%'}` : '--'}`}
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default ValueBox;
