/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { InvitationErrors } from './user-invitation.types';

const initialState = {
  invitationId: '',
  isLoading: false,
  isValidated: false,
  error: null,
  isSignupInProgress: false,
  isSignupFinished: false,
  signupError: null,
};

const userInvitationState = createSlice({
  name: 'user-invitation',
  initialState,
  reducers: {
    validateInvitation(state, action: PayloadAction<string>) {
      state.isLoading = true;
      state.error = null;
      state.invitationId = action.payload;
    },
    validateInvitationSuccess(state) {
      state.isLoading = false;
      state.isValidated = true;
      state.error = null;
    },
    validateInvitationError(state, action: PayloadAction<InvitationErrors | undefined>) {
      state.error = action.payload ?? InvitationErrors.Validation;
      state.isValidated = true;
      state.isLoading = false;
    },
    invitationSignup(state, action: PayloadAction<{ user: string; password: string }>) {
      state.isSignupInProgress = true;
      state.isSignupFinished = false;
      state.signupError = null;
    },
    invitationSingupSuccess(state) {
      state.isSignupInProgress = false;
      state.isSignupFinished = true;
    },
    invitationSingupError(state, action: PayloadAction<string>) {
      state.isSignupInProgress = false;
      state.signupError = action.payload;
    },
  },
});

export const {
  validateInvitation,
  validateInvitationSuccess,
  validateInvitationError,
  invitationSignup,
  invitationSingupSuccess,
  invitationSingupError,
} = userInvitationState.actions;
export default userInvitationState.reducer;
