import { Box, Stack } from '@mui/material';

import DeployButton from '~components/dashboard/ContractDeploymentCard/components/DeployButton';
import MenuDrawer, { MenuDrawerVariant } from '~components/menus/MenuDrawer';
import AlertBanner from '~components/miscs/AlertBanner';
import { ContractNetwork } from '~constants/networks/networks';
import { selectContractDeployerIsLoading } from '~features/contract-deployer/contract-deployer.selectors';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

const ProjectPageLayout = ({ children }: React.PropsWithChildren<void>) => {
  const project = useAppSelector(selectProjectConfig);
  const network = getCurrentContractNetwork(project?.contract);
  const isDeploying = useAppSelector(selectContractDeployerIsLoading);

  return (
    <Stack height={'100%'}>
      <Box height={'100%'}>{children}</Box>
      <MenuDrawer variant={MenuDrawerVariant.PROJECT} />
      {network && network !== ContractNetwork.BASE_MAINNET && (
        <AlertBanner
          isLoading={isDeploying}
          title={'Your project is currently in test mode.'}
          loadingTitle={'Upgrading project to live mode. Please wait a moment...'}
          buttonTitle={'Go Live'}
          acceptButton={
            <DeployButton
              containterStyles={{ margin: 0 }}
              buttonStyles={{ backgroundColor: 'white', color: '#599CFF' }}
            />
          }
        />
      )}
    </Stack>
  );
};

export default ProjectPageLayout;
