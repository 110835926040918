import '~components/miscs/ItemLoader/ItemLoader.scss';

import { CircularProgress, Stack } from '@mui/material';
import { Box } from '@mui/system';

import logo from '~images/common/logo/logo.png';

const ItemLoader = () => (
  <Stack
    className={'CAKE__item-loader__container'}
    justifyContent="center"
    alignItems="center"
    gap={2}
  >
    <Box className={'CAKE__item-loader__image--container'}>
      <CircularProgress
        size={100}
        thickness={1}
        disableShrink
      />
      <Box className={'CAKE__item-loader__image--logo'}>
        <img src={logo} />
      </Box>
    </Box>
  </Stack>
);

export default ItemLoader;
