import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AgreementsSettingsCard from '~components/dashboard/AgreementsSettingsCard';
import AppearanceSettingsCard from '~components/dashboard/AppearanceSettingsCard';
import BrandingSettingsCard from '~components/dashboard/BrandingSettingsCard';
import FAQSettingsCard from '~components/dashboard/FAQSettingsCard';
import GallerySettingsCard from '~components/dashboard/GallerySettingsCard';
import ProjectNameCard from '~components/dashboard/ProjectNameCard';
import SlugSettingsCard from '~components/dashboard/SlugSettingsCard';
import SocialSettingsCard from '~components/dashboard/SocialSettingsCard';
import TemplateSettingsCard from '~components/dashboard/TemplateSettingsCard';
import TypographySettingsCard from '~components/dashboard/TypographySettingsCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectDAppView = () => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const error = useAppSelector(selectProjectConfigError);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error || projectConfig === null) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <ProjectNameCard />
      <SlugSettingsCard />
      {new Date(projectConfig.createdAt) < new Date('02-01-2024') && <TemplateSettingsCard />}
      {/* <EssentialSettingsCard /> */}
      <AppearanceSettingsCard />
      <TypographySettingsCard />
      <BrandingSettingsCard />
      <GallerySettingsCard />
      {/* <MintButttonSettingsCard /> */}
      <AgreementsSettingsCard />
      <SocialSettingsCard />
      <FAQSettingsCard />
    </PageLayout>
  );
};

export default ProjectDAppView;
