import AppsIcon from '@mui/icons-material/Apps';
// import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import PrivateRoutes from '~constants/routes/private-routes.consts';
import { ActivityIcon, AnalyticsIcon, ContractIcon, DappSettingsIcon } from '~icons/project/menu';

const projectMenuItems = [
  {
    title: 'Community',
    link: (projectId: string) => `/dashboard/projects/${projectId}/community`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_COMMUNITY,
    icon: <AutoModeIcon sx={{ color: '#000000' }} />,
  },
  {
    title: 'Site builder',
    link: (projectId: string) => `/dashboard/projects/${projectId}/dapp`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_DAPP,
    icon: <DappSettingsIcon />,
  },
  {
    title: 'Collection',
    link: (projectId: string) => `/dashboard/projects/${projectId}/collection`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_COLLECTION,
    icon: <AppsIcon sx={{ color: '#000000' }} />,
  },
  {
    title: 'Activity',
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_OVERVIEW,
    link: (projectId: string) => `/dashboard/projects/${projectId}/`,
    icon: <ActivityIcon />,
  },
  {
    title: 'Analytics',
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_ANALYTICS,
    link: (projectId: string) => `/dashboard/projects/${projectId}/analytics`,
    icon: <AnalyticsIcon />,
  },
  {
    title: 'Advanced Settings',
    link: (projectId: string) => `/dashboard/projects/${projectId}/contract`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_CONTRACT,
    icon: <ContractIcon />,
  },
  // {
  //   title: 'Utility',
  //   linkPattern: PrivateRoutes.DASHBOARD_PROJECT_UTILITY,
  //   link: (projectId: string) => `/dashboard/projects/${projectId}/utility`,
  //   icon: <UtilityIcon />,
  // },
];

export { projectMenuItems };
