import type { PayloadAction } from '@reduxjs/toolkit';
import type { Task } from 'redux-saga';
import { all, call, join, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import { v1 as uuidv1 } from 'uuid';

import { ContractNetwork } from '~constants/networks/networks';
import { ProjectCollectionType } from '~constants/project/project-constants';
import {
  deleteAllCollectionItemsSaga,
  updateProjectProvenanceHashSaga,
} from '~features/collection-items/collection-items.sagas';
import { clearCollection, getCollection } from '~features/collection-items/collection-items.slice';
import { removeTokenGroupSaga } from '~features/collection-token-groups/collection-token-groups.sagas';
import { selectCollectionTokenGroups } from '~features/collection-token-groups/collection-token-groups.selectors';
import { removeTokenGroup } from '~features/collection-token-groups/collection-token-groups.slice';
import { readJSONFile, validateTokenMetadata } from '~features/collection-upload/collection-upload.helpers';
import { selectUploadQueue } from '~features/collection-upload/collection-upload.selectors';
import {
  addItemsToUploadQueue,
  addValidationErrors,
  removeItemFromUploadQueue,
  setTokenPlaceholderImageError,
  setTokenPlaceholderImageLoading,
  setTokenPlaceholderImageSuccess,
  updateItemImage,
  updateTokenPlaceholderImage,
  uploadItemAlreadyExists,
  uploadItemFinished,
  uploadItems,
  uploadItemsFinished,
} from '~features/collection-upload/collection-upload.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { updateProjectSaga } from '~features/project-config/project-config.sagas';
import {
  selectProjectConfig,
  selectProjectId,
  selectProjectSlug,
} from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import { postProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { S3_STORAGE_TOKENS_ARTWORK_DIR } from '~features/utils/s3storage/s3.storage.consts';
import { getImageHash } from '~features/utils/s3storage/s3.storage.helpers';
import { doesFileExistsOnS3, uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import type CollectionItemType from '~types/CollectionItemType';
import type { ProjectType } from '~types/ProjectType';
import type UploadTokenValidationType from '~types/token/UploadTokenValidationType';
import getS3TokenFilePath from '~utils/api/getS3TokenFilePath';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

let uploadWorkers: Array<Task> = [];
let uploadWorkersCount = 0;
const MAX_UPLOAD_WORKERS_AMOUNT = 2;

function* getItem(): Iterator<any, UploadTokenValidationType> {
  const tokens: Array<UploadTokenValidationType> = yield select(selectUploadQueue);
  yield put(removeItemFromUploadQueue());
  if (tokens.length === 0) {
    return null;
  }
  return tokens[0];
}

function* checkErrors(token: UploadTokenValidationType): Iterator<any> {
  const upladErrors: Array<string> = [];
  if (!token.isMetadataValid) {
    upladErrors.push(`Metadata data not correct for token: ${token.name}`);
  } else if (Object.keys(token.metadata).length === 0) {
    upladErrors.push(`Metadata file is missing for token: ${token.name}`);
  }

  if (!token.artwork) {
    upladErrors.push(`Artwork is missing for token: ${token.name}`);
  }

  yield put(addValidationErrors(upladErrors));
}

function* uploadArtwork(
  token: UploadTokenValidationType,
  fileLocation: { path: string; dir: string },
  uuid: string,
): Iterator<any> {
  const isFile: boolean = yield doesFileExistsOnS3(uuid, fileLocation.path);
  if (!isFile) {
    yield console.log(`upload ${uuid}`);
    yield call(uploadFileToS3, token.artwork, fileLocation.dir, uuid);
    const hash = yield call(getImageHash, token.artwork);
    return hash;
  } else {
    yield put(addValidationErrors([`Artwork for token ${token.name} already exists`]));
  }
}

function* uploadMetadata(
  token: UploadTokenValidationType,
  hash: string,
  fileLocation: { path: string; dir: string },
  uuid: string,
): Iterator<any, any> {
  const projectId: string = yield select(selectProjectId);
  const imageUrl = token.artwork ? `${fileLocation.path}/${uuid}` : token.metadata.image;
  const metadata = {
    ...token.metadata,
    hash,
    image: imageUrl,
    isMetadataValid: token.isMetadataValid,
    originTokenId: token.originTokenId,
  };

  const res: Response = yield call(postProtectedAPI, `project/${projectId}/tokens`, metadata);
  const data = yield res.json();

  return data;
}

function* spawnUploadWorkerSaga(token: UploadTokenValidationType) {
  yield uploadWorkersCount++;

  let hash = '';
  const projectSlug: string = yield select(selectProjectSlug);
  const fileLocation: {
    dir: string;
    path: string;
  } = getS3TokenFilePath(projectSlug, S3_STORAGE_TOKENS_ARTWORK_DIR);

  yield call(checkErrors, token);
  const uuid = uuidv1();

  if (token.artwork) {
    hash = yield call(uploadArtwork, token, fileLocation, uuid);
  }
  const createdMetadata = yield call(uploadMetadata, token, hash, fileLocation, uuid);

  yield uploadWorkersCount--;
  yield put(
    uploadItemFinished({
      ...token,
      metadata: createdMetadata,
    }),
  );
}

function* processFilesToUpload(files: Array<File>): Iterator<any, Array<UploadTokenValidationType>> {
  const tokens = yield files.reduce(async (acc, curr) => {
    const currAcc = await acc;
    const fileName: string = curr.name.split('.')[0];
    const isFileMetadata: boolean = curr.type.includes('json');
    const fileValue = isFileMetadata ? await readJSONFile(curr) : curr;
    const isMetadataValid: boolean = isFileMetadata
      ? await validateTokenMetadata(fileValue)
      : currAcc[fileName]?.isMetadataValid || false;
    return {
      ...currAcc,
      [fileName]: {
        ...currAcc[fileName],
        metadata: currAcc[fileName]?.metadata || {},
        name: fileName,
        originTokenId: Number(fileName),
        [isFileMetadata ? 'metadata' : 'artwork']: isFileMetadata && !isMetadataValid ? {} : fileValue,
        isMetadataValid,
      },
    };
  }, {});

  const sortedTokens: Array<UploadTokenValidationType> = Object.values<UploadTokenValidationType>(tokens).sort(
    (a: UploadTokenValidationType, b: UploadTokenValidationType) => a.name.localeCompare(b.name),
  );
  return sortedTokens;
}

function* uploadItemsSaga(action: PayloadAction<Array<File>>) {
  const projectConfig = yield select(selectProjectConfig);

  if (projectConfig.collectionType === ProjectCollectionType.GROUP) {
    yield call(deleteAllCollectionItemsSaga);
  }

  const validatedAndSortedTokens: Array<UploadTokenValidationType> = yield call(
    processFilesToUpload,
    action.payload || [],
  );
  yield put(addItemsToUploadQueue(validatedAndSortedTokens));
  const tokenGroups: Array<CollectionTokenGroupType> = yield select(selectCollectionTokenGroups);

  let isNextToken = validatedAndSortedTokens.length > 0;
  if (!isNextToken) {
    return;
  }
  while (isNextToken) {
    const item = yield call(getItem);
    if (item) {
      if (uploadWorkersCount >= MAX_UPLOAD_WORKERS_AMOUNT) {
        yield take([uploadItemFinished.type, uploadItemAlreadyExists.type]);
      }
      const task = yield spawn(spawnUploadWorkerSaga, item);
      uploadWorkers = [...uploadWorkers.filter((item: Task) => item.isRunning()), task];
    } else {
      isNextToken = false;
    }
  }

  yield join(uploadWorkers);
  yield put(uploadItemsFinished());
  yield call(
    updateProjectSaga,
    updateProjectConfig({ ...projectConfig, collectionType: ProjectCollectionType.CUSTOM }),
  );
  yield call(updateProjectProvenanceHashSaga);

  // delete token groups
  if (tokenGroups.length) {
    for (let i = 0; i < tokenGroups.length; i++) {
      yield call(removeTokenGroupSaga, removeTokenGroup(tokenGroups[i]._id));
    }
  }

  yield put(clearCollection());
  yield put(getCollection());
}

function* updateItemImageSaga(action: PayloadAction<{ file: File; token: CollectionItemType }>) {
  try {
    const { file, token } = action.payload;
    const projectSlug: string = yield select(selectProjectSlug);
    const projectId: string = yield select(selectProjectId);
    const projectConfig: ProjectType = yield select(selectProjectConfig);
    const fileLocation: {
      dir: string;
      path: string;
    } = getS3TokenFilePath(projectSlug, S3_STORAGE_TOKENS_ARTWORK_DIR);

    const [, extension] = file.name.split('.');
    const uuid = uuidv1();
    const updatedFile = new File([file], `${uuid}`, {
      type: file.type,
    });
    yield call(uploadFileToS3, updatedFile, fileLocation.dir, uuid);
    const newImageUrl = `${fileLocation.path}/${uuid}`;
    const res: Response = yield call(putProtectedAPI, `project/${projectId}/tokens/${token.tokenId}`, {
      ...token,
      tokenId: Number(token.tokenId),
      image: newImageUrl,
    });
    const newToken: CollectionItemType = yield res.json();

    // update provenanceHash for custom collections if not deployed to mainnet
    if (
      projectConfig.collectionType === ProjectCollectionType.CUSTOM &&
      getCurrentContractNetwork(projectConfig.contract) !== ContractNetwork.MAINNET
    ) {
      yield call(updateProjectProvenanceHashSaga);
    }

    yield put(clearCollection());
    yield put(getCollection());
    yield put(
      addNotification({
        message: 'Token image updated',
        severity: 'success',
        duration: 5000,
      }),
    );
  } catch (e) {
    console.log(e);
    yield addNotification({
      message: 'Failed to update image',
      severity: 'error',
      duration: 5000,
    });
  }
}

function* updateTokenPlaceholderImageSaga(action: PayloadAction<{ file: File }>) {
  const { file } = action.payload;
  const projectConfig: ProjectType = yield select(selectProjectConfig);
  const projectSlug: string = yield select(selectProjectSlug);

  yield put(setTokenPlaceholderImageLoading());

  const uuid = uuidv1();

  try {
    const fileLocation: {
      dir: string;
      path: string;
    } = getS3TokenFilePath(projectSlug, S3_STORAGE_TOKENS_ARTWORK_DIR);

    const [, extension] = file.name.split('.');

    const updatedFile = new File([file], `${uuid}`, {
      type: file.type,
    });
    yield call(uploadFileToS3, updatedFile, fileLocation.dir, uuid);
    const newImageUrl = `${fileLocation.path}/${uuid}`;
    yield call(putProtectedAPI, `collection/${projectConfig.collectionId}/placeholder-image`, {
      placeholderImage: newImageUrl,
    });
    yield put(
      addNotification({
        message: 'Your token placeholder image has been updated.',
        severity: 'success',
        duration: 5000,
      }),
    );

    yield put(setTokenPlaceholderImageSuccess());
  } catch (e) {
    yield put(setTokenPlaceholderImageError(e));
    yield put(
      addNotification({
        message: 'An error occured updating your token placeholder image',
        severity: 'error',
        duration: 5000,
      }),
    );
  }
}

export default function* collectionUploadSaga(): Iterator<any> {
  yield all([
    takeLatest(uploadItems.type, uploadItemsSaga),
    takeLatest(updateItemImage.type, updateItemImageSaga),
    takeLatest(updateTokenPlaceholderImage.type, updateTokenPlaceholderImageSaga),
  ]);
}
