import './AlertBanner.scss';

import { Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import classNames from 'classnames';

type AlertBannerProps = {
  isLoading: boolean;
  title: string;
  loadingTitle: string;
  tooltipTitle?: string;
  onAccept?: () => void;
  buttonTitle?: string;
  icon?: JSX.Element;
  severity?: string;
  acceptButton?: JSX.Element;
};

const AlertBanner = ({
  isLoading,
  title,
  loadingTitle,
  tooltipTitle,
  onAccept,
  buttonTitle,
  icon,
  severity,
  acceptButton,
}: AlertBannerProps) => {
  if (isLoading) {
    return (
      <Stack
        className={classNames({
          'CAKE__alert-banner__update-header__container': true,
          'CAKE__alert-banner__update-header__container--error': severity === 'high',
        })}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={1}
      >
        <CircularProgress
          size={15}
          sx={{ color: 'white' }}
        />
        <Typography className="CAKE__alert-banner__update-header__text">{loadingTitle}</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      className={classNames({
        'CAKE__alert-banner__update-header__container': true,
        'CAKE__alert-banner__update-header__container--error': severity === 'high',
      })}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={1}
    >
      {icon && (
        <Tooltip
          title={tooltipTitle}
          sx={{ cursor: 'pointer' }}
        >
          {icon}
        </Tooltip>
      )}
      <Typography className="CAKE__alert-banner__update-header__text">{title}</Typography>
      {acceptButton ? (
        acceptButton
      ) : (
        <Button
          variant={'outlined'}
          size={'small'}
          onClick={onAccept}
          className="CAKE__alert-banner__update-header__button"
          sx={{
            color: 'white',
            border: 'solid white 1px',
            '&:hover': {
              border: 'solid white 1px',
              backgroundColor: 'white',
              color: '#599CFF',
            },
          }}
        >
          <span>{buttonTitle ? buttonTitle : 'Update Now'}</span>
        </Button>
      )}
    </Stack>
  );
};

export default AlertBanner;
