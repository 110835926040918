import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import PluginsCard from '~components/dashboard/PluginsCard/PluginsCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import {
  selectPartnerPluginsError,
  selectPartnerPluginsIsLoading,
} from '~features/partner-plugins/partner-plugins.selectors';
import { getPartnerPlugins } from '~features/partner-plugins/partner-plugins.slice';
import { selectProjectConfigIsLoading } from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectUtilityView = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const isPluginsLoading = useAppSelector(selectPartnerPluginsIsLoading);
  const error = useAppSelector(selectPartnerPluginsError);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
    dispatch(getPartnerPlugins());
  }, []);

  if (isLoading || isPluginsLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <PluginsCard />
      {/* {projectShopifyId ? <ProjectDropsCard /> : <ShopifyImporterCard />}
        <DashboardCard subtitle={'Token-Gated Content'}>
          <ContentControls onAddContentItem={onAddContentItem} />
          <ContentItemsTable
            content={projectContent}
            onSelectItem={onSelectContentItem}
          />
        </DashboardCard>
        <DashboardCard subtitle={'Data Room'}>
          <DataRoomTableControls onAddDataObject={onAddDataObject} />
          <DataRoomObjectsTable
            objects={projectDataRoomObjects}
            onSelectItem={onSelectDataRoomObject}
          />
        </DashboardCard> */}
    </PageLayout>
  );
};

export default ProjectUtilityView;
