import { TimelineConnector, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Chip, Divider } from '@mui/material';
import moment from 'moment';

import type { FeedAggregateActivityType } from '~types/Streams';

type GroupedDateLineBreakProps = {
  currentItm: FeedAggregateActivityType;
  prevItem: FeedAggregateActivityType;
};

const GroupedDateLineBreak = ({ currentItm, prevItem }: GroupedDateLineBreakProps) => {
  if (!prevItem) {
    return (
      <TimelineItem sx={{ marginLeft: '5px' }}>
        <TimelineOppositeContent></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ width: '1px', color: 'rgba(0, 0, 0, 0.03)' }} />
        </TimelineSeparator>
        <TimelineContent>
          <Divider>
            <Chip
              label={
                moment(currentItm.updated_at).isSame(new Date(), 'day')
                  ? 'Today'
                  : moment(currentItm.updated_at).format('D MMM YYYY')
              }
              variant="outlined"
            />
          </Divider>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (moment(prevItem.updated_at).diff(moment(currentItm.updated_at), 'days') > 0) {
    return (
      <TimelineItem sx={{ marginLeft: '5px' }}>
        <TimelineOppositeContent></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ width: '1px', color: 'rgba(0, 0, 0, 0.03)' }} />
        </TimelineSeparator>
        <TimelineContent>
          <Divider>
            <Chip
              label={moment(currentItm.updated_at).format('D MMM YYYY')}
              variant="outlined"
            />
          </Divider>
        </TimelineContent>
      </TimelineItem>
    );
  }

  return <></>;
};

export default GroupedDateLineBreak;
