import type TokenBlueprintType from './TokenBlueprintType';

enum UpdateType {
  STANDARD = 'STANDARD',
  LAYERED = 'LAYERED',
}

interface TokenGroupUpdateType {
  _id: string;
  type: UpdateType;
  imageFile?: File;
  blueprint: Partial<TokenBlueprintType>;
  tokenGroupId: string;
  startDate: string;
  endDate: string;
  status: string;
}

export type { TokenGroupUpdateType };
export { UpdateType };
