import './ProjectHeader.scss';

import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';

import { FileUploadKey } from '~components/inputs/FileUploadInput';
import ImageDropzone from '~components/inputs/ImageDropzone';
import { ProjectSelectors } from '~constants/cypress/project.selectors';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  selectProjectConfig,
  selectProjectDappConfig,
  selectProjectName,
  selectUploadStatus,
} from '~features/project-config/project-config.selectors';
import { updateProjectConfig, uploadImage } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { PencilIcon } from '~icons/common';
import { UploadStatus } from '~types/miscs/UploadStatus';

import ProjectNameField from './components/ProjectNameField';

interface ProjectHeaderProps {
  subtitle?: string;
  isEditable?: boolean;
  children?: JSX.Element;
}

const ProjectHeader = ({ subtitle, isEditable, children }: ProjectHeaderProps) => {
  const dispatch = useAppDispatch();
  const [isEditName, setIsEdit] = useState<boolean>(false);
  const settings = useAppSelector(selectProjectDappConfig);
  const projectConfig = useAppSelector(selectProjectConfig);
  const projectName = useAppSelector(selectProjectName);
  const uploadStatuses = useAppSelector(selectUploadStatus);

  const onUploadFile = (files: Array<File>, key: FileUploadKey) => {
    dispatch(uploadImage({ file: files[0], key }));
  };

  const onUploadError = (message: string) => dispatch(addNotification({ severity: 'error', message, duration: 3000 }));

  const onUpdateDisplayName = (value: string) => {
    dispatch(updateProjectConfig({ ...projectConfig, name: value, slug: value.toLowerCase().replaceAll(' ', '-') }));
  };

  const onSetEdit = (val: boolean) => {
    setIsEdit(val);
  };

  return (
    <Box className={'CAKE__project-header'}>
      <Box className={'CAKE__project-header__image'}>
        {isEditable ? (
          <ImageDropzone
            onDropFile={(files: Array<File>) => onUploadFile(files, FileUploadKey.FAVICON)}
            onError={onUploadError}
            image={settings?.favicon || '/favicon.png'}
            variant={'small'}
            isUploading={uploadStatuses[FileUploadKey.FAVICON] === UploadStatus.LOADING}
          />
        ) : (
          <img
            src={settings?.favicon || '/favicon.png'}
            alt="fav"
          />
        )}
      </Box>
      <Box className={'CAKE__project-header__name'}>
        <Box>
          {isEditable && !isEditName && (
            <Box
              className={'CAKE__project-header__editable'}
              data-cy={ProjectSelectors.NameCard}
            >
              <Typography variant={'h5'}>{projectName}</Typography>
              <IconButton
                data-cy={ProjectSelectors.EditNameButton}
                onClick={(e) => {
                  e.stopPropagation();
                  onSetEdit(true);
                }}
              >
                <PencilIcon fontSize={'small'} />
              </IconButton>
            </Box>
          )}

          {isEditable && isEditName && (
            <Box
              className={'CAKE__project-header__editable'}
              data-cy={ProjectSelectors.NameCard}
            >
              <ProjectNameField
                onChange={onUpdateDisplayName}
                onClickOutside={() => onSetEdit(false)}
                projectName={projectName}
              />
            </Box>
          )}

          {!isEditable && <Typography variant={'h5'}>{projectName}</Typography>}
          <Typography variant={'body2'}>{subtitle}</Typography>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default ProjectHeader;
