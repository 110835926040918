import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import BooleanSlider from '~components/inputs/BooleanSlider';
import FileUploadInput from '~components/inputs/FileUploadInput';
import {
  selectAgreementConfig,
  selectAgreementConfigIsLoading,
} from '~features/agreements-config/agreements-config.selectors';
import {
  createAgreement,
  getAgreementConfig,
  resetAgreementConfig,
  updateAgreementConfig,
  uploadAgreementFile,
} from '~features/agreements-config/agreements-config.slice';
import { selectProjectDappConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { UploadStatus } from '~types/miscs/UploadStatus';
import type { CakeActivityType } from '~types/Streams';

const AgreementsSettingsCard = () => {
  const dispatch = useAppDispatch();
  const projectDappConfig = useAppSelector(selectProjectDappConfig);
  const agreementConfig = useAppSelector(selectAgreementConfig);
  const isAgreementLoading = useAppSelector(selectAgreementConfigIsLoading);

  useEffect(() => {
    dispatch(getAgreementConfig());
    return () => {
      dispatch(resetAgreementConfig());
    };
  }, []);

  const handleUserAgreementUpdate = (event?: CakeActivityType) => {
    if (!projectDappConfig.mintAgreementId) {
      dispatch(createAgreement());
    } else {
      dispatch(
        updateAgreementConfig({
          updatedAgreementData: {
            ...agreementConfig,
            isActivated: agreementConfig.isActivated ? false : true,
          },
          event,
        }),
      );
    }
  };

  const onUploadFile = (file: File) => {
    dispatch(uploadAgreementFile({ file }));
  };

  return (
    <DashboardCard
      title={'Terms and Agreements'}
      subtitle={
        <Typography variant={'body2'}>Require your holders to sign a Terms and Conditions agreement.</Typography>
      }
    >
      <Stack gap={2}>
        <BooleanSlider
          isChecked={agreementConfig.isActivated}
          title={agreementConfig.isActivated ? 'Enabled' : 'Disabled'}
          onUpdate={() =>
            handleUserAgreementUpdate({
              content: {
                event: 'dApp Settings Update',
                description: `Mint terms ${agreementConfig.isActivated ? 'disabled' : 'enabled'}`,
              },
            })
          }
        />
      </Stack>
      {agreementConfig.isActivated && (
        <FileUploadInput
          title={agreementConfig.url ? agreementConfig.url : 'Upload Terms Document'}
          path={agreementConfig.url || ''}
          uploadState={isAgreementLoading ? UploadStatus.LOADING : 'A'}
          onUpload={(file: File) => onUploadFile(file)}
          viewSource={true}
          required={true}
        />
      )}
    </DashboardCard>
  );
};

export default AgreementsSettingsCard;
