import './TypographySettingsCard.scss';

import { useEffect } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard/DashboardCard';
import TypographySettings from '~components/dashboard/TypographySettingsCard/components/TypographySettings';
import { selectProjectDappConfig } from '~features/project-config/project-config.selectors';
import { updateTypography } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { TypographyItemType, TypographyKeys } from '~types/ProjectType';
import { TypographySources } from '~types/ProjectType';
import { useGoogleFonts } from '~utils/hooks/useGoogleFonts';

const TypographySettingsCard = () => {
  const dispatch = useAppDispatch();
  const dappConfig = useAppSelector(selectProjectDappConfig);
  const { loadFont } = useGoogleFonts();

  useEffect(() => {
    if (!dappConfig.typography) {
      return;
    }

    Object.values(dappConfig.typography).forEach((type) => {
      if (type.source === TypographySources.Google) {
        loadFont(type.family);
      } else if (type.source === TypographySources.Uploaded) {
        const fontFile = new FontFace(type.family, `url(${encodeURI(type.url)})`);
        document.fonts.add(fontFile);
        void fontFile.load();
      }
    });
  }, []);

  const onUpdateFont = (newFont: TypographyItemType, type: TypographyKeys, file?: File) => {
    dispatch(updateTypography({ font: newFont, key: type, file }));
  };

  return (
    <DashboardCard
      className="CAKE__typography-settings-card"
      title={'Typography settings'}
      subtitle={'Select a font for your headings and body text.'}
    >
      <TypographySettings
        onUpdateFont={onUpdateFont}
        settings={dappConfig.typography}
      />
    </DashboardCard>
  );
};

export default TypographySettingsCard;
