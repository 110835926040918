import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageLoader from '~components/miscs/PageLoader';
import { InvitationSelectors } from '~constants/cypress/invitation.selectors';
import { invitationErrors } from '~constants/invitation/invitation';
import { PublicRoutes } from '~constants/routes';
import {
  selectIsInvitationInvalid,
  selectIsInvitationValid,
  selectValidateInvitationError,
} from '~features/user-invitation/user-invitation.selectors';
import { validateInvitation } from '~features/user-invitation/user-invitation.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const InvitationView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isInvalid = useAppSelector(selectIsInvitationInvalid);
  const isValid = useAppSelector(selectIsInvitationValid);
  const validationError = useAppSelector(selectValidateInvitationError);

  useEffect(() => {
    const invitationId = searchParams.get('id');
    dispatch(validateInvitation(invitationId));
  }, []);

  useEffect(() => {
    if (isValid) {
      navigate(PublicRoutes.SIGNUP);
    }
  }, [isValid, navigate]);

  return (
    <Stack
      height={'100%'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {isInvalid ? (
        <Typography
          data-cy={InvitationSelectors.ErrorMessage}
          variant="h6"
          color="primary"
        >
          {invitationErrors[validationError]}
        </Typography>
      ) : (
        <PageLoader />
      )}
    </Stack>
  );
};

export default InvitationView;
