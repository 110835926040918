import '~components/dashboard/CollectionArtworksCard/CollectionArtworksCard.scss';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import ViewTypeSwitch from '~components/buttons/ViewTypeSwitch';
import CollectionAdvancedSettingsModal from '~components/collections/CollectionAdvancedSettingsModal';
import CollectionTokenGroups from '~components/collections/CollectionTokenGroups';
import CollectionViewer from '~components/collections/CollectionViewer';
import DashboardCard from '~components/dashboard/DashboardCard';
import ErrorModal from '~components/miscs/ErrorModal';
import ProjectHeader from '~components/projects/ProjectHeader';
import ViewType from '~constants/miscs/ViewType';
import { ContractNetwork } from '~constants/networks/networks';
import { ProjectCollectionType } from '~constants/project/project-constants';
import { updateTokenPlaceholderImage, uploadItems } from '~features/collection-upload/collection-upload.slice';
import { selectDeployedContractState } from '~features/contract-config/contract-config.selectors';
import { selectProjectCollectionType, selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

const CollectionArtworksCard = () => {
  const dispatch = useAppDispatch();

  const collectionType = useAppSelector(selectProjectCollectionType);
  const deployedContractConfig = useAppSelector(selectDeployedContractState);
  const projectConfig = useAppSelector(selectProjectConfig);

  const [acceptUploadNotice, setAcceptUploadNotice] = useState<boolean>(false);
  const [isAdvancedSettings, setIsAdvancedSettings] = useState<boolean>(false);
  const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
  const [isPlaceholderImageUpload, setIsPlaceholderImageUpload] = useState<boolean>(false);
  const [isVRFConfirmBanner, setIsVRFConfirmBanner] = useState<boolean>(false);
  const [showUploadNotice, setShowUploadNotice] = useState<boolean>(false);
  const [viewType, setViewType] = useState<string>(ViewType.GRID);

  useEffect(() => {
    if (collectionType === ProjectCollectionType.GROUP) {
      setViewType(ViewType.LIST);
    }
  }, [collectionType]);

  useEffect(() => {
    if (
      // @dev - make sure the project has a deployed contract
      projectConfig &&
      getCurrentContractNetwork(projectConfig?.contract) &&
      projectConfig?.collectionType === ProjectCollectionType.CUSTOM &&
      // @dev - value must be equal to String(0) as any number that is returned from on-chain
      // contract is typeof === 'string'
      deployedContractConfig.find((item: any) => item.name === 'startingIndex').value !== null &&
      deployedContractConfig.find((item: any) => item.name === 'startingIndex').value === 0
    ) {
      setIsVRFConfirmBanner(true);
    } else {
      setIsVRFConfirmBanner(false);
    }
  }, [projectConfig, deployedContractConfig]);

  useEffect(() => {
    if (
      projectConfig &&
      getCurrentContractNetwork(projectConfig?.contract) === ContractNetwork.GOERLI &&
      projectConfig?.collectionType === ProjectCollectionType.CUSTOM &&
      deployedContractConfig.find((item: any) => item.name === 'startingIndex').value !== 0
    ) {
      setShowUploadNotice(true);
    } else {
      setShowUploadNotice(false);
    }
  }, [projectConfig, deployedContractConfig]);

  const onUploadSettingFiles = (acceptedFiles: Array<File>) => {
    if (isPlaceholderImageUpload) {
      dispatch(updateTokenPlaceholderImage({ file: acceptedFiles[0] }));
      setIsAdvancedSettings(false);
      return;
    }
    if (!showUploadNotice || (showUploadNotice && acceptUploadNotice)) {
      dispatch(uploadItems(acceptedFiles));
      setIsAdvancedSettings(false);
      if (viewType === ViewType.LIST) {
        setViewType(ViewType.GRID);
      }
    } else {
      setIsErrorModal(true);
    }
  };

  return (
    <DashboardCard
      className={classNames({
        'CAKE__collection-artworks-card': true,
        'CAKE__collection-artworks-card__controls-container-padding': isVRFConfirmBanner,
      })}
      containerClassName={'CAKE__collection-artworks-card__container'}
    >
      <ProjectHeader subtitle="NFT Media & Metadata" />
      <Box className={'CAKE__collection-artworks-card__controls-container'}>
        <Box>
          <Typography variant={'h6'}>NFT Media</Typography>
          <Typography variant={'body2'}>These assets will make up the assets that become your NFT</Typography>
        </Box>
        <ViewTypeSwitch
          onChange={setViewType}
          viewType={viewType}
        />
      </Box>
      <Box className={'CAKE__collection-artworks-card__inner-container'}>
        <Box className={'CAKE__collection-artworks-card__viewer-container'}>
          {viewType === ViewType.GRID && (
            <CollectionViewer
              toggleAdvancedSettings={() => setIsAdvancedSettings(true)}
              isVRFConfirmBanner={isVRFConfirmBanner}
            />
          )}
          {viewType === ViewType.LIST && (
            <CollectionTokenGroups toggleAdvancedSettings={() => setIsAdvancedSettings(true)} />
          )}
        </Box>
      </Box>
      <CollectionAdvancedSettingsModal
        open={isAdvancedSettings}
        onClose={() => setIsAdvancedSettings(false)}
        onDropSettingFiles={onUploadSettingFiles}
        showUploadNotice={showUploadNotice}
        onAccept={() => setAcceptUploadNotice(!acceptUploadNotice)}
        isAccept={acceptUploadNotice}
        isPlaceholderImageUpload={isPlaceholderImageUpload}
        onTogglePlaceholderImageUpload={() => setIsPlaceholderImageUpload(!isPlaceholderImageUpload)}
      />
      <ErrorModal
        open={isErrorModal}
        onClose={() => setIsErrorModal(false)}
        footer={<Button onClick={() => setIsErrorModal(false)}>Okay</Button>}
        height={'300px'}
        width={'400px'}
        title={
          <Stack
            direction={'row'}
            alignContent={'center'}
            gap={1}
          >
            <InfoOutlinedIcon
              fontSize="small"
              color="info"
            />
            <Typography variant="h6">Hold-Up</Typography>
          </Stack>
        }
      >
        <Typography>You must accept the notice to proceed with your upload.</Typography>
      </ErrorModal>
    </DashboardCard>
  );
};

export default CollectionArtworksCard;
