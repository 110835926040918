import './MenuDrawerHeader.scss';

import { Box } from '@mui/material';

import PrivateRoutes from '~constants/routes/private-routes.consts';
import useSmartNavigate from '~hooks/useSmartNavigate';
import { CakeLogo } from '~icons/common';

const MenuDrawerHeader = () => {
  const { navigate } = useSmartNavigate();
  return (
    <Box className={'CAKE__menu-drawer-header'}>
      <Box
        className={'CAKE__menu-drawer-header__home'}
        onClick={() => navigate(PrivateRoutes.DASHBOARD_PROJECTS_LIST)}
      >
        <CakeLogo />
      </Box>
    </Box>
  );
};

export default MenuDrawerHeader;
