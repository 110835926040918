import type { ProjectType } from '~types/ProjectType';

const getDeployedContracts = (project: ProjectType) => {
  const deployedContracts = [];
  if (project.contract.livenet.contractAddress) {
    deployedContracts.push(project.contract.livenet.contractAddress);
  }
  if (project.contract.testnet.contractAddress) {
    deployedContracts.push(project.contract.testnet.contractAddress);
  }
  return deployedContracts;
};

export default getDeployedContracts;
