import type { RootState } from '~features';

const selectUploadQueue = (state: RootState) => state.collectionUpload.uploadQueue;

const selectIsCollectionUpladInProgress = (state: RootState): boolean =>
  state.collectionUpload.itemsCounter !== state.collectionUpload.totalItems;

const selectTokenPlaceholderImageUploadInProgress = (state: RootState): boolean =>
  state.collectionUpload.isTokenPlaceholderImageLoading;

export { selectIsCollectionUpladInProgress, selectTokenPlaceholderImageUploadInProgress, selectUploadQueue };
