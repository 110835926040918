import { increaseTotalSupply } from '~features/analytics/analytics.slice';
import { addRealTimeCollectionItem } from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';

const singleTokenMintedHandler = (emitter, data) => {
  const { insertedToken } = data;

  emitter(addRealTimeCollectionItem(insertedToken));
  emitter(increaseTotalSupply());

  emitter(
    addNotification({
      message: `Token ${insertedToken.name} - minted`,
      severity: 'info',
      duration: 5000,
    }),
  );
};

export default singleTokenMintedHandler;
