import './DashboardCard.scss';

import { Box, Stack, Typography } from '@mui/material';

import { DashboardSelectors } from '~constants/cypress/dashboard.selectors';
import { ContractNetwork } from '~constants/networks/networks';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

type DashboardCardProps = {
  className?: string;
  containerClassName?: string;
  title?: string;
  titleIcon?: JSX.Element;
  subtitle?: string | JSX.Element;
  rightContainer?: JSX.Element;
  leftContainer?: JSX.Element;
};

const DashboardCard = ({
  children,
  className = '',
  containerClassName = '',
  title,
  titleIcon,
  subtitle,
  rightContainer,
}: React.PropsWithChildren<DashboardCardProps>) => {
  const project = useAppSelector(selectProjectConfig);
  const network = getCurrentContractNetwork(project?.contract);

  // className={classNames({
  //   `CAKE__dashboard-card ${className}`: true,
  //   'CAKE__dashboard-card__controls-container-padding':
  //       network && network !== ContractNetwork.BASE_MAINNET
  // })}

  return (
    <Box
      className={`CAKE__dashboard-card ${className}`}
      sx={network && network !== ContractNetwork.BASE_MAINNET && { paddingTop: '20px' }}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'center'}
      >
        <Box
          className={'CAKE__dashboard-card__header-container'}
          marginY={1.5}
        >
          <Box>
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
            >
              {title && (
                <Typography
                  className={'CAKE__dashboard-card__title'}
                  variant={'h6'}
                  data-cy={DashboardSelectors.CardTitle}
                >
                  {title}
                </Typography>
              )}
              {titleIcon && titleIcon}
            </Stack>
            {subtitle && (
              <Typography
                className={'CAKE__dashboard-card__subtitle'}
                variant={'body2'}
                data-cy={DashboardSelectors.CardSubTitle}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          {rightContainer}
        </Box>
      </Stack>
      <Box className={`CAKE__dashboard-card__container ${containerClassName}`}>{children}</Box>
    </Box>
  );
};

export default DashboardCard;
