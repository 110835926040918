import './ProjectTimeline.scss';

import { Timeline, timelineOppositeContentClasses } from '@mui/lab';
import { useEffect, useRef } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import FeedEmpty from '~components/dashboard/ProjectTimeline/components/FeedEmpty';
import FeedError from '~components/dashboard/ProjectTimeline/components/FeedError';
import FeedItem from '~components/dashboard/ProjectTimeline/components/FeedItem';
import UpdateNotification from '~components/dashboard/ProjectTimeline/components/UpdateNotification';
import ProjectHeader from '~components/projects/ProjectHeader';
import { selectProjectId } from '~features/project-config/project-config.selectors';
import {
  selectFeedError,
  selectFeedOrderAndDataObject,
  selectIsNewFeedEventWaiting,
} from '~features/streams/streams.selectors';
import { setNewFeedEventWaiting, setProjectFollowing } from '~features/streams/streams.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const ProjectTimeline = () => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectProjectId);
  const feed = useAppSelector(selectFeedOrderAndDataObject);
  const isNewFeedEventWaiting = useAppSelector(selectIsNewFeedEventWaiting);
  const feedError = useAppSelector(selectFeedError);

  useEffect(() => {
    dispatch(setProjectFollowing({ id: projectId }));
  }, []);

  const topRef = useRef(null);

  const executeScroll = () => {
    topRef.current.scrollIntoView();
    dispatch(setNewFeedEventWaiting(false));
  };

  return (
    <DashboardCard>
      <ProjectHeader subtitle="Project Activity" />
      <div ref={topRef}>
        <div className={'CAKE__project_timeline__container'}>
          {isNewFeedEventWaiting && <UpdateNotification executeScroll={executeScroll} />}
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.08,
                paddingLeft: '0px',
              },
            }}
          >
            {feed.order.map((i: string, idx: number) => {
              return (
                <FeedItem
                  key={i}
                  data={feed.data[i]}
                  prevItem={idx > 0 ? feed.data[feed.order[idx - 1]] : null}
                />
              );
            })}
          </Timeline>
          {!feed.order.length && !feedError && <FeedEmpty />}
          {feedError && <FeedError />}
        </div>
      </div>
    </DashboardCard>
  );
};

export default ProjectTimeline;
