import { Box } from '@mui/material';

import ProjectItem from '~components/projects/ProjectsTable/components/ProjectItem';
import type { ProjectType } from '~types/ProjectType';

type ProjectsTableProps = {
  projects: Array<ProjectType>;
  onSelectItem: (project: ProjectType) => void;
};

const ProjectsTable = ({ projects, onSelectItem }: ProjectsTableProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {projects.map((item: ProjectType) => (
        <ProjectItem
          key={`project-item-${item._id}`}
          project={item}
          onClick={onSelectItem}
        />
      ))}
    </Box>
  );
};

export default ProjectsTable;
