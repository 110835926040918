import '~components/dashboard/ContractDeploymentCard/ContractDeploymentCard.scss';

import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import { Accordion, AccordionDetails, AccordionSummary } from '~components/miscs/AccordionCustomComponents';
import ProjectLaunchDatesForm from '~components/projects/ProjectLaunchDatesForm/ProjectLaunchDatesForm';
import ProjectSplitPayment from '~components/projects/ProjectSplitPayment';
import { checkDeploymentContractMatch } from '~features/contract-config/contract-config.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { ProjectType } from '~types/ProjectType';
import useDebounce from '~utils/hooks/useDebounce';
import walletAddressValidator from '~utils/web3/validators/wallet-address-validator';

type ContractBasicInfoFormProps = {
  config: ProjectType;
  isLive: boolean;
  network: string;
  handleDisabledUpdate: (message: string) => void;
  onConfigUpdated: (config: ProjectType) => void;
};

const ContractBasicInfoForm = ({
  config,
  isLive,
  network,
  handleDisabledUpdate,
  onConfigUpdated,
}: ContractBasicInfoFormProps) => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [name, setName] = useState<string>(config.name || '');
  const [symbol, setSymbol] = useState<string>(config.contract.symbol || '');
  const [supply, setSupply] = useState<string>(String(config.contract.totalSupply));
  const [price, setPrice] = useState<string>(String(config.contract.price || 0));
  const [maxTotal, setMaxTotal] = useState<string>(String(config.contract.maxMintTotal || 0));
  const [maxTxn, setMaxTxn] = useState<string>(String(config.contract.maxMintTxn || 0));
  const [minTxn, setMinTxn] = useState<string>(String(config.contract.minMint));
  const [superAdmin, setSuperAdmin] = useState<string>(String(config.contract.superAdmin || ''));
  const [royaltyAddress, setRoyaltyAddress] = useState<string>(String(config.contract.royalty.address || ''));
  const [royaltyAmount, setRoyaltyAmount] = useState<string>(String(config.contract.royalty.amount || 0));

  useEffect(() => {
    setSupply(String(config.contract.totalSupply));
  }, [config.contract.totalSupply]);

  const updateConfig = () => {
    onConfigUpdated({
      ...config,
      name,
      contract: {
        ...config.contract,
        symbol: symbol ? symbol : projectConfig.contract.symbol,
        totalSupply: parseInt(supply) >= 0 ? Number(supply) : projectConfig.contract.totalSupply,
        price: parseFloat(price) >= 0 ? Number(price) : projectConfig.contract.price,
        maxMintTotal: parseInt(maxTotal) >= 0 ? Number(maxTotal) : projectConfig.contract.maxMintTotal,
        maxMintTxn: parseInt(maxTxn) >= 0 ? Number(maxTxn) : projectConfig.contract.maxMintTxn,
        minMint: parseInt(minTxn) >= 1 ? Number(minTxn) : projectConfig.contract.minMint,
        superAdmin: walletAddressValidator(superAdmin) ? superAdmin : projectConfig.contract.superAdmin,
        royalty: {
          address: walletAddressValidator(royaltyAddress) ? royaltyAddress : projectConfig.contract.superAdmin,
          amount: Number(royaltyAmount) >= 0 && Number(royaltyAmount) <= 15 ? Number(royaltyAmount) : 0,
        },
      },
    });
  };

  useEffect(() => {
    if (network) dispatch(checkDeploymentContractMatch({ project: projectConfig }));
  }, [projectConfig]);

  useDebounce(
    updateConfig,
    [name, symbol, supply, price, maxTotal, maxTxn, minTxn, superAdmin, royaltyAddress, royaltyAmount],
    700,
  );

  const handleAdvanceSettings = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Stack spacing={2}>
      <V2TextInput
        label="Project Title"
        variant="standard"
        color={'primary'}
        value={name}
        fullWidth
        required
        onUpdate={setName}
        validator={(value) => value.length > 2}
        showSuccessIcon
      />

      <Stack
        direction={'row'}
        spacing={2}
      >
        <V2TextInput
          label="Project Symbol"
          variant="standard"
          color={'primary'}
          value={symbol}
          validator={(value: string) => value.replaceAll(' ', '') !== ''}
          onUpdate={setSymbol}
        />

        <V2TextInput
          label="Total supply"
          variant="standard"
          color={'primary'}
          type={parseInt(supply) ? 'number' : 'string'}
          value={parseInt(supply) ? supply : 'Unlimited'}
          validator={(value: string) => parseInt(value) >= 0 || value === 'Unlimited'}
          onUpdate={setSupply}
        />

        <V2TextInput
          label="ETH Price"
          variant="standard"
          color={'primary'}
          type="number"
          value={price}
          validator={(value: string) => parseFloat(value) >= 0}
          onUpdate={setPrice}
        />
      </Stack>
      <Accordion
        expanded={isExpanded}
        onChange={handleAdvanceSettings}
      >
        <AccordionSummary>
          <Typography variant="caption">Advanced Contract Configurations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Stack
              direction={'row'}
              spacing={2}
            >
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ width: '100%' }}
              >
                <V2TextInput
                  label="Max Total Mint Per Wallet"
                  variant="standard"
                  color={'primary'}
                  type="number"
                  value={maxTotal}
                  validator={(value: string) => parseInt(value) >= 0}
                  onUpdate={setMaxTotal}
                />

                <V2TextInput
                  label="Max Total Mint Per Purchase"
                  variant="standard"
                  color={'primary'}
                  type="number"
                  value={maxTxn}
                  validator={(value: string) => parseInt(value) >= 0}
                  onUpdate={setMaxTxn}
                />

                <V2TextInput
                  label="Minimum Purchase"
                  variant="standard"
                  color={'primary'}
                  type="number"
                  value={minTxn}
                  validator={(value: string) => parseInt(value) >= 1}
                  onUpdate={setMinTxn}
                />
              </Stack>
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ width: '50%' }}
              >
                <ProjectLaunchDatesForm
                  config={config}
                  onConfigUpdated={onConfigUpdated}
                />
              </Stack>
            </Stack>
            <V2TextInput
              label="Admin Wallet"
              variant="standard"
              color={'primary'}
              value={superAdmin}
              fullWidth
              required
              onUpdate={setSuperAdmin}
              validator={walletAddressValidator}
              showSuccessIcon
            />
            <Stack
              width={'100%'}
              spacing={2}
              direction={'row'}
            >
              <Stack width={'100%'}>
                <V2TextInput
                  label="Royalty Payment Recipient Address"
                  variant="standard"
                  color={'primary'}
                  value={royaltyAddress}
                  onUpdate={
                    isLive
                      ? () =>
                          handleDisabledUpdate(
                            'The contract royalty settings cannot be changed after a project has been deployed to Mainnet.',
                          )
                      : setRoyaltyAddress
                  }
                  validator={walletAddressValidator}
                  showSuccessIcon
                />
              </Stack>
              <Stack width={'25%'}>
                <V2TextInput
                  label="Royalty Percent (Max 15%)"
                  variant="standard"
                  color={'primary'}
                  type="number"
                  value={royaltyAmount}
                  onUpdate={
                    isLive
                      ? () =>
                          handleDisabledUpdate(
                            'The contract royalty settings cannot be changed after a project has been deployed to Mainnet.',
                          )
                      : setRoyaltyAmount
                  }
                  validator={(value) => Number(value) <= 15}
                />
              </Stack>
            </Stack>
            <Stack className={'CAKE__contract-deployment-card__form__payment-split__container'}>
              <Stack
                gap={2}
                className={'CAKE__contract-deployment-card__form__payment-split__inner-content'}
              >
                <Box className={'CAKE__contract-deployment-card__form__payment-split__header'}>
                  <Typography sx={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)' }}>Payment Split Settings</Typography>
                </Box>
                <ProjectSplitPayment
                  config={config}
                  onConfigUpdated={
                    isLive
                      ? () =>
                          handleDisabledUpdate(
                            'Payment settings cannot be changed after a project has been deployed on mainnet.',
                          )
                      : onConfigUpdated
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default ContractBasicInfoForm;
