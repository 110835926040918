import { Link } from '@mui/material';
import { useState } from 'react';

import LoginSignupContainer from '~components/auth/LoginSignupContainer';
import LoginSignupForm from '~components/auth/LoginSignupForm';
import MultiFactorAuth from '~components/auth/MultiFactorAuth';
import Redirect from '~components/navigation/Redirect';
import { PublicRoutes } from '~constants/routes';
import { selectIsLoading } from '~features/auth/auth.selectors';
import { login } from '~features/auth/auth.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { isPasswordValid, isUsernameValid } from '~utils/auth/validators';
import isMobile from '~utils/isMobile';

const LoginView = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isMultiFactorAuthView, setIsMultiFactorAuthView] = useState(false);
  const [multiFactorAuthState, setMultiFactorAuthState] = useState<{ code: number[] }>({ code: [...new Array(6)] });
  const isLoading = useAppSelector(selectIsLoading);
  const loginState = { username, password };

  const canSubmit = isUsernameValid(loginState.username) && isPasswordValid(loginState.password);
  const dispatch = useAppDispatch();

  const onLoginPassword = () => {
    // TODO: Remove this when MultiFactorAuth is actually implemented via Auth0.
    if (process.env.REACT_APP_USE_2FA === 'true' && !isMultiFactorAuthView) {
      return setIsMultiFactorAuthView(true);
    }

    dispatch(login(loginState));
  };

  const onCodeUpdate = (value: string, index: number) => {
    const newCode = [...multiFactorAuthState.code];
    newCode.splice(index, 1, Number(value));
    setMultiFactorAuthState({ ...multiFactorAuthState, code: newCode });
  };

  if (isMobile()) {
    return <Redirect to={PublicRoutes.BROWSER_NOT_SUPPORTED} />;
  }

  return (
    <LoginSignupContainer
      canSubmit={canSubmit}
      onSubmit={onLoginPassword}
      isLoading={isLoading}
      submitText={'Sign In'}
      slotBottom={
        <Link
          underline="none"
          fontWeight={'bold'}
          paddingTop={1}
          href="https://trycake.xyz"
          target="_blank"
        >
          Don&apos;t have an account?
        </Link>
      }
    >
      {isMultiFactorAuthView ? (
        <MultiFactorAuth
          code={multiFactorAuthState.code}
          email={loginState.username}
          onCodeUpdate={onCodeUpdate}
        />
      ) : (
        <LoginSignupForm
          loginState={loginState}
          setPassword={setPassword}
          setUsername={setUsername}
        />
      )}
    </LoginSignupContainer>
  );
};

export default LoginView;
