import { Templates } from '~types/ProjectType';

import PreviewChocolate from './images/chocolate.png';
import PreviewVanilla from './images/vanilla.png';

export type TemplateOption = {
  name: string;
  description: string;
  template: Templates;
  preview: string;
};

export const templateOptions: Record<Templates, TemplateOption> = {
  [Templates.Chocolate]: {
    name: 'Chocolate',
    template: Templates.Chocolate,
    preview: PreviewChocolate,
    description:
      'Moody and rich, chocolate lives on the cutting edge. It’s a bold choice for brands who want to stand out.',
  },
  [Templates.Vanilla]: {
    name: 'Vanilla',
    template: Templates.Vanilla,
    preview: PreviewVanilla,
    description: 'Simple and clean, vanilla is a great default for brands who want to let their work speak for itself.',
  },
};
