import '~components/dashboard/FAQSettingsCard/components/FAQSettingsEntry/FAQSettingsEntry.scss';

import InputIconButton from '~components/buttons/InputIconButton';
import V2TextInput from '~components/inputs/V2TextInput';
import { BinIcon, PencilIcon } from '~icons/common';
import type { FAQEntry } from '~types/ProjectType';

type FAQSettingsEntryProps = {
  faq: FAQEntry;
  index: number;
  onDelete: () => void;
  onEdit: () => void;
};

const FAQSettingsEntry = ({ index, faq, onDelete, onEdit }: FAQSettingsEntryProps) => {
  return (
    <div className={'CAKE__faq-settings-card-entry'}>
      <div className={'CAKE__faq-settings-card-entry__field'}>
        <V2TextInput
          inputProps={{
            readOnly: true,
            endAdornment: (
              <div className={'CAKE__faq-settings-card-entry__buttons'}>
                <InputIconButton
                  onClick={onEdit}
                  color="#009688"
                >
                  <PencilIcon />
                </InputIconButton>
                <InputIconButton
                  onClick={onDelete}
                  color="#009688"
                >
                  <BinIcon />
                </InputIconButton>
              </div>
            ),
          }}
          label={`Question ${index + 1}`}
          onUpdate={null}
          value={faq.question}
        />
      </div>
    </div>
  );
};

export default FAQSettingsEntry;
