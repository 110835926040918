import './TableContainer.scss';

import { CardActions, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { useState } from 'react';

import EmptyStateView from '~components/miscs/EmptyStateView';

interface TableContainerProps<T> {
  data: T[];
  headers: { text: string; value: string }[];
  itemRenderer?: (data: T, key?: string) => JSX.Element;
  keyExtractor?: (data: T) => string;
  noDataText?: string;
  pagination?: boolean;
}

const TableContainer = <T extends object>({
  data,
  headers,
  itemRenderer,
  keyExtractor,
  noDataText,
  pagination,
}: TableContainerProps<T>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  // if (!data || data.length === 0) {
  //   return (
  //     <div className={'CAKE__table-container__no-data'}>

  //     </div>
  //   );
  // }

  return (
    <>
      <div className={'CAKE__table-container'}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell key={item.value + Math.random()}>
                  <span className={'CAKE__table-container__header'}>{item.text}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && data.length > 0 ? (
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: T) => itemRenderer(item, keyExtractor && keyExtractor(item)))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7}>
                  <EmptyStateView message={noDataText} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </div>
      {pagination && (
        <CardActions className={'CAKE__token-holders-table CAKE__token-holders-table__actions'}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardActions>
      )}
    </>
  );
};

export default TableContainer;
