const computeTotalSupplyChange = (totalSupply, lastWeekTotalSupply) => {
  return lastWeekTotalSupply && totalSupply ? ((lastWeekTotalSupply / totalSupply) * 100).toFixed(0).toString() : null;
};

const computeHoldersChange = (holdersData) => {
  return holdersData.order.length - holdersData.trailingWalletCount
    ? (((holdersData.order.length - holdersData.trailingWalletCount) / holdersData.order.length) * 100)
        .toFixed(0)
        .toString()
    : null;
};

const computeVolumeChange = (volume, lastWeekVolume) => {
  return lastWeekVolume &&
    parseFloat(lastWeekVolume.toString().split('ETH')[0]) &&
    parseFloat(volume.toString().split('ETH')[0])
    ? Math.round(
        ((parseFloat(volume.toString().split('ETH')[0]) - parseFloat(lastWeekVolume.toString().split('ETH')[0])) /
          parseFloat(lastWeekVolume.toString().split('ETH')[0])) *
          100,
      ).toString()
    : null;
};

const computeFloorPriceChange = (floorPrice, lastWeekFloorPrice) => {
  return parseFloat(lastWeekFloorPrice.toString().split('ETH')[0]) && parseFloat(floorPrice.toString().split('ETH')[0])
    ? Math.round(
        ((parseFloat(floorPrice.toString().split('ETH')[0]) -
          parseFloat(lastWeekFloorPrice.toString().split('ETH')[0])) /
          parseFloat(lastWeekFloorPrice.toString().split('ETH')[0])) *
          100,
      ).toString()
    : null;
};

const computeEligibleTokenChange = (totalSupply, eligibleTokens) => {
  return eligibleTokens && totalSupply ? ((eligibleTokens / totalSupply) * 100).toFixed(0).toString() : null;
};

export {
  computeEligibleTokenChange,
  computeFloorPriceChange,
  computeHoldersChange,
  computeTotalSupplyChange,
  computeVolumeChange,
};
