import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: ['1 project', '2 users included', 'Help center access', 'Email support'],
    buttonText: 'Stay free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Recommended',
    price: '99',
    description: ['10 projects', '10 users included', 'Help center access', 'Priority email support', 'Dedicated team'],
    buttonText: 'Contact us',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: 'Contact Support',
    description: ['Unlimited projects', 'Unlimited users included', 'Help center access', 'Phone & email support'],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

type PricingProps = {
  onClose: () => void;
};

const Pricing = ({ onClose }: PricingProps) => {
  return (
    <Container
      id="pricing"
      sx={{
        pt: 4,
        pb: 8,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
        >
          Upgrade Your Plan
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          Create multiple projects, manage team members, and access advanced analytics with Pro and Enterprise plans.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Pro' ? '1px solid' : undefined,
                borderColor: tier.title === 'Pro' ? 'primary.main' : undefined,
                background: tier.title === 'Pro' ? 'linear-gradient(#033363, #021F3B)' : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === 'Pro' ? 'grey.100' : '',
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h6"
                  >
                    {tier.title}
                  </Typography>
                  {tier.title === 'Pro' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) => (theme.palette.mode === 'light' ? '' : 'none'),
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Pro' ? 'grey.50' : undefined,
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h2"
                  >
                    {tier.title === 'Enterprise' ? 'Contact' : `$${tier.price}`}
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h6"
                  >
                    {tier.title === 'Enterprise' ? '' : 'per month'}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: tier.title === 'Pro' ? 'primary.light' : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color: tier.title === 'Pro' ? 'grey.200' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  component={tier.title !== 'Free' ? 'a' : 'button'}
                  onClick={tier.title === 'Free' ? () => onClose() : () => console.log('Contact us')}
                  href={
                    tier.title === 'Free'
                      ? ''
                      : `mailto:support@cake.xyz?subject=Upgrade to ${tier.title}&body=Hi, I would like to upgrade to the ${tier.title} plan.`
                  }
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Pricing;
