import { Stack, Typography } from '@mui/material';

const BrowserNotSupportedView = () => (
  <Stack
    alignItems={'center'}
    justifyContent={'center'}
    width={'100%'}
    height={'100%'}
    gap={'4px'}
  >
    <Stack maxWidth={'240px'}>
      <img
        src="/images/warning-state-icon.png"
        alt="empty-state"
      />
    </Stack>
    <Typography textAlign={'center'}>Admin app is not optimised for mobile devices.</Typography>
    <Typography textAlign={'center'}>Please log in using desktop.</Typography>
  </Stack>
);

export default BrowserNotSupportedView;
