import { Stack } from '@mui/system';

import CopyValueIcon from '~components/miscs/CopyValueIcon';
import { UserCircleIcon } from '~icons/common';
import trimWalletAddress from '~utils/contracts/trim-wallet-address';
import etherscanLinkBuilder from '~utils/url-builder/etherscanLinkBuilder/etherscanLinkBuilder';

interface Props {
  address: string;
  chainId?: number;
}

const ContractTransactionsUserWallet = ({ address, chainId }: Props) => {
  return (
    <div className={'CAKE__contract-transaction-item__user-wallet'}>
      <UserCircleIcon />
      <Stack
        direction={'row'}
        alignItems={'center'}
      >
        <a
          className={'CAKE__contract-transaction-item__user-wallet__link'}
          href={etherscanLinkBuilder({ chainId, type: 'address', data: { address } })}
          target="_blank"
          rel="noreferrer"
        >
          <p>{trimWalletAddress(address)}</p>
        </a>
        <CopyValueIcon value={address} />
      </Stack>
    </div>
  );
};

export default ContractTransactionsUserWallet;
