import '~components/collections/CollectionViewer/components/CollectionItem/CollectionItem.scss';

import BrokenMetadataIcon from '@mui/icons-material/AssignmentLate';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, Button, Checkbox, Chip, Paper, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import CollectionItemImage from '~components/collections/CollectionItemImage';
import { ContractNetwork } from '~constants/networks/networks';
import { ProjectCollectionType } from '~constants/project/project-constants';
import {
  deleteCollectionItem,
  selectCollectionItem,
  unselectCollectionItem,
} from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  selectProjectCollectionType,
  selectProjectContractConfig,
} from '~features/project-config/project-config.selectors';
import { selectTokenUpdates } from '~features/token-group-updates/token-group-updates.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type CollectionItemType from '~types/CollectionItemType';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

type CollectionItemProps = {
  token: CollectionItemType;
  onEdit: (item: CollectionItemType) => void;
};

const CollectionItem = ({ token, onEdit }: CollectionItemProps) => {
  const dispatch = useAppDispatch();
  const tokenUpdates = useAppSelector(selectTokenUpdates);
  const projectContractConfig = useAppSelector(selectProjectContractConfig);
  const { activeUpdate, image, isMetadataValid, name } = token;
  const collectionType = useAppSelector(selectProjectCollectionType);

  const [isImage, setIsImage] = useState<boolean>(!!image);

  useEffect(() => {
    setIsImage(!!image);
  }, [image]);

  const onDelete = () => {
    if (getCurrentContractNetwork(projectContractConfig) === ContractNetwork.MAINNET) {
      dispatch(
        addNotification({
          message: 'This project is live. You cannot delete tokens from this collection',
          severity: 'error',
          duration: 5000,
        }),
      );
    } else {
      dispatch(deleteCollectionItem(token));
    }
  };

  const onChangeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(selectCollectionItem(token));
    } else {
      dispatch(unselectCollectionItem(token));
    }
  };

  return (
    <Paper
      elevation={1}
      className={'CAKE__collection-item'}
    >
      <Box onClick={() => onEdit(token)}>
        <CollectionItemImage
          image={image}
          onImageError={() => setIsImage(false)}
        />
      </Box>
      <Box className={'CAKE__collection-item__description'}>
        <Box>
          <Typography>{name}</Typography>
        </Box>
        <Box>
          <Button onClick={onDelete}>Delete</Button>
          <Button onClick={() => onEdit(token)}>Edit</Button>
        </Box>
      </Box>
      <Box className={'CAKE__collection-item__errors'}>
        {activeUpdate?.updateId &&
          (tokenUpdates[activeUpdate.updateId]?.status === 'ACTIVE' ||
            tokenUpdates[activeUpdate.updateId]?.status === 'SCHEDULED') && (
            <Chip
              label={
                tokenUpdates[activeUpdate.updateId].status.charAt(0) +
                tokenUpdates[activeUpdate.updateId].status.slice(1).toLowerCase()
              }
              icon={<UpdateIcon />}
              size="small"
              color="info"
              sx={{ color: 'white', fontSize: 11, fontWeight: 600 }}
            />
          )}
        {!isImage && (
          <Tooltip title={'Missing image'}>
            <BrokenImageIcon color={'inherit'} />
          </Tooltip>
        )}
        {!isMetadataValid && collectionType === ProjectCollectionType.CUSTOM && (
          <Tooltip title={'Metadata not valid'}>
            <BrokenMetadataIcon color={'inherit'} />
          </Tooltip>
        )}
      </Box>
      <Box className={'CAKE__collection-item__selection'}>
        <Checkbox
          color="primary"
          onChange={onChangeSelection}
        />
      </Box>
    </Paper>
  );
};

export default CollectionItem;
