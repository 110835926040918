import { useEffect, useRef } from 'react';

export default function useDebounce<T extends (...params: any[]) => void>(
  handler: T,
  watchedValue: Array<any>,
  delay: number,
) {
  const prevValue = useRef(watchedValue);
  useEffect(() => {
    if (JSON.stringify(prevValue.current) === JSON.stringify(watchedValue)) {
      return;
    }
    prevValue.current = watchedValue;
    const timeoutHandler = setTimeout(() => {
      handler();
    }, delay);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [...watchedValue, delay]);
}
