import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useState } from 'react';

import AlertBanner from '~components/miscs/AlertBanner';
import ComponentLoader from '~components/miscs/ComponentLoader';
import { useWallet } from '~context/WalletContext';
import {
  selectContractUpdateIsProcessing,
  selectDeployedContractStateMatch,
} from '~features/contract-config/contract-config.selectors';
import { updateDeployedContractState } from '~features/contract-config/contract-config.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import getProjectNetwork from '~utils/projects/get-network';

import ContractUpdateDialog from './components/ContractUpdateDialog';

const ContractUpdateAlert = () => {
  const dispatch = useAppDispatch();
  const isMatch: boolean = useAppSelector(selectDeployedContractStateMatch);
  const isUpdateProcessing = useAppSelector(selectContractUpdateIsProcessing);
  const project = useAppSelector(selectProjectConfig);
  const network = getProjectNetwork(project);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { provider, walletAddress } = useWallet();

  const handleContractUpdate = () => {
    dispatch(updateDeployedContractState({ network, provider, walletAddress }));
  };

  return (
    <>
      <ComponentLoader isOpen={isUpdateProcessing} />

      <ContractUpdateDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={handleContractUpdate}
      />

      <AlertBanner
        isLoading={isUpdateProcessing}
        title={'Your new contract settings need to be updated on-chain.'}
        loadingTitle={`${network.charAt(0).toUpperCase() + network.slice(1)} Contract Sync In Progress...`}
        tooltipTitle={
          'You must submit a new transaction to the blockchain each time you want to make an update to a deployed contract'
        }
        onAccept={() => setIsOpen(true)}
        icon={<WarningRoundedIcon sx={{ color: 'white' }} />}
      />
    </>
  );
};

export default ContractUpdateAlert;
