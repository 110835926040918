const getImageUserPrompt = (prompt: string, keyword = 'hyperrealistic, ') => {
  if (prompt) {
    const splitPrompt = prompt.split(keyword)[0];
    const firstLetter = splitPrompt.charAt(0);
    const remainingPrompt = splitPrompt.slice(1);
    return firstLetter.toUpperCase() + remainingPrompt;
  }
  return;
};

const getImagePromptInputs = (prompt: string, isUser = true, keyword = 'hyperrealistic, ', delimiter = ',') => {
  if (prompt) {
    let splitPrompt = '';
    if (isUser) {
      splitPrompt = prompt.split(keyword)[0];
    } else {
      splitPrompt = `${keyword}${prompt.split(keyword)[1]}`;
    }
    return splitPrompt.split(delimiter);
  }
  return [];
};

export { getImagePromptInputs, getImageUserPrompt };
