/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { BatterDrop } from '~types/DropType';

interface ProjectDropsManagerState {
  drops: BatterDrop[];
  dropsProjectId: string | null;
  isLoading: boolean;
  isUpdating: boolean;
  error: string | null;
}

const initialState: ProjectDropsManagerState = {
  drops: [],
  dropsProjectId: null,
  isLoading: false,
  isUpdating: false,
  error: null,
};

const projectDropsManagerState = createSlice({
  name: 'project-utility-manager',
  initialState,
  reducers: {
    getProjectDropsManager(state, action: PayloadAction<{ id: string; force?: boolean }>) {
      state.isLoading = true;
      state.error = null;
    },
    setProjectDropsManager(state, action: PayloadAction<{ drops: BatterDrop[]; projectId: string }>) {
      state.drops = action.payload.drops;
      state.dropsProjectId = action.payload.projectId;
      state.isLoading = false;
    },
    cancelProjectDropsManagerLoad(state) {
      state.isLoading = false;
    },
    setProjectDropsManagerError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.drops = [];
    },
  },
});

export const {
  getProjectDropsManager,
  setProjectDropsManager,
  cancelProjectDropsManagerLoad,
  setProjectDropsManagerError,
} = projectDropsManagerState.actions;

export default projectDropsManagerState.reducer;
