import '~components/buttons/WalletConnectButton/WalletConnectButton.scss';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import WalletConnectModal from '~components/miscs/WalletConnectModal';
import { useWallet } from '~context/WalletContext/WalletContext';

const WalletConnectButton = () => {
  const { walletAddress } = useWallet();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <IconButton
        sx={{ color: '#1b1d1f' }}
        onClick={toggleModal}
      >
        {walletAddress && <div className={'CAKE__wallet-connect-button__modal__status'}></div>}
        <AccountBalanceWalletIcon fontSize={'small'} />
      </IconButton>
      <WalletConnectModal
        isOpen={isOpen}
        onClose={toggleModal}
      />
    </div>
  );
};

export default WalletConnectButton;
