import '~components/miscs/WalletConnectModal/WalletConnectModal.scss';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

type DeploymentWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const DeploymentWarningModal = ({ isOpen, onClose, onAccept }: DeploymentWarningModalProps) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const handleUserAcceptance = () => {
    setChecked(checked ? false : true);
    setIsError(checked ? true : false);
  };

  const handleMainnetDeployment = () => {
    if (checked) {
      onAccept();
      onClose();
    } else {
      setIsError(true);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <DialogTitle>Deploy To Production</DialogTitle>
      <DialogContent>
        <Stack
          width={'100%'}
          justifyContent="center"
          alignItems={'center'}
          gap={2}
        >
          <Typography>
            Typically its recommended that projects are first deployed to a testnet like Goerli where test ether can be
            used to pay for mints and assure your contract is functioning properly.
          </Typography>
          <Typography>
            Please acknowledge your choice to defer testing your contract by agreeing to the statement below.
          </Typography>
        </Stack>
        <Stack
          mt={2}
          gap={1}
        >
          <FormControlLabel
            control={<Checkbox checked={checked} />}
            label="I acknowledge my choice to skip testing my contract."
            onChange={handleUserAcceptance}
          />
          {isError && (
            <Typography
              variant="caption"
              sx={{ color: 'red' }}
            >
              You must agree to the statement above prior to continuing.
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: '24px' }}>
        <Button
          variant={'contained'}
          onClick={handleMainnetDeployment}
        >
          Accept
        </Button>
        <Button
          variant={'outlined'}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeploymentWarningModal;
