import { Button, Stack } from '@mui/material';
import { useState } from 'react';

import Modal from '~components/miscs/Modal';
import { DashboardSelectors } from '~constants/cypress/dashboard.selectors';

import Pricing from './Pricing';

type ProjectControlsProps = {
  onAddProject: () => void;
};

const ProjectControls = ({ onAddProject }: ProjectControlsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Stack
      alignItems="flex-end"
      justifyContent="top"
      paddingTop={'6px'}
    >
      <Button
        variant="outlined"
        onClick={() => setIsModalOpen(true)}
        color={'inherit'}
        size={'small'}
        sx={{
          backgroundColor: 'white',
          textTransform: 'none',
        }}
        data-cy={DashboardSelectors.NewProjectButton}
      >
        {'New Project +'}
      </Button>
      <Modal
        open={isModalOpen}
        height={'75%'}
        width={'75%'}
        onClose={() => setIsModalOpen(false)}
      >
        <Pricing onClose={() => setIsModalOpen(false)} />
      </Modal>
    </Stack>
  );
};

export default ProjectControls;
