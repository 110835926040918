import '~components/dashboard/ContractDeploymentCard/ContractDeploymentCard.scss';

import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import { ContractNetwork, NetworkLayer } from '~constants/networks/networks';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';
import { BaseIcon } from '~icons/common';
import type { ProjectType } from '~types/ProjectType';
import getCurrentContractAddress from '~utils/contracts/get-current-contract-address';
import useDebounce from '~utils/hooks/useDebounce';
import getProjectNetwork from '~utils/projects/get-network';
import getProjectNetworkLayer from '~utils/projects/get-network-layer';

enum ContractType {
  STANDARD = 'Standard',
  OPEN_EDITION = 'Open Edition',
}

interface ContractSummaryInfoProps {
  onConfigUpdated: (project: ProjectType) => void;
}

const ContractSummaryInfo = ({ onConfigUpdated }: ContractSummaryInfoProps) => {
  const projectConfig = useAppSelector(selectProjectConfig);
  const [contractType, setContractType] = useState<ContractType.STANDARD | ContractType.OPEN_EDITION>(
    projectConfig.contract.totalSupply ? ContractType.STANDARD : ContractType.OPEN_EDITION,
  );
  const [networkLayer, setNetworkLayer] = useState<NetworkLayer>(getProjectNetworkLayer(projectConfig));

  const handleNetworkChange = (event: SelectChangeEvent) => {
    switch (event.target.value as NetworkLayer) {
      case NetworkLayer.BASE:
        setNetworkLayer(NetworkLayer.BASE);
        break;
      case NetworkLayer.ETH:
        setNetworkLayer(NetworkLayer.ETH);
        break;
    }
  };

  const handleContractTypeUpdate = () => {
    switch (contractType) {
      case 'Standard':
        setContractType(ContractType.OPEN_EDITION);
        break;
      case 'Open Edition':
        setContractType(ContractType.STANDARD);
        break;
    }
  };

  const getDeploymentStatusValue = (): string => {
    if (!getCurrentContractAddress(projectConfig.contract)) {
      return 'Not Deployed';
    }

    switch (networkLayer) {
      case NetworkLayer.BASE:
        if (projectConfig.contract.livenet.contractAddress) {
          return 'Deployed to Base Network';
        } else {
          return 'Deployed to Base Test Network';
        }
      case NetworkLayer.ETH:
        if (projectConfig.contract.livenet.contractAddress) {
          return 'Deployed to Ethereum Network';
        } else {
          return 'Deployed to Ethereum Test Network';
        }
    }
  };

  const updateConfig = () => {
    onConfigUpdated({
      ...projectConfig,
      contract: {
        ...projectConfig.contract,
        testnet: {
          ...projectConfig.contract.testnet,
          network: networkLayer === NetworkLayer.BASE ? ContractNetwork.BASE_SEPOLIA : ContractNetwork.SEPOLIA,
        },
        livenet: {
          ...projectConfig.contract.livenet,
          network: networkLayer === NetworkLayer.BASE ? ContractNetwork.BASE_MAINNET : ContractNetwork.MAINNET,
        },
        totalSupply: contractType === ContractType.OPEN_EDITION ? 0 : 100,
      },
    });
  };

  useDebounce(updateConfig, [contractType, networkLayer], 100);

  return (
    <Stack
      direction={'row'}
      gap={1}
      className={'CAKE__contract-deployment-card__contract-type__container'}
    >
      <Tooltip
        title="You cannot update your Chain after you have deployed a contract."
        placement="top"
        disableHoverListener={!projectConfig?.contract.livenet.contractAddress}
      >
        <FormControl
          variant="standard"
          style={{ width: '100%' }}
        >
          <InputLabel id="chain-label">Chain</InputLabel>
          <Select
            labelId="chain-label"
            label="Chain"
            value={networkLayer}
            onChange={handleNetworkChange}
            disabled={getProjectNetwork(projectConfig) !== ContractNetwork.UNKNOWN}
          >
            {Object.values(NetworkLayer).map((type: string) => {
              if (type === NetworkLayer.BASE) {
                return (
                  <MenuItem
                    key={type}
                    value={type}
                  >
                    <Stack
                      direction={'row'}
                      spacing={1}
                    >
                      {/* {type === NetworkLayer.ETH ? (
                      <EthIcon
                        width={'24px'}
                        height={'24px'}
                      />
                    ) : ( */}
                      <BaseIcon
                        width={'24px'}
                        height={'24px'}
                      />
                      {/* )
                    } */}
                      <Typography>{type}</Typography>
                    </Stack>
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </Tooltip>
      <Tooltip
        title={
          !projectConfig.contract.totalSupply
            ? 'Your community can mint any number of tokens within a specified time window.'
            : 'Your mint window remains open until all tokens have been purchased.'
        }
        arrow={true}
        placement={'top-start'}
        sx={{ cursor: 'pointer' }}
      >
        <FormControl
          variant="standard"
          style={{ cursor: 'pointer', width: '100%' }}
        >
          <InputLabel id="select-label">Contract Type</InputLabel>
          <Select
            labelId="select-label"
            value={contractType}
            label="Contract Type"
            onChange={handleContractTypeUpdate}
          >
            {Object.values(ContractType).map((type: string) => {
              return (
                <MenuItem
                  key={type}
                  value={type}
                >
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Tooltip>
      <V2TextInput
        label="Deployment Status"
        variant="standard"
        color={'primary'}
        value={getDeploymentStatusValue()}
        validator={() => true}
        onUpdate={() => console.log('contract summary: deployment status')}
        isReadOnly={true}
      />
    </Stack>
  );
};

export default ContractSummaryInfo;
