import { Button, Stack } from '@mui/material';

import { ContractNetwork, EtherscanURI, PROD_NETWORKS } from '~constants/networks/networks';
import { selectContractDeployerIsLoading } from '~features/contract-deployer/contract-deployer.selectors';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';

type ViewContractButtonProps = {
  network: string;
};

const ViewContractButton = ({ network }: ViewContractButtonProps) => {
  const projectConfig = useAppSelector(selectProjectConfig);
  const contractIsDeploying = useAppSelector(selectContractDeployerIsLoading);
  const { contract } = projectConfig;

  const handleContractView = () => {
    const url = `${
      EtherscanURI[Object.keys(ContractNetwork)[Object.values(ContractNetwork).indexOf(network as ContractNetwork)]]
    }/address/${
      PROD_NETWORKS.includes(network as ContractNetwork)
        ? contract.livenet.contractAddress
        : contract.testnet.contractAddress
    }`;

    window.open(url, '_blank', 'noopener,noreferrer');
    return;
  };

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {(contract.testnet.contractAddress || contract.livenet.contractAddress) && !contractIsDeploying && (
          <Button
            size="small"
            variant="outlined"
            onClick={handleContractView}
          >
            View Contract
          </Button>
        )}

        {(contract.testnet.contractAddress || contract.livenet.contractAddress) && contractIsDeploying && (
          <Button
            disabled
            size="small"
            variant="outlined"
          >
            View Contract
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default ViewContractButton;
