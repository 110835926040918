import './ImageGenerationModal.scss';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { memo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '~components/miscs/AccordionCustomComponents';
import CopyWalletAddressIcon from '~components/miscs/CopyValueIcon';
import Modal from '~components/miscs/Modal';
import type ImageGeneration from '~types/ImageGeneration';
import trimWalletAddress from '~utils/contracts/trim-wallet-address';
import { getImagePromptInputs, getImageUserPrompt } from '~utils/image-generations/get-image-prompt';

interface ImageGenerationModalProps {
  imageGeneration: ImageGeneration;
  isOpen: boolean;
  onClose: () => void;
}
const ImageGenerationModal = ({ imageGeneration, isOpen, onClose }: ImageGenerationModalProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAdvancedPromptSettings = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      footer={
        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
        >
          <Button onClick={onClose}>Close</Button>
        </Box>
      }
      width="850px"
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'20px'}
      >
        <Stack
          width={'50%'}
          position={'relative'}
        >
          <img
            className={'CAKE__image-generation-modal__image'}
            src={imageGeneration?.storedImages[0]}
            alt="image-generation"
          />
          <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
            {imageGeneration &&
              (imageGeneration.isFavourite ? (
                <FavoriteIcon sx={{ color: 'aliceblue' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: 'aliceblue' }} />
              ))}
          </Box>
        </Stack>

        <Stack
          width={'50%'}
          paddingTop={'4px'}
        >
          <Typography className={'CAKE__image-generation-modal__title'}>{'Created:'}</Typography>
          <Typography className={'CAKE__image-generation-modal__field'}>
            {moment(imageGeneration?.created).fromNow()}
          </Typography>
          <Typography className={'CAKE__image-generation-modal__title'}>{'Creator:'}</Typography>
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{ marginBottom: '20px' }}
          >
            <Typography className={'CAKE__image-generation-modal__field__walletAddress'}>
              {trimWalletAddress(imageGeneration?.walletAddress) || '-'}
            </Typography>
            <CopyWalletAddressIcon value={imageGeneration?.walletAddress} />
          </Stack>
          <Typography className={'CAKE__image-generation-modal__title'}>{'Prompt:'}</Typography>
          <Stack
            direction={'row'}
            gap={0.5}
            flexWrap={'wrap'}
            maxHeight={'175px'}
            overflow={'scroll'}
          >
            <Typography className={'CAKE__image-generation-modal__field'}>
              {getImageUserPrompt(imageGeneration?.prompt)}
            </Typography>
            {/* {getImagePromptInputs(imageGeneration?.prompt).map((keyword: string, index: number) => {
              return (
                <Typography
                  key={index}
                  className={'CAKE__image-generation-modal__field'}
                >
                  {keyword}
                </Typography>
              );
            })} */}
          </Stack>
          {getImagePromptInputs(imageGeneration?.prompt).length && (
            <Accordion
              expanded={isExpanded}
              onChange={handleAdvancedPromptSettings}
            >
              <AccordionSummary>
                <Typography variant="caption">Advanced Prompt Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction={'row'}
                  gap={0.5}
                  flexWrap={'wrap'}
                >
                  {getImagePromptInputs(imageGeneration?.prompt, false).map((keyword: string, index: number) => {
                    return (
                      <Typography
                        key={index}
                        className={'CAKE__image-generation-modal__field__prompt'}
                      >
                        {keyword}
                      </Typography>
                    );
                  })}
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

const comparator = (prevProps: ImageGenerationModalProps, nextProps: ImageGenerationModalProps) => {
  return prevProps.isOpen === nextProps.isOpen;
};

export default memo(ImageGenerationModal, comparator);
