import moment from 'moment';

import type { FeedAggregateActivityType } from '~types/Streams';

const isDuplicateEvent = (currentEvent: FeedAggregateActivityType, previousEvent: FeedAggregateActivityType | null) => {
  if (!previousEvent) {
    return false;
  }

  if (
    // @ts-ignore
    currentEvent.activities[0]?.object?.data?.content?.description &&
    // @ts-ignore
    currentEvent.activities[0]?.object?.data?.content?.description ===
      // @ts-ignore
      previousEvent.activities[0]?.object?.data?.content?.description &&
    moment(previousEvent.created_at).diff(moment(currentEvent.created_at), 'seconds') < 120
  ) {
    return true;
  }

  return false;
};

export { isDuplicateEvent };
