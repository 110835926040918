import './Modal.scss';

import { Backdrop, Box, Fade, Modal as MaterialModal, Typography } from '@mui/material';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars-2';

type ModalProps = {
  className?: string;
  footer?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  subtitle?: string;
  title?: string | React.ReactElement;
  height?: string;
  width?: string;
};

const Modal = ({
  children,
  className,
  open,
  onClose,
  title,
  subtitle,
  footer,
  height,
  width,
  ...rest
}: React.PropsWithChildren<ModalProps>) => {
  const isHeader = Boolean(title) || Boolean(subtitle);
  const isSubheader = Boolean(subtitle);
  const isFooter = Boolean(footer);

  return (
    <MaterialModal
      open={open}
      disableAutoFocus={true}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
        },
      }}
      {...rest}
    >
      <Fade
        in={open}
        timeout={300}
      >
        <Box
          className={classNames({
            CAKE__modal: true,
            [className]: Boolean(className),
          })}
          sx={{ height: height ?? '500px', width: width ?? '750px' }}
        >
          {isHeader && (
            <Box className={'CAKE__modal__header'}>
              {title && typeof title === 'string' ? <Typography variant={'h6'}>{title}</Typography> : title}
              <Typography variant={'body2'}>{subtitle}</Typography>
            </Box>
          )}
          <Box
            className={classNames({
              CAKE__modal__content: true,
              'CAKE__modal__content__no-header': !isHeader,
              'CAKE__modal__content__no-subheader': !isSubheader && isHeader,
              'CAKE__modal__content__no-footer': !isFooter,
            })}
          >
            <Scrollbars autoHide>{children}</Scrollbars>
          </Box>
          {isFooter && <Box className={'CAKE__modal__footer'}>{footer}</Box>}
        </Box>
      </Fade>
    </MaterialModal>
  );
};

export default Modal;
