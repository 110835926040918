import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import type { MomentInput } from 'moment';
import moment from 'moment';

import type { ProjectType } from '~types/ProjectType';

type ProjectLaunchDatesFormProps = {
  config: ProjectType;
  onConfigUpdated: (config: ProjectType) => void;
};

const ProjectLaunchDatesForm = ({ config, onConfigUpdated }: ProjectLaunchDatesFormProps) => {
  const updateDate = (date: MomentInput, field: 'presale' | 'publicSale' | 'closeDate') => {
    if (field !== 'closeDate') {
      onConfigUpdated({
        ...config,
        contract: {
          ...config.contract,
          [field]: Number(date.valueOf()),
          closeDate: Number(date.valueOf()),
        },
      });
    } else {
      onConfigUpdated({
        ...config,
        contract: {
          ...config.contract,
          closeDate: Number(date.valueOf()),
        },
      });
    }
  };

  const renderInput = (params: TextFieldProps) => (
    <TextField
      {...params}
      variant="standard"
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {config.contract.totalSupply ? (
        <>
          <DateTimePicker
            label="Pre-sale date"
            value={moment(config.contract.presale)}
            inputFormat="MM/DD/yyyy"
            onChange={(val) => updateDate(val, 'presale')}
            renderInput={renderInput}
          />

          <DateTimePicker
            label="Public sale date"
            value={moment(config.contract.publicSale)}
            inputFormat="MM/DD/yyyy"
            onChange={(val) => updateDate(val, 'publicSale')}
            renderInput={renderInput}
          />
        </>
      ) : (
        <DateTimePicker
          label="Open Edition close date"
          value={moment(config.contract.closeDate)}
          onChange={(val) => updateDate(val, 'closeDate')}
          renderInput={renderInput}
        />
      )}
    </LocalizationProvider>
  );
};

export default ProjectLaunchDatesForm;
