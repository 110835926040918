import { ContractNetwork } from '~constants/networks/networks';
import type { ProjectType } from '~types/ProjectType';

const getContractMigrationStatus = (project: ProjectType) => {
  if (project.contract.livenet.contractAddress && !project.contract.livenet.facets?.length) {
    return { isMigration: true, network: ContractNetwork.MAINNET };
  } else if (project.contract.testnet.contractAddress && !project.contract.testnet.facets?.length) {
    return { isMigration: true, network: ContractNetwork.GOERLI };
  }
  return;
};

export { getContractMigrationStatus };
