import { Avatar, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';

type ActivityHeaderProps = {
  profileImage?: string;
  updated?: string;
  userName: string;
};

const ActivityHeader = ({ profileImage, userName }: ActivityHeaderProps) => {
  return (
    <Stack
      direction={'row'}
      gap={1}
      sx={{ alignItems: 'center' }}
    >
      <Tooltip
        title={
          userName ? <Typography>{userName.charAt(0).toUpperCase() + userName.slice(1)}</Typography> : <>Cake Admin</>
        }
      >
        <Avatar
          src={profileImage}
          alt="Cake"
          sx={{ width: 24, height: 24, cursor: 'pointer' }}
        />
      </Tooltip>
    </Stack>
  );
};

export default ActivityHeader;
