import './CollectionAddTokenGroupModal.scss';

import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import Modal from '~components/miscs/Modal';
import { ContractNetwork } from '~constants/networks/networks';
import { ProjectCollectionType } from '~constants/project/project-constants';
import {
  selectCollectionTokenGroups,
  selectTokenGroupsSupply,
} from '~features/collection-token-groups/collection-token-groups.selectors';
import { addTokenGroup } from '~features/collection-token-groups/collection-token-groups.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  selectContractTotalSupply,
  selectProjectCollectionId,
  selectProjectConfig,
} from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

import { getNextTokenGroupName } from './CollectionAddTokenGroupModal.helpers';

type CollectionAddTokenGroupModalProps = {
  open: boolean;
  onClose: () => void;
};

const CollectionAddTokenGroupModal = ({ open, onClose }: CollectionAddTokenGroupModalProps) => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const collectionId = useAppSelector(selectProjectCollectionId);
  const tokenGroups: CollectionTokenGroupType[] = useAppSelector(selectCollectionTokenGroups);

  const [name, setName] = useState<string>(getNextTokenGroupName(projectConfig.name, tokenGroups));
  const [totalCount, setTotalCount] = useState<number>();

  const totalSupply = useAppSelector(selectContractTotalSupply);
  const currentSupply = useAppSelector(selectTokenGroupsSupply);
  const isTotalSupplyExceeded = totalSupply - currentSupply - totalCount < 0;

  const onAddTokenGroup = () => {
    if (
      projectConfig.collectionType === ProjectCollectionType.CUSTOM &&
      getCurrentContractNetwork(projectConfig.contract) === ContractNetwork.MAINNET
    ) {
      dispatch(
        addNotification({
          message: 'You cannot change your collection to Token Groups after your Custom collection is deployed.',
          severity: 'error',
          duration: 5000,
        }),
      );
    } else {
      dispatch(
        addTokenGroup({
          name,
          totalCount,
          collectionId,
        }),
      );
      onClose();
    }
  };

  return (
    <Modal
      className={'CAKE__collection-add-token-group-modal'}
      open={open}
      onClose={onClose}
      title={'Add token group'}
      footer={
        <Button
          onClick={onAddTokenGroup}
          disabled={name.length === 0 || (totalCount || 0) <= 0 || isTotalSupplyExceeded}
        >
          Save
        </Button>
      }
    >
      <Box className={'CAKE__collection-add-token-group-modal__container'}>
        {projectConfig.collectionType === ProjectCollectionType.CUSTOM ? (
          <Typography
            variant="caption"
            color={'red'}
          >
            Warning: adding tokens groups will cause your existing tokens (uploaded using Advanced Settings) to be
            deleted.
          </Typography>
        ) : (
          <></>
        )}
        <V2TextInput
          name="name"
          label="Group name"
          variant="standard"
          disabled
          value={name}
          onUpdate={(val) => setName(val)}
          fullWidth
        />
        <V2TextInput
          name="Token count"
          label="Token group count"
          variant="standard"
          type="number"
          value={(totalCount || '').toString()}
          onUpdate={(val) => setTotalCount(val ? Number.parseInt(val, 10) : undefined)}
          fullWidth
          error={isTotalSupplyExceeded}
          errorText="Total count of tokens in token groups exceeds the total supply of tokens for contract."
        />
      </Box>
    </Modal>
  );
};

export default CollectionAddTokenGroupModal;
