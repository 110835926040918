import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { selectProjectId } from '~features/project-config/project-config.selectors';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { selectWhitelistConfig } from '~features/whitelist-config/whitelist-config.selectors';
import {
  cancelWhitelistConfigLoad,
  getWhitelistConfig,
  setWhitelistConfig,
  setWhitelistConfigError,
  updateWhitelistConfig,
  updateWhitelistConfigError,
} from '~features/whitelist-config/whitelist-config.slice';
import type { WhitelistResponseType, WhitelistUpdateType } from '~types/WhitelistTypes';

window.Buffer = window.Buffer || Buffer;

function* fetchProjectWhitelistSaga(action: PayloadAction<{ projectId: string; forceUpdate: boolean }>): Iterator<any> {
  const { projectId, forceUpdate } = action.payload;
  const whitelist: Array<string> = yield select(selectWhitelistConfig);

  if (whitelist.length && !forceUpdate) {
    yield put(cancelWhitelistConfigLoad());
    return;
  }

  try {
    const path = `project/${projectId}/whitelist`;
    const res: Response = yield call(fetchProtectedAPI, path);
    const data: WhitelistResponseType = yield res.json();

    if (data) {
      yield put(setWhitelistConfig({ whitelist: data.whitelist, merkleroot: data.merkleroot }));
    } else {
      yield put(setWhitelistConfigError('Whitelist not found'));
    }
  } catch (e) {
    console.log(e);
    yield put(setWhitelistConfigError(e.toString()));
  }
}

function* updateProjectWhitelistSaga(action: PayloadAction<WhitelistUpdateType>) {
  const projectId = yield select(selectProjectId);

  const path = `project/${projectId}/whitelist`;
  try {
    const res: Response = yield call(putProtectedAPI, path, action.payload);

    if (res.status === 200) {
      const data: WhitelistResponseType = yield res.json();

      yield put(
        setWhitelistConfig({
          whitelist: data.whitelist,
          merkleroot: data.merkleroot,
        }),
      );
    } else {
      yield put(updateWhitelistConfigError(`Update failed with status code ${res.status}`));
    }
  } catch (e) {
    console.log(e);
    yield put(updateWhitelistConfigError(e.toString()));
  }
}

export default function* whitelistSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getWhitelistConfig.type, fetchProjectWhitelistSaga),
    yield takeLatest(updateWhitelistConfig.type, updateProjectWhitelistSaga),
  ]);
}
