import { setUpgradeIsProcessing } from '~features/contract-config/contract-config.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { getProjectConfig } from '~features/project-config/project-config.slice';

const contractUpgradeSuccessHandler = (emitter, data) => {
  console.log(data);
  emitter(setUpgradeIsProcessing(false));
  emitter(getProjectConfig({ id: data.projectId, force: true }));
  emitter(
    addNotification({
      message: 'Contract has been upgraded',
      severity: 'success',
      duration: 5000,
    }),
  );
};

export default contractUpgradeSuccessHandler;
