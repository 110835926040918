import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TableCell, TableRow } from '@mui/material';

import V2TextInput from '~components/inputs/V2TextInput';
import WalletAddressFieldInput from '~components/inputs/WalletAddressFieldInput';
import type { PaymentShareType } from '~types/ProjectType';

type ProjectRecipientItemProps = {
  address: string;
  amount: number;
  onUpdate: (recipient: PaymentShareType) => void;
  onDelete: () => void;
  isShareError: boolean;
};
const ProjectRecipientItem = ({ address, isShareError, amount, onUpdate, onDelete }: ProjectRecipientItemProps) => {
  const onUpdateAddress = (newAddress: string) => {
    onUpdate({
      address: newAddress,
      amount,
    });
  };

  const onUpdateShare = (newShare: string) => {
    onUpdate({
      address,
      amount: Number.parseInt(newShare, 10),
    });
  };

  return (
    <TableRow
      className={'CAKE__project-table__item'}
      sx={{ height: '50px' }}
      tabIndex={-1}
    >
      <TableCell
        component="td"
        scope="row"
        padding="none"
        width="75%"
      >
        <WalletAddressFieldInput
          noBorder
          size="small"
          value={address}
          onUpdate={onUpdateAddress}
        />
      </TableCell>

      <TableCell
        align="right"
        width="25%"
      >
        <V2TextInput
          noBorder
          value={String(amount)}
          onUpdate={onUpdateShare}
          validator={() => !isShareError}
          size="small"
          textAlign="right"
        />
      </TableCell>

      <TableCell align="right">
        <IconButton
          color="primary"
          aria-label="delete"
          component="label"
          onClick={onDelete}
          size={'small'}
          sx={{ padding: 1 }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ProjectRecipientItem;
