import { ContractNetwork, ContractNetworkIds } from '~constants/networks/networks';
import type { ProjectType } from '~types/ProjectType';

const getProjectCurrentChainId = (projectConfig: ProjectType) => {
  if (!projectConfig) return 0;
  const { contract } = projectConfig;
  const { livenet, testnet } = contract;

  if (livenet.contractAddress) {
    const networkKey: number = Object.values(ContractNetwork).indexOf(projectConfig.contract.livenet.network);
    return ContractNetworkIds[Object.keys(ContractNetwork)[networkKey]];
  }

  if (testnet.contractAddress) {
    const networkKey: number = Object.values(ContractNetwork).indexOf(projectConfig.contract.testnet.network);
    return ContractNetworkIds[Object.keys(ContractNetwork)[networkKey]];
  }

  return 0;
};

export default getProjectCurrentChainId;
