import './PageLayout.scss';

import { Box, Stack } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';

type PageLayoutProps = {
  scrollHandler?: (values: any) => void;
};

const PageLayout = ({ children, scrollHandler }: React.PropsWithChildren<PageLayoutProps>) => {
  return (
    <Stack className={'CAKE__page-layout'}>
      <Scrollbars
        onScrollFrame={scrollHandler}
        className={'CAKE__page-layout__scroll'}
        autoHide
      >
        <Box
          className={'CAKE__page-layout__container'}
          paddingX={{ sm: 2, md: 8, lg: 10 }}
        >
          <Box className={'CAKE__page-layout__inner-container'}>{children}</Box>
        </Box>
      </Scrollbars>
    </Stack>
  );
};

export default PageLayout;
