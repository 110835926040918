import '~components/layout/AuthLayout/AuthLayout.scss';

import type { PropsWithChildren } from 'react';

type AuthLayoutProps = PropsWithChildren<Record<string, never>>;

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className={'CAKE__auth-layout'}>
      <div className={'CAKE__auth-layout__content'}>{children}</div>
      <div className={'CAKE__auth-layout__copyright'}>Copyright ©2022 Cake App by Good Month Labs</div>
    </div>
  );
};

export default AuthLayout;
