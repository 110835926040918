import { updateCollectionItemWithUpdateIdLocally } from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { storeBlueprint } from '~features/token-blueprints/token-blueprints.slice';

const singleTokenUpdateHandler = (emitter, data) => {
  const { blueprint, tokenId, tokenMetadata, updateId } = data;

  emitter(updateCollectionItemWithUpdateIdLocally({ tokenId, tokenMetadata, updateId }));

  emitter(storeBlueprint(blueprint));
  emitter(
    addNotification({
      message: `Token ${tokenMetadata.name} - updated`,
      severity: 'info',
      duration: 5000,
    }),
  );
};

export default singleTokenUpdateHandler;
