import { ContractNetwork, NetworkLayer } from '~constants/networks/networks';
import type { ProjectType } from '~types/ProjectType';

const getProjectNetworkLayer = (project: ProjectType) => {
  switch (project?.contract.livenet.network) {
    case ContractNetwork.MAINNET:
      return NetworkLayer.ETH;
    case ContractNetwork.BASE_MAINNET:
      return NetworkLayer.BASE;
  }
  return NetworkLayer.BASE;
};

export default getProjectNetworkLayer;
