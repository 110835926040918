const PrivateRoutes = {
  DASHBOARD_PROJECTS_LIST: '/dashboard/projects',
  DASHBOARD_PROJECT_OVERVIEW: '/dashboard/projects/:id',
  DASHBOARD_PROJECT_CONTRACT: '/dashboard/projects/:id/contract',
  DASHBOARD_PROJECT_COMMUNITY: '/dashboard/projects/:id/community',
  DASHBOARD_PROJECT_COLLECTION: '/dashboard/projects/:id/collection',
  DASHBOARD_PROJECT_DAPP: '/dashboard/projects/:id/dapp',
  DASHBOARD_PROJECT_ANALYTICS: '/dashboard/projects/:id/analytics',
  DASHBOARD_PROJECT_UTILITY: '/dashboard/projects/:id/utility',
  DASHBOARD_PROJECT_CONTENT_ITEM: '/dashboard/projects/:id/utility/content/:itemId',
  DASHBOARD_PROJECT_DATA_ROOM_OBJECT: '/dashboard/projects/:id/utility/data-room/:itemId',
  DASHBOARD_PROJECT_DROP: '/dashboard/projects/:id/utility/drops/:drop_id',
  DASHBOARD_PROJECT_NEW_DROP: '/dashboard/projects/:id/utility/drops/new',
  PROJECT_SHOPIFY_AUTH: '/shopify/auth',
  PROJECT_BUILDER: '/project-builder',
};

export default PrivateRoutes;
