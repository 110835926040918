import '~components/inputs/FileUploadInput/FileUploadInput.scss';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, IconButton, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useRef } from 'react';

import { UploadStatus } from '~types/miscs/UploadStatus';

type FileUploadInputProps = {
  path: string;
  title: string;
  uploadState: string;
  onUpload: (file: File) => void;
  required?: boolean;
  viewSource?: boolean;
};

const FileUploadInput = ({ path, title, uploadState, onUpload, viewSource, required }: FileUploadInputProps) => {
  const inputRef = useRef(null);

  const onUploadFile = () => {
    inputRef.current.click();
  };

  const onFileChoosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = e.target.files[0];
    onUpload(file);
  };

  return (
    <Stack direction={'column'}>
      <Stack position="relative">
        {uploadState === UploadStatus.LOADING ? (
          <Skeleton height={38} />
        ) : (
          <>
            <TextField
              InputProps={{
                readOnly: true,
                disabled: true,
              }}
              label={title}
              onClick={onUploadFile}
            />

            <IconButton
              sx={{ position: 'absolute', transform: 'translateY(-50%)', top: '50%', right: '1rem' }}
              onClick={onUploadFile}
            >
              <FileUploadIcon />
            </IconButton>

            <input
              type="file"
              name=""
              id="input_file"
              accept="image/png, image/gif, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/quicktime, video/mp4"
              ref={inputRef}
              hidden
              onChange={onFileChoosen}
            />
          </>
        )}
      </Stack>
      {required && !path && (
        <Typography
          variant="caption"
          color="error"
        >
          File upload required
        </Typography>
      )}
      {viewSource && path && (
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="text"
            color="primary"
          >
            View File
          </Button>
        </a>
      )}
    </Stack>
  );
};

export default FileUploadInput;
