import DashboardCard from '~components/dashboard/DashboardCard';
import V2TextInput from '~components/inputs/V2TextInput';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const SlugSettingsCard = () => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const { slug } = projectConfig;

  const onUpdateSlug = (slug: string) => {
    dispatch(updateProjectConfig({ ...projectConfig, slug }));
  };

  return (
    <DashboardCard title={'Project Slug'}>
      <V2TextInput
        value={slug || ''}
        onUpdate={onUpdateSlug}
      />
    </DashboardCard>
  );
};

export default SlugSettingsCard;
