import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContentItemEditor from '~components/dashboard/ContentItemEditor';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import {
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { selectProjectContentItem } from '~features/project-content/project-content.selectors';
import { getProjectContentItem, resetContentItem } from '~features/project-content/project-content.slice';
import { setNewContentItemId } from '~features/project-content/project-content.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectContentItemView = () => {
  const dispatch = useAppDispatch();

  const contentItem = useAppSelector(selectProjectContentItem);
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const error = useAppSelector(selectProjectConfigError);

  const location = useLocation();
  const contentId = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(setNewContentItemId(''));
    dispatch(getProjectConfig({ id: projectId, force: false }));
    dispatch(getProjectContentItem(contentId));

    return () => {
      dispatch(resetContentItem());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || contentItem === null) {
    return <PageLoader />;
  }

  if (error) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <Stack gap={2}>
        <ContentItemEditor contentItem={contentItem} />
      </Stack>
    </PageLayout>
  );
};

export default ProjectContentItemView;
