import './VRFCollectionConfirmModal.scss';

import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ComponentLoader from '~components/miscs/ComponentLoader';
import Modal from '~components/miscs/Modal';
import WalletConnectModal from '~components/miscs/WalletConnectModal';
import TokenPlaceholders from '~constants/tokens/token-placeholder';
import { useWallet } from '~context/WalletContext';
import { selectCollectionTokens, selectTotalTokens } from '~features/collection-items/collection-items.selectors';
import {
  selectContractUpdateIsProcessing,
  selectContractUpdateItems,
} from '~features/contract-config/contract-config.selectors';
import type { ContractStateType } from '~features/contract-config/contract-config.slice';
import { updateDeployedContractState } from '~features/contract-config/contract-config.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { requestRandomNumber } from '~features/vrf/vrf.slice';
import useAppSelector from '~hooks/useAppSelector';
import { ChainlinkIcon } from '~icons/common';
import type CollectionItemType from '~types/CollectionItemType';
import getProjectNetwork from '~utils/projects/get-network';

type VRFCollectionConfirmModalProps = {
  open: boolean;
  onClose: () => void;
};

const VRFCollectionConfirmModal = ({ open, onClose }: VRFCollectionConfirmModalProps) => {
  const dispatch = useDispatch();
  const updatedItems = useAppSelector(selectContractUpdateItems);
  const totalUploadedTokensCount = useAppSelector(selectTotalTokens);
  const tokens: Array<CollectionItemType> = useAppSelector(selectCollectionTokens);
  const currentTokenPlaceholderImage = tokens.length && tokens[0].placeholderImage;
  const isContractUpdateProcessing = useAppSelector(selectContractUpdateIsProcessing);
  const projectConfig = useAppSelector(selectProjectConfig);
  const { walletAddress, provider } = useWallet();
  const [network, setNetwork] = useState<string>('');
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [isWalletConnect, setIsWalletConnect] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (projectConfig) {
      setNetwork(getProjectNetwork(projectConfig));
    }
  }, [projectConfig]);

  useEffect(() => {
    if (provider && updatedItems.find((item: ContractStateType) => item.name === 'provenanceHash')) {
      setError(
        "Your contract requires some updates to its on-chain state before your collection can be revealed. After making these updates you can then reveal your collection. Click 'Update Contract' to proceed.",
      );
    } else if (projectConfig && totalUploadedTokensCount !== projectConfig.contract.totalSupply) {
      setError(
        'Your collection size does not match the total supply of your contract. Please ensure the number tokens in your collection matches the total supply stated in your Contract Settings. You can do this by either decreasing your total supply in the Contract Settings or importing/deleting uploaded tokens.',
      );
    } else {
      setError('');
    }
  }, [updatedItems, provider, totalUploadedTokensCount, projectConfig]);

  const handleClose = () => {
    setAcceptedTerms(false);
    onClose();
  };

  const onAccept = () => {
    if (!provider) {
      setIsWalletConnect(true);
    } else if (totalUploadedTokensCount !== projectConfig.contract.totalSupply) {
      return;
    } else if (updatedItems.find((item: ContractStateType) => item.name === 'provenanceHash')) {
      dispatch(updateDeployedContractState({ network, provider, walletAddress }));
    } else if (acceptedTerms) {
      dispatch(requestRandomNumber({ network, provider, walletAddress }));
      handleClose();
    } else {
      setError("Please acknowledge your understanding of Cake's reveal mechanics");
    }
  };

  const onAgree = () => {
    setAcceptedTerms(!acceptedTerms);
    setError('');
  };

  return (
    <Modal
      open={open}
      className={'CAKE__vrf-confirm-modal'}
      onClose={handleClose}
      height="70%"
      width="50%"
      title={
        <Stack
          direction={'row'}
          gap={2}
          className="CAKE__vrf-confirm-modal__title"
        >
          <Typography variant="h6">
            Provably Fair Reveals{' '}
            <span>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600 }}
              >
                Powered by
              </Typography>
            </span>
          </Typography>
          <ChainlinkIcon />
        </Stack>
      }
      footer={
        <Stack sx={{ width: '-webkit-fill-available' }}>
          <Stack
            direction={'row'}
            gap={2}
            sx={{ justifyContent: 'space-between', width: '100%' }}
          >
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={acceptedTerms} />}
                label={
                  <Typography variant="caption"> I am ready to make my collection visible to the public </Typography>
                }
                onClick={onAgree}
              />
            </FormGroup>
            <Stack
              direction={'row'}
              gap={1}
            >
              <Button
                variant="outlined"
                disabled={projectConfig && totalUploadedTokensCount !== projectConfig.contract.totalSupply}
                onClick={onAccept}
              >
                {!provider
                  ? 'Connect Wallet'
                  : updatedItems.find((item: ContractStateType) => item.name === 'provenanceHash')
                  ? 'Update Contract'
                  : 'Reveal'}
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </Stack>
          </Stack>
        </Stack>
      }
    >
      <Stack
        className="CAKE__vrf-confirm-modal__content"
        gap={1}
      >
        <Typography variant={'body2'}>
          Each token in your collection is currently set to a display a{' '}
          <span>
            <a
              className={'CAKE__vrf-confirm-modal__link'}
              href={currentTokenPlaceholderImage ? currentTokenPlaceholderImage : TokenPlaceholders.IMAGE}
              target="_blank"
              rel="noreferrer"
            >
              placeholder image
            </a>
          </span>{' '}
          that hides the actual token image.
        </Typography>

        <Typography variant={'body2'}>
          Revealing your collection will expose the actual token image and metadata publicly.{' '}
          <b>This action cannot be undone</b>. Once you reveal your collection anyone can search and view each token
          image.
        </Typography>
        <Typography variant={'body2'}>
          To ensure a fair distribution for token holders we reorder your collection based on a provably random number
          generated by{' '}
          <span>
            <a
              href="https://docs.chain.link/vrf/v2/introduction"
              target="_blank"
              rel="noreferrer"
              className={'CAKE__vrf-confirm-modal__link'}
            >
              Chainlink VRF
            </a>
          </span>
          . This number becomes the new starting index for your collection. For example, if your random number is 5 then
          the token data for <code>tokenId 5</code> becomes the new token data for <code>tokenId 0</code>. We meanwhile
          store the data of the orginal ordering of your collection on-chain in a <code>provenanceHash</code> that can
          be used to verify the integrity of the collection.
        </Typography>
        <Typography variant={'body2'}>
          In total, these steps ensure your collection follows best-practices and builds trust in your community.
        </Typography>
        <Typography variant={'body2'}>
          Requesting randomness through Chainlink does require a blockchain transaction so you will be prompted by your
          wallet provider. Additionally,{' '}
          <span>
            <b>Chainlink takes about 30 seconds to 1 minute to fulfill the request</b>
          </span>{' '}
          so expect a small wait period before your collection is revealed.
        </Typography>
        <Typography
          className={'CAKE__vrf-confirm-modal__error'}
          variant="caption"
        >
          {error}
        </Typography>
      </Stack>
      <ComponentLoader isOpen={isContractUpdateProcessing} />
      <WalletConnectModal
        isOpen={isWalletConnect}
        onClose={() => setIsWalletConnect(false)}
      />
    </Modal>
  );
};

export default VRFCollectionConfirmModal;
