import './MenuDrawerMenu.scss';

import { Box } from '@mui/material';
import { useMatch } from 'react-router-dom';

import MenuDrawerMenuButton from '~components/menus/MenuDrawer/components/MenuDrawerMenuButton';
import { selectProjectId } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';

type MenuDrawerMenuProps = {
  small: boolean;
  items: Array<any>;
};
const MenuDrawerMenu = ({ items, small }: MenuDrawerMenuProps) => {
  const projectId = useAppSelector(selectProjectId);

  return (
    <Box className={'CAKE__menu-drawer-menu'}>
      {items.map((item) => (
        <MenuDrawerMenuButton
          key={item.title}
          title={item.title}
          link={item.link(projectId)}
          icon={item.icon}
          isActive={Boolean(useMatch(item.linkPattern))}
          small={small}
        />
      ))}
    </Box>
  );
};

export default MenuDrawerMenu;
