import { Grid } from '@mui/material';

import DashboardCard from '~components/dashboard/DashboardCard';
import ImageDropzone from '~components/inputs/ImageDropzone';
import TokenPlaceholders from '~constants/tokens/token-placeholder';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectGallery } from '~features/project-config/project-config.selectors';
import { deleteGalleryImage, uploadGalleryImage } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { ProjectGalleryType } from '~types/ProjectType';

const GallerySettingsCard = () => {
  const dispatch = useAppDispatch();
  const projectGallery: ProjectGalleryType = useAppSelector(selectProjectGallery);

  const onDropGalleryImage = (files: Array<File>) => {
    dispatch(uploadGalleryImage({ file: files[0], key: `project-gallery-${Math.floor(1000 * Math.random())}` }));
  };

  const onUploadError = (message: string) => dispatch(addNotification({ severity: 'error', message, duration: 3000 }));

  const onDeleteGalleryImage = (index: number) => {
    dispatch(deleteGalleryImage(index));
  };

  return (
    <DashboardCard
      title={'Tutorial Image'}
      subtitle={
        'Please upload a brand icon or image which the user will be presented with for their onboarding tutorial.'
      }
    >
      <Grid
        container
        gap={'20px'}
      >
        <Grid item>
          <ImageDropzone
            onDropFile={onDropGalleryImage}
            onError={onUploadError}
            title={'New image'}
            sizeRecommendation={'960px x 960px'}
            image={projectGallery.gallery.length ? projectGallery.gallery[0] : TokenPlaceholders.TOKEN}
          />
        </Grid>
        {/* {projectGallery.gallery.length ? projectGallery.gallery.map((item: string, idx: number) => (
          <Grid
            item
            key={`gallery-image-${item.split('/').reverse()[0].split('.')[0]}`}
          >
            <GalleryImage
              url={item}
              onDelete={() => onDeleteGalleryImage(idx)}
            />
          </Grid>
        )) */}
      </Grid>
    </DashboardCard>
  );
};

export default GallerySettingsCard;
