import '~components/inputs/BooleanSlider/BooleanSlider.scss';

import { Stack, Switch } from '@mui/material';
import classNames from 'classnames';
import type { ChangeEvent } from 'react';

type BooleanSliderProps = {
  dense?: boolean;
  isChecked: boolean;
  title?: string;
  onUpdate: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const BooleanSlider = ({ dense = false, isChecked, title, onUpdate }: BooleanSliderProps) => {
  return (
    <Stack className={'CAKE__boolean-slider-field'}>
      {!dense && (
        <span
          className={classNames({
            'CAKE__boolean-slider-field__title': true,
          })}
        >
          {title}
        </span>
      )}
      <Stack
        className={classNames({
          'CAKE__boolean-slider-field__input-container': true,
          'CAKE__boolean-slider-field__input-container--dense': dense,
        })}
      >
        <Switch
          checked={isChecked}
          onChange={onUpdate}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
    </Stack>
  );
};

export default BooleanSlider;
