import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import { ContractNetwork, NetworkLayer } from '~constants/networks/networks';
import { BaseIcon } from '~icons/common';
import type { ProjectType } from '~types/ProjectType';
import useDebounce from '~utils/hooks/useDebounce';
import getProjectNetworkLayer from '~utils/projects/get-network-layer';

type ProjectBasicInfoFormProps = {
  config: ProjectType;
  onConfigUpdated: (config: ProjectType) => void;
};

const ProjectBasicInfoForm = ({ config, onConfigUpdated }: ProjectBasicInfoFormProps) => {
  const [name, setName] = useState<string>(config.name || '');
  const [symbol, setSymbol] = useState<string>(config.contract.symbol || '');
  const [supply, setSupply] = useState<string>(String(config.contract.totalSupply || ''));
  const [price, setPrice] = useState<string>(String(config.contract.price || ''));
  const [networkLayer, setNetworkLayer] = useState<NetworkLayer>(getProjectNetworkLayer(config));
  const [testNetwork, setTestNetwork] = useState<ContractNetwork>(config.contract.testnet.network);
  const [liveNetwork, setLiveNetwork] = useState<ContractNetwork>(config.contract.livenet.network);

  const handleNetworkChange = (event: SelectChangeEvent) => {
    switch (event.target.value as NetworkLayer) {
      case NetworkLayer.BASE:
        setNetworkLayer(NetworkLayer.BASE);
        setTestNetwork(ContractNetwork.BASE_SEPOLIA);
        setLiveNetwork(ContractNetwork.BASE_MAINNET);
        break;
      case NetworkLayer.ETH:
        setNetworkLayer(NetworkLayer.ETH);
        setTestNetwork(ContractNetwork.SEPOLIA);
        setLiveNetwork(ContractNetwork.MAINNET);
        break;
    }
  };

  const updateConfig = () => {
    onConfigUpdated({
      ...config,
      name,
      contract: {
        ...config.contract,
        symbol,
        totalSupply: Number(supply),
        price: Number(price),
        testnet: {
          ...config.contract.testnet,
          network: testNetwork,
        },
        livenet: {
          ...config.contract.livenet,
          network: liveNetwork,
        },
      },
    });
  };

  useDebounce(updateConfig, [name, symbol, supply, price, testNetwork, liveNetwork], 700);

  return (
    <Stack spacing={2}>
      <V2TextInput
        label="Project Title"
        variant="standard"
        color={'primary'}
        value={name}
        fullWidth
        required
        onUpdate={setName}
        validator={(value) => value.length > 2}
        showSuccessIcon
      />

      <Stack
        direction={'row'}
        spacing={2}
      >
        <V2TextInput
          label="Project Symbol"
          variant="standard"
          color={'primary'}
          value={symbol}
          onUpdate={setSymbol}
        />

        <V2TextInput
          label="Total supply"
          variant="standard"
          color={'primary'}
          type="number"
          value={supply}
          onUpdate={setSupply}
        />

        <V2TextInput
          label="ETH Price"
          variant="standard"
          color={'primary'}
          type="number"
          value={price}
          onUpdate={setPrice}
        />
      </Stack>
      <Stack width={'50%'}>
        <FormControl variant="standard">
          <InputLabel id="chain-label">Chain</InputLabel>
          <Select
            labelId="chain-label"
            label="Chain"
            value={networkLayer}
            onChange={handleNetworkChange}
          >
            {Object.values(NetworkLayer).map((type: string) => {
              if (type === NetworkLayer.BASE) {
                return (
                  <MenuItem
                    key={type}
                    value={type}
                  >
                    <Stack
                      direction={'row'}
                      spacing={1}
                    >
                      {/* {type === NetworkLayer.ETH ? (
                        <EthIcon
                          width={'24px'}
                          height={'24px'}
                        />
                      ) : ( */}
                      <BaseIcon
                        width={'24px'}
                        height={'24px'}
                      />
                      {/* )
                      } */}
                      <Typography>{type}</Typography>
                    </Stack>
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default ProjectBasicInfoForm;
