import Web3 from 'web3';

import { REQUEST_FULFILLED_ABI } from '~features/vrf/vrf.constants';
import type { TransactionLog } from '~types/Web3Type';

const getWeb3Provider = () => {
  return new Web3(`${process.env.REACT_APP_ETHEREUM_HTTPS}` || '');
};

const getTransactionLogs = async (txnHash: string, provider: any) => {
  const web3 = new Web3(provider);
  return await web3.eth.getTransactionReceipt(txnHash);
};

const decodeTransactionLogData = (log: TransactionLog, inputs: Array<any>, provider: any) => {
  const web3 = new Web3(provider);
  return web3.eth.abi.decodeLog(inputs, log.data, log.topics);
};

const getEthTransactionParams = async (
  provider: any,
  walletAddress: string,
  contractAddress: string,
  abi: any,
  chainId: string,
  args: Array<any>,
) => {
  const web3 = new Web3(provider);
  const encodedFunctionCall = web3.eth.abi.encodeFunctionCall(abi, args);
  const gasEstimate = await web3.eth.estimateGas({
    from: walletAddress,
    to: contractAddress,
    data: encodedFunctionCall,
    value: '0x00',
  });

  return {
    nonce: '', // ignored by MetaMask
    gasPrice: '', // customizable by user during MetaMask confirmation.
    gas: `0x${gasEstimate.toString(16)}`, // customizable by user during MetaMask confirmation.
    to: contractAddress, // Required except during contract publications.
    from: walletAddress, // must match user's active address.
    data: encodedFunctionCall, // Optional, but used for defining smart contract creation and interaction.
    value: '0x00',
    chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
  };
};

const confirmTransactions = (provider: any, txnHashArray: Array<string>) => {
  return Promise.all(
    txnHashArray.map(async (txnHash: string) => {
      const { status } = await getTransactionConfirmation(provider, txnHash);
      return { txnHash, status };
    }),
  );
};

const getTransactionConfirmation = (provider: any, txnHash: string): Promise<{ status: string }> => {
  // see: https://ethereum.stackexchange.com/questions/67232/how-to-wait-until-transaction-is-confirmed-web3-js

  const web3 = new Web3(provider);

  return new Promise((resolve) => {
    let interval;

    try {
      interval = setInterval(async () => {
        await web3.eth.getTransactionReceipt(txnHash, (error, receipt) => {
          if (error) {
            resolve({ status: 'error' });
            clearInterval(interval);
          }
          if (receipt && receipt.status) {
            clearInterval(interval);
            console.log({ status: 'success' });
            resolve({ status: 'success' });
          }
          if (receipt && receipt.status === false) {
            console.log(receipt);
            console.log({ status: 'fail' });
            clearInterval(interval);
            resolve({ status: 'fail' });
          }
        });
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  });
};

const pollEventLog = (address: string, topics: Array<string>, provider: any, filter: any): Promise<any> => {
  const web3 = new Web3(provider);
  let iterations = 40;

  return new Promise((resolve, reject) => {
    let interval;

    try {
      interval = setInterval(async () => {
        await web3.eth.getPastLogs({ fromBlock: '0x0', address, topics }, async (error: any, data) => {
          if (error) {
            if (error?.code === -32603) {
              console.log('waiting for connection');
              await new Promise((r) => setTimeout(r, 10000));
            } else {
              reject();
              clearInterval(interval);
            }
          }
          if (data.length) {
            const log = data.find(
              (log: TransactionLog) =>
                decodeTransactionLogData(log, REQUEST_FULFILLED_ABI, provider).requestId === filter,
            );
            if (log) {
              clearInterval(interval);
              resolve(log);
            }
          }
        });
        iterations--;
        if (!iterations) {
          clearInterval(interval);
          reject();
        }
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  });
};

export {
  confirmTransactions,
  decodeTransactionLogData,
  getEthTransactionParams,
  getTransactionLogs,
  getWeb3Provider,
  pollEventLog,
};
