import type { RouteType } from '~constants/routes/Routes.types';
import RoutesPageLayout from '~constants/routes/RoutesPageLayout';
import BrowserNotSupportedView from '~views/BrowserNotSupportedView';
import InvitationView from '~views/user/InvitationView';
import LoginView from '~views/user/LoginView';
import SignupView from '~views/user/SignupView';

const PublicRoutes = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  INVITATION: '/invitation',
  BROWSER_NOT_SUPPORTED: '/browser-not-supported',
};

const publicRoutes: Array<RouteType> = [
  {
    path: PublicRoutes.LOGIN,
    Component: LoginView,
    LayoutComponent: RoutesPageLayout.AUTH_LAYOUT,
  },
  {
    path: PublicRoutes.SIGNUP,
    Component: SignupView,
    LayoutComponent: RoutesPageLayout.AUTH_LAYOUT,
  },
  {
    path: PublicRoutes.INVITATION,
    Component: InvitationView,
    LayoutComponent: RoutesPageLayout.PUBLIC_PAGE_LAYOUT,
  },
  {
    path: PublicRoutes.BROWSER_NOT_SUPPORTED,
    Component: BrowserNotSupportedView,
    LayoutComponent: RoutesPageLayout.PUBLIC_PAGE_LAYOUT,
  },
];

export default publicRoutes;
export { PublicRoutes };
