import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';
import type CollectionItemType from '~types/CollectionItemType';
import type AggregateTraitType from '~types/token/AggregateTraitType';

const selectCollectionTokensMap = (state: RootState) => state.collectionItems.tokens;

const selectCollectionTokenOrder = (state: RootState) => state.collectionItems.tokensOrder;

const selectCollectionTokensSelection = (state: RootState) => state.collectionItems.selection;

const selectCollectionIsPageLoading = (state: RootState) => state.collectionItems.isPageLoading;

const selectCollectionIsNextPage = (state: RootState) => state.collectionItems.isNextPage;

const selectCollectionPageLoaded = (state: RootState) => state.collectionItems.pageLoaded;

const selectCollectionTokensIsSelection = createDraftSafeSelector(
  selectCollectionTokensSelection,
  (tokens: { [name: string]: string }) => Object.keys(tokens).length > 0,
);

const selectCollectionTokens = createDraftSafeSelector(
  selectCollectionTokensMap,
  selectCollectionTokenOrder,
  (map: { [name: string]: CollectionItemType }, order: Array<string>) => order.map((item: string) => map[item]),
);

const selectCollectionTraitsAggregated = (state: RootState) => state.collectionItems.traits;

const selectCollectionTraitTypes = createDraftSafeSelector(
  selectCollectionTraitsAggregated,
  (aggregatedTraits: Array<AggregateTraitType>) => aggregatedTraits.map((item: AggregateTraitType) => item.trait_type),
);

const selectStartingIndexIsUpdating = (state: RootState) => state.collectionItems.isLoading;

const selectTotalTokens = createDraftSafeSelector(
  selectCollectionTokensMap,
  selectCollectionTokenOrder,
  (map: { [name: string]: CollectionItemType }, order: Array<string>) => order.map((item: string) => map[item]).length,
);

export {
  selectCollectionIsNextPage,
  selectCollectionIsPageLoading,
  selectCollectionPageLoaded,
  selectCollectionTokens,
  selectCollectionTokensIsSelection,
  selectCollectionTokensMap,
  selectCollectionTokensSelection,
  selectCollectionTraitsAggregated,
  selectCollectionTraitTypes,
  selectStartingIndexIsUpdating,
  selectTotalTokens,
};
