import { Stack } from '@mui/material';
import { useState } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import { FileUploadKey } from '~components/inputs/FileUploadInput';
import ImageDropzone from '~components/inputs/ImageDropzone';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectDappConfig, selectUploadStatus } from '~features/project-config/project-config.selectors';
import { deleteBrandingImage, uploadImage } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { UploadStatus } from '~types/miscs/UploadStatus';
import type { ImageBrandType } from '~types/ProjectType';

const BrandingSettingsCard = () => {
  const dispatch = useAppDispatch();
  const [isLightMode] = useState<boolean>(false);
  const { backgroundImage, logo, customLoader } = useAppSelector(selectProjectDappConfig);
  const uploadStatuses = useAppSelector(selectUploadStatus);
  const settings = useAppSelector(selectProjectDappConfig);

  const onUploadFile = (files: Array<File>, key: FileUploadKey) => {
    dispatch(uploadImage({ file: files[0], key }));
  };

  const onUploadError = (message: string) => dispatch(addNotification({ severity: 'error', message, duration: 3000 }));

  const onDeleteFile = (brandImageType: ImageBrandType) => {
    dispatch(deleteBrandingImage(brandImageType));
  };

  return (
    <DashboardCard
      title={'Branding'}
      subtitle={'Please select your brand images.'}
    >
      <Stack
        flexDirection={'row'}
        gap={'20px'}
      >
        <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_BACKGROUND_LOGO : FileUploadKey.DARK_BACKGROUND_LOGO)
          }
          onError={onUploadError}
          title={'Background Image'}
          image={backgroundImage[isLightMode ? 'light' : 'dark']}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_BACKGROUND_LOGO : FileUploadKey.DARK_BACKGROUND_LOGO] ===
            UploadStatus.LOADING
          }
          onDelete={() => onDeleteFile('BACKGROUND')}
        />
        <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_PRIMARY_LOGO : FileUploadKey.DARK_PRIMARY_LOGO)
          }
          onError={onUploadError}
          title={'Primary logo'}
          sizeRecommendation={'256px x 256px'}
          image={logo[isLightMode ? 'light' : 'dark'].primary}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_PRIMARY_LOGO : FileUploadKey.DARK_PRIMARY_LOGO] ===
            UploadStatus.LOADING
          }
          onDelete={() => onDeleteFile('PRIMARY_LOGO')}
        />
        <ImageDropzone
          title={'Favicon'}
          sizeRecommendation={'64px x 64px'}
          onDropFile={(files: Array<File>) => onUploadFile(files, FileUploadKey.FAVICON)}
          onError={onUploadError}
          image={settings?.favicon || ''}
          isUploading={uploadStatuses[FileUploadKey.FAVICON] === UploadStatus.LOADING}
        />
        {/* <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_SECONDARY_LOGO : FileUploadKey.DARK_SECONDARY_LOGO)
          }
          onError={onUploadError}
          title={'Secondary logo'}
          sizeRecommendation={'256px x 256px'}
          image={logo[isLightMode ? 'light' : 'dark'].secondary}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_SECONDARY_LOGO : FileUploadKey.DARK_SECONDARY_LOGO] ===
            UploadStatus.LOADING
          }
          onDelete={() => onDeleteFile('SECONDARY_LOGO')}
        /> */}
        {/* <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_TITLE_LOGO : FileUploadKey.DARK_TITLE_LOGO)
          }
          onError={onUploadError}
          title={'Title logo'}
          sizeRecommendation={'1080px x 140px'}
          image={logo[isLightMode ? 'light' : 'dark'].title}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_TITLE_LOGO : FileUploadKey.DARK_TITLE_LOGO] ===
            UploadStatus.LOADING
          }
          onDelete={() => onDeleteFile('TITLE')}
        /> */}
        {/* <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_CUSTOM_LOADER : FileUploadKey.DARK_CUSTOM_LOADER)
          }
          onError={onUploadError}
          title={'Custom Loader'}
          sizeRecommendation={'256px x 256px'}
          image={customLoader?.[isLightMode ? 'light' : 'dark']}
          inputProps={{ accept: 'image/gif' }}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_CUSTOM_LOADER : FileUploadKey.DARK_CUSTOM_LOADER] ===
            UploadStatus.LOADING
          }
          onDelete={() => onDeleteFile('LOADER')}
        /> */}
      </Stack>
    </DashboardCard>
  );
};

export default BrandingSettingsCard;
