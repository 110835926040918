import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { ProjectCollectionType } from '~constants/project/project-constants';
import { deleteAllCollectionItems } from '~features/collection-items/collection-items.slice';
import { updateProjectSaga } from '~features/project-config/project-config.sagas';
import { selectProjectCollectionId, selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import {
  updateBlueprint,
  updateBlueprintFailed,
  updateBlueprintSuccess,
} from '~features/token-blueprints/token-blueprints.slice';
import {
  deleteProtectedAPI,
  fetchProtectedAPI,
  postProtectedAPI,
  putProtectedAPI,
} from '~features/utils/api/api.sagas';
import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';
import type TokenBlueprintType from '~types/token/TokenBlueprintType';

import {
  addTokenGroup,
  addTokenGroupFailed,
  addTokenGroupSuccess,
  getTokenGroups,
  getTokenGroupsFailed,
  getTokenGroupsSuccess,
  removeTokenGroup,
  removeTokenGroupFailed,
  removeTokenGroupSuccess,
  updateTokenGroup,
  updateTokenGroupFailed,
  updateTokenGroupSuccess,
} from './collection-token-groups.slice';

function* addTokenGroupSaga(action: PayloadAction<Partial<CollectionTokenGroupType>>) {
  const tokenGroup = action.payload;
  const collectionId = yield select(selectProjectCollectionId);
  const projectConfig = yield select(selectProjectConfig);

  const res: Response = yield call(postProtectedAPI, `collection/${collectionId}/groups`, tokenGroup);

  try {
    const data = yield res.json();

    if (res.status !== 201) {
      yield put(addTokenGroupFailed(data.message));
    } else {
      yield put(addTokenGroupSuccess(data));
      yield call(
        updateProjectSaga,
        updateProjectConfig({
          ...projectConfig,
          collectionType: ProjectCollectionType.GROUP,
          contract: {
            ...projectConfig.contract,
            provenanceHash: '',
          },
        }),
      );
      if (projectConfig.collectionType === ProjectCollectionType.CUSTOM) {
        yield put(deleteAllCollectionItems());
      }
    }
  } catch (e) {
    yield put(addTokenGroupFailed(e.message));
  }
}

function* fetchTokenGroupsSaga() {
  const collectionId = yield select(selectProjectCollectionId);

  const res: Response = yield call(fetchProtectedAPI, `collection/${collectionId}/groups`);

  try {
    const data = yield res.json();

    if (res.status !== 200) {
      yield put(getTokenGroupsFailed(data.message));
    } else {
      yield put(getTokenGroupsSuccess(data));
    }
  } catch (e) {
    yield put(getTokenGroupsFailed(e.message));
  }
}

function* removeTokenGroupSaga(action: PayloadAction<string>) {
  const tokenGroupId = action.payload;
  const collectionId = yield select(selectProjectCollectionId);

  const res: Response = yield call(deleteProtectedAPI, `collection/${collectionId}/groups/${tokenGroupId}`);

  try {
    if (res.status !== 204) {
      const data = yield res.json();
      yield put(removeTokenGroupFailed(data.message));
    } else {
      yield put(removeTokenGroupSuccess(tokenGroupId));
    }
  } catch (e) {
    yield put(removeTokenGroupFailed(e.message));
  }
}

function* updateTokenGroupSaga(
  action: PayloadAction<{ tokenGroup: CollectionTokenGroupType; tokenBlueprint: TokenBlueprintType }>,
) {
  const { tokenGroup, tokenBlueprint } = action.payload;

  yield put(updateBlueprint(tokenBlueprint));
  const result = yield take([updateBlueprintSuccess.type, updateBlueprintFailed.type]);

  if (result.type === updateBlueprintFailed.type) {
    yield put(updateTokenGroupFailed('blueprint update failed'));
    return;
  }
  const collectionId = yield select(selectProjectCollectionId);
  const res: Response = yield call(putProtectedAPI, `collection/${collectionId}/groups/${tokenGroup._id}`, tokenGroup);

  try {
    const data = yield res.json();
    if (res.status !== 200) {
      yield put(updateTokenGroupFailed(data.message));
    } else {
      yield put(updateTokenGroupSuccess(data));
    }
  } catch (e) {
    yield put(removeTokenGroupFailed(e.message));
  }
}

export default function* collectionTokenGroupsSaga() {
  yield all([
    yield takeLatest(addTokenGroup.type, addTokenGroupSaga),
    yield takeLatest(getTokenGroups.type, fetchTokenGroupsSaga),
    yield takeLatest(removeTokenGroup.type, removeTokenGroupSaga),
    yield takeLatest(updateTokenGroup.type, updateTokenGroupSaga),
  ]);
}

export { removeTokenGroupSaga };
