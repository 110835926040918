import './StatusChip.scss';

import { Box } from '@mui/material';

type Props = {
  status: string;
};

const StatusChip = ({ status }: Props) => (
  <Box className={'CAKE__status-chip'}>
    <p>{status}</p>
  </Box>
);

export default StatusChip;
