import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import ContractBasicInfoForm from '~components/dashboard/ContractDeploymentCard/components/ContractBasicInfoForm';
import DeployButton from '~components/dashboard/ContractDeploymentCard/components/DeployButton';
import ViewContractButton from '~components/dashboard/ContractDeploymentCard/components/ViewContractButton';
import ContractMigrationAlert from '~components/dashboard/ContractMigrationAlert';
import ContractUpdateAlert from '~components/dashboard/ContractUpdateAlert';
import ContractUpgradeAlert from '~components/dashboard/ContractUpgradeAlert';
import DashboardCard from '~components/dashboard/DashboardCard';
import ComponentLoader from '~components/miscs/ComponentLoader';
import ProjectHeader from '~components/projects/ProjectHeader';
import type { ContractNetwork } from '~constants/networks/networks';
import { PROD_NETWORKS } from '~constants/networks/networks';
import { getCollection } from '~features/collection-items/collection-items.slice';
import {
  selectDeployedContractStateMatch,
  selectIsContractUpgrade,
  selectUpgradeCheckIsProcessing,
} from '~features/contract-config/contract-config.selectors';
import {
  callContractUpgradeState,
  callDeployedContractState,
  resetContractConfig,
} from '~features/contract-config/contract-config.slice';
import { selectContractDeployerIsLoading } from '~features/contract-deployer/contract-deployer.selectors';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { ProjectType } from '~types/ProjectType';
import { getContractMigrationStatus } from '~utils/contracts/contract-migration-status';
import getCurrentContractNetwork from '~utils/contracts/get-current-contract-network';

import ContractSummaryInfo from './components/ContractSummaryInfo';

type ContractDeploymentCardProps = {
  projectId: string;
};

const ContractDeploymentCard = ({ projectId }: ContractDeploymentCardProps) => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const isDeploying = useAppSelector(selectContractDeployerIsLoading);
  const waitingForUpgradeResponse = useAppSelector(selectUpgradeCheckIsProcessing);
  const isUpgradeNeeded = useAppSelector(selectIsContractUpgrade);
  const isMatch = useAppSelector(selectDeployedContractStateMatch);
  const { contract } = projectConfig;
  const [network, setNetwork] = useState<string>('');
  const [isLive, setIsLive] = useState<boolean>(false); // value representing if contract deployed to mainnet
  const [isMigration, setIsMigration] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCollection());
    return () => {
      dispatch(resetContractConfig());
    };
  }, []);

  useEffect(() => {
    if (network) dispatch(callDeployedContractState({ projectId, network }));
  }, [network]);

  useEffect(() => {
    dispatch(callContractUpgradeState());
  }, []);

  useEffect(() => {
    const contractMigrationStatus: { isMigration: boolean; network: string } | undefined =
      getContractMigrationStatus(projectConfig);
    if (contractMigrationStatus) {
      setIsMigration(contractMigrationStatus.isMigration);
    } else {
      setIsMigration(false);
    }
  }, [isDeploying]);

  useEffect(() => {
    const contractMigrationStatus: { isMigration: boolean; network: string } | undefined =
      getContractMigrationStatus(projectConfig);
    if (contractMigrationStatus) {
      setIsMigration(contractMigrationStatus.isMigration);
      setNetwork(contractMigrationStatus.network);
    } else {
      setIsMigration(false);
    }
  }, [isDeploying]);

  useEffect(() => {
    const network: ContractNetwork = getCurrentContractNetwork(contract);
    setNetwork(network);
    setIsLive(PROD_NETWORKS.includes(network) ? true : false);
  }, [isDeploying, contract]);

  const onProjectConfigUpdated = (config: ProjectType) => {
    dispatch(updateProjectConfig(config));
  };

  const handleDisabledUpdate = (message: string) => {
    dispatch(
      addNotification({
        message,
        severity: 'error',
        duration: 3000,
      }),
    );
  };

  return (
    <Stack>
      <DashboardCard
        className={classNames({
          'CAKE__contract-deployment-card': true,
          'CAKE__contract-deployment-card__controls-container-padding':
            (!isMatch && !waitingForUpgradeResponse) || isMigration || isUpgradeNeeded,
        })}
        containerClassName={'CAKE__contract-deployment-card__container'}
      >
        <Stack
          direction={'row'}
          className={'CAKE__contract-deployment-card__title'}
        >
          <ProjectHeader subtitle="Contract Manager">
            <Stack
              direction={'row'}
              gap={2}
            >
              <ViewContractButton network={network} />
            </Stack>
          </ProjectHeader>
        </Stack>

        <Box className={'CAKE__contract-deployment-card__controls-container'}>
          <Box>
            <Stack
              className={'CAKE__contract-deployment-card__controls-title'}
              direction={'row'}
              gap={1}
            >
              <Typography variant={'h6'}>Contract Settings</Typography>
              {isMigration && <ContractMigrationAlert />}
              {!isMigration && isUpgradeNeeded && <ContractUpgradeAlert />}
              {!isMigration && !isUpgradeNeeded && !isMatch && <ContractUpdateAlert />}
              {!isMigration && !isUpgradeNeeded && isMatch && (
                <Tooltip
                  title="Your contract does not require any updates"
                  placement="right"
                  arrow
                >
                  <CheckCircleIcon color={'success'} />
                </Tooltip>
              )}
            </Stack>
            <Typography variant={'body2'}>Configure and deploy your NFT smart contract</Typography>
          </Box>
        </Box>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <ContractSummaryInfo onConfigUpdated={onProjectConfigUpdated} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <ContractBasicInfoForm
              config={projectConfig}
              isLive={isLive}
              network={network}
              handleDisabledUpdate={handleDisabledUpdate}
              onConfigUpdated={onProjectConfigUpdated}
            />
          </Grid>
          {!isMigration && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ marginTop: '20px' }}
            >
              <DeployButton />
            </Grid>
          )}
        </Grid>
        <ComponentLoader isOpen={isDeploying} />
      </DashboardCard>
    </Stack>
  );
};

export default ContractDeploymentCard;
