import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import moment from 'moment';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  selectCumulativeTransferData,
  selectCumulativeTransferResolution,
  selectTransferData,
  selectWalletHolders,
  selectWalletHoldersData,
} from '~features/analytics/analytics.selectors';
import {
  getEligibleTokens,
  getTotalSupply,
  getTransferData,
  getWalletHolders,
  processNewTransfer,
  resetWalletHolders,
  setCumulativeTransferData,
  setEligibleTokens,
  setTotalEventsCount,
  setTotalMonthlyEventsCount,
  setTotalSupply,
  setTransferData,
  setWalletHolders,
} from '~features/analytics/analytics.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { fetchProtectedAPI, postProtectedAPI } from '~features/utils/api/api.sagas';
import type { CumulativeTransferData, TokenHolder, TransferDataType } from '~types/AnalyticsType';
import { ChartResolution } from '~types/ChartResolution';
import convertWeiToEth from '~utils/web3/conversion/eth-converter';

import { computeCumulativeTransferData, getDateFormatForResolution } from './analytics.helpers';

window.Buffer = window.Buffer || Buffer;

function* fetchTransferDataSaga(
  action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
): Iterator<any> {
  const { projectId, contractAddress, refresh } = action.payload;
  const currentTransferData: TransferDataType[] = yield select(selectTransferData);

  if (!currentTransferData.length || refresh) {
    try {
      const transfersResponse: Response = yield call(
        fetchProtectedAPI,
        `analytics/${projectId}/transfers?contractAddress=${contractAddress}`,
      );
      const transfers: Array<TransferDataType> = yield transfersResponse.json();

      if (transfers.length > 0) {
        const eventsResponse: Response = yield call(
          fetchProtectedAPI,
          `analytics/${projectId}/events?contractAddress=${contractAddress}`,
        );

        const events: any[] = yield eventsResponse.json();
        const monthlyEvents: number = events.filter((event) =>
          moment(event.date).isAfter(moment().subtract(30, 'day')),
        ).length;
        yield put(setTotalEventsCount(events.length));
        yield put(setTotalMonthlyEventsCount(monthlyEvents));

        const cumulativeTransferData = yield computeCumulativeTransferData(transfers, events);
        yield put(setCumulativeTransferData(cumulativeTransferData));

        transfers.sort((a, b) => {
          return moment(b.date).diff(moment(a.date));
        });
        yield put(setTransferData(transfers));
      } else {
        yield put(setTransferData([]));
      }
    } catch (e) {
      console.log(e);
      yield put(
        addNotification({
          message: 'We encountered an error trying to update your data.',
          severity: 'error',
          duration: 5000,
        }),
      );
    }
  }
}

function* fetchWalletHoldersSaga(
  action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
): Iterator<any> {
  const { projectId, contractAddress, refresh } = action.payload;
  const currentTopWalletHolderData: TokenHolder[] = yield select(selectWalletHolders);

  if (!currentTopWalletHolderData.length || refresh) {
    try {
      const res: Response = yield call(
        fetchProtectedAPI,
        `analytics/${projectId}/wallets?contractAddress=${contractAddress}`,
      );
      const data: { wallets: Array<TokenHolder>; walletsPast: Array<TokenHolder> } = yield res.json();

      data.wallets.sort((a, b) => {
        return moment(b.date).diff(moment(a.date));
      });

      if (data.wallets.length > 0) {
        const { holders, order } = data.wallets.reduce(
          (acc, item) => {
            acc.holders[item.walletAddress] = item;
            acc.order.push(item.walletAddress);
            return acc;
          },
          { holders: {}, order: [] },
        );
        yield put(setWalletHolders({ holders, order, trailingWalletCount: data.walletsPast.length }));
      } else {
        yield put(resetWalletHolders());
      }
    } catch (e) {
      console.log(e);
      yield put(resetWalletHolders());
    }
  }
}

function* appendTransferLogSaga(transfer: TransferDataType): Iterator<any> {
  const currentTransferData: TransferDataType[] = yield select(selectTransferData);
  yield put(setTransferData([transfer, ...currentTransferData]));
}

function* appendWalletHoldersSaga(transfer: TransferDataType): Iterator<any> {
  const data: { holders: Record<string, TokenHolder>; order: string[]; trailingWalletCount: number } = yield select(
    selectWalletHoldersData,
  );
  const holders = { ...data.holders };
  const order = [...data.order];
  if (holders[transfer.to]) {
    holders[transfer.to] = {
      ...holders[transfer.to],
      count: holders[transfer.to].count + 1,
    };
  } else {
    holders[transfer.to] = {
      walletAddress: transfer.to,
      count: 1,
      contractAddress: transfer.contractAddress,
      date: transfer.date,
    };
    order.push(transfer.to);
  }
  if (holders[transfer.from]) {
    holders[transfer.from] = {
      ...holders[transfer.from],
      count: holders[transfer.from].count - 1,
    };
  }

  yield put(setWalletHolders({ holders, order, trailingWalletCount: data.trailingWalletCount }));
}

function* appendCumulativeTransferDataSaga(transfer: TransferDataType): Iterator<any> {
  console.log('appendCumulativeTransferDataSaga');
  const cumulativeData: CumulativeTransferData[] = yield select(selectCumulativeTransferData);
  const data = [...cumulativeData];
  const resolution = yield select(selectCumulativeTransferResolution);
  const transferDate = moment(transfer.date);

  const dateFormat = getDateFormatForResolution(resolution);

  if (!data) {
    return;
  }

  if (resolution === ChartResolution.Day) {
    transferDate.hours(0);
  }
  transferDate.minutes(0);

  if (data.length > 0 && transferDate.diff(moment(data[data.length - 1].x), 'days') === 0) {
    data[data.length - 1] = {
      ...data[data.length - 1],
      volume: data[data.length - 1].volume + 1,
      y: `${(
        parseFloat(data[data.length - 1].y.toString().split('ETH')[0]) + parseFloat(convertWeiToEth(transfer.value))
      ).toFixed(3)} ETH`,
    };
  } else {
    data.push({
      volume: 1,
      x: transferDate.format(dateFormat),
      y: parseFloat(convertWeiToEth(transfer.value)),
    });
  }
  yield put(setCumulativeTransferData({ data, resolution }));
}

function* processNewTransferSaga(action: PayloadAction<TransferDataType>) {
  const transfer = action.payload;

  yield call(appendTransferLogSaga, transfer);
  yield call(appendWalletHoldersSaga, transfer);
  yield call(appendCumulativeTransferDataSaga, transfer);
}

function* fetchTotalSupplySaga(action: PayloadAction<{ projectId: string; network: string }>): Iterator<any> {
  const { projectId, network } = action.payload;

  try {
    const res: Response = yield call(
      postProtectedAPI,
      `contract/${projectId}/call?name=totalSupply&network=${network}`,
      { args: [] },
    );
    const { data }: { data: number } = yield res.json();
    yield put(setTotalSupply(data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchEligibleTokensSaga(action: PayloadAction<{ projectId: string }>): Iterator<any> {
  const { projectId } = action.payload;

  try {
    const res: Response = yield call(fetchProtectedAPI, `analytics/${projectId}/eligibile-updates`);
    const { data }: { data: number } = yield res.json();
    yield put(setEligibleTokens(data));
  } catch (e) {
    console.log(e);
  }
}

export default function* analyticsSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getTransferData.type, fetchTransferDataSaga),
    yield takeLatest(getWalletHolders.type, fetchWalletHoldersSaga),
    yield takeEvery(processNewTransfer.type, processNewTransferSaga),
    yield takeLatest(getTotalSupply.type, fetchTotalSupplySaga),
    yield takeLatest(getEligibleTokens.type, fetchEligibleTokensSaga),
  ]);
}
