import type { CollectionTokenGroupType } from '~types/collection/CollectionTokenGroupType';

const getNextTokenGroupName = (projectName: string, tokenGroups: CollectionTokenGroupType[]) => {
  if (tokenGroups.length) {
    const r = /\d+/g;
    const s = tokenGroups[tokenGroups.length - 1].name;

    let lastNumber = '0';
    let m;
    while ((m = r.exec(s)) != null) {
      lastNumber = m[0];
    }
    return `${projectName} #${parseInt(lastNumber) + 1}`;
  } else {
    return `${projectName} #1`;
  }
};

export { getNextTokenGroupName };
