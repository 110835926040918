import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContractDeploymentCard from '~components/dashboard/ContractDeploymentCard';
import PageLayout from '~components/layout/PageLayout';
import PageLoader from '~components/miscs/PageLoader';
import { resetContractConfig } from '~features/contract-config/contract-config.slice';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { getWhitelistConfig } from '~features/whitelist-config/whitelist-config.slice';
import { resetWhitelistConfig } from '~features/whitelist-config/whitelist-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectContractView = () => {
  const dispatch = useAppDispatch();

  const projectConfig = useAppSelector(selectProjectConfig);
  const isLoadingProjectConfig = useAppSelector(selectProjectConfigIsLoading);
  const error = useAppSelector(selectProjectConfigError);
  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
    dispatch(getWhitelistConfig({ projectId, forceUpdate: false }));

    return () => {
      dispatch(resetWhitelistConfig());
      dispatch(resetContractConfig());
    };
  }, []);

  if (isLoadingProjectConfig) {
    return <PageLoader />;
  }

  if (error || projectConfig === null) {
    return <NotFoundView label={error} />;
  }

  return (
    <PageLayout>
      <Box marginRight={8}>
        <Stack>
          <ContractDeploymentCard projectId={projectId} />
          {/* <WhitelistSettingsCard />
          {(projectConfig.contract.testnet.contractAddress || projectConfig.contract.livenet.contractAddress) && (
            <ContractManagementCard />
          )} */}
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default ProjectContractView;
