import './SegmentedButton.scss';

import classNames from 'classnames';

interface SegmentedButtonProps {
  onChange: (value: string) => void;
  options: string[];
  value?: string;
}

const SegmentedButton = ({ onChange, options, value }: SegmentedButtonProps) => {
  return (
    <div className={'CAKE__segmented-button'}>
      {options.map((option) => (
        <div
          className={classNames({
            'CAKE__segmented-button__button': true,
            'CAKE__segmented-button__button--active': option === value,
          })}
          key={option}
          onClick={() => onChange(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default SegmentedButton;
