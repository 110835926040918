import '~components/inputs/FileDropzone/FileDropzone.scss';

import { Stack } from '@mui/system';
import { useDropzone } from 'react-dropzone';

type FileDropzoneProps = {
  title?: string;
  onDropFiles: (files: Array<File>) => void;
};

const FileDropzone = ({ title = 'Drop files here', onDropFiles }: FileDropzoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropFiles });

  return (
    <Stack
      className={'CAKE__file-dropzone'}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>{title}</p>
    </Stack>
  );
};

export default FileDropzone;
