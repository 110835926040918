import { Tooltip } from '@mui/material';
import type { CustomLayerProps } from '@nivo/line';
import { range } from 'd3';
import { scaleBand } from 'd3-scale';

import { eventGradient } from './chart-gradients';

const EventsLayer = (data) => {
  const Component = ({ innerWidth, innerHeight }: CustomLayerProps) => {
    const xScale = scaleBand<number>()
      .domain(range(0, data.length))
      .range([0, innerWidth + innerWidth / (data.length - 1)])
      .padding(0);

    return (
      <g className="events-layer">
        <defs>{eventGradient}</defs>
        {data.map((item, index) => {
          const event = item.events;
          if (!event || Object.values(event).length === 0) {
            return;
          }

          const posX = xScale(index);
          const posY = innerHeight - 206;
          const size = 22;

          const points = `
          ${posX - (2 * size) / 5},${posY}
          ${posX},${posY + size / 2}
          ${posX + (2 * size) / 5},${posY}
          ${posX},${posY - size / 2}
        `;

          return (
            <Tooltip
              key={index}
              title={event.title}
              placement="top"
            >
              <polygon
                key={index}
                className="event"
                fill="url(#eventGradient)"
                points={points}
              />
            </Tooltip>
          );
        })}
      </g>
    );
  };

  Component.displayName = 'EventsLayer';

  return Component;
};

export default EventsLayer;
